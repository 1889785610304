import {
    IAnyObject,
    IInsuranceQuoteApplication_MainDriver,
} from '../../../insuranceQuoteApplication/reducer'
import {
    IClaim_API,
    IInsuranceConviction_API,
    IInsuranceApplication_API,
    IMembership_API,
    InsuranceCustomer_API,
    IInsuranceDisability_API,
    IInsuranceMotoringConviction_API,
    IInsuranceApplication_APIErrors,
    InsuranceCustomer_APIErrors,
    IMembership_APIErrors,
    IClaim_APIErrors,
    IInsuranceConviction_APIErrors,
    IInsuranceDisability_APIErrors,
    IInsuranceMotoringConviction_APIErrors,
} from '../../../insuranceQuoteApplication/types'

import {
    accidental_damage_cost_subform,
    accidental_fire_cost_subform,
    accidental_glass_cost_subform,
    accidental_theft_cost_subform,
    accidental_vandalism_cost_subform,
    disability_self_replicating_fields,
    IInsuranceFieldType,
    insurance_fields_main_driver_additional_details,
    insurance_fields_main_driver_basic_details,
    insurance_fields_main_driver_licence_address,
    licence_number_subfields,
    insurance_fields_main_driver_track_record,
    insurance_fields_main_driver_track_record_special_terms_imposed_subform,
    insurance_fields_track_record_previous_motor_insurance_voided_subform,
    insurance_fields_track_record_previous_motor_terms_cancelled_subform,
    insurance_fields_track_record_previous_motor_terms_refused_subform,
    insurance_sub_fields_other_driver_occupation_secondary,
    insurance_sub_fields_other_driver_track_record_conviction_replicating,
    insurance_sub_fields_other_driver_track_record_motorconviction_replicating,
    insurance_sub_sub_fields_disabilities_rescriction_time,
    membership_self_replicating_fields,
    is_uk_resident_positive_answer_subform,
    is_uk_resident_since_birth_negative_answer_subform,
    resides_in_the_uk_negative_answer_subform,
    self_replicating_claim,
    subquestions_claims_customer,
    third_party_damage_property_cost_subform,
} from '../../../localdata/insuranceLocalData/fieldTypes'
import { getInsuranceFEValue } from './helpers'

const licenseAddressAdditionalFields = [
    // addresses : only put uid
    'is_uk_resident',
    'resides_in_the_uk',
    'residential_address',
    'correspondence_address',
]

const residence_additional_q: IInsuranceFieldType[] = [
    ...is_uk_resident_positive_answer_subform,
    ...is_uk_resident_since_birth_negative_answer_subform,
    ...resides_in_the_uk_negative_answer_subform,
]

// frequency will be filled in the convs for main / other driver

export const additionalSubFieldsClaims: IInsuranceFieldType[] = [
    ...accidental_damage_cost_subform,
    // ...accidental_third_party_injury_cost_subform,
    ...third_party_damage_property_cost_subform,
    ...accidental_fire_cost_subform,
    ...accidental_vandalism_cost_subform,
    ...accidental_glass_cost_subform,
    ...accidental_theft_cost_subform,
]

export const additionalSubFieldsDisabilities: IInsuranceFieldType[] = [
    ...insurance_sub_sub_fields_disabilities_rescriction_time,
]

export const additionalDetailsSubfields: IInsuranceFieldType[] = [
    ...insurance_sub_fields_other_driver_occupation_secondary,
]

export const additionalSubFieldsTrackRecord: IInsuranceFieldType[] = [
    ...insurance_fields_main_driver_track_record_special_terms_imposed_subform,
    ...insurance_fields_track_record_previous_motor_terms_refused_subform,
    ...insurance_fields_track_record_previous_motor_terms_cancelled_subform,
    ...insurance_fields_track_record_previous_motor_insurance_voided_subform,
    ...subquestions_claims_customer,
]

export const insurance_fields_main_driver_licence_address_enriched = [
    ...insurance_fields_main_driver_licence_address,
    ...licence_number_subfields,
]

export const additionalSubFieldsConvictions: string[] = [
    'sentence_start_date',
    'sentence_end_date',
]

export const additionalSubFieldsMotoringConvictionsStrings: string[] = [
    'points_awarded',
    'fine_amount',
    'driving_ban_start_date',
    'driving_ban_end_date',
]

export const isPatchAsInt_insurancefields = ['points_awarded', 'modified_bhp']

export const is_price_insurance_fields = [
    // 'purchased_value',
    'estimated_value',
    'fine_amount',
    'total_cost',
    'accidental_damage_cost',
    'third_party_personal_injury_cost',
    'third_party_property_damage_cost',
    'fire_cost',
    'theft_cost',
    'vandalism_damage_cost',
    'windscreen_damage_cost',
    'voluntary_excess',
]

export const convertCustomerToReducerMainDriver = (
    application: IInsuranceApplication_API
): IInsuranceQuoteApplication_MainDriver => {
    let customer: InsuranceCustomer_API | undefined = application.customer

    let res: IInsuranceQuoteApplication_MainDriver = {
        entities: {
            memberships: [],
            claims: [],
            disabilities: [],
            convictions: [],
            motoring_convictions: [],
        },
    }
    if (customer) {
        let membership_api: IMembership_API[] = customer.memberships ?? []
        let claims_api: IClaim_API[] = customer.claims ?? []
        let disability_api: IClaim_API[] = customer.disabilities ?? []
        let conviction_api: IInsuranceConviction_API[] =
            customer.convictions ?? []
        let motoring_conviction_api: IInsuranceConviction_API[] =
            customer.motoring_convictions ?? []

        res = {
            id: customer.uid,
            completion: 0, // to be calculated below
            basic_details: {},
            basic_details_errors: undefined,
            licence_address: {},
            licence_address_errors: undefined,
            additional_details: {},
            additional_details_errors: undefined,
            track_record: {},
            track_record_errors: undefined,
            entities: {
                memberships: [],
                claims: [],
                disabilities: [],
                convictions: [],
                motoring_convictions: [],
            },
        }

        //  basic details section
        for (
            let i = 0;
            i < insurance_fields_main_driver_basic_details.length;
            i++
        ) {
            let field_id = insurance_fields_main_driver_basic_details[i].id
            res.basic_details = {
                ...res.basic_details,
                [field_id]: getInsuranceFEValue(
                    insurance_fields_main_driver_basic_details[i],
                    //@ts-ignore
                    customer[field_id]
                ),
            }
        }

        for (let a = 0; a < licenseAddressAdditionalFields.length; a++) {
            res.licence_address = {
                ...res.licence_address,
                [licenseAddressAdditionalFields[a]]:
                    // @ts-ignore
                    customer[licenseAddressAdditionalFields[a]]?.uid,
            }
        }

        // residence_additional_q

        for (let a = 0; a < residence_additional_q.length; a++) {
            res.licence_address = {
                ...res.licence_address,
                [residence_additional_q[a].id]: getInsuranceFEValue(
                    residence_additional_q[a],
                    // @ts-ignore
                    customer[residence_additional_q[a].id]
                ),
            }
        }

        //  licence address section
        for (
            let i = 0;
            i < insurance_fields_main_driver_licence_address_enriched.length;
            i++
        ) {
            let field_id =
                insurance_fields_main_driver_licence_address_enriched[i].id
            res.licence_address = {
                ...res.licence_address,
                [field_id]: getInsuranceFEValue(
                    insurance_fields_main_driver_licence_address_enriched[i],
                    // @ts-ignore
                    customer[field_id]
                ),
            }
        }

        //  additional details section
        for (
            let i = 0;
            i < insurance_fields_main_driver_additional_details.length;
            i++
        ) {
            let field_id = insurance_fields_main_driver_additional_details[i].id
            if (
                field_id === 'family_vehicles_count' ||
                field_id === 'vehicles_driven_count' ||
                field_id === 'vehicles_owned_count'
            ) {
                //@ts-ignore
                res.additional_details[field_id] = getInsuranceFEValue(
                    insurance_fields_main_driver_additional_details[i],
                    // @ts-ignore
                    application[field_id]
                )
            } else {
                res.additional_details = {
                    ...res.additional_details,
                    [field_id]: getInsuranceFEValue(
                        insurance_fields_main_driver_additional_details[i],
                        // @ts-ignore
                        customer[field_id]
                    ),
                }
            }
        }

        for (let i = 0; i < additionalDetailsSubfields.length; i++) {
            let field_id = additionalDetailsSubfields[i].id
            res.additional_details = {
                ...res.additional_details,
                [field_id]: getInsuranceFEValue(
                    additionalDetailsSubfields[i],
                    // @ts-ignore
                    customer[field_id]
                ),
            }
        }

        //  basic details section
        for (
            let i = 0;
            i < insurance_fields_main_driver_track_record.length;
            i++
        ) {
            let field_id = insurance_fields_main_driver_track_record[i].id
            res.track_record = {
                ...res.track_record,
                [field_id]: getInsuranceFEValue(
                    insurance_fields_main_driver_track_record[i],
                    // @ts-ignore
                    customer[field_id]
                ),
            }
        }

        for (let i = 0; i < additionalSubFieldsTrackRecord.length; i++) {
            let field_id = additionalSubFieldsTrackRecord[i].id
            res.track_record = {
                ...res.track_record,
                [field_id]: getInsuranceFEValue(
                    additionalSubFieldsTrackRecord[i],
                    // @ts-ignore
                    customer[field_id]
                ),
            }
        }

        if (membership_api && membership_api.length > 0) {
            let entityArr: IAnyObject[] = []

            for (let i = 0; i < membership_api.length; i++) {
                let membershipObjApi: IMembership_API = membership_api[i]
                let entityObj: IAnyObject = {
                    uid: membership_api[i]?.uid,
                }
                membership_self_replicating_fields.forEach(
                    (item: IInsuranceFieldType) => {
                        entityObj = {
                            ...entityObj,
                            [`${item.id}`]: getInsuranceFEValue(
                                item,
                                // @ts-ignore
                                membershipObjApi[`${item.id}`]
                            ),
                        }
                    }
                )
                entityArr = [...entityArr, { ...entityObj }]
            }

            res.entities.memberships = [...entityArr]
        }

        if (disability_api && disability_api.length > 0) {
            let entityArr: IAnyObject[] = []

            for (let i = 0; i < disability_api.length; i++) {
                let disabilityObjApi: IInsuranceDisability_API =
                    disability_api[i]
                let entityObj: IAnyObject = {
                    uid: disability_api[i]?.uid,
                }
                disability_self_replicating_fields.forEach(
                    (item: IInsuranceFieldType) => {
                        entityObj = {
                            ...entityObj,
                            [`${item.id}`]: getInsuranceFEValue(
                                item,
                                // @ts-ignore
                                disabilityObjApi[`${item.id}`]
                            ),
                        }
                    }
                )

                additionalSubFieldsDisabilities.forEach(
                    (item: IInsuranceFieldType) => {
                        // @ts-ignore
                        if (disabilityObjApi[item.id]) {
                            entityObj = {
                                ...entityObj,
                                [`${item.id}`]: getInsuranceFEValue(
                                    item,
                                    // @ts-ignore
                                    disabilityObjApi[item.id]
                                ),
                            }
                        }
                    }
                )
                entityArr = [...entityArr, { ...entityObj }]
            }

            res.entities.disabilities = [...entityArr]
        }

        // later this is where completion is calculated

        if (claims_api && claims_api.length > 0) {
            let entityArr: IAnyObject[] = []

            for (let i = 0; i < claims_api.length; i++) {
                let claimAPIObj: IClaim_API = claims_api[i]
                let entityObj: IAnyObject = { uid: claims_api[i]?.uid }
                self_replicating_claim.forEach((item: IInsuranceFieldType) => {
                    entityObj = {
                        ...entityObj,
                        [`${item.id}`]: getInsuranceFEValue(
                            item,
                            // @ts-ignore
                            claimAPIObj[`${item.id}`]
                        ),
                    }
                })
                additionalSubFieldsClaims.forEach(
                    (item: IInsuranceFieldType) => {
                        // @ts-ignore
                        if (claimAPIObj[item.id]) {
                            entityObj = {
                                ...entityObj,
                                [`${item.id}`]: getInsuranceFEValue(
                                    item,
                                    // @ts-ignore
                                    claimAPIObj[item.id]
                                ),
                            }
                        }
                    }
                )

                entityArr = [...entityArr, { ...entityObj }]
            }

            res.entities.claims = [...entityArr]
        }

        if (conviction_api && conviction_api.length > 0) {
            let entityArr: IAnyObject[] = []

            for (let i = 0; i < conviction_api.length; i++) {
                let convictionApiObj: IInsuranceConviction_API =
                    conviction_api[i]
                let entityObj: IAnyObject = { uid: conviction_api[i]?.uid }
                insurance_sub_fields_other_driver_track_record_conviction_replicating.forEach(
                    (item: IInsuranceFieldType) => {
                        const valuee = getInsuranceFEValue(
                            item,
                            // @ts-ignore
                            convictionApiObj[`${item.id}`]
                        )
                        entityObj = {
                            ...entityObj,
                            [`${item.id}`]: valuee,
                        }
                    }
                )

                additionalSubFieldsConvictions.forEach((subFieldID) => {
                    // @ts-ignore
                    if (convictionApiObj[subFieldID]) {
                        entityObj = {
                            ...entityObj,
                            // @ts-ignore
                            [`${subFieldID}`]: convictionApiObj[subFieldID],
                        }
                    }
                })
                entityArr = [...entityArr, { ...entityObj }]
            }

            res.entities.convictions = [...entityArr]
        }

        if (motoring_conviction_api && motoring_conviction_api.length > 0) {
            let entityArr: IAnyObject[] = []

            for (let i = 0; i < motoring_conviction_api.length; i++) {
                let motoringConvictionObjApi: IInsuranceMotoringConviction_API =
                    motoring_conviction_api[i]
                let entityObj: IAnyObject = {
                    uid: motoring_conviction_api[i]?.uid,
                }
                insurance_sub_fields_other_driver_track_record_motorconviction_replicating.forEach(
                    (item: IInsuranceFieldType) => {
                        entityObj = {
                            ...entityObj,
                            [`${item.id}`]: getInsuranceFEValue(
                                item,
                                // @ts-ignore
                                motoringConvictionObjApi[`${item.id}`]
                            ),
                        }
                    }
                )

                additionalSubFieldsMotoringConvictionsStrings.forEach(
                    (subFieldID) => {
                        // @ts-ignore
                        if (motoringConvictionObjApi[subFieldID]) {
                            let is_price =
                                is_price_insurance_fields.indexOf(
                                    subFieldID
                                ) === -1
                                    ? false
                                    : true
                            entityObj = {
                                ...entityObj,
                                [`${subFieldID}`]:
                                    is_price &&
                                    // @ts-ignore
                                    motoringConvictionObjApi[subFieldID]
                                        ? // @ts-ignore
                                          +motoringConvictionObjApi[
                                              subFieldID
                                          ] / 100
                                        : // @ts-ignore
                                          motoringConvictionObjApi[subFieldID],
                            }
                        }
                    }
                )

                entityArr = [...entityArr, { ...entityObj }]
            }

            res.entities.motoring_convictions = [...entityArr]
        }
    }

    return res
}

export const convertCustomerToReducerMainDriverError = (
    application: IInsuranceApplication_APIErrors
): IInsuranceQuoteApplication_MainDriver => {
    let customer: InsuranceCustomer_APIErrors | undefined = application.customer

    let res: IInsuranceQuoteApplication_MainDriver = {
        entities: {
            memberships: [],
            claims: [],
            disabilities: [],
            convictions: [],
            motoring_convictions: [],
        },
        entities_error: {
            disabilities: {},
        },
    }
    if (customer) {
        let membership_api: IMembership_APIErrors[] = customer.memberships ?? []
        let claims_api: IClaim_APIErrors[] = customer.claims ?? []
        let disability_api: IInsuranceDisability_APIErrors[] =
            customer.disabilities ?? []
        let conviction_api: IInsuranceConviction_APIErrors[] =
            customer.convictions ?? []
        let motoring_conviction_api: IInsuranceConviction_APIErrors[] =
            customer.motoring_convictions ?? []

        res = {
            id: customer.uid,
            completion: 0, // to be calculated below
            basic_details: {},
            basic_details_errors: undefined,
            licence_address: {},
            licence_address_errors: undefined,
            additional_details: {},
            additional_details_errors: undefined,
            track_record: {},
            track_record_errors: undefined,
            entities: {
                memberships: [],
                claims: [],
                disabilities: [],
                convictions: [],
                motoring_convictions: [],
            },
            entities_error: undefined,
        }

        let basic_details_ids_error =
            insurance_fields_main_driver_basic_details.map((field) => field.id)

        let license_address_ids_error = [
            ...insurance_fields_main_driver_licence_address_enriched.map(
                (field) => field.id
            ),
            ...licenseAddressAdditionalFields,
            ...residence_additional_q.map((field) => field.id),
        ]

        let track_record_ids_error = [
            ...insurance_fields_main_driver_track_record,
            ...additionalSubFieldsTrackRecord,
        ].map((field) => field.id)

        //  basic details section
        for (let i = 0; i < basic_details_ids_error.length; i++) {
            res.basic_details_errors = {
                ...res.basic_details_errors,
                //@ts-ignore
                [basic_details_ids_error[i]]:
                    //@ts-ignore
                    customer[basic_details_ids_error[i]],
            }
        }

        //  licence address section
        for (let i = 0; i < license_address_ids_error.length; i++) {
            res.licence_address_errors = {
                ...res.licence_address_errors,
                //@ts-ignore
                [license_address_ids_error[i]]:
                    //@ts-ignore
                    customer[license_address_ids_error[i]],
            }
        }

        //  additional details section
        for (
            let i = 0;
            i < insurance_fields_main_driver_additional_details.length;
            i++
        ) {
            let field_id = insurance_fields_main_driver_additional_details[i].id
            {
                res.additional_details_errors = {
                    ...res.additional_details_errors,

                    [field_id]:
                        //@ts-ignore
                        customer[field_id],
                }
            }
        }

        for (let i = 0; i < additionalDetailsSubfields.length; i++) {
            let field_id = additionalDetailsSubfields[i].id
            res.additional_details_errors = {
                ...res.additional_details_errors,
                [field_id]:
                    // @ts-ignore
                    customer[field_id],
            }
        }

        //  track record section
        for (let i = 0; i < track_record_ids_error.length; i++) {
            //@ts-ignore
            res.track_record_errors = res.track_record_errors
                ? {
                      ...res.track_record_errors,

                      [track_record_ids_error[i]]:
                          //@ts-ignore
                          customer[track_record_ids_error[i]],
                  }
                : {
                      [track_record_ids_error[i]]:
                          //@ts-ignore
                          customer[track_record_ids_error[i]],
                  }
        }

        for (let i = 0; i < additionalSubFieldsTrackRecord.length; i++) {
            let field_id = additionalSubFieldsTrackRecord[i].id
            res.track_record_errors = {
                ...res.track_record_errors,
                [field_id]:
                    // @ts-ignore
                    customer[field_id],
            }
        }

        if (
            application?.customer?.has_previous_motor_insurance_cancelled ||
            application?.customer?.has_previous_motor_insurance_refused ||
            application?.customer?.has_previous_motor_insurance_voided ||
            application?.customer?.has_previous_special_terms_imposed
        ) {
            res.track_record_errors = res.track_record_errors
                ? {
                      ...res.track_record_errors,
                      has_any_previous_insurance_refusals: {
                          status: 'missing',
                          message: 'This is a required field',
                      },
                  }
                : {
                      has_any_previous_insurance_refusals: {
                          status: 'missing',
                          message: 'This is a required field',
                      },
                  }
        }
        if (membership_api && membership_api.length > 0) {
            let entitiesErrorsObj: IAnyObject = {}

            for (let i = 0; i < membership_api.length; i++) {
                let modifObjApi: IMembership_APIErrors = membership_api[i]
                let entityObj: IAnyObject = {
                    uid: membership_api[i]?.uid,
                }
                membership_self_replicating_fields.forEach(
                    (item: IInsuranceFieldType) => {
                        // @ts-ignore
                        let valuee = modifObjApi[`${item.id}`]

                        entityObj[`${item.id}`] = valuee
                    }
                )
                entitiesErrorsObj = {
                    ...entitiesErrorsObj,
                    [`"${entityObj.uid}"`]: { ...entityObj },
                }
            }

            res.entities_error = {
                ...res.entities_error,
                memberships: { ...entitiesErrorsObj },
            }
        }

        if (disability_api && disability_api.length > 0) {
            let entitiesErrorsObj: IAnyObject = {}

            for (let i = 0; i < disability_api.length; i++) {
                let disabilityObjApi: IInsuranceDisability_APIErrors =
                    disability_api[i]
                let entityObj: IAnyObject = {
                    uid: disability_api[i]?.uid,
                }
                disability_self_replicating_fields.forEach(
                    (item: IInsuranceFieldType) => {
                        // @ts-ignore
                        let valuee = disabilityObjApi[`${item.id}`]

                        entityObj[`${item.id}`] = valuee
                    }
                )

                additionalSubFieldsDisabilities.forEach(
                    (item: IInsuranceFieldType) => {
                        // @ts-ignore
                        if (disabilityObjApi[item.id]) {
                            entityObj = {
                                ...entityObj,
                                [`${item.id}`]:
                                    // @ts-ignore
                                    disabilityObjApi[item.id],
                            }
                        }
                    }
                )
                entitiesErrorsObj = {
                    ...entitiesErrorsObj,
                    [`"${entityObj.uid}"`]: { ...entityObj },
                }
            }

            res.entities_error = {
                ...res.entities_error,
                disabilities: { ...entitiesErrorsObj },
            }
        }

        // console.log('res after disabilities ===>>>', res)

        // later this is where completion is calculated

        if (claims_api && claims_api.length > 0) {
            let entitiesErrorsObj: IAnyObject = {}

            for (let i = 0; i < claims_api.length; i++) {
                let claimObjApi: IClaim_APIErrors = claims_api[i]
                let entityObj: IAnyObject = { uid: claims_api[i]?.uid }
                self_replicating_claim.forEach((item: IInsuranceFieldType) => {
                    // @ts-ignore
                    let valuee = claimObjApi[`${item.id}`]

                    entityObj[`${item.id}`] = valuee
                })

                additionalSubFieldsClaims.forEach(
                    (item: IInsuranceFieldType) => {
                        // @ts-ignore
                        if (claimObjApi[`${item.id}`]) {
                            // @ts-ignore
                            let valuee = claimObjApi[`${item.id}`]

                            entityObj[`${item.id}`] = valuee
                        }
                    }
                )
                entitiesErrorsObj = {
                    ...entitiesErrorsObj,
                    [`"${entityObj.uid}"`]: { ...entityObj },
                }
            }

            res.entities_error = {
                ...res.entities_error,
                claims: { ...entitiesErrorsObj },
            }
        }

        if (conviction_api && conviction_api.length > 0) {
            let entitiesErrorsObj: IAnyObject = {}

            for (let i = 0; i < conviction_api.length; i++) {
                let convictionApiObj: IInsuranceConviction_APIErrors = {
                    ...conviction_api[i],
                }
                let entityObj: IAnyObject = { uid: conviction_api[i]?.uid }
                insurance_sub_fields_other_driver_track_record_conviction_replicating.forEach(
                    (item: IInsuranceFieldType) => {
                        // @ts-ignore
                        let valuee = convictionApiObj[`${item.id}`]

                        entityObj[`${item.id}`] = valuee
                    }
                )

                additionalSubFieldsConvictions.forEach((subFieldID) => {
                    // @ts-ignore
                    if (convictionApiObj[subFieldID]) {
                        entityObj = {
                            ...entityObj,
                            // @ts-ignore
                            [`${subFieldID}`]: convictionApiObj[subFieldID],
                        }
                    }
                })
                entitiesErrorsObj = {
                    ...entitiesErrorsObj,
                    [`"${entityObj.uid}"`]: { ...entityObj },
                }
            }

            res.entities_error = {
                ...res.entities_error,
                convictions: { ...entitiesErrorsObj },
            }
        }

        if (motoring_conviction_api && motoring_conviction_api.length > 0) {
            let entitiesErrorsObj: IAnyObject = {}

            for (let i = 0; i < motoring_conviction_api.length; i++) {
                let motoringConvictionObjApi: IInsuranceMotoringConviction_APIErrors =
                    motoring_conviction_api[i]
                let entityObj: IAnyObject = {
                    uid: motoring_conviction_api[i]?.uid,
                }
                insurance_sub_fields_other_driver_track_record_motorconviction_replicating.forEach(
                    (item: IInsuranceFieldType) => {
                        // @ts-ignore
                        let valuee = motoringConvictionObjApi[`${item.id}`]

                        entityObj[`${item.id}`] = valuee
                    }
                )

                additionalSubFieldsMotoringConvictionsStrings.forEach(
                    (subFieldID) => {
                        // @ts-ignore
                        if (motoringConvictionObjApi[subFieldID]) {
                            entityObj = {
                                ...entityObj,
                                [`${subFieldID}`]:
                                    // @ts-ignore
                                    motoringConvictionObjApi[subFieldID],
                            }
                        }
                    }
                )

                entitiesErrorsObj = {
                    ...entitiesErrorsObj,
                    [`"${entityObj.uid}"`]: { ...entityObj },
                }
            }

            res.entities_error = {
                ...res.entities_error,
                motoring_convictions: { ...entitiesErrorsObj },
            }
        }
    }
    // removing the else
    if (application) {
        //  additional details section
        for (
            let i = 0;
            i < insurance_fields_main_driver_additional_details.length;
            i++
        ) {
            let field_id = insurance_fields_main_driver_additional_details[i].id
            if (
                field_id === 'family_vehicles_count' ||
                field_id === 'vehicles_driven_count' ||
                field_id === 'vehicles_owned_count'
            ) {
                res.additional_details_errors = {
                    ...res.additional_details_errors,

                    [field_id]:
                        //@ts-ignore
                        application[field_id],
                }
            }
        }
    }
    // console.log('res l 837', res)
    return res
}
