import React from 'react'
import Faded from '../../templates/animated/faded'
import SectionHeader from '../../atoms/header/section/sectionHeader'
import ButtonAtom from '../../atoms/Button/ButtonAtom'
import Loader from '../../atoms/loader/loader'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import styled from 'styled-components'
import { LightTitle } from '../../atoms/typography'
import Icon from '../../atoms/icons'
import { device } from '../../templates/displays/devices'
import ListManagerDesktop from '../../molecules/list/ListManagerDesktop'
import ModalDisplay from '../../templates/displays/pageWrappers/modalDisplay'
import { editFormsActions } from '../../../redux/editForms/reducer'
import AccountExternalInfoFormDesktop from '../../organisms/editForms/account/accountExternalInfoForm/accountExternalInfoFormDesktop'
import AccountPrivateInfoManagerFormDesktop from '../../organisms/editForms/account/accountPrivateInfoForm/manager/accountPrivateInfoManagerFormDesktop'
import AccountAddressFormManagerDesktop from '../../organisms/editForms/account/accountAddressForm/accountAddressFormManagerDesktop'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import MobileSettingsSectionHeader from '../../atoms/header/section/mobileSettingsSection'
import UserAccountSquare from '../../atoms/user/userAccountSquare'
import ListManagerMobile from '../../molecules/list/listManagerMobile'
import ListItemMobile from '../../atoms/list/listItemMobile'
import AccountExternalInfoFormMobile from '../../organisms/editForms/account/accountExternalInfoForm/accountExternalInfoFormMobile'
import AccountPrivateInfoManagerMobileForm from '../../organisms/editForms/account/accountPrivateInfoForm/manager/accountPrivateInfoManagerMobileForm'
import AccountAddressFormManagerMobile from '../../organisms/editForms/account/accountAddressForm/accountAddressFormManagerMobile'
import WrapperUserProfileImageDesktop from '../../organisms/editForms/imageFormWrapper/wrapperUserProfileImageDesktop'
import WrapperUserProfileImageMobile from '../../organisms/editForms/imageFormWrapper/wrapperUserProfileImageMobile'
import { dropDownActions } from '../../../redux/localdata/dropdownData/reducer'
import { message } from 'antd'
import '../../organisms/dropzone/antd.css'
import * as ReactScroll from 'react-scroll'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import AccountDeletionManagerDesktop from '../../organisms/accountDeletionManager/accountDeletionManagerDesktop'
import AccountDeletionManagerMobile from '../../organisms/accountDeletionManager/accountDeletionManagerMobile'
import BottomSheetPage from '../../templates/bottomSheetPages.tsx/bottomSheetPage'
import FadedSlower from '../../templates/animated/FadedSlower'
import JourneyRedirectCardDesktop from '../../molecules/cards/journey/redirectCards/redirectCardDesktop'
import { journeyRedirectCardsCopy } from '../../../redux/localdata/journey/copyData'
import UserSquareProfileImage from '../../atoms/user/userSquareProfileImage'
import { IList } from 'entityModels'
import { IAccDelItems } from '../../../redux/services/user_data/types'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { usersActions } from '../../../redux/user/reducer'
import { garagesActions } from '../../../redux/entities/garages/reducer'
import { useLocation, useNavigate } from 'react-router'
import { IUSerGeneralInfoUpdatePayload } from '../../../redux/user/types'
import ModalDisplayFullPageDesktopExclusive from '../../templates/displays/pageWrappers/modalDisplayFullPageDesktopExclusive'
import { IUserAddressApi } from '../../../redux/services/typedefinitions/apiPayloads'
import InfoBarMobile from '../../atoms/info/infoBarMobile'

const FirstSectionContentWrapDesktop = styled.div`
    padding-top: 20px;
    padding-bottom: 50px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
    box-sizing: border-box;
    padding-right: 15vw;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-top: 2px;
    padding-bottom: 2px;
    line-height: 1.7;
`

const RowText = styled.div`
    font-family: Lato-Italic;
    padding-left: 8px;
    font-size: 15px;
    color: var(--text-default, #666666);

    @media ${device.smallest_laptops} {
        font-size: 15px;
    }
`

const Text = styled.div`
    font-family: Lato !important;
    font-size: 16px;
    text-align: left;
    width: 100%;
    box-sizing: border-box;
    color: var(--text-strong, #1a1a1a);
    padding-bottom: 10px;
    line-height: 25px;
    white-space: pre-line;

    cursor: pointer;

    @media ${device.smallest_laptops} {
        font-size: 16px;
    }
`

const CenterDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    position: relative;
    z-index: 0;
`

const CenteredDiv = styled.div`
    padding-top: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
`

const AccountPage = () => {
    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)

    const userError = useAppSelector((state) => state.user.error)

    const isAccountExternalInfoFormOpened = useAppSelector(
        (state) => state.editForms.formsData.accountExternalInfoForm.isOpen
    )
    const isAccountPrivateInfoFormOpened = useAppSelector(
        (state) => state.editForms.formsData.accountPrivateInfoForm.isOpen
    )
    const currentPrivateItemEdited = useAppSelector(
        (state) => state.editForms.formsData.accountPrivateInfoForm.item
    )
    const currentExternalItemEdited = useAppSelector(
        (state) => state.editForms.formsData.accountExternalInfoForm.item
    )
    const isAccountPrivateAddressInfoOpened = useAppSelector(
        (state) =>
            state.editForms.formsData.userAccountAddressInformationForm.isOpen
    )
    const isUSerProfileImageFormOpened = useAppSelector(
        (state) => state.editForms.formsData.userProfileImageForm.isOpen
    )
    const countriesdropdownlist = useAppSelector(
        (state) => state.localdata.dropdownData.countriesCode
    )

    const carsData = useAppSelector((state) => state.entities.carsData.cars)
    const garageData = useAppSelector((state) => state.entities.garagesData)
    const garage_id = useAppSelector(
        (state) => state.user.userLoggedIn?.owns_garage?.uid
    )
    const usaAdminCodes = useAppSelector(
        (state) => state.localdata.dropdownData.usaStatesCodes
    )

    const [state, setState] = React.useState({
        hasResetPasswordBeenClicked: false,
        accountDeletionWizard: {
            isOpen: false,
            step: 0,
            answers: {
                deletion_agreement: false,
                reason: '',
                custom_reason_detail: '',
                rating: 0,
                feedback: '',
                further_feedback: undefined,
                confirmation: false,
                userEmail: '',
            },
        },
        showJourneyCard: true,
    })

    const errorWarning = () => {
        message.error({
            content: 'Invalid',
            duration: 2,
        })
    }

    const setToResetPasswordClicked = () => {
        setState({
            ...state,
            hasResetPasswordBeenClicked: !state.hasResetPasswordBeenClicked,
        })
    }

    let dispatch = useAppDispatch()

    React.useEffect(() => {
        dispatch(customNavDataActions.setInitialLandingUrl('/account'))

        isAccountExternalInfoFormOpened === true &&
            dispatch(editFormsActions.toggleAccountExternalInfoEditForm())

        isUSerProfileImageFormOpened === true &&
            dispatch(editFormsActions.toggleUserProfileImageForm())

        return () => {
            isAccountPrivateInfoFormOpened === true &&
                dispatch(
                    editFormsActions.toggleAccountPrivateInfoEditForm(undefined)
                )

            isAccountPrivateAddressInfoOpened === true &&
                dispatch(
                    editFormsActions.toggleAccounPrivateAddressEditForm(
                        undefined
                    )
                )
        }
    }, [])

    React.useEffect(() => {
        if (userError) {
            errorWarning()
        }
        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        } else if (userLoggedIn) {
            if (!countriesdropdownlist) {
                dispatch(dropDownActions.setCountriesCodeDropdownDataRequest())
            }
            if (
                garage_id &&
                (!garageData.garages ||
                    !garageData.garages[garage_id] ||
                    !garageData.garages[garage_id].cars ||
                    garageData.garages[garage_id].cars.length === 0)
            ) {
                dispatch(garagesActions.getGarageByUserIdRequest())
            }
        }
    }, [userLoggedIn, userError])

    let hasNoNames =
        userLoggedIn?.family_name === userLoggedIn?.given_name ? true : false

    let is_social_signin =
        userLoggedIn?.login_mode?.includes('google') ||
        userLoggedIn?.login_mode?.includes('apple')
            ? true
            : false

    let location = useLocation()

    let params = new URLSearchParams(location.search)

    let form_to_open = params.get('id')

    let accountInternalInfoList = userLoggedIn && {
        profile_image_url: userLoggedIn.profile_image_url
            ? userLoggedIn.profile_image_url
            : null,
        email: {
            id: 'email',
            text: 'email',
            answer: userLoggedIn.email,
        },
        password: {
            id: 'password',
            text: 'password',
            answer: '****************',
        },
        first_name: {
            id: 'given_name',
            text: 'given name',
            answer: hasNoNames ? undefined : userLoggedIn.given_name,
        },
        last_name: {
            id: 'family_name',
            text: 'family name',
            answer: hasNoNames ? undefined : userLoggedIn.family_name,
        },
        display_name: userLoggedIn.display_name
            ? userLoggedIn.display_name
            : userLoggedIn.given_name === userLoggedIn.family_name
            ? userLoggedIn.given_name
            : `${userLoggedIn.given_name} ${userLoggedIn.family_name}`,
        one_liner: userLoggedIn.one_liner ? userLoggedIn.one_liner : '',

        location: `${
            userLoggedIn.address && userLoggedIn.address.locality
                ? `${userLoggedIn.address.locality}, `
                : 'Unknown'
        } ${
            userLoggedIn.address && userLoggedIn.address.country
                ? `${userLoggedIn.address.country}`
                : ''
        }`,

        address: {
            id: 'address',
            text: 'address',
            answer: `${
                userLoggedIn.address && userLoggedIn.address.street_1
                    ? userLoggedIn.address.street_1 + ','
                    : ''
            } ${
                userLoggedIn.address && userLoggedIn.address.street_2
                    ? userLoggedIn.address.street_2 + ','
                    : ''
            } ${
                userLoggedIn.address && userLoggedIn.address.locality
                    ? userLoggedIn.address.locality + ','
                    : ''
            } 
            ${
                userLoggedIn.address && userLoggedIn.address.postcode
                    ? userLoggedIn.address.postcode + ','
                    : ''
            } ${
                userLoggedIn.address && userLoggedIn.address.administrative_area
                    ? userLoggedIn.address.administrative_area
                    : ''
            } ${
                userLoggedIn.address && userLoggedIn.address.country
                    ? userLoggedIn.address.country
                    : ''
            }
            
            `,
        },
    }

    let listInternalAccountInfo = is_social_signin
        ? ['email', 'password', 'first_name', 'last_name', 'address']
        : ['email', 'first_name', 'last_name', 'address']

    const hasResetPasswordBeenClicked = state.hasResetPasswordBeenClicked

    if (
        isAccountPrivateAddressInfoOpened &&
        (isAccountExternalInfoFormOpened ||
            isAccountPrivateInfoFormOpened ||
            isUSerProfileImageFormOpened)
    ) {
        dispatch(editFormsActions.toggleAccounPrivateAddressEditForm())
    }

    let currentGarage =
        garage_id &&
        garageData &&
        garageData.garages &&
        garageData.garages[garage_id]
            ? garageData.garages[garage_id]
            : undefined

    let garageCars =
        currentGarage && currentGarage.cars && currentGarage.cars.length > 0
            ? currentGarage.cars
            : currentGarage &&
              currentGarage.sorted_car_ids &&
              currentGarage.sorted_car_ids.length > 0
            ? currentGarage.sorted_car_ids
            : undefined

    let cars_no =
        garageData && garageData.total_cars_number_found
            ? garageData.total_cars_number_found
            : garageCars
            ? garageCars.length
            : 0

    let tasks_no =
        userLoggedIn && userLoggedIn.total_tasks_count
            ? userLoggedIn.total_tasks_count
            : 0

    let history_file_no =
        garageCars && carsData
            ? garageCars.reduce(
                  (acc, current) =>
                      carsData &&
                      current &&
                      carsData[current] &&
                      carsData[current].car_entries_count
                          ? acc + carsData![current].car_entries_count!
                          : acc + 0,
                  0
              )
            : 0

    let navigate = useNavigate()

    return (
        <React.Fragment>
            <CenteredPageWrapper hasMobileSubNav removeHorizPaddings>
                {accountInternalInfoList !== null && userLoggedIn && (
                    <Faded>
                        <Loader />
                        <DesktopDisplayOnly>
                            {state.showJourneyCard ? (
                                <div
                                    style={{
                                        width: '100%',
                                        boxSizing: 'border-box',
                                        paddingBottom: 32,
                                        paddingLeft: 50,
                                        // paddingRight: 24,
                                    }}
                                >
                                    <FadedSlower>
                                        <JourneyRedirectCardDesktop
                                            sectionID="your_profile"
                                            dataPH={
                                                'start_your_profile_journey'
                                            }
                                            copyData={journeyRedirectCardsCopy}
                                            onStartNow={() => {
                                                navigate(
                                                    `/journey/your-profile?step=welcome`
                                                )
                                            }}
                                        />
                                    </FadedSlower>
                                </div>
                            ) : null}
                            <div
                                style={{
                                    paddingLeft: '50px',
                                    // paddingRight: '50px',
                                }}
                            >
                                <SectionHeader
                                    title="your profile"
                                    openForm={() => {
                                        dispatch(
                                            editFormsActions.toggleAccountExternalInfoEditForm()
                                        )
                                    }}
                                    customPadding={'0px'}
                                    dataCyId={`edit-profile-clickable-icon-desktop`}
                                />

                                <FirstSectionContentWrapDesktop>
                                    <div
                                        style={{
                                            height: '30px',
                                            marginLeft: '15px',
                                        }}
                                    >
                                        <UserSquareProfileImage
                                            url={
                                                accountInternalInfoList.profile_image_url &&
                                                accountInternalInfoList.profile_image_url
                                            }
                                            onClick={() => {
                                                dispatch(
                                                    editFormsActions.toggleUserProfileImageForm()
                                                )
                                            }}
                                        />
                                        <div style={{ paddingTop: '100px' }} />
                                        <ButtonAtom
                                            theme="lowercase-white-background-smaller"
                                            onClick={() => {
                                                dispatch(
                                                    editFormsActions.toggleUserProfileImageForm()
                                                )
                                            }}
                                        >
                                            Update image
                                        </ButtonAtom>
                                    </div>

                                    <div>
                                        <LightTitle
                                            removeIpadStyle={true}
                                            themedStyle="lightest"
                                            align="left"
                                        >
                                            <div
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    dispatch(
                                                        editFormsActions.toggleAccountExternalInfoEditForm()
                                                    )
                                                }}
                                            >
                                                {
                                                    accountInternalInfoList.display_name
                                                }
                                            </div>
                                        </LightTitle>

                                        <Text>
                                            <div
                                                onClick={() => {
                                                    dispatch(
                                                        editFormsActions.toggleAccountExternalInfoEditForm()
                                                    )
                                                }}
                                                style={{
                                                    minHeight: '20px',
                                                    paddingBottom: '5px',
                                                    color:
                                                        accountInternalInfoList
                                                            .one_liner.length >
                                                        1
                                                            ? 'inherit'
                                                            : '#B0B0B0',
                                                    fontFamily:
                                                        accountInternalInfoList
                                                            .one_liner.length >
                                                        1
                                                            ? 'inherit'
                                                            : 'Lato-light',
                                                }}
                                            >
                                                {accountInternalInfoList
                                                    .one_liner.length > 1
                                                    ? accountInternalInfoList.one_liner
                                                    : '+ Add bio'}
                                            </div>
                                        </Text>

                                        <ReactScroll.Link
                                            to={'user-address-section-desktop'}
                                            spy={false}
                                            smooth={true}
                                            offset={-70}
                                            duration={500}
                                            onClick={() => {
                                                dispatch(
                                                    editFormsActions.toggleAccounPrivateAddressEditForm()
                                                )
                                            }}
                                        >
                                            <Row>
                                                <Icon
                                                    icon="location"
                                                    width="15px"
                                                />
                                                <RowText>
                                                    {accountInternalInfoList
                                                        .location.length > 1
                                                        ? accountInternalInfoList.location
                                                        : ' Unknown '}
                                                </RowText>
                                            </Row>{' '}
                                        </ReactScroll.Link>
                                    </div>
                                </FirstSectionContentWrapDesktop>
                                <div id="user-address-section-desktop" />
                                <SectionHeader
                                    title={
                                        isAccountPrivateAddressInfoOpened
                                            ? 'Update your address'
                                            : 'Account Details'
                                    }
                                    openForm={() => {
                                        dispatch(
                                            editFormsActions.toggleAccountPrivateInfoEditForm()
                                        )
                                    }}
                                    removeEdit={true}
                                />

                                {isAccountPrivateAddressInfoOpened !== true && (
                                    <ListManagerDesktop
                                        toggle={(item: IList) => {
                                            item.id === 'address'
                                                ? dispatch(
                                                      editFormsActions.toggleAccounPrivateAddressEditForm()
                                                  )
                                                : dispatch(
                                                      editFormsActions.toggleAccountPrivateInfoEditForm(
                                                          item
                                                      )
                                                  )
                                        }}
                                        data={accountInternalInfoList}
                                        list={listInternalAccountInfo}
                                        removeBottomBorderCondition={
                                            isAccountPrivateAddressInfoOpened
                                        }
                                    />
                                )}

                                {isAccountPrivateAddressInfoOpened &&
                                    countriesdropdownlist && (
                                        <AccountAddressFormManagerDesktop
                                            countriesdropdownlist={
                                                countriesdropdownlist
                                            }
                                            usaAdminCodes={usaAdminCodes}
                                            addAddress={(
                                                p: IUserAddressApi
                                            ) => {
                                                dispatch(
                                                    usersActions.addAddressRequest(
                                                        { data: p }
                                                    )
                                                )
                                            }}
                                            currentAddress={
                                                userLoggedIn.address
                                                    ? userLoggedIn.address
                                                    : null
                                            }
                                            toggleAddresForm={() => {
                                                dispatch(
                                                    editFormsActions.toggleAccounPrivateAddressEditForm()
                                                )
                                            }}
                                        />
                                    )}
                                <div style={{ height: '70px' }} />
                                {isAccountPrivateAddressInfoOpened !== true && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <ButtonAtom
                                            theme="lowercase-red-background"
                                            onClick={() => {
                                                dispatch(
                                                    usersActions.logoutRequest()
                                                )
                                            }}
                                        >
                                            <span
                                                style={{
                                                    textTransform: 'capitalize',
                                                }}
                                            >
                                                Logout
                                            </span>
                                        </ButtonAtom>
                                        <div style={{ paddingRight: '32px' }} />

                                        <ButtonAtom
                                            theme="naked-text"
                                            fontSize={16}
                                            onClick={() =>
                                                setState({
                                                    ...state,
                                                    accountDeletionWizard: {
                                                        ...state.accountDeletionWizard,
                                                        isOpen: true,
                                                        step:
                                                            cars_no === 0
                                                                ? 2
                                                                : 1,
                                                    },
                                                })
                                            }
                                        >
                                            <span
                                                style={{
                                                    textTransform: 'capitalize',
                                                    color: '#DF6F6F',
                                                }}
                                            >
                                                Delete account
                                            </span>
                                        </ButtonAtom>
                                    </div>
                                )}
                            </div>
                        </DesktopDisplayOnly>

                        <IpadAndMobileDisplay>
                            <InfoBarMobile>
                                Please tap the field you wish to edit
                            </InfoBarMobile>
                            {/* <MobileSettingsSectionHeader title="your profile" /> */}
                            <div style={{ paddingTop: '20px' }} />
                            <CenterDiv>
                                <UserAccountSquare
                                    url={
                                        accountInternalInfoList.profile_image_url &&
                                        accountInternalInfoList.profile_image_url
                                    }
                                    onClick={() => {
                                        dispatch(
                                            editFormsActions.toggleUserProfileImageForm()
                                        )
                                    }}
                                />
                            </CenterDiv>
                            <CenterDiv>
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '150px',
                                    }}
                                >
                                    <ButtonAtom
                                        theme="lowercase-white-background-smaller"
                                        onClick={() => {
                                            dispatch(
                                                editFormsActions.toggleUserProfileImageForm()
                                            )
                                        }}
                                        height={'42px'}
                                        width={'128px'}
                                    >
                                        update image
                                    </ButtonAtom>
                                </div>
                            </CenterDiv>
                            <div style={{ marginTop: '200px' }}>
                                <ListItemMobile
                                    item={{
                                        id: 'display_name',
                                        text: 'display name*',
                                        answer: accountInternalInfoList.display_name,
                                    }}
                                    toggle={() =>
                                        dispatch(
                                            editFormsActions.toggleAccountExternalInfoEditForm(
                                                {
                                                    id: 'display_name',
                                                    text: 'display name*',
                                                    answer: accountInternalInfoList
                                                        ? accountInternalInfoList.display_name
                                                        : '',
                                                }
                                            )
                                        )
                                    }
                                    height="80px"
                                    dataCyId={'display_name-field-mobile'}
                                />
                                <div style={{ height: '10px' }} />
                                <ListItemMobile
                                    item={{
                                        id: 'one_liner',
                                        text: 'your bio*',
                                        answer: accountInternalInfoList.one_liner,
                                    }}
                                    minHeight="80px"
                                    toggle={() =>
                                        dispatch(
                                            editFormsActions.toggleAccountExternalInfoEditForm(
                                                {
                                                    id: 'one_liner',
                                                    text: 'your bio*',
                                                    answer: accountInternalInfoList
                                                        ? accountInternalInfoList.one_liner
                                                        : '',
                                                }
                                            )
                                        )
                                    }
                                    fontStyle="Georgia"
                                    multipleLineAnswer={true}
                                />

                                <ReactScroll.Link
                                    to={'user-address-section'}
                                    spy={false}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                    onClick={() => {
                                        dispatch(
                                            editFormsActions.toggleAccounPrivateAddressEditForm()
                                        )
                                    }}
                                >
                                    <ListItemMobile
                                        item={{
                                            id: 'location',
                                            text: 'location',
                                            answer: accountInternalInfoList.location,
                                        }}
                                        height="80px"
                                        toggle={
                                            () => {}
                                            // toggleAccountExternalInfoEditForm({
                                            //     id: 'location',
                                            //     text: 'location *',
                                            //     answer: accountInternalInfoList
                                            //         ? accountInternalInfoList.location
                                            //         : '',
                                            // })
                                        }
                                    />
                                </ReactScroll.Link>

                                <div style={{ height: '50px' }} />

                                <MobileSettingsSectionHeader
                                    title={
                                        isAccountPrivateAddressInfoOpened !==
                                        true
                                            ? 'Account Details'
                                            : 'update your address'
                                    }
                                />
                                {!isAccountPrivateAddressInfoOpened && (
                                    <div
                                        style={{ paddingTop: '40px' }}
                                        id="user-address-section"
                                    >
                                        <ListManagerMobile
                                            toggle={(item: IList) => {
                                                item.id === 'address'
                                                    ? dispatch(
                                                          editFormsActions.toggleAccounPrivateAddressEditForm()
                                                      )
                                                    : dispatch(
                                                          editFormsActions.toggleAccountPrivateInfoEditForm(
                                                              item
                                                          )
                                                      )
                                            }}
                                            data={accountInternalInfoList}
                                            list={listInternalAccountInfo}
                                        />
                                        <CenteredDiv
                                            style={{ flexDirection: 'column' }}
                                        >
                                            <ButtonAtom
                                                theme="lowercase-red-background"
                                                onClick={() => {
                                                    dispatch(
                                                        usersActions.logoutRequest()
                                                    )
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        textTransform:
                                                            'capitalize',
                                                    }}
                                                >
                                                    Logout
                                                </span>
                                            </ButtonAtom>
                                            <div
                                                style={{ paddingTop: '40px' }}
                                            />

                                            <ButtonAtom
                                                theme="naked-text"
                                                onClick={() =>
                                                    setState({
                                                        ...state,
                                                        accountDeletionWizard: {
                                                            ...state.accountDeletionWizard,
                                                            isOpen: true,
                                                            step:
                                                                cars_no === 0
                                                                    ? 2
                                                                    : 1,
                                                        },
                                                    })
                                                }
                                            >
                                                <span
                                                    style={{
                                                        textTransform:
                                                            'capitalize',
                                                        color: 'var(--error, #DF6F6F)',
                                                        fontSize: 14,
                                                    }}
                                                >
                                                    Delete account
                                                </span>
                                            </ButtonAtom>
                                        </CenteredDiv>
                                    </div>
                                )}
                            </div>
                        </IpadAndMobileDisplay>
                    </Faded>
                )}
            </CenteredPageWrapper>
            {userLoggedIn && (
                <React.Fragment>
                    <DesktopDisplayOnly>
                        {isUSerProfileImageFormOpened && (
                            <ModalDisplayFullPageDesktopExclusive
                                toggle={() => {
                                    dispatch(
                                        editFormsActions.toggleUserProfileImageForm()
                                    )
                                }}
                                isOpen={true}
                            >
                                <WrapperUserProfileImageDesktop
                                    close={() => {
                                        dispatch(
                                            editFormsActions.toggleUserProfileImageForm()
                                        )
                                    }}
                                    user_id={userLoggedIn.id}
                                />
                            </ModalDisplayFullPageDesktopExclusive>
                        )}

                        {state.accountDeletionWizard.isOpen && (
                            <ModalDisplay
                                toggle={() => {
                                    dispatch(
                                        editFormsActions.toggleUserProfileImageForm
                                    )
                                }}
                                isOpen={true}
                                customZindex={8}
                            >
                                <AccountDeletionManagerDesktop
                                    step_id={state.accountDeletionWizard.step}
                                    user={userLoggedIn}
                                    changeSteps={(step: number) =>
                                        setState({
                                            ...state,
                                            accountDeletionWizard: {
                                                ...state.accountDeletionWizard,
                                                step: step,
                                            },
                                        })
                                    }
                                    handleChange={(
                                        id: keyof IAccDelItems,
                                        value: any
                                    ) =>
                                        setState({
                                            ...state,
                                            accountDeletionWizard: {
                                                ...state.accountDeletionWizard,
                                                answers: {
                                                    ...state
                                                        .accountDeletionWizard
                                                        .answers,
                                                    [id]: value,
                                                },
                                            },
                                        })
                                    }
                                    activeItems={
                                        state.accountDeletionWizard.answers
                                    }
                                    cars_no={cars_no}
                                    history_files_no={history_file_no}
                                    tasks_no={tasks_no}
                                    onClose={() =>
                                        setState({
                                            ...state,
                                            accountDeletionWizard: {
                                                ...state.accountDeletionWizard,
                                                isOpen: false,
                                            },
                                        })
                                    }
                                    deleteAccount={() => {
                                        dispatch(
                                            usersActions.deleteAccountRequest(
                                                state.accountDeletionWizard
                                                    .answers
                                            )
                                        )
                                    }}
                                />
                            </ModalDisplay>
                        )}

                        {form_to_open === 'profile_picture' && (
                            <ModalDisplay
                                toggle={() => {
                                    navigate(`/account`)
                                }}
                                isOpen={true}
                                customZindex={8}
                            >
                                <WrapperUserProfileImageDesktop
                                    close={() => navigate(`/account`)}
                                    user_id={userLoggedIn.id}
                                />
                            </ModalDisplay>
                        )}

                        {isAccountExternalInfoFormOpened && (
                            <ModalDisplay
                                toggle={() => {
                                    dispatch(
                                        editFormsActions.toggleAccountExternalInfoEditForm()
                                    )
                                }}
                                isOpen={true}
                                customZindex={8}
                            >
                                <AccountExternalInfoFormDesktop
                                    close={() => {
                                        dispatch(
                                            editFormsActions.toggleAccountExternalInfoEditForm()
                                        )
                                    }}
                                    userLoggedIn={userLoggedIn}
                                    submit_func={(
                                        p: IUSerGeneralInfoUpdatePayload
                                    ) => {
                                        dispatch(
                                            usersActions.updateUserGeneralInfoRequest(
                                                p
                                            )
                                        )
                                    }}
                                />
                            </ModalDisplay>
                        )}

                        {isAccountPrivateInfoFormOpened && (
                            <ModalDisplay
                                toggle={() => {
                                    dispatch(
                                        editFormsActions.toggleAccountPrivateInfoEditForm()
                                    )
                                }}
                                isOpen={true}
                                customZindex={8}
                            >
                                <AccountPrivateInfoManagerFormDesktop
                                    toggle={() => {
                                        dispatch(
                                            editFormsActions.toggleAccountPrivateInfoEditForm()
                                        )
                                    }}
                                    userLoggedIn={userLoggedIn}
                                    item={currentPrivateItemEdited}
                                    submit_func={(
                                        p: IUSerGeneralInfoUpdatePayload
                                    ) => {
                                        dispatch(
                                            usersActions.updateUserGeneralInfoRequest(
                                                p
                                            )
                                        )
                                    }}
                                    setToResetPasswordClicked={
                                        setToResetPasswordClicked
                                    }
                                    hasResetPasswordBeenClicked={
                                        hasResetPasswordBeenClicked
                                    }
                                    request_reset_password_func={(p: {
                                        passwordGiven: string
                                        uid: string
                                    }) => {
                                        dispatch(
                                            usersActions.resetPasswordRequest(
                                                p.passwordGiven
                                            )
                                        )
                                    }}
                                />
                            </ModalDisplay>
                        )}
                    </DesktopDisplayOnly>

                    <IpadAndMobileDisplay>
                        <BottomSheetPage
                            isOpen={state.accountDeletionWizard.isOpen}
                        >
                            <AccountDeletionManagerMobile
                                step_id={state.accountDeletionWizard.step}
                                user={userLoggedIn}
                                changeSteps={(step: number) =>
                                    setState({
                                        ...state,
                                        accountDeletionWizard: {
                                            ...state.accountDeletionWizard,
                                            step: step,
                                        },
                                    })
                                }
                                handleChange={(
                                    id: keyof IAccDelItems,
                                    value: any
                                ) =>
                                    setState({
                                        ...state,
                                        accountDeletionWizard: {
                                            ...state.accountDeletionWizard,
                                            answers: {
                                                ...state.accountDeletionWizard
                                                    .answers,
                                                [id]: value,
                                            },
                                        },
                                    })
                                }
                                activeItems={
                                    state.accountDeletionWizard.answers
                                }
                                cars_no={cars_no}
                                history_files_no={history_file_no}
                                tasks_no={tasks_no}
                                onClose={() =>
                                    setState({
                                        ...state,
                                        accountDeletionWizard: {
                                            ...state.accountDeletionWizard,
                                            isOpen: false,
                                        },
                                    })
                                }
                                deleteAccount={() => {
                                    dispatch(
                                        usersActions.deleteAccountRequest(
                                            state.accountDeletionWizard.answers
                                        )
                                    )
                                }}
                            />
                        </BottomSheetPage>

                        {isUSerProfileImageFormOpened && (
                            <ModalDisplay
                                toggle={() => {
                                    dispatch(
                                        editFormsActions.toggleUserProfileImageForm()
                                    )
                                }}
                                isOpen={true}
                            >
                                <WrapperUserProfileImageMobile
                                    close={() => {
                                        dispatch(
                                            editFormsActions.toggleUserProfileImageForm()
                                        )
                                    }}
                                    user_id={userLoggedIn.id}
                                />
                            </ModalDisplay>
                        )}

                        {form_to_open === 'profile_picture' && (
                            <ModalDisplay
                                toggle={() => {
                                    navigate(`/account`)
                                }}
                                isOpen={true}
                            >
                                <WrapperUserProfileImageMobile
                                    close={() => navigate(`/account`)}
                                    user_id={userLoggedIn.id}
                                />
                            </ModalDisplay>
                        )}

                        {isAccountExternalInfoFormOpened &&
                            currentExternalItemEdited &&
                            currentExternalItemEdited.id !== 'location' && (
                                <ModalDisplay
                                    toggle={() => {
                                        dispatch(
                                            editFormsActions.toggleAccountExternalInfoEditForm()
                                        )
                                    }}
                                    isOpen={true}
                                >
                                    <AccountExternalInfoFormMobile
                                        toggle={() => {
                                            dispatch(
                                                editFormsActions.toggleAccountExternalInfoEditForm()
                                            )
                                        }}
                                        item={currentExternalItemEdited}
                                        submitFunc={(
                                            p: IUSerGeneralInfoUpdatePayload
                                        ) => {
                                            dispatch(
                                                usersActions.updateUserGeneralInfoRequest(
                                                    p
                                                )
                                            )
                                        }}
                                        user={userLoggedIn}
                                    />
                                </ModalDisplay>
                            )}

                        {isAccountPrivateInfoFormOpened && (
                            <ModalDisplay
                                toggle={() => {
                                    dispatch(
                                        editFormsActions.toggleAccountPrivateInfoEditForm()
                                    )
                                }}
                                isOpen={true}
                            >
                                <AccountPrivateInfoManagerMobileForm
                                    toggle={() => {
                                        dispatch(
                                            editFormsActions.toggleAccountPrivateInfoEditForm()
                                        )
                                    }}
                                    userLoggedIn={userLoggedIn}
                                    item={currentPrivateItemEdited}
                                    submit_func={(
                                        p: IUSerGeneralInfoUpdatePayload
                                    ) => {
                                        dispatch(
                                            usersActions.updateUserGeneralInfoRequest(
                                                p
                                            )
                                        )
                                    }}
                                    setToResetPasswordClicked={
                                        setToResetPasswordClicked
                                    }
                                    hasResetPasswordBeenClicked={
                                        hasResetPasswordBeenClicked
                                    }
                                    request_reset_password_func={(p: {
                                        passwordGiven: string
                                        uid: string
                                    }) => {
                                        dispatch(
                                            usersActions.resetPasswordRequest(
                                                p.passwordGiven
                                            )
                                        )
                                    }}
                                />
                            </ModalDisplay>
                        )}

                        {isAccountPrivateAddressInfoOpened &&
                            countriesdropdownlist && (
                                <CenterDiv>
                                    <AccountAddressFormManagerMobile
                                        countriesdropdownlist={
                                            countriesdropdownlist
                                        }
                                        usaAdminCodes={usaAdminCodes}
                                        addAddress={(p: IUserAddressApi) => {
                                            dispatch(
                                                usersActions.addAddressRequest({
                                                    data: p,
                                                })
                                            )
                                        }}
                                        currentAddress={
                                            userLoggedIn.address
                                                ? userLoggedIn.address
                                                : null
                                        }
                                        toggleAddresForm={() => {
                                            dispatch(
                                                editFormsActions.toggleAccounPrivateAddressEditForm()
                                            )
                                        }}
                                    />
                                </CenterDiv>
                            )}

                        <div style={{ paddingTop: '200px' }} />
                    </IpadAndMobileDisplay>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export default AccountPage
