import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IPublicShareSuccessPayloadRedux } from '../garages/actions/actions'
import { I_archiveCar_api_req_args } from '../../services/archive/archiveCar'
import { ICreateHandoverAPI_req } from '../../services/handover/createHandover'
import { ITimeRangeEnum } from '../../../components/atoms/menu/carInsights/timeMenu'
import {
    IFilterCarSharesDisplay_change_success,
    ISearchQueryCarSharesDisplay_change_success,
    IDeleteShareReq,
    IEntitySharesOwnedDataArg,
    IDirectShareSuccessPayloadRedux,
    IShareRecipientRequestDeletion_success,
    IcreateHandoverSuccessArg,
    IGetLastCarInsuranceEntrySuccess,
    IGetCarInsightsSuccess,
    IGetCarCategoryInsightsSuccess,
    ICreateShareInviteRequestPayload,
    IFilterCarSharesDisplay_change_request,
    IgetSingleDirectShareRequest_redux,
    ISearchQueryCarSharesDisplay_change_request,
    IShareInviteDeletion,
    IShareInvitResent,
    IShareRecipientRequest_redux,
    ISortCarSharesDisplay_change_request,
    ISortCarSharesDisplay_change_success,
} from './actions'
import { ITimelineEntryTypes } from '../../timeline/types'
import { IGalleryImage } from '../galleries/types'
import {
    ICarsState,
    IReduxError,
    ICarsObject,
    IExternalCarsObject,
    IGetCarActionCheckSuccess,
    IUpdateCarGeneralPayloadReq,
    IAddACarInitialPayload,
    IUpdateCarImagePayload,
    IExternalCarDataRequestPayload,
    IExternalCarDataRequestPayloadVersion2,
    IExternalDataByRegistrationNumberObject,
    ITasksNormalisedByCar,
    ISortShareRecipientsDisplay_change_success,
    ISortShareRecipientsDisplay_change_request,
    IDirectSharesSuccessPayload_redux_arg,
    IUpdateCarOverviewGridInfo,
    IDirectShareOwnedItemsData,
    ICreateDirectShareRequest,
    IUpdateCarDirectShareSuccess,
    IUpdateCarDirectShareRequest,
    IDirectShareOwnedItem,
} from './types'
import { ICreateCarVersion2 } from 'entityModels'

const initialState: ICarsState = {
    cars: {},
    directly_shared_ext_cars: {},
    currentCarID: undefined,
    currentCarID_ext_directShare: undefined,
    tasks: {},
    error: null,
    loading: false,
    carSharesLoading: false,
    car_checks_loading: false,
    showcaseCarQRcodeShortUrl: undefined,
}

const carsSlice = createSlice({
    name: 'cars',
    initialState,
    reducers: {
        getCarDataRequest: (state) => {
            state.loading = true
            state.error = null
        },

        getCarDataError: (state, action: PayloadAction<IReduxError>) => {
            state.loading = false
            state.error = action.payload
        },

        getCarDataSuccess: (state, action: PayloadAction<ICarsObject>) => {
            state.cars = {
                ...state.cars,
                ...action.payload,
            }
            state.loading = false
            state.error = null
        },

        getExternalCarsSharedWithYouRequest: (
            state,
            action: PayloadAction<string>
        ) => {
            state.loading = true
            state.error = null
        },

        getExternalCarsSharedWithYouError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },

        getExternalCarsSharedWithYouSuccess: (
            state,
            action: PayloadAction<IExternalCarsObject>
        ) => {
            state.directly_shared_ext_cars = {
                ...state.directly_shared_ext_cars,
                ...action.payload,
            }
            state.loading = false
            state.error = null
        },

        getCarDataByIdRequest: (state, action: PayloadAction<string>) => {
            state.error = null
        },

        setEmptyCarRequest: (state, payload: PayloadAction<string>) => {
            state.loading = true
            state.error = null
        },

        getCarDataByIdError: (state, action: PayloadAction<IReduxError>) => {
            state.loading = false
            state.error = action.payload
        },

        getCarDataByIdSuccess: (state, action: PayloadAction<ICarsObject>) => {
            const carID = Object.keys(action.payload)[0]
            const currentCarData = state.cars[carID]
                ? { ...state.cars[carID] }
                : {}

            state.cars = {
                ...state.cars,
                [carID]: {
                    ...currentCarData,
                    ...action.payload[carID],
                },
            }
            state.loading = false
            state.error = null
        },

        // Car Action Checks
        getCarActionCheckRequest: (
            state,
            action: PayloadAction<{ carid: string }>
        ) => {
            state.car_checks_loading = true
            state.error = null
        },

        getCarActionCheckError: (state, action: PayloadAction<IReduxError>) => {
            state.car_checks_loading = false
            state.error = action.payload
        },

        getCarActionCheckSuccess: (
            state,
            action: PayloadAction<IGetCarActionCheckSuccess>
        ) => {
            if (state.cars && state.cars[action.payload.carid]) {
                //@ts-ignore
                state.cars[action.payload.carid].car_actions_checks = state
                    .cars[action.payload.carid].car_actions_checks
                    ? {
                          ...state.cars[action.payload.carid]
                              .car_actions_checks,
                          ...action.payload.action_check,
                      }
                    : {
                          ...action.payload.action_check,
                      }
            }
            state.car_checks_loading = false
        },

        setCurrentCar: (state, action: PayloadAction<string | undefined>) => {
            state.currentCarID = action.payload
            state.loading = false
            state.error = null
        },

        setCurrentExternalCarRequest: (
            state,
            action: PayloadAction<string | undefined>
        ) => {
            state.loading = true
            state.error = null
        },

        setCurrentExternalCarSuccess: (
            state,
            action: PayloadAction<string | undefined>
        ) => {
            state.currentCarID_ext_directShare = action.payload
            state.loading = false
            state.error = null
        },

        // Garage Cars
        getGarageCarsDataRequest: (
            state,
            action: PayloadAction<string | undefined>
        ) => {
            state.loading = true
            state.error = null
        },

        getGarageCarsDataError: (state, action: PayloadAction<IReduxError>) => {
            state.loading = false
            state.error = action.payload
        },

        resetSearchQueryCarShareDisplay_request: (
            state,
            action: PayloadAction<string>
        ) => {},

        getGarageCarsDataSuccess: (
            state,
            action: PayloadAction<ICarsObject>
        ) => {
            state.cars = {
                ...state.cars,
                ...action.payload,
            }
            state.loading = false
            state.error = null
        },

        // Write Car Data
        writeCarDataGeneralRequest: (
            state,
            action: PayloadAction<IUpdateCarGeneralPayloadReq>
        ) => {
            state.loading = true
            state.error = null
        },

        writeCarDataGeneralError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },

        writeCarDataGeneralSuccess: (
            state,
            action: PayloadAction<ICarsObject>
        ) => {
            state.cars = {
                ...state.cars,
                ...action.payload,
            }
            state.loading = false
            state.error = null
        },

        // Create Car
        createCarRequest: (
            state,
            action: PayloadAction<IAddACarInitialPayload>
        ) => {
            state.loading = true
            state.error = null
        },

        createCarError: (state, action: PayloadAction<IReduxError>) => {
            state.loading = false
            state.error = action.payload
        },

        createCarSuccess: (state, action: PayloadAction<ICarsObject>) => {
            state.cars = {
                ...state.cars,
                ...action.payload,
            }
            state.loading = false
            state.error = null
        },

        // // Update Car Banner URL
        // updateCarBannerUrlRequest: (state) => {
        //     state.loading = true
        //     state.error = null
        // },

        updateCarBannerUrlError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },

        updateCarBannerUrlSuccess: (
            state,
            action: PayloadAction<IUpdateCarImagePayload>
        ) => {
            state.cars = {
                ...state.cars,
                [action.payload.car_id]: {
                    ...state.cars[action.payload.car_id],
                    banner_url: action.payload.image,
                },
            }
            state.loading = false
            state.error = null
        },

        // External Car Data
        getExternalCarDataByRegistrationNumberRequest: (
            state,
            action: PayloadAction<IExternalCarDataRequestPayload>
        ) => {
            state.loading = true
            state.error = null
        },

        getExternalCarDataByRegistrationNumberRequestVersion2: (
            state,
            action: PayloadAction<IExternalCarDataRequestPayloadVersion2>
        ) => {
            state.loading = true
            state.error = null
        },

        getExternalCarDataByRegistrationNumberError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },

        getExternalCarDataByRegistrationNumberSuccess: (
            state,
            action: PayloadAction<IExternalDataByRegistrationNumberObject>
        ) => {
            state.externalDataByRegistrationNumber = {
                ...state.externalDataByRegistrationNumber,
                ...action.payload,
            }
            state.cars = state.cars
            state.loading = false
            state.error = null
        },

        getExternalCarDataByRegistrationNumberForInsuranceSuccess: (state) => {
            state.loading = false
            state.error = null
        },

        // Task IDs
        setCarTaskIdsRequest: (state) => {
            state.loading = true
            state.error = null
        },

        setCarTaskIdsError: (state, action: PayloadAction<IReduxError>) => {
            state.loading = false
            state.error = action.payload
        },

        setCarTaskIdsSuccess: (
            state,
            action: PayloadAction<ITasksNormalisedByCar>
        ) => {
            state.tasks = {
                ...state.tasks,
                ...action.payload,
            }
            state.loading = false
            state.error = null
        },

        // Delete Car
        deleteCarRequest: (state, action: PayloadAction<string>) => {
            state.loading = true
            state.error = null
        },

        deleteCarError: (state, action: PayloadAction<IReduxError>) => {
            state.loading = false
            state.error = action.payload
        },

        deleteCarSuccess: (state, action: PayloadAction<string>) => {
            state.loading = false
            state.error = null
            delete state.cars[action.payload]
        },

        // Car Shares Display
        sortCarSharesDisplay_change_success: (
            state,
            action: PayloadAction<ISortCarSharesDisplay_change_success>
        ) => {
            state.carSharesLoading = false
            state.error = null

            state.cars[action.payload.carid] = {
                ...state.cars[action.payload.carid],
                private_shares_owned: {
                    ...state.cars[action.payload.carid].private_shares_owned!,
                    active_display: {
                        ...state.cars[action.payload.carid].private_shares_owned
                            ?.active_display!,
                        activeSort: action.payload.sortID!,
                        item_ids_display: [...action.payload.item_ids_display]!,
                        isLoading: false,
                    },
                },
            }
        },

        filterCarSharesDisplay_change_success: (
            state,
            action: PayloadAction<IFilterCarSharesDisplay_change_success>
        ) => {
            state.carSharesLoading = false
            state.error = null

            state.cars[action.payload.carid] = {
                ...state.cars[action.payload.carid],
                private_shares_owned: {
                    ...state.cars[action.payload.carid].private_shares_owned!,
                    active_display: {
                        ...state.cars[action.payload.carid].private_shares_owned
                            ?.active_display!,
                        activeFilter: action.payload.filterIDs,
                        item_ids_display: [...action.payload.item_ids_display],
                        isLoading: false,
                    },
                },
            }
        },

        searchQueryCarSharesDisplay_change_success: (
            state,
            action: PayloadAction<ISearchQueryCarSharesDisplay_change_success>
        ) => {
            state.carSharesLoading = false
            state.error = null

            state.cars[action.payload.carid] = {
                ...state.cars[action.payload.carid]!,
                private_shares_owned: {
                    ...state.cars[action.payload.carid]!.private_shares_owned!,
                    active_display: {
                        ...state.cars[action.payload.carid]
                            .private_shares_owned!.active_display!,
                        activeSearchQuery: action.payload.searchQuery!,
                        item_ids_display: [...action.payload.item_ids_display]!,
                        isLoading: false,
                    },
                },
            }
        },

        resetSearchQueryCarShareDisplay_success: (
            state,
            action: PayloadAction<string>
        ) => {
            state.carSharesLoading = false
            state.error = null

            state.cars[action.payload] = {
                ...state.cars[action.payload]!,
                private_shares_owned: {
                    ...state.cars[action.payload].private_shares_owned!,
                    active_display: {
                        ...state.cars[action.payload].private_shares_owned
                            ?.active_display!,
                        activeSearchQuery: '',
                        item_ids_display:
                            state.cars[action.payload].private_shares_owned!
                                ?.entity_owned_direct_shares_ids!,
                        isLoading: false,
                    },
                },
            }
        },

        sortShareRecipientsDisplay_change_success: (
            state,
            action: PayloadAction<ISortShareRecipientsDisplay_change_success>
        ) => {
            state.loading = false
            state.error = null

            state.cars[action.payload.entityId].private_shares_owned = {
                ...state.cars[action.payload.entityId].private_shares_owned!,
                entity_owned_direct_shares_data: {
                    ...state.cars[action.payload.entityId].private_shares_owned!
                        .entity_owned_direct_shares_data!,
                    [action.payload.shareid]: {
                        ...state.cars[action.payload.entityId]
                            .private_shares_owned!
                            .entity_owned_direct_shares_data[
                            action.payload.shareid
                        ]!,
                        active_display: {
                            ...state.cars[action.payload.entityId]
                                .private_shares_owned!
                                .entity_owned_direct_shares_data[
                                action.payload.shareid
                            ].active_display!,
                            activeSort: action.payload.sortID,
                            item_ids_display: [
                                ...action.payload.item_ids_display!,
                            ],
                            isLoading: false,
                        },
                    },
                },
            }
        },

        searchQueryCarSharesDisplay_change_request: (
            state,
            action: PayloadAction<ISearchQueryCarSharesDisplay_change_request>
        ) => {
            state.carSharesLoading = true
            state.error = null

            state.cars[action.payload.carid] = {
                ...state.cars[action.payload.carid],
                private_shares_owned: {
                    ...state.cars[action.payload.carid].private_shares_owned!,
                    active_display: {
                        ...state.cars[action.payload.carid].private_shares_owned
                            ?.active_display!,
                        isLoading: true,
                    },
                },
            }
        },
        filterCarSharesDisplay_change_request: (
            state,
            action: PayloadAction<IFilterCarSharesDisplay_change_request>
        ) => {
            state.carSharesLoading = true
            state.error = null

            state.cars[action.payload.carid] = {
                ...state.cars[action.payload.carid],
                private_shares_owned: {
                    ...state.cars[action.payload.carid].private_shares_owned!,
                    active_display: {
                        ...state.cars[action.payload.carid].private_shares_owned
                            ?.active_display!,
                        isLoading: true,
                    },
                },
            }
        },

        sortCarSharesDisplay_change_request: (
            state,
            action: PayloadAction<ISortCarSharesDisplay_change_request>
        ) => {
            state.carSharesLoading = true
            state.error = null

            state.cars[action.payload.carid] = {
                ...state.cars[action.payload.carid],
                private_shares_owned: {
                    ...state.cars[action.payload.carid].private_shares_owned!,
                    active_display: {
                        ...state.cars[action.payload.carid].private_shares_owned
                            ?.active_display!,
                        isLoading: true,
                    },
                },
            }
        },
        sortShareRecipientsDisplay_change_request: (
            state,
            action: PayloadAction<ISortShareRecipientsDisplay_change_request>
        ) => {
            state.loading = true
            state.error = null

            state.cars[action.payload.entityid] = {
                ...state.cars[action.payload.entityid],
                private_shares_owned: {
                    ...state.cars[action.payload.entityid]
                        .private_shares_owned!,
                    entity_owned_direct_shares_data: {
                        ...state.cars[action.payload.entityid]
                            .private_shares_owned
                            ?.entity_owned_direct_shares_data!,
                        [action.payload.share.id]: {
                            ...state.cars[action.payload.entityid]
                                .private_shares_owned
                                ?.entity_owned_direct_shares_data[
                                action.payload.share.id
                            ]!,
                            active_display: {
                                ...state.cars[action.payload.entityid]
                                    .private_shares_owned
                                    ?.entity_owned_direct_shares_data[
                                    action.payload.share.id
                                ].active_display!,
                                isLoading: true,
                            },
                        },
                    },
                },
            }
        },

        getPublicSharesCarRequest: (state, action: PayloadAction<string>) => {
            state.carSharesLoading = true
            state.error = null
        },

        getPublicSharesCarSuccess: (
            state,
            action: PayloadAction<IPublicShareSuccessPayloadRedux>
        ) => {
            state.cars = {
                ...state.cars,
                [action.payload.id]: {
                    ...state.cars[action.payload.id],
                    public_share_owned: {
                        ...action.payload.public_share,
                    },
                },
            }
            state.loading = false
            state.carSharesLoading = false
            state.error = null
        },

        getPublicSharesCarError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.error = action.payload
        },

        getOwnedDirectSharesCarRequest: (
            state,
            action: PayloadAction<string>
        ) => {
            state.error = null
            state.carSharesLoading = true
        },

        getAllCarSharesRequest: (state, action: PayloadAction<string>) => {
            state.carSharesLoading = true
            state.error = null
        },

        getAllCarSharesSuccess: (state) => {
            state.carSharesLoading = false
            state.error = null
        },

        getOwnedDirectSharesCarSuccess: (
            state,
            action: PayloadAction<IDirectSharesSuccessPayload_redux_arg>
        ) => {
            state.cars = {
                ...state.cars,
                [action.payload.id]: {
                    ...state.cars[action.payload.id],
                    private_shares_owned: {
                        ...action.payload.private_shares_owned,
                    },
                },
            }
            state.carSharesLoading = false
            state.error = null
        },

        getPublicSharesAllGarageCarsSuccess: (state) => {
            state.carSharesLoading = false
            state.error = null
        },

        getAllCarSharesError: (state, action: PayloadAction<IReduxError>) => {
            state.carSharesLoading = false
            state.error = action.payload
        },

        getOwnedDirectSharesCarError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.carSharesLoading = false
            state.error = action.payload
        },

        getPublicSharesAllUserGarageCarsRequest: (
            state,
            action: PayloadAction<string>
        ) => {
            state.carSharesLoading = true
            state.error = null
        },

        createCarPublicShareRequest: (state, action: PayloadAction<string>) => {
            state.carSharesLoading = true
            state.error = null
        },

        createCarPublicShareError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.carSharesLoading = false
            state.error = action.payload
        },

        createCarPublicShareSuccess: (
            state,
            action: PayloadAction<IPublicShareSuccessPayloadRedux>
        ) => {
            state.cars = {
                ...state.cars,
                [action.payload.id]: {
                    ...state.cars[action.payload.id],
                    public_share_owned: {
                        ...action.payload.public_share,
                    },
                },
            }
            state.carSharesLoading = false
            state.error = null
        },

        createShowcaseCarQRcodeShortUrlRequest: (
            state,
            action: PayloadAction<string>
        ) => {
            state.carSharesLoading = true
            state.error = null
        },

        createShowcaseCarQRcodeShortUrlError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.carSharesLoading = false
            state.error = action.payload
        },

        createShowcaseCarQRcodeShortUrlSuccess: (
            state,
            action: PayloadAction<string>
        ) => {
            state.carSharesLoading = false
            state.showcaseCarQRcodeShortUrl = action.payload
            state.error = null
        },

        deleteCarPublicShareRequest: (
            state,
            action: PayloadAction<IDeleteShareReq>
        ) => {
            state.carSharesLoading = true
            state.error = null
        },

        deleteCarPublicShareError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.carSharesLoading = false
            state.error = action.payload
        },

        deleteCarPublicShareSuccess: (
            state,
            action: PayloadAction<IDeleteShareReq>
        ) => {
            state.carSharesLoading = false
            state.error = null

            state.cars[action.payload.entity_id] = {
                ...state.cars[action.payload.entity_id],
                public_share_owned: undefined,
            }
        },

        updatCarOverviewGridInfo: (
            state,
            action: PayloadAction<IUpdateCarOverviewGridInfo>
        ) => {
            state.cars = {
                ...state.cars,
                [action.payload.carid]: {
                    ...state.cars[action.payload.carid],
                    overview: {
                        ...state.cars[action.payload.carid].overview,
                        data: {
                            ...state.cars[action.payload.carid].overview.data,
                            [action.payload.id]: action.payload.data,
                        },
                    },
                },
            }
            state.loading = false
            state.error = null
        },

        updatCarTitleOnCreation: (
            state,
            action: PayloadAction<{ carid: string; title: string }>
        ) => {
            state.cars = {
                ...state.cars,
                [action.payload.carid]: {
                    ...state.cars[action.payload.carid],
                    title: action.payload.title,
                },
            }
            state.loading = false
            state.error = null
        },

        updatCarEntriesCount: (
            state,
            action: PayloadAction<{ carid: string; update: 'add' | 'delete' }>
        ) => {
            let current_car_entries_count: number | undefined =
                state.cars[action.payload.carid] &&
                state.cars[action.payload.carid].car_entries_count
            state.cars = {
                ...state.cars,
                [action.payload.carid]: {
                    ...state.cars[action.payload.carid],
                    car_entries_count:
                        current_car_entries_count !== undefined
                            ? action.payload.update === 'add'
                                ? current_car_entries_count + 1
                                : current_car_entries_count - 1
                            : 0,
                },
            }
            state.loading = false
            state.error = null
        },

        addToCarImagesIdsArray: (
            state,
            action: PayloadAction<{ carid: string; image_ids: string[] }>
        ) => {
            let current_car_images_ids: string[] | undefined =
                state.cars[action.payload.carid] &&
                state.cars[action.payload.carid].gallery &&
                state.cars[action.payload.carid].gallery.images
            let new_images_ids_array: string[] = current_car_images_ids
                ? [...current_car_images_ids]
                : []

            for (let i = 0; i < action.payload.image_ids.length; i++) {
                let indexOfImg: number = new_images_ids_array.indexOf(
                    action.payload.image_ids[i]
                )
                if (indexOfImg < 0) {
                    new_images_ids_array.unshift(action.payload.image_ids[i])
                }
            }

            state.cars = {
                ...state.cars,
                [action.payload.carid]: {
                    ...state.cars[action.payload.carid],
                    gallery: {
                        ...state.cars[action.payload.carid].gallery,
                        images: new_images_ids_array,
                    },
                },
            }
            state.loading = false
            state.error = null
        },

        deleteFromCarImagesIdsArray: (
            state,
            action: PayloadAction<{
                carid: string
                imageid: string
            }>
        ) => {
            let current_car_images_ids: string[] =
                state.cars[action.payload.carid] &&
                state.cars[action.payload.carid].gallery &&
                state.cars[action.payload.carid].gallery.images
                    ? state.cars[action.payload.carid].gallery.images
                    : []

            let new_images_ids_array: string[] | undefined =
                current_car_images_ids ? [...current_car_images_ids] : []

            if (new_images_ids_array) {
                let indexOfImg: number = new_images_ids_array.indexOf(
                    action.payload.imageid
                )
                if (indexOfImg >= 0) {
                    new_images_ids_array.splice(indexOfImg, 1)
                }
            }

            state.cars = {
                ...state.cars,
                [action.payload.carid]: {
                    ...state.cars[action.payload.carid],
                    gallery: {
                        ...state.cars[action.payload.carid].gallery,
                        images: new_images_ids_array,
                    },
                },
            }
        },

        updateCoverImgId: (
            state,
            action: PayloadAction<{
                carid: string
                new_cover_id: string | undefined
            }>
        ) => {
            state.cars = {
                ...state.cars,
                [action.payload.carid]: {
                    ...state.cars[action.payload.carid],
                    gallery: {
                        ...state.cars[action.payload.carid].gallery,
                        cover: action.payload.new_cover_id ?? '',
                    },
                },
            }
        },

        updateBannerImage: (
            state,
            action: PayloadAction<{ carid: string; banner_img: IGalleryImage }>
        ) => {
            state.cars = {
                ...state.cars,
                [action.payload.carid]: {
                    ...state.cars[action.payload.carid],
                    banner_url: action.payload.banner_img,
                },
            }
            state.loading = false
            state.error = null
        },

        populateSingleCarAllOwnedSharesSuccess: (
            state,
            action: PayloadAction<IEntitySharesOwnedDataArg>
        ) => {
            state.loading = false
            state.error = null

            if (state.cars[action.payload.entity_id]) {
                state.cars[action.payload.entity_id] = {
                    ...state.cars[action.payload.entity_id],
                    public_share_owned: {
                        ...action.payload.owned_shares_obj.public_share_owned,
                    },
                    private_shares_owned: action.payload.owned_shares_obj
                        .private_shares_owned
                        ? {
                              ...action.payload.owned_shares_obj
                                  .private_shares_owned,
                          }
                        : undefined,
                }
            }
        },

        createCarDirectShareSuccess: (
            state,
            action: PayloadAction<IDirectShareSuccessPayloadRedux>
        ) => {
            let sharing_ids: string[] =
                state.cars[action.payload.id] &&
                state.cars[action.payload.id].private_shares_owned &&
                state.cars[action.payload.id].private_shares_owned!
                    .entity_owned_direct_shares_ids
                    ? state.cars[action.payload.id].private_shares_owned!
                          .entity_owned_direct_shares_ids
                    : []

            let sharing_data: IDirectShareOwnedItemsData =
                state.cars[action.payload.id] &&
                state.cars[action.payload.id].private_shares_owned &&
                state.cars[action.payload.id].private_shares_owned!
                    .entity_owned_direct_shares_data
                    ? state.cars[action.payload.id].private_shares_owned!
                          .entity_owned_direct_shares_data
                    : {}

            state.cars = {
                ...state.cars,
                [action.payload.id]: {
                    ...state.cars[action.payload.id],
                    private_shares_owned: {
                        entity_owned_direct_shares_ids: [
                            ...sharing_ids,
                            action.payload.direct_share.id,
                        ],
                        entity_owned_direct_shares_data: {
                            ...sharing_data,
                            [action.payload.direct_share.id]: {
                                ...action.payload.direct_share,
                            },
                        },
                    },
                },
            }
            state.loading = false
            state.error = null
        },

        createCarDirectShareRequest: (
            state,
            action: PayloadAction<ICreateDirectShareRequest>
        ) => {
            state.carSharesLoading = true
            state.error = null
        },

        createCarDirectShareError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.carSharesLoading = false
            state.error = action.payload
        },

        updateCarDirectShareSuccess: (
            state,
            action: PayloadAction<IUpdateCarDirectShareSuccess>
        ) => {
            let sharing_data_for_update: IDirectShareOwnedItemsData =
                state.cars[action.payload.car_id] &&
                state.cars[action.payload.car_id].private_shares_owned &&
                state.cars[action.payload.car_id].private_shares_owned!
                    .entity_owned_direct_shares_data
                    ? state.cars[action.payload.car_id].private_shares_owned!
                          .entity_owned_direct_shares_data
                    : {}

            state.carSharesLoading = false
            state.error = null

            if (state.cars[action.payload.car_id]) {
                state.cars[action.payload.car_id] = {
                    ...state.cars[action.payload.car_id],
                    private_shares_owned: {
                        ...state.cars[action.payload.car_id]
                            .private_shares_owned!,
                        entity_owned_direct_shares_data: {
                            ...sharing_data_for_update,
                            [action.payload.updated_share.id]: {
                                ...action.payload.updated_share!,
                            },
                        },
                    },
                }
            }
        },

        updateCarDirectShareRequest: (
            state,
            action: PayloadAction<IUpdateCarDirectShareRequest>
        ) => {
            state.carSharesLoading = true
            state.error = null
        },

        updateCarDirectShareError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.carSharesLoading = false
            state.error = action.payload
        },

        getSingleCarDirectShareSuccess: (
            state,
            action: PayloadAction<IDirectShareSuccessPayloadRedux>
        ) => {
            let sharing_ids_2: string[] =
                state.cars[action.payload.id] &&
                state.cars[action.payload.id].private_shares_owned &&
                state.cars[action.payload.id].private_shares_owned!
                    .entity_owned_direct_shares_ids
                    ? state.cars[action.payload.id].private_shares_owned!
                          .entity_owned_direct_shares_ids
                    : []

            let new_sharing_ids: string[] = sharing_ids_2.includes(
                action.payload.direct_share.id
            )
                ? [...sharing_ids_2]
                : [...sharing_ids_2, action.payload.direct_share.id]

            let sharing_data_2: IDirectShareOwnedItemsData =
                state.cars[action.payload.id] &&
                state.cars[action.payload.id].private_shares_owned &&
                state.cars[action.payload.id].private_shares_owned!
                    .entity_owned_direct_shares_data
                    ? state.cars[action.payload.id].private_shares_owned!
                          .entity_owned_direct_shares_data
                    : {}

            state.cars = {
                ...state.cars,
                [action.payload.id]: {
                    ...state.cars[action.payload.id],
                    private_shares_owned: {
                        entity_owned_direct_shares_ids: new_sharing_ids,
                        entity_owned_direct_shares_data: {
                            ...sharing_data_2,
                            [action.payload.direct_share.id]: {
                                ...action.payload.direct_share,
                            },
                        },
                    },
                },
            }
            state.carSharesLoading = false
            state.error = null
        },

        getSingleCarDirectShareRequest: (
            state,
            action: PayloadAction<IgetSingleDirectShareRequest_redux>
        ) => {
            state.carSharesLoading = true
            state.error = null
        },

        getSingleCarDirectShareError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.carSharesLoading = false
            state.error = action.payload
        },

        addCarShareRecipientRequest: (
            state,
            action: PayloadAction<IShareRecipientRequest_redux>
        ) => {
            state.carSharesLoading = true
            state.error = null
        },

        addCarShareRecipientError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.carSharesLoading = false
            state.error = action.payload
        },

        addCarShareRecipientSuccess: (state) => {
            state.carSharesLoading = false
            state.loading = false
            state.error = null
        },

        deleteCarShareRecipientRequest: (
            state,
            action: PayloadAction<IShareRecipientRequest_redux>
        ) => {
            state.carSharesLoading = true
            state.error = null
        },

        deleteCarShareRecipientError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.carSharesLoading = false
            state.error = action.payload
        },

        deleteCarShareRecipientSuccess: (
            state,
            action: PayloadAction<IShareRecipientRequestDeletion_success>
        ) => {
            let current_share: IDirectShareOwnedItem | undefined =
                state.cars[action.payload.entity_id].private_shares_owned!
                    .entity_owned_direct_shares_data[action.payload.share_id]

            let current_all_share_recipients_filtered_for_recipients_deletion =
                current_share.all_share_recipients_ids_list.filter(
                    (id) => id !== action.payload.recipient_id
                )
            let current_platform_share_recipients_filtered_for_recipients_deletion =
                current_share.platform_share_recipients_ids_list.filter(
                    (id) => id !== action.payload.recipient_id
                )

            if (state.cars[action.payload.entity_id].private_shares_owned) {
                state.cars[action.payload.entity_id] = {
                    ...state.cars[action.payload.entity_id],
                    private_shares_owned: {
                        ...state.cars[action.payload.entity_id]
                            .private_shares_owned!,
                        entity_owned_direct_shares_data: {
                            ...state.cars[action.payload.entity_id]
                                .private_shares_owned
                                ?.entity_owned_direct_shares_data,
                            [action.payload.share_id]: {
                                ...current_share,
                                platform_share_recipients_ids_list: [
                                    ...current_platform_share_recipients_filtered_for_recipients_deletion,
                                ],
                                all_share_recipients_ids_list: [
                                    ...current_all_share_recipients_filtered_for_recipients_deletion,
                                ],
                                active_display: {
                                    ...state.cars[action.payload.entity_id]
                                        .private_shares_owned
                                        ?.entity_owned_direct_shares_data
                                        .active_display,
                                    activeSort: 'added_at',
                                    item_ids_display: [
                                        ...current_all_share_recipients_filtered_for_recipients_deletion,
                                    ],
                                },
                            },
                        },
                    },
                }
            }

            state.carSharesLoading = false
            state.error = null
        },

        deleteDirectCarShareError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.carSharesLoading = false
            state.error = action.payload
        },

        deleteCarDirectShareRequest: (
            state,
            action: PayloadAction<IgetSingleDirectShareRequest_redux>
        ) => {
            state.carSharesLoading = true
            state.error = null
        },

        deleteDirectCarShareSuccess: (
            state,
            action: PayloadAction<IDeleteShareReq>
        ) => {
            let car_share_ids_filtered: string[] =
                state.cars[action.payload.entity_id].private_shares_owned &&
                state.cars[action.payload.entity_id].private_shares_owned!
                    .entity_owned_direct_shares_ids
                    ? state.cars[
                          action.payload.entity_id
                      ].private_shares_owned!.entity_owned_direct_shares_ids.filter(
                          (id: string) => id !== action.payload.share_id
                      )
                    : []
            state.loading = false
            state.carSharesLoading = false
            state.error = null

            state.cars[action.payload.entity_id] = {
                ...state.cars[action.payload.entity_id],
                private_shares_owned: {
                    ...state.cars[action.payload.entity_id]
                        .private_shares_owned!,
                    entity_owned_direct_shares_ids: [...car_share_ids_filtered],
                },
            }
        },

        createCarShareInviteSuccess: (state) => {
            state.loading = false
            state.error = null
        },

        createCarShareInviteError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },

        // RESEND CAR SHARE INVITE

        resendCarShareInviteSuccess: (state) => {
            state.loading = false
            state.error = null
        },

        // ACCEPT CAR SHARE INVITE
        acceptCarShareInviteRequest: (
            state,
            action: PayloadAction<{
                invite_code: string
                next_path: string
            }>
        ) => {
            state.loading = true
            state.error = null
        },

        acceptCarShareInviteSuccess: (state) => {
            state.loading = false
            state.error = null
        },

        acceptCarShareInviteError: (
            state,
            action: PayloadAction<IReduxError | null>
        ) => {
            state.loading = false
            state.error = action.payload
        },

        deleteCarShareInviteError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },

        updateCarWithShowroomData: (
            state,
            action: PayloadAction<{
                carid: string
                showroom_uid: string
                type_of_sale: 'SET_PRICE' | 'PRICE_RANGE' | 'PRICE_ON_ASKING'
                state: 'DRAFT' | 'PUBLISHED' | 'ARCHIVED' | 'SOLD'
            }>
        ) => {
            state.cars[action.payload.carid].showroom_entry = {
                ...state.cars[action.payload.carid].showroom_entry,
                uid: action.payload.showroom_uid,
                type_of_sale: action.payload.type_of_sale,
                state: action.payload.state,
            }
        },

        // cleanUpCarDataOnLogoutSuccess: (state) => {
        //     state.cars = {}
        //     state.directly_shared_ext_cars = {}
        //     return Object.assign({}, state, {
        //         ...state,
        //         cars: {
        //             ...state.cars,
        //             [action.payload.entity_id]: {
        //                 ...state.cars[action.payload.entity_id],
        //                 private_shares_owned: {
        //                     ...state.cars[action.payload.entity_id]
        //                         .private_shares_owned,
        //                     entity_owned_direct_shares_ids: [
        //                         ...car_share_ids_filtered,
        //                     ],
        //                 },
        //             },
        //         },
        //         carSharesLoading: false,
        //         error: null,
        //     })
        // },

        // CREATE CAR SHARE INVITE

        createCarShareInviteRequest: (
            state,
            action: PayloadAction<ICreateShareInviteRequestPayload>
        ) => {
            state.loading = true
            state.error = null
        },

        // RESEND CAR SHARE INVITE

        resendCarShareInviteRequest: (
            state,
            action: PayloadAction<IShareInvitResent>
        ) => {
            state.loading = true
            state.error = null
        },

        resendCarShareInviteError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },

        // DELETE CAR SHARE INVITE
        deleteCarShareInviteRequest: (
            state,
            action: PayloadAction<IShareInviteDeletion>
        ) => {
            state.loading = true
            state.error = null
        },

        deleteCarShareInviteSuccess: (
            state,
            action: PayloadAction<IShareInviteDeletion>
        ) => {
            state.loading = false
            state.error = null

            let current_share_for_invites: IDirectShareOwnedItem =
                state.cars[action.payload.entity_id].private_shares_owned!
                    .entity_owned_direct_shares_data[action.payload.share_id]

            let current_all_share_recipients_filtered_for_invite_deletion: string[] =
                current_share_for_invites.all_share_recipients_ids_list.filter(
                    (id) => id !== action.payload.invite_id
                )

            let current_external_share_recipients_filtered_for_invite_deletion: string[] =
                current_share_for_invites.external_share_recipients_ids_list.filter(
                    (id) => id !== action.payload.invite_id
                )

            if (state.cars[action.payload.entity_id].private_shares_owned) {
                state.cars[action.payload.entity_id] = {
                    ...state.cars[action.payload.entity_id],
                    private_shares_owned: {
                        ...state.cars[action.payload.entity_id]
                            .private_shares_owned!,
                        entity_owned_direct_shares_data: {
                            ...state.cars[action.payload.entity_id]
                                .private_shares_owned
                                ?.entity_owned_direct_shares_data,
                            [action.payload.share_id]: {
                                ...current_share_for_invites,
                                external_share_recipients_ids_list: [
                                    ...current_external_share_recipients_filtered_for_invite_deletion,
                                ],
                                all_share_recipients_ids_list: [
                                    ...current_all_share_recipients_filtered_for_invite_deletion,
                                ],
                                active_display: {
                                    activeSort: 'added_at',
                                    item_ids_display: [
                                        ...current_all_share_recipients_filtered_for_invite_deletion,
                                    ],
                                },
                            },
                        },
                    },
                }
            }
        },

        cleanUpCarDataOnLogoutSuccess: (state) => {
            state.cars = {}
            state.directly_shared_ext_cars = {}
            state.currentCarID = undefined
            state.currentCarID_ext_directShare = undefined
            state.tasks = {}
            state.error = null
            state.loading = false
            state.carSharesLoading = false
        },

        // ARCHIVE CAR

        archiveCarRequest: (
            state,
            action: PayloadAction<I_archiveCar_api_req_args>
        ) => {
            state.loading = true
            state.error = null
        },

        archiveCarSuccess: (state) => {
            state.loading = false
            state.error = null
        },

        archiveCarError: (state, action: PayloadAction<IReduxError>) => {
            state.loading = false
            state.error = action.payload
        },

        // SHOWROOM ENTRY REMOVED SYNC
        setCarShowroomEntryDeleted: (state, action: PayloadAction<string>) => {
            state.cars = {
                ...state.cars,
                [action.payload]: {
                    ...state.cars[action.payload],
                    showroom_entry: undefined,
                },
            }
            state.loading = false
            state.error = null
        },

        createHandoverSuccess: (
            state,
            action: PayloadAction<IcreateHandoverSuccessArg>
        ) => {
            state.cars = {
                ...state.cars,
                [action.payload.car_id]: {
                    ...state.cars[action.payload.car_id],
                    handover: action.payload.handover
                        ? { ...action.payload.handover }
                        : undefined,
                },
            }
            state.loading = false
            state.error = null
        },

        createHandoverRequest: (
            state,
            action: PayloadAction<ICreateHandoverAPI_req>
        ) => {
            state.loading = true
            state.error = null
        },

        createHandoverError: (state, action: PayloadAction<IReduxError>) => {
            state.loading = false
            state.error = action.payload
        },

        deleteHandoverSuccess: (state, action: PayloadAction<string>) => {
            state.cars = {
                ...state.cars,
                [action.payload]: {
                    ...state.cars[action.payload],
                    handover: undefined,
                },
            }
            state.loading = false
            state.error = null
        },

        resetCarInsights: (state, action: PayloadAction<string>) => {
            state.cars = {
                ...state.cars,
                [action.payload]: {
                    ...state.cars[action.payload],
                    insights: undefined,
                },
            }
            state.loading = false
            state.error = null
        },

        deleteHandoverRequest: (state, action: PayloadAction<string>) => {
            state.loading = true
            state.error = null
        },

        deleteHandoverError: (state, action: PayloadAction<IReduxError>) => {
            state.loading = false
            state.error = action.payload
        },

        updateCarTasksArrayOnTasksCreationOrDeletion: (
            state,
            action: PayloadAction<{ car_id: string; tasks_ids: string[] }>
        ) => {
            state.tasks = {
                ...state.tasks,
                [action.payload.car_id]: action.payload.tasks_ids,
            }
            state.loading = false
            state.error = null
        },

        getLastCarInsuranceEntrySuccess: (
            state,
            action: PayloadAction<IGetLastCarInsuranceEntrySuccess>
        ) => {
            state.cars = {
                ...state.cars,
                [action.payload.carid]: {
                    ...state.cars[action.payload.carid],
                    last_insurance_entry: action.payload.timelineItem,
                },
            }
        },

        getCarInsightsRequest: (
            state,
            action: PayloadAction<{
                carid: string
                start_date: string
                end_date: string
                timeRange: ITimeRangeEnum
            }>
        ) => {
            state.loading = true
            state.error = null
        },

        getCarCategoryInsightsRequest: (
            state,
            action: PayloadAction<{
                carid: string
                start_date: string
                end_date: string
                timeRange: ITimeRangeEnum
                categoryID: ITimelineEntryTypes | string
                representation: 'monthly' | 'yearly'
            }>
        ) => {
            state.loading = true
            state.error = null
        },

        getCarInsightsError: (state, action: PayloadAction<IReduxError>) => {
            state.loading = false
            state.error = action.payload
        },

        getCarInsightsSuccess: (
            state,
            action: PayloadAction<IGetCarInsightsSuccess>
        ) => {
            state.loading = false

            let carInsightsData = state.cars[action.payload.carid]?.insights
                ? { ...state.cars[action.payload.carid].insights }
                : {}

            state.cars[action.payload.carid].insights = {
                ...carInsightsData,
                [action.payload.timeRange]: {
                    ...action.payload.data,
                },
            }
        },

        getCategoryInsightsSuccess: (
            state,
            action: PayloadAction<IGetCarCategoryInsightsSuccess>
        ) => {
            state.loading = false
            let carInsightsData2 = state.cars[action.payload.carid].insights
                ? { ...state.cars[action.payload.carid].insights }
                : {}

            let carInsightsDataCategory =
                state.cars[action.payload.carid] &&
                state.cars[action.payload.carid].insights &&
                state.cars[action.payload.carid].insights?.categories &&
                // @ts-ignore
                state.cars[action.payload.carid].insights?.categories![
                    action.payload.categoryID
                ]
                    ? {
                          // @ts-ignore
                          ...state.cars[action.payload.carid]!.insights!
                              .categories![action.payload.categoryID]!,
                      }
                    : {}

            let carInsightsDataCategoryTimeType =
                state.cars[action.payload.carid] &&
                state.cars[action.payload.carid].insights &&
                state.cars[action.payload.carid].insights?.categories &&
                // @ts-ignore
                state.cars[action.payload.carid].insights?.categories![
                    action.payload.categoryID
                ] &&
                // @ts-ignore
                state.cars[action.payload.carid].insights?.categories![
                    action.payload.categoryID
                ][action.payload.timeRange]
                    ? {
                          // @ts-ignore
                          ...state.cars[action.payload.carid].insights
                              ?.categories![action.payload.categoryID][
                              action.payload.timeRange
                          ],
                      }
                    : {}

            let carInsightsDataCategories =
                state.cars[action.payload.carid] &&
                state.cars[action.payload.carid].insights &&
                state.cars[action.payload.carid].insights?.categories &&
                state.cars[action.payload.carid].insights?.categories
                    ? {
                          ...state.cars[action.payload.carid]!.insights!
                              .categories,
                      }
                    : {}

            state.cars[action.payload.carid].insights = {
                ...carInsightsData2,
                // @ts-ignore
                categories: {
                    ...carInsightsDataCategories,
                    [action.payload.categoryID]: {
                        ...carInsightsDataCategory,
                        [action.payload.timeRange]: {
                            ...carInsightsDataCategoryTimeType,
                            [action.payload.representation]: action.payload.data
                                ? [...action.payload.data]
                                : undefined,
                        },
                    },
                },
            }
        },

        getLastCarInsuranceEntryRequest: (
            state,
            action: PayloadAction<string>
        ) => {
            // should be non blocking as more important stuff
            // state.loading = true
        },

        getLastCarInsuranceEntryError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            // should be non blocking as more important stuff
            // state.loading = false
            // state.error = action.payload
        },

        removeNewCarDuplicate: (state) => {
            const carsCopy = { ...state.cars }
            delete carsCopy['new_car']

            state.cars = {
                ...carsCopy,
            }
        },

        createCarRequestVersion2: (
            state,
            action: PayloadAction<ICreateCarVersion2>
        ) => {},
    },
})

export const carActions = carsSlice.actions

export default carsSlice.reducer
