import styled from 'styled-components'
import colours from '../../../providers/theme/colours'
import { IRootState } from '../../../redux/store'
import { useAppSelector } from '../../../redux/store/hooks'
import { determineAnswerType } from './listItemDesktop'
import useThemes from '../../../providers/theme/hooks'
import { IUser } from '../../../redux/user/types'

const Wrap = styled.section<IStyle>`
    width: 100%;
    box-sizing: border-box;
    height: 100%;
`

const ListItem = styled.div<IStyle>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    flex-wrap: wrap;
    border-bottom: solid 0.05rem var(--border-muted, #e5e5e5);

    min-height: ${(props) => (props.minHeight ? props.minHeight : '65px')};
    padding-right: 24px;
    padding-left: 24px;
    padding-top: 10px;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: Lato;
`

// height: ${(props) => (props.height ? props.height : '65px')};

const Title = styled.div<IStyle>`
    font-family: Lato-Light;
    font-size: 14px;
    padding-right: 20px;
    text-transform: capitalize;
    color: var(--text-darker, #616161);
    padding-bottom: ${(props) => (props.height ? '10px' : '2px')};
`

const Answer = styled.div<IStyle>`
    text-transform: ${(props) =>
        props.isLowercase
            ? 'lowercase'
            : props.isUppercase
            ? 'uppercase'
            : props.fontStyle
            ? 'auto'
            : 'capitalize'};
    font-family: Lato;
    font-size: 14px;
    color: ${(props) =>
        (props.removeEdit || props.fieldEmpty) && !props.readOnlyMode
            ? 'var(--text-muted)'
            : 'var(--text-strong, #1a1a1a)'};
    font-family: ${(props) => (props.fieldEmpty ? ' Lato-light' : 'inherit')};
    font-weight: 500;
    overflow-wrap: anywhere;
    ${(props) => props.multipleLineAnswer && 'white-space: pre-line;'};
`
const Answers = styled.div<IStyle>`
    text-transform: ${(props) => (props.fontStyle ? 'auto' : 'capitalize')};
    font-family: Lato;
    font-size: 14px;
    color: ${(props) =>
        props.removeEdit ? 'var(--text-muted)' : 'var(--text-strong, #1a1a1a)'};

    font-weight: 500;
    overflow-wrap: anywhere;
`
const WrapAnwersMultiple = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
`

interface Props {
    item: any
    toggle: any
    fontStyle?: string | undefined
    height?: string | undefined
    minHeight?: string | undefined
    removeEdit?: boolean | undefined
    unit?: string | undefined
    multipleLineAnswer?: boolean | undefined
    dataCyId?: string
    requiredLabel?: boolean
    readOnlyMode?: boolean
}

interface IStyle {
    fontStyle?: string | undefined
    height?: string | undefined
    minHeight?: string | undefined
    isLowercase?: boolean | undefined
    isUppercase?: boolean | undefined
    removeEdit?: boolean | undefined
    multipleLineAnswer?: boolean | undefined
    fieldEmpty?: boolean
    readOnlyMode?: boolean
}

const ListItemMobile = (props: Props) => {
    const { theme } = useThemes()
    let userLoggedIn: IUser | null = useAppSelector(
        (state: IRootState) => state.user?.userLoggedIn
    )

    let hasNoNames =
        userLoggedIn?.family_name === userLoggedIn?.given_name ? true : false

    return (
        <Wrap
            onClick={() => {
                !props.removeEdit &&
                    !props.readOnlyMode &&
                    props.toggle(props.item)
            }}
        >
            {props.item !== undefined && (
                <ListItem
                    height={props.height && props.height}
                    minHeight={props.minHeight && props.minHeight}
                    data-attr={props.dataCyId && props.dataCyId}
                >
                    <Title
                        fontStyle={props.fontStyle && props.fontStyle}
                        height={props.height && props.height}
                    >
                        {props.requiredLabel &&
                        (props.item.id === 'make' ||
                            props.item.id === 'model') ? (
                            <span>
                                {props.item.text}
                                {` `}
                                <div
                                    style={{
                                        display: 'inline-block',
                                        fontSize: '10px',
                                        transform: 'translateY(-7px)',
                                        textTransform: 'lowercase',
                                        fontStyle: 'italic',
                                        color: '#ff5a60',
                                    }}
                                >
                                    {`required `}
                                </div>
                                {` `}
                                <div
                                    style={{
                                        display: 'inline-block',
                                        transform: 'translateY(-3px)',
                                        color: '#ff5a60',
                                    }}
                                >
                                    {` *`}
                                </div>
                            </span>
                        ) : (
                            props.item.text
                        )}
                    </Title>

                    {props.item.answers ? (
                        <WrapAnwersMultiple>
                            {props.item.answers.length < 0
                                ? '-'
                                : props.item.answers.map(
                                      (item: any, index: number) => {
                                          return (
                                              <Answers
                                                  readOnlyMode={
                                                      props.readOnlyMode
                                                  }
                                                  fontStyle={
                                                      props.fontStyle &&
                                                      props.fontStyle
                                                  }
                                                  key={`list_item_${item.id}_${index}_mobile`}
                                              >
                                                  {item}{' '}
                                                  {props.unit && props.unit}
                                              </Answers>
                                          )
                                      }
                                  )}
                        </WrapAnwersMultiple>
                    ) : (
                        <Answer
                            readOnlyMode={props.readOnlyMode}
                            removeEdit={props.removeEdit}
                            isLowercase={
                                (props.item.id && props.item.id === 'email') ||
                                (props.item.id &&
                                    props.item.id === 'quantity_units') ||
                                (props.item.id &&
                                    props.item.id === 'distance_units')
                                    ? true
                                    : false
                            }
                            isUppercase={
                                props.item.id &&
                                props.item.id === 'registration_number'
                                    ? true
                                    : false
                            }
                            fontStyle={props.fontStyle && props.fontStyle}
                            multipleLineAnswer={props.multipleLineAnswer}
                            fieldEmpty={
                                props.item.id === 'one_liner' &&
                                props.item.answer === ''
                            }
                        >
                            {/* {determineAnswerType(
                                props.item.answer,
                                props.item.custom ? props.item.custom : null,
                                props.item.id
                            )} */}

                            {hasNoNames &&
                            (props.item.id === 'family_name' ||
                                props.item.id === 'given_name') ? (
                                <div
                                    style={{
                                        color: colours[theme].primary,
                                    }}
                                >
                                    Add your {props.item.text}
                                </div>
                            ) : (
                                determineAnswerType(
                                    props.item.answer,
                                    props.item.custom
                                        ? props.item.custom
                                        : null,
                                    props.item.id
                                )
                            )}

                            {props.item.id === 'engine_capacity' &&
                                props.item.answer && (
                                    <span
                                        style={{
                                            paddingLeft: '6px',
                                            textTransform: 'initial',
                                        }}
                                    >
                                        {props.unit && props.unit}
                                    </span>
                                )}

                            {props.item.id === 'mileage' &&
                                props.item.answer !== null &&
                                props.item.answer !== undefined && (
                                    <span
                                        style={{
                                            paddingLeft: '6px',
                                            textTransform: 'initial',
                                        }}
                                    >
                                        {props.unit && props.unit}
                                    </span>
                                )}
                        </Answer>
                    )}
                </ListItem>
            )}
        </Wrap>
    )
}

export default ListItemMobile
