import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import Faded from '../../templates/animated/faded'
import SmallTimelineItemMobile from '../../molecules/smallTimelineItem/smallTimelineItemMobile'
import { FixedSizeList as List } from 'react-window'
import JourneyStartIcon from '../../atoms/icons/components/journey/playIcon'
import { journey_colours } from '../../../providers/theme/colours'
import { useAppSelector } from '../../../redux/store/hooks'
import { INormalisedAttachmentsByID } from '../../../redux/attachments/types'
import { ITimelineItem } from '../../../redux/timeline/types'

interface IStyled {
    isAnimated?: boolean
}

const Container = styled.div`
    width: 100%;
    box-sizing: border-box;

    @media ${device.desktop} {
        margin-top: 10px;
    }
`
const WrapperView = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: 85vh;

    @media (min-device-height: 600px) {
        height: 78vh;
    }

    @media (min-device-height: 700px) {
        height: 80vh !important;
    }

    @media (min-device-height: 800px) {
        height: 82vh !important;
    }

    @media (min-device-height: 1000px) {
        height: 85vh !important;
    }

    @media (max-device-height: 600px) {
        height: 74vh !important;
    }
`

const Separator = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: 1px;
    background-color: var(--border-muted, #e5e5e5);
    margin-top: 16px;
`

const AnimatedWrapper = styled.div<IStyled>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    box-sizing: border-box;

    @keyframes scale {
        0% {
            transform: scale(1);
        }

        25% {
            transform: scale(1.05);
        }

        50% {
            transform: scale(1);
        }

        100% {
            transform: scale(1.05);
        }
    }

    ${(props) => props.isAnimated && 'animation: 0.4s scale linear'};
    animation-delay: 0.5s;
`

const JourneyWrapper = styled.div`
    border-radius: 8px;
    border: 1px solid var(--journey-history_file-primary_300);
    background: linear-gradient(
        89deg,
        var(--journey-history_file-primary_500_08) 0%,
        var(--bg-color, #fff) 100%
    );
    display: flex;
    height: 100%;
    align-items: center;
    align-self: center;
    width: 100%;
    box-sizing: border-box;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
`

const JournetTxt2 = styled.div`
    color: var(--journey-history_file-primary_900, #67a0e3);
    text-align: center;
    font-family: Lato;
    font-size: 14px;
    letter-spacing: 0.001px;
    max-width: 450px;
`

const JournetTxt1 = styled.div`
    color: var(--journey-history_file-primary_900, #67a0e3);
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    letter-spacing: 0.001px;
`

interface Props {
    timelineItems: ITimelineItem[] | undefined
    activeView: string
    attachmentsObj: INormalisedAttachmentsByID
    dataCyId?: string
    withPlaceholder?: boolean
    onPlaceholderClick?: any
    hasSampleData?: boolean
    userCurrency: string | undefined
    readOnlyMode?: boolean
}

const TimelineViewManagerMobile: React.FC<Props> = ({
    timelineItems,
    activeView,
    attachmentsObj,
    dataCyId,
    withPlaceholder,
    onPlaceholderClick,
    hasSampleData,
    userCurrency,
    readOnlyMode,
}) => {
    const [itemSize, setItemSize] = useState(225)
    // const [listOffsetScrollTo, setListOffsetScrollTo] = useState<
    //     number | undefined
    // >(undefined)

    const listRef = useRef<List>(null)
    const navigate = useNavigate()
    const location = useLocation()
    const { carid } = useParams<{ carid: string }>()

    const currentCarID = useAppSelector(
        (state) => state.entities.carsData.currentCarID
    )

    const handleResize = () => {
        switch (true) {
            case hasSampleData && window.innerWidth > 600:
                return setItemSize(200)
            case hasSampleData && window.innerWidth < 300:
                return setItemSize(240)
            case hasSampleData:
                return setItemSize(220)
            case window.innerWidth < 360:
                return setItemSize(220)
            case window.innerWidth >= 360:
                return setItemSize(220)
            default:
                return setItemSize(220)
        }
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)

        const query_params = new URLSearchParams(location.search)
        let scrolltoentryid: any = query_params.get('scrolltoid')

        if (scrolltoentryid && timelineItems) {
        }

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(() => {
        const query_params = new URLSearchParams(location.search)
        let scrolltoentryid: any = query_params.get('scrolltoid')
        if (scrolltoentryid && timelineItems) {
            const index_to_scroll_to = timelineItems.findIndex(
                (item) => item.id === scrolltoentryid
            )

            const new_list_offset_scroll =
                index_to_scroll_to && index_to_scroll_to > 0
                    ? index_to_scroll_to * itemSize
                    : undefined

            if (listRef?.current && new_list_offset_scroll) {
                listRef.current.scrollTo(new_list_offset_scroll)
            }
        }
    }, [timelineItems])

    const renderRightView = (activeView: string) => {
        let data: ITimelineItem[] = timelineItems ? timelineItems : []
        let onClick = readOnlyMode
            ? (timelineItem: ITimelineItem) => {
                  navigate(
                      `/car/${carid}/history-file/entry?entryid=${timelineItem.id}`
                  )
              }
            : hasSampleData
            ? () => navigate(`/car/${carid}/history-file/create`)
            : (timelineItem: ITimelineItem) => {
                  navigate(
                      `/car/${carid}/history-file/entry?entryid=${timelineItem.id}`
                  )
              }

        if (activeView === 'small') {
            return (
                <Faded>
                    <WrapperView data-attr={dataCyId}>
                        {data.map((item, index) => (
                            <SmallTimelineItemMobile
                                key={item.id}
                                item={item}
                                onClick={() => onClick(item)}
                                dataCyId={`small-timeline-entry-${item.id}`}
                                userCurrency={userCurrency}
                                hasSampleData={hasSampleData}
                                onAddNewLabelClick={() =>
                                    navigate(
                                        `/car/${carid}/history-file/entry?entryid=${item.id}&section_id=labels`
                                    )
                                }
                                readOnlyMode={readOnlyMode}
                                height={itemSize}
                            />
                        ))}
                    </WrapperView>
                    {/* <WrapperView data-attr={dataCyId}>
                        <AutoSizer>
                            {({ width, height }: any) => (
                                <List
                                    ref={listRef}
                                    height={height}
                                    itemCount={
                                        timelineItems ? timelineItems.length : 1
                                    }
                                    itemSize={itemSize}
                                    width={width}
                                    overscanCount={20}
                                    innerElementType={innerElementType}
                                    initialScrollOffset={
                                        listOffsetScrollTo ?? 0
                                    }
                                >
                                    {({ index, style }) =>
                                        renderSmallRow({
                                            data,
                                            index,
                                            style,
                                            onClick,
                                            cardHeight: itemSize,
                                            userCurrency,
                                            hasSampleData,
                                            // navigate,
                                            carid,
                                            readOnlyMode,
                                        })
                                    }
                                </List>
                            )}
                        </AutoSizer>
                    </WrapperView> */}
                </Faded>
            )
        } else if (activeView === 'fat') {
            return <div />
        }
    }

    const params = new URLSearchParams(location.search)
    const section_id: string | null = params.get('section_id')

    return (
        <Container>
            {withPlaceholder && !readOnlyMode && (
                <>
                    <div
                        style={{
                            width: '100%',
                            paddingLeft: 24,
                            paddingRight: 24,
                        }}
                    >
                        <AnimatedWrapper isAnimated={section_id === 'create'}>
                            <JourneyWrapper
                                role="button"
                                onClick={() => {
                                    navigate(
                                        {
                                            pathname: `/journey/history-file`,
                                            search: `?step=welcome&carid=${currentCarID}`,
                                        },
                                        {
                                            state: {
                                                prevPath: `${
                                                    location.pathname
                                                }${location.search ?? ''}`,
                                            },
                                        }
                                    )
                                }}
                            >
                                <JourneyStartIcon
                                    color={
                                        journey_colours.light.section
                                            .history_file.primary_500
                                    }
                                    size={'40px'}
                                />
                                <div style={{ paddingTop: '12px' }} />
                                <JournetTxt1>
                                    Bring your car's history online
                                </JournetTxt1>
                                <div style={{ paddingTop: '8px' }} />
                                <JournetTxt2>
                                    Learn how you can easily keep track of
                                    expenses associated with your car's history,
                                    including parts, labor, and other costs.
                                </JournetTxt2>
                            </JourneyWrapper>
                        </AnimatedWrapper>
                    </div>
                    <Separator />
                </>
            )}
            {renderRightView(activeView)}
        </Container>
    )
}

export default TimelineViewManagerMobile
