import React, { useState, useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import Faded from '../../templates/animated/faded'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import SingleTimelineItemBodyMobile from '../../molecules/singleTimelineItemBody/singleTimelineItemBodyMobile'
import TimelineItemReturnNav from '../../atoms/header/timelineItemReturnNav'
import PageSubMenuActions from '../../atoms/menu/pageSubMenuActions/pageSubMenuActions'
import styled from 'styled-components'
import SideSlider from '../../templates/animated/sideSlider'
import EntryPageTopHeaderDesktop from '../../molecules/entryPageTopHeader/entryPageTopHeaderDesktop'
import SingleTimelineItemBodyDesktop from '../../molecules/singleTimelineItemBody/singleTimelineItemBodyDesktop'
import DeletionConfirmationBottomSheet from '../../atoms/menu/bottomSheetMenu/deletionConfirmationBottomSheet'
import DeletionConfirmationDesktop from '../../molecules/deletionConfirmationDesktop/deletionConfirmationDesktop'
import Loader from '../../atoms/loader/loader'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import EntryOptionsMenuBar from '../../molecules/entryOptionsMenuBar/entryOptionsMenuBar'
import FullPagePageWrapper from '../../templates/displays/pageWrappers/FullPagePageWrapper'
import PenIcon from '../../atoms/icons/components/penIcon'
import BinIcon from '../../atoms/icons/components/binIcon'
import EntryAttachmentsFormWrapperMobile from '../../organisms/editForms/attachmentsFormWrapper/entryAttachmentsFormWrapperMobile'
import { editFormsActions } from '../../../redux/editForms/reducer'
import WrapperImageFormsDesktop from '../../organisms/editForms/imageFormWrapper/wrapperImageFormsDesktop'
import ModalDisplayAbsolute from '../../templates/displays/pageWrappers/modalDisplayAbsolute'
import FadedSlower from '../../templates/animated/FadedSlower'
import JourneyRedirectCardDesktop from '../../molecules/cards/journey/redirectCards/redirectCardDesktop'
import { journeyRedirectCardsCopy } from '../../../redux/localdata/journey/copyData'
import CostIcon from '../../atoms/icons/components/historyFile/costIcon'
import colours from '../../../providers/theme/colours'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { carActions } from '../../../redux/entities/cars/reducer'
import { usersActions } from '../../../redux/user/reducer'
import { timelineActions } from '../../../redux/timeline/reducer'
import { ILabel, ITimelineItem } from '../../../redux/timeline/types'
import { IisOpen } from '../../../redux/menus/types'
import useThemes from '../../../providers/theme/hooks'
import { device } from '../../templates/displays/devices'

export const EntryOptionsMenuWrapper = styled.div`
    position: absolute;
    top: 0px;
    right: 48px;
    background-color: var(--off-bg-color, #fafafa);
    width: 248px;
    border-radius: 16px;
    padding: 24px;
    @media ${device.smallest_laptops} {
        right: 24px;
    }
`

export const EntryTopSectionWrapper = styled.div`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 24px 48px;
    justify-content: center;
    align-items: flex-start;
    border-bottom: 1px solid var(--border-muted, #e5e5e5);
    @media ${device.smallest_laptops} {
        padding-left: 24px;
        padding-right: 24px;
    }
`

const CarTimelineSingleEntry: React.FC = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { carid } = useParams<{ carid: string }>()

    const [isMobileActionsSubMenuOpen, setIsMobileActionsSubMenuOpen] =
        useState(false)
    const [bottomsheetTimer, setBottomsheetTimer] = useState(500)
    const [showConfirmDeleteEntrySheet, setShowConfirmDeleteEntrySheet] =
        useState(false)
    const [showJourneyCard, _] = useState(true)

    const activeEntry = useAppSelector((state) => state.timeline.activeEntry)
    const isActiveEntryLoading = useAppSelector(
        (state) => state.timeline.loading
    )

    const timelineItemEditForm = useAppSelector(
        (state) => state.editForms.timelineItemEditForm
    )
    const attachmentsObj = useAppSelector(
        (state) => state.attachments.attachmentsById
    )
    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)

    const isUserLoading = useAppSelector((state) => state.user.loading)
    const carsTimelineData = useAppSelector(
        (state) => state.timeline.timeline_data_normalised_by_car_id
    )
    const isEntryAttachmentLoading = useAppSelector(
        (state) => state.timeline.isEntryAttachmentLoading
    )
    const isCostAttachmentLoading = useAppSelector(
        (state) => state.timeline.isCostAttachmentLoading
    )
    const userCurrency = useAppSelector(
        (state) =>
            state.user.userLoggedIn?.preferences.data.units.data
                .default_currency.user_choice_id
    )
    const technicalInformationData = useAppSelector(
        (state) => state.entities.technicalInformationData.technical_information
    )
    const isAddGalleryImageToEntryFormOpen = useAppSelector(
        (state) =>
            state.editForms.formsData.isAddGalleryImageToEntryFormOpen.isOpen
    )

    useEffect(() => {
        if (carid) {
            dispatch(carActions.setCurrentCar(`${carid}`))
        }

        const params = new URLSearchParams(location.search)
        const entryid = params.get('entryid')

        dispatch(
            customNavDataActions.setInitialLandingUrl(
                `/car/${carid}/history-file/entry?entryid=${entryid}`
            )
        )

        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        }

        if (userLoggedIn && entryid) {
            dispatch(
                timelineActions.getTimelineItemByIDRequest({
                    entryUID: entryid,
                    car_id: `${carid}`,
                })
            )
        }
    }, [carid, userLoggedIn, location.search])

    useEffect(() => {
        if (timelineItemEditForm.isOpen === false) {
            setBottomsheetTimer(50)
        } else {
            setBottomsheetTimer(500)
        }
    }, [timelineItemEditForm.isOpen])

    const navigateEntries = (direction: 'next' | 'previous') => {
        let all_entries: '' | ITimelineItem[] | null | undefined =
            carid &&
            carsTimelineData &&
            carsTimelineData[carid] &&
            carsTimelineData[carid].entries

        let indexOFActiveEntry: number | undefined =
            all_entries && all_entries?.length > 0
                ? all_entries?.findIndex(function (entry: ITimelineItem) {
                      return activeEntry && entry.id === activeEntry.id
                  })
                : undefined

        let nextEntry =
            all_entries &&
            (indexOFActiveEntry || indexOFActiveEntry === 0) &&
            (indexOFActiveEntry < all_entries.length - 1
                ? all_entries[indexOFActiveEntry + 1]
                : all_entries[all_entries.length - 1])

        let previousEntry =
            all_entries &&
            (indexOFActiveEntry || indexOFActiveEntry === 0) &&
            (indexOFActiveEntry > 1
                ? all_entries[indexOFActiveEntry - 1]
                : all_entries[0])

        if (direction === 'next' && nextEntry) {
            navigate(`/car/${carid}/history-file/entry?entryid=${nextEntry.id}`)
            dispatch(timelineActions.setActiveEntrySuccess(nextEntry))
        } else if (previousEntry) {
            navigate(
                `/car/${carid}/history-file/entry?entryid=${previousEntry.id}`
            )
            dispatch(timelineActions.setActiveEntrySuccess(previousEntry))
        }
    }

    const handleActionSubMenu = () => {
        setIsMobileActionsSubMenuOpen(!isMobileActionsSubMenuOpen)
    }

    const removeLabel = (labelname: string) => {
        let labelToRemove =
            activeEntry &&
            activeEntry.labels &&
            activeEntry.labels.filter(
                (label: ILabel) => label.name === labelname
            )

        activeEntry &&
            labelToRemove &&
            dispatch(
                timelineActions.removeLabelFromCarEntryRequest({
                    car_id: `${carid}`,
                    entry_id: activeEntry.id,
                    labels: labelToRemove,
                })
            )
    }

    let { theme } = useThemes()

    return (
        <>
            <Loader isLoading={isActiveEntryLoading || isUserLoading} />

            <Faded>
                {activeEntry && (
                    <>
                        <DesktopDisplayOnly>
                            <FullPagePageWrapper>
                                {showJourneyCard &&
                                    activeEntry.categoryID === 'insurance' && (
                                        <div
                                            style={{
                                                width: '100%',
                                                boxSizing: 'border-box',
                                                paddingBottom: 0,
                                                paddingTop: 16,
                                                paddingLeft: 32,
                                                paddingRight: 48,
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    maxWidth: 1200,
                                                    width: '100%',
                                                    boxSizing: 'border-box',
                                                }}
                                            >
                                                <FadedSlower>
                                                    <JourneyRedirectCardDesktop
                                                        sectionID="insurance"
                                                        dataPH={
                                                            'start_car_profile_journey'
                                                        }
                                                        copyData={
                                                            journeyRedirectCardsCopy
                                                        }
                                                        onStartNow={() => {
                                                            navigate({
                                                                pathname: `/journey/insurance`,
                                                                search: `?step=welcome&carid=${carid}`,
                                                            })
                                                        }}
                                                    />
                                                </FadedSlower>
                                            </div>
                                        </div>
                                    )}
                                <Faded>
                                    <div
                                        style={{
                                            width: '100%',
                                            boxSizing: 'border-box',
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <EntryTopSectionWrapper>
                                            <div
                                                style={{
                                                    maxWidth: 1304,
                                                    width: '100%',
                                                    boxSizing: 'border-box',
                                                }}
                                            >
                                                <EntryPageTopHeaderDesktop
                                                    item={activeEntry}
                                                    elementToScrollTo={document.getElementById(
                                                        'attachments-section'
                                                    )}
                                                    entityID={activeEntry.id}
                                                    carID={`${carid}`}
                                                    entityType="entry"
                                                    onEditTitleClick={() => {
                                                        navigate(
                                                            `/car/${carid}/history-file/entry/edit?entry_id=${activeEntry.id}&section_id=general`
                                                        )
                                                    }}
                                                    goBackToTimeline={`/car/${carid}/history-file`}
                                                    onAddCost={() => {
                                                        navigate(
                                                            `/car/${carid}/history-file/${activeEntry.id}/cost/create`
                                                        )
                                                    }}
                                                    onDeleteClick={() =>
                                                        setShowConfirmDeleteEntrySheet(
                                                            true
                                                        )
                                                    }
                                                    goToNextEntry={() =>
                                                        navigateEntries('next')
                                                    }
                                                    goToPreviousEntry={() =>
                                                        navigateEntries(
                                                            'previous'
                                                        )
                                                    }
                                                />
                                            </div>
                                        </EntryTopSectionWrapper>
                                    </div>
                                    <div style={{ paddingTop: 32 }} />

                                    <div
                                        style={{
                                            width: '100%',
                                            boxSizing: 'border-box',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            // padding: '0px 40px',
                                        }}
                                    >
                                        <div
                                            style={{
                                                maxWidth: 1400,
                                                position: 'relative',
                                                width: '100%',
                                                boxSizing: 'border-box',
                                            }}
                                        >
                                            <EntryOptionsMenuWrapper>
                                                <EntryOptionsMenuBar
                                                    carid={`${carid}`}
                                                    activeEntry={activeEntry}
                                                />
                                            </EntryOptionsMenuWrapper>
                                            <SingleTimelineItemBodyDesktop
                                                item={activeEntry}
                                                entityType="entry"
                                                carID={`${carid}`}
                                                entityID={activeEntry.id}
                                                attachmentsObj={attachmentsObj}
                                                onEditTitleClick={() => {
                                                    navigate(
                                                        `/car/${carid}/history-file/entry/edit?entry_id=${activeEntry.id}&section_id=general`
                                                    )
                                                }}
                                                addNewCost={() => {
                                                    navigate(
                                                        `/car/${carid}/history-file/${activeEntry.id}/cost/create`
                                                    )
                                                }}
                                                onEditShortDescription={() => {
                                                    navigate(
                                                        `/car/${carid}/history-file/entry/edit?entry_id=${activeEntry.id}&section_id=general`
                                                    )
                                                }}
                                                onEditNotes={() => {
                                                    navigate(
                                                        `/car/${carid}/history-file/entry/edit?entry_id=${activeEntry.id}&section_id=notes`
                                                    )
                                                }}
                                                onEditInsurer={() => {
                                                    navigate(
                                                        `/car/${carid}/history-file/entry/edit?entry_id=${activeEntry.id}&section_id=insurance_fields`
                                                    )
                                                }}
                                                onCostClick={(
                                                    costid: string
                                                ) => {
                                                    navigate(
                                                        `/car/${carid}/history-file/${activeEntry.id}/cost/edit?cost_id=${costid}`
                                                    )
                                                }}
                                                isEntryAttachmentBeingUploaded={
                                                    isEntryAttachmentLoading
                                                }
                                                isCostAttachmentBeingUploaded={
                                                    isCostAttachmentLoading
                                                }
                                                costsObj={
                                                    activeEntry.costItemsObj
                                                }
                                                userCurrency={userCurrency}
                                                toggleAddGalleryImageToEntryForm={(
                                                    p: IisOpen
                                                ) =>
                                                    dispatch(
                                                        editFormsActions.toggleAddGalleryImageToEntryForm(
                                                            p
                                                        )
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>

                                    <DeletionConfirmationDesktop
                                        isOpen={showConfirmDeleteEntrySheet}
                                        toggle={() =>
                                            setShowConfirmDeleteEntrySheet(
                                                false
                                            )
                                        }
                                        action={() => {
                                            navigate(
                                                `/car/${carid}/history-file/entry?entryid=${activeEntry.id}`
                                            )
                                            dispatch(
                                                timelineActions.deleteTimelineEntryRequest(
                                                    {
                                                        itemid: activeEntry.id,
                                                        carid: `${carid}`,
                                                    }
                                                )
                                            )
                                        }}
                                        actionCopy="Confirm deletion"
                                        titleCopy="Are you sure?"
                                        detailsCopy="On deletion, the data will be lost."
                                    />
                                </Faded>
                            </FullPagePageWrapper>
                            {isAddGalleryImageToEntryFormOpen && (
                                <Faded>
                                    <ModalDisplayAbsolute
                                        isFixed
                                        isOpen={
                                            isAddGalleryImageToEntryFormOpen
                                        }
                                        toggle={() => {}}
                                    >
                                        <WrapperImageFormsDesktop
                                            close={() =>
                                                dispatch(
                                                    editFormsActions.toggleAddGalleryImageToEntryForm(
                                                        {
                                                            isOpen: false,
                                                        }
                                                    )
                                                )
                                            }
                                            carid={`${carid}`}
                                            entryid={activeEntry.id}
                                        />
                                    </ModalDisplayAbsolute>
                                </Faded>
                            )}
                        </DesktopDisplayOnly>

                        <IpadAndMobileDisplay>
                            <CenteredPageWrapper
                                fullwidth
                                removePaddingsOnAllDevices
                            >
                                <div style={{ width: '100%' }}>
                                    <TimelineItemReturnNav
                                        chevron={true}
                                        customText={`Entries`}
                                        linkTo={`/car/${carid}/history-file`}
                                        linkSearch={`?scrolltoid=${activeEntry.id}`}
                                        threeDots={true}
                                        onThreeDotsClick={handleActionSubMenu}
                                        dataCyIdThreeDots={
                                            'open-entry-slide-up-menu'
                                        }
                                        dataCyIdChevron={'go-back-to-timeline'}
                                        category={activeEntry.categoryID}
                                    />

                                    <SideSlider
                                        direction="right"
                                        initial="enter"
                                    >
                                        <SingleTimelineItemBodyMobile
                                            item={activeEntry}
                                            // @ts-ignore
                                            carMileage={
                                                technicalInformationData &&
                                                technicalInformationData[
                                                    `hf-${carid}`
                                                ]?.mileage?.answer
                                            }
                                            userDistanceUnit={
                                                userLoggedIn?.preferences.data
                                                    .units.data.distance_unit
                                                    .user_choice_id
                                            }
                                            attachmentsObj={attachmentsObj}
                                            onMileageEdit={() => {
                                                navigate(
                                                    `/car/${carid}/history-file/entry/edit?entry_id=${activeEntry.id}&section_id=mileage`
                                                )
                                            }}
                                            onCreateCostClick={() => {
                                                navigate(
                                                    `/car/${carid}/history-file/${activeEntry.id}/cost/create`
                                                )
                                            }}
                                            setFileUploaded={() => {}}
                                            entityType="entry"
                                            carID={`${carid}`}
                                            entityID={activeEntry.id}
                                            onAddNewLabelClick={() => {
                                                navigate(
                                                    `/car/${carid}/history-file/entry/edit?entry_id=${activeEntry.id}&section_id=labels`
                                                )
                                            }}
                                            onRemoveLabel={(
                                                labelname: string
                                            ) => removeLabel(labelname)}
                                            onEditNotes={() => {
                                                navigate(
                                                    `/car/${carid}/history-file/entry/edit?entry_id=${activeEntry.id}&section_id=notes`
                                                )
                                            }}
                                            onEditInsurer={() => {
                                                navigate(
                                                    `/car/${carid}/history-file/entry/edit?entry_id=${activeEntry.id}&section_id=insurance_fields`
                                                )
                                            }}
                                            onCostClick={(costid: string) => {
                                                navigate(
                                                    `/car/${carid}/history-file/${activeEntry.id}/cost/edit?cost_id=${costid}`
                                                )
                                            }}
                                            isEntryAttachmentBeingUploaded={
                                                isEntryAttachmentLoading
                                            }
                                            isCostAttachmentBeingUploaded={
                                                isCostAttachmentLoading
                                            }
                                            costsObj={activeEntry.costItemsObj}
                                            userCurrency={userCurrency}
                                        />
                                    </SideSlider>

                                    {isMobileActionsSubMenuOpen && (
                                        <PageSubMenuActions
                                            items={[
                                                {
                                                    id: 'edit',
                                                    text: 'edit entry details',
                                                    icon: (
                                                        <PenIcon
                                                            color={
                                                                colours[theme]
                                                                    .text_default
                                                            }
                                                        />
                                                    ),
                                                    action: () => {
                                                        navigate(
                                                            `/car/${carid}/history-file/entry/edit?entry_id=${activeEntry.id}&section_id=general`
                                                        )
                                                    },
                                                },
                                                {
                                                    id: 'add_cost',
                                                    text: 'add cost',
                                                    icon: <CostIcon />,
                                                    action: () => {
                                                        navigate(
                                                            `/car/${carid}/history-file/${activeEntry.id}/cost/create`
                                                        )
                                                    },
                                                },
                                                {
                                                    id: 'delete',
                                                    text: 'delete history file entry',
                                                    icon: (
                                                        <BinIcon color="#DF6F6F" />
                                                    ),
                                                    action: () => {
                                                        setShowConfirmDeleteEntrySheet(
                                                            true
                                                        )
                                                    },
                                                    color: '#DF6F6F',
                                                },
                                            ]}
                                            customMenuItem={
                                                <EntryAttachmentsFormWrapperMobile
                                                    entityID={activeEntry.id}
                                                    carid={`${carid}`}
                                                    isBottomsheet
                                                    closeBottomSheet={() =>
                                                        setTimeout(
                                                            handleActionSubMenu,
                                                            bottomsheetTimer
                                                        )
                                                    }
                                                />
                                            }
                                            customMenuItemIndex={1}
                                            toggle={() =>
                                                setTimeout(
                                                    handleActionSubMenu,
                                                    bottomsheetTimer
                                                )
                                            }
                                            isOpen={isMobileActionsSubMenuOpen}
                                            dataCyPrefix="timeline-entry"
                                        />
                                    )}

                                    <DeletionConfirmationBottomSheet
                                        isOpen={showConfirmDeleteEntrySheet}
                                        toggle={() =>
                                            setShowConfirmDeleteEntrySheet(
                                                false
                                            )
                                        }
                                        action={() =>
                                            activeEntry
                                                ? dispatch(
                                                      timelineActions.deleteTimelineEntryRequest(
                                                          {
                                                              itemid: activeEntry.id,
                                                              carid: `${carid}`,
                                                          }
                                                      )
                                                  )
                                                : {}
                                        }
                                        actionCopy="Confirm deletion"
                                        titleCopy="Are you sure?"
                                        detailsCopy="On deletion, the data will be lost."
                                    />
                                </div>
                            </CenteredPageWrapper>
                        </IpadAndMobileDisplay>
                    </>
                )}
            </Faded>
        </>
    )
}

export default CarTimelineSingleEntry
