import * as React from 'react'
import styled from 'styled-components'
// import { device } from '../../../templates/displays/devices'

const SectionHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 58px;
    background-color: var(--off-bg-color, #fafafa);
    margin: 0 auto;
`

const Text = styled.div`
    font-size: 16px;
    text-transform: uppercase;
    font-family: Lato-Light;
    white-space: nowrap;
    padding-right: 20px;
    color: var(--text-strong, #1a1a1a);
`

interface IProps {
    title: string
    id?: string
}

const MobileSettingsSectionHeader: React.FunctionComponent<IProps> = (
    props
) => {
    const { title, id } = props

    return (
        <SectionHeaderWrapper id={id ? id : undefined}>
            <Text>{title}</Text>
        </SectionHeaderWrapper>
    )
}

export default MobileSettingsSectionHeader
