import React, { useEffect, useState } from 'react'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import Faded from '../../templates/animated/faded'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import TaskViewsManagerDesktop from '../../organisms/tasksViewManager/tasksViewManagerDesktop'
import TaskViewsManagerMobile from '../../organisms/tasksViewManager/tasksViewManagerMobile'
import { generate_task_cars_dropdown_list } from '../../../redux/conversions/dropdowns/taskCarsDropdownGenerator'
import { editFormsActions } from '../../../redux/editForms/reducer'
import ModalDisplay from '../../templates/displays/pageWrappers/modalDisplay'
import CreateTaskFormDesktop from '../../organisms/editForms/taskFormManager/custom/createTaskFormDesktop'
import CreateTaskFormMobile from '../../organisms/editForms/taskFormManager/custom/createTaskFormMobile'

import TaskListManagerDesktop from '../../organisms/tasksViewManager/taskListManager/taskListManagerDesktop'
import TaskListManagerMobile from '../../organisms/tasksViewManager/taskListManager/taskListManagerMobile'
import TutorialConfigsTasksMobile from '../../molecules/tutorialConfigs/tasks/tutorialConfigsTasksMobile'
import TutorialConfigsTasksDesktop from '../../molecules/tutorialConfigs/tasks/tutorialConfigsTasksDesktop'
import { OOtherTaskOptions, OTaskFilterNSort } from '../../../ol/ol'
import TasksSubMenuActions from '../../atoms/menu/pageSubMenuActions/tasksSubMenuActions'
import FabsMenusMobile from '../../templates/menus/fabsMenuMobile'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
// import { getCookie } from '../../../redux/services/authcheck'
// import AndroidInstallPwaInstructionsPropmt from '../../templates/pwaCustom/androidInstallPwaInstructionsPropmt'
import Loader from '../../atoms/loader/loader'
import FullPagePageWrapper from '../../templates/displays/pageWrappers/FullPagePageWrapper'
import { loadingPlaceholders } from '../car/carTasks'
import task_skeleton_desktop from '../../atoms/skeleton/tasks/task_skeleton_desktop.png'
import task_skeleton_mobile from '../../atoms/skeleton/tasks/task_skeleton_mobile.png'
import task_skeleton_desktop_dark from '../../atoms/skeleton/tasks/task_skeleton_desktop_dark.png'
import task_skeleton_mobile_dark from '../../atoms/skeleton/tasks/task_skeleton_mobile_dark.png'
import { useNavigate, useLocation } from 'react-router-dom'
import { ICreateTaskPayload, IDropdownItem } from 'entityModels'
import { tasksActions } from '../../../redux/tasks/reducer'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { usersActions } from '../../../redux/user/reducer'
import { garagesActions } from '../../../redux/entities/garages/reducer'
import { tutorialsActions } from '../../../redux/localdata/tutorialsCheck/reducer'
import useThemes from '../../../providers/theme/hooks'

const Wrapper = styled.section`
    @media ${device.beyond_ipad_mobile} {
        background-color: var(--bg-color, #fff);
        padding-left: 50px;
        /* padding-right: 50px; */
        transform: translateY(-50px);
        min-height: 100vh;
    }
`

const PaddingViewsMenuMobileIpad = styled.div`
    @media ${device.mobile} {
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 24px;
    }
    @media ${device.ipad} {
        padding-top: 20px;
        padding-left: 24px;
        padding-right: 24px;
    }
`

const Tasks = () => {
    let { theme } = useThemes()

    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useAppDispatch()

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    const isUserLoading = useAppSelector((state) => state.user.loading)

    const garage_id = useAppSelector(
        (state) => state.user.userLoggedIn?.owns_garage?.uid
    )
    const all_tasks = useAppSelector((state) => state.tasks)
    const tasksLoading = useAppSelector((state) => state.tasks.loading)
    const carLoading = useAppSelector(
        (state) => state.entities.carsData.loading
    )
    const garageLoading = useAppSelector(
        (state) => state.entities.garagesData.loading
    )
    const remindersLoading = useAppSelector(
        (state) => state.tasks.isRemindersLoading
    )
    const carsData = useAppSelector((state) => state.entities.carsData)
    const garage_data = useAppSelector(
        (state) => state.entities.garagesData.garages
    )
    const isCreateCarFormOpen = useAppSelector(
        (state) => state.editForms.formsData.createTaskForm.isOpen
    )
    const isFilterByOpened = useAppSelector(
        (state) => state.editForms.formsData.filterBy.isOpen
    )
    const isSortByOpened = useAppSelector(
        (state) => state.editForms.formsData.sortBy.isOpen
    )
    const tutorialsCheck = useAppSelector(
        (state) => state.localdata.tutorials_check_state
    )
    const tasksDisplayCriteria = useAppSelector(
        (state) => state.tasks.tasksDisplayCriteria
    )
    const [dropdownCars, setDropdownCars] = useState<IDropdownItem[]>([])
    const [carSelected, setCarSelected] = useState<string | null>(
        tasksDisplayCriteria.selectedCarID
    )
    const [sortBy, setSortBy] = useState<string>(tasksDisplayCriteria.sortBy)
    const [filterBy, setFilterBy] = useState<string>(
        tasksDisplayCriteria.filteredBy
    )
    const [dropdownCarsWithOptions, setDropdownCarsWithOptions] = useState<
        IDropdownItem[]
    >([])

    const selectCarViewId = (name?: string) => {
        if (name === OOtherTaskOptions.general_task.name) {
            setCarSelected(OOtherTaskOptions.general_task.name)
            return
        }
        if (carsData.cars) {
            let car_ids_list = Object.keys(carsData.cars)
            for (const carid of car_ids_list) {
                if (carsData.cars[carid].title === name) {
                    setCarSelected(carid)
                    return
                }
            }
        }
        setCarSelected(null)
    }

    const sortView = (id: string) => {
        setSortBy(id)
    }

    const filterView = (text: string) => {
        setFilterBy(text)
    }

    const generate_cars_dropdown_list = (arr: string[]) => {
        let cars = carsData.cars

        if (userLoggedIn && garage_id) {
            let obj = generate_task_cars_dropdown_list(arr, cars, garage_id)

            let options = [
                {
                    uid: garage_id,
                    name: OOtherTaskOptions.all_tasks.name,
                },
                {
                    uid: OOtherTaskOptions.general_task.uid,
                    name: OOtherTaskOptions.general_task.name,
                },
            ]

            let cars_only_dropdown = [...obj]
            let cars_dropdown_with_options = [...obj]
            cars_dropdown_with_options.unshift(...options)

            setDropdownCars(cars_only_dropdown)
            setDropdownCarsWithOptions(cars_dropdown_with_options)
        }
    }

    useEffect(() => {
        if (location.state) {
            let prevPath = location.state?.prevPath
            let taskId = prevPath?.replace('/tasks/', '')
            let hasLettersAndNumber = /^[0-9a-zA-Z]+$/

            let thisIsATaskPage = taskId?.match(hasLettersAndNumber)
                ? true
                : false

            if (thisIsATaskPage === false) {
                setCarSelected(null)
                setSortBy(OTaskFilterNSort.sort_by.created_at.name)
                setFilterBy(OTaskFilterNSort.filter_by.outstanding.name)
            }
        }
    }, [location])

    useEffect(() => {
        dispatch(customNavDataActions.setInitialLandingUrl('/tasks'))

        if (userLoggedIn) {
            if (
                tutorialsCheck.isFirstSession === true &&
                tutorialsCheck.tutorialsCheckByPageId.tasks === false
            ) {
                dispatch(tutorialsActions.manageUserTutorialByIdCheck('tasks'))
                navigate('/tasks?tutorial=true')
            }

            if (garage_id && !tasksLoading) {
                if (
                    !all_tasks ||
                    !all_tasks.tasks ||
                    Object.keys(all_tasks.tasks).length === 0 ||
                    Object.keys(all_tasks.tasks).length === 1
                ) {
                    dispatch(tasksActions.getTasksByGarageIdRequest(garage_id))
                }
            }
        } else {
            dispatch(usersActions.getCurrentUserDataRequest())
        }
    }, [userLoggedIn])

    useEffect(() => {
        if (
            userLoggedIn &&
            garage_id &&
            garage_data &&
            garage_data[garage_id] &&
            garage_data[garage_id].cars
        ) {
            generate_cars_dropdown_list(garage_data[garage_id].cars)
        } else if (garage_id) {
            if (
                !garage_data ||
                !garage_data[garage_id] ||
                !garage_data[garage_id].cars
            ) {
                dispatch(garagesActions.getGarageByUserIdRequest())
            }
        }
    }, [garage_data, userLoggedIn])

    useEffect(() => {
        if (
            carSelected !== tasksDisplayCriteria.selectedCarID ||
            filterBy !== tasksDisplayCriteria.filteredBy ||
            sortBy !== tasksDisplayCriteria.sortBy
        ) {
            dispatch(
                tasksActions.registerTasksDisplayCriteriaSuccess({
                    selectedCarID: carSelected,
                    sortBy: sortBy,
                    filteredBy: filterBy,
                })
            )
        }
    }, [carSelected, filterBy, sortBy, tasksDisplayCriteria])

    const all_tasks_uids = all_tasks.tasks && Object.keys(all_tasks.tasks)

    const params = new URLSearchParams(location.search)

    const isDataLoading =
        tasksLoading || carLoading || garageLoading || remindersLoading
            ? true
            : false

    return (
        <React.Fragment>
            <Loader isLoading={isUserLoading} />
            <FabsMenusMobile hasAdd={true} hasSubNav={false} />

            {params.get('tutorial') === 'true' && (
                <TutorialConfigsTasksMobile />
            )}
            {params.get('tutorial') === 'true' && (
                <TutorialConfigsTasksDesktop />
            )}

            {/* {params.get('tutorial') !== 'true' &&
                getCookie('android_install_prompt') !== 'seen' && (
                    <AndroidInstallPwaInstructionsPropmt />
                )} */}

            <Wrapper>
                <FullPagePageWrapper paddingRight="24px">
                    {userLoggedIn && (
                        <Faded>
                            <DesktopDisplayOnly>
                                <div style={{ paddingTop: '90px' }} />
                                <div
                                    data-tut="tasks-tuto-desktop-2"
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        height: '100%',
                                        width: '100%',
                                        boxSizing: 'border-box',
                                    }}
                                >
                                    <TaskViewsManagerDesktop
                                        dropdownCars={dropdownCarsWithOptions}
                                        selectCarViewId={selectCarViewId}
                                        toggleCreateTaskForm={() =>
                                            dispatch(
                                                editFormsActions.toggleCreateTaskForm()
                                            )
                                        }
                                        sortView={sortView}
                                        filterView={filterView}
                                        initialValues={{
                                            selectedCarID: carSelected,
                                            sortBy: sortBy,
                                            filteredBy: filterBy,
                                        }}
                                        selectedCarData={
                                            carSelected !== null
                                                ? carsData.cars[carSelected]
                                                : undefined
                                        }
                                    />
                                </div>
                                <div style={{ paddingTop: '70px' }} />

                                {isDataLoading ||
                                !(
                                    garage_data &&
                                    garage_id &&
                                    garage_data[garage_id] &&
                                    garage_data[garage_id].cars
                                ) ? (
                                    loadingPlaceholders(
                                        6,
                                        theme === 'dark'
                                            ? task_skeleton_desktop_dark
                                            : task_skeleton_desktop
                                    )
                                ) : (
                                    <div
                                        style={{
                                            width: '100%',
                                            boxSizing: 'border-box',
                                            paddingBottom: 80,
                                            minHeight: '100vh',
                                        }}
                                    >
                                        <TaskListManagerDesktop
                                            all_tasks={all_tasks}
                                            all_tasks_uids={all_tasks_uids}
                                            carSelected={carSelected}
                                            sortBy={sortBy}
                                            filterBy={filterBy}
                                            dropdownCars={dropdownCars}
                                            dropdownCarsWithOptions={
                                                dropdownCarsWithOptions
                                            }
                                            updateTaskRequest={(payload) =>
                                                dispatch(
                                                    tasksActions.updateTaskRequest(
                                                        payload
                                                    )
                                                )
                                            }
                                            carsData={carsData}
                                            toggleCreateTaskForm={() =>
                                                dispatch(
                                                    editFormsActions.toggleCreateTaskForm()
                                                )
                                            }
                                            tasks_ids_garage_level_only={
                                                garage_data[garage_id]
                                                    ?.tasks_garage_level
                                            }
                                        />
                                    </div>
                                )}
                            </DesktopDisplayOnly>

                            <IpadAndMobileDisplay>
                                <PaddingViewsMenuMobileIpad
                                    style={{ paddingBottom: 24 }}
                                >
                                    <TaskViewsManagerMobile
                                        dropdownCars={dropdownCarsWithOptions}
                                        selectCarViewId={selectCarViewId}
                                        carSelected={carSelected}
                                        sortBy={sortBy}
                                        filterBy={filterBy}
                                        // dropdownCars={dropdownCars}
                                        dropdownCarsWithOptions={
                                            dropdownCarsWithOptions
                                        }
                                        selectedCarData={
                                            carSelected !== null
                                                ? carsData.cars[carSelected]
                                                : undefined
                                        }
                                    />
                                </PaddingViewsMenuMobileIpad>

                                {isDataLoading ||
                                !(
                                    garage_data &&
                                    garage_id &&
                                    garage_data[garage_id] &&
                                    garage_data[garage_id].cars
                                ) ? (
                                    <div
                                        style={{
                                            position: 'relative',
                                            marginTop: '50px',
                                            height: '20vh',
                                        }}
                                    >
                                        {loadingPlaceholders(
                                            3,
                                            theme === 'dark'
                                                ? task_skeleton_mobile_dark
                                                : task_skeleton_mobile
                                        )}
                                    </div>
                                ) : (
                                    <TaskListManagerMobile
                                        all_tasks={all_tasks}
                                        all_tasks_uids={all_tasks_uids}
                                        carSelected={carSelected}
                                        sortBy={sortBy}
                                        filterBy={filterBy}
                                        dropdownCars={dropdownCars}
                                        dropdownCarsWithOptions={
                                            dropdownCarsWithOptions
                                        }
                                        updateTaskRequest={(payload) =>
                                            dispatch(
                                                tasksActions.updateTaskRequest(
                                                    payload
                                                )
                                            )
                                        }
                                        carsData={carsData}
                                        toggleCreateTaskForm={() =>
                                            dispatch(
                                                editFormsActions.toggleCreateTaskForm()
                                            )
                                        }
                                        tasks_ids_garage_level_only={
                                            garage_data[garage_id]
                                                ?.tasks_garage_level
                                        }
                                    />
                                )}

                                <div style={{ paddingBottom: '100px' }} />
                            </IpadAndMobileDisplay>
                        </Faded>
                    )}
                </FullPagePageWrapper>
            </Wrapper>
            <DesktopDisplayOnly>
                {isCreateCarFormOpen && garage_id && (
                    <ModalDisplay
                        toggle={() =>
                            dispatch(editFormsActions.toggleCreateTaskForm())
                        }
                        isOpen={true}
                        customZindex={11}
                    >
                        <CreateTaskFormDesktop
                            toggle={() =>
                                dispatch(
                                    editFormsActions.toggleCreateTaskForm()
                                )
                            }
                            submitFunc={(payload: ICreateTaskPayload) =>
                                dispatch(
                                    tasksActions.createTaskRequest(payload)
                                )
                            }
                            dropdownData={dropdownCars}
                            carsData={carsData.cars}
                            garage_id={garage_id}
                            currentCarID={undefined}
                            garageCarsIds={garage_data[garage_id].cars}
                        />
                    </ModalDisplay>
                )}
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                {isCreateCarFormOpen && garage_id && (
                    <ModalDisplay
                        toggle={() =>
                            dispatch(editFormsActions.toggleCreateTaskForm())
                        }
                        isOpen={isCreateCarFormOpen}
                    >
                        <CreateTaskFormMobile
                            toggle={() =>
                                dispatch(
                                    editFormsActions.toggleCreateTaskForm()
                                )
                            }
                            submitFunc={(payload: ICreateTaskPayload) =>
                                dispatch(
                                    tasksActions.createTaskRequest(payload)
                                )
                            }
                            dropdownData={dropdownCars}
                            carsData={carsData.cars}
                            garage_id={garage_id}
                            currentCarID={undefined}
                            garageCarsIds={garage_data[garage_id].cars}
                        />
                    </ModalDisplay>
                )}

                {isFilterByOpened && (
                    <TasksSubMenuActions
                        isOpen={isFilterByOpened}
                        items={[
                            {
                                id: OTaskFilterNSort.filter_by.outstanding.uid,
                                text: OTaskFilterNSort.filter_by.outstanding
                                    .name,
                                action: () => {
                                    filterView(
                                        OTaskFilterNSort.filter_by.outstanding
                                            .name
                                    )
                                    dispatch(
                                        editFormsActions.toggleFilterByChoice()
                                    )
                                },
                            },
                            {
                                id: OTaskFilterNSort.filter_by.completed.uid,
                                text: OTaskFilterNSort.filter_by.completed.name,
                                action: () => {
                                    filterView(
                                        OTaskFilterNSort.filter_by.completed
                                            .name
                                    )
                                    dispatch(
                                        editFormsActions.toggleFilterByChoice()
                                    )
                                },
                            },
                            {
                                id: OTaskFilterNSort.filter_by.all.uid,
                                text: OTaskFilterNSort.filter_by.all.name,
                                action: () => {
                                    filterView(
                                        OTaskFilterNSort.filter_by.all.name
                                    )
                                    dispatch(
                                        editFormsActions.toggleFilterByChoice()
                                    )
                                },
                            },
                        ]}
                        toggle={() =>
                            dispatch(editFormsActions.toggleFilterByChoice())
                        }
                        active_item={filterBy}
                    />
                )}

                {isSortByOpened && (
                    <TasksSubMenuActions
                        isOpen={isSortByOpened}
                        items={[
                            {
                                id: OTaskFilterNSort.sort_by.created_at.uid,
                                text: OTaskFilterNSort.sort_by.created_at.text,
                                action: () => {
                                    sortView(
                                        OTaskFilterNSort.sort_by.created_at.name
                                    )
                                    dispatch(
                                        editFormsActions.toggleSortByChoice()
                                    )
                                },
                                name: OTaskFilterNSort.sort_by.created_at.name,
                            },
                            {
                                id: OTaskFilterNSort.sort_by.due.uid,
                                text: OTaskFilterNSort.sort_by.due.text,
                                action: () => {
                                    sortView(OTaskFilterNSort.sort_by.due.name)
                                    dispatch(
                                        editFormsActions.toggleSortByChoice()
                                    )
                                },
                                name: OTaskFilterNSort.sort_by.due.name,
                            },
                        ]}
                        toggle={() =>
                            dispatch(editFormsActions.toggleSortByChoice())
                        }
                        active_item={sortBy}
                    />
                )}
            </IpadAndMobileDisplay>
        </React.Fragment>
    )
}

export default Tasks
