import * as React from 'react'
import styled from 'styled-components'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

interface IStyle {
    iconOnLeft?: boolean | undefined
    isUppercase?: boolean | undefined
    width?: string | undefined
    isdisabled?: boolean
    $theme: ITheme
}

const Button = styled.button<IStyle>`
    display: flex;
    justify-content: ${(props) =>
        props.iconOnLeft ? 'space-between' : 'center'};
    align-items: center;
    width: ${(props) => (props.width ? props.width : 'fit-content')};
    height: 32px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border: ${(props) =>
        `1px solid ${colours[props.$theme].border_muted_light}`};
    outline: none;
    background-color: ${(props) =>
        colours[props.$theme].background_neutral_soft};
    border-radius: 5px;
    transition: all 200ms;
    cursor: pointer;
    :hover {
        box-shadow: ${(props) =>
            ` 2px 2px 4px ${colours[props.$theme].shadow_strongest_09}`};
        transition: all 200ms;
    }
`

const Text = styled.div<IStyle>`
    color: ${(props) =>
        props.isdisabled
            ? colours[props.$theme].text_disabled
            : colours[props.$theme].text_strong};
    font-family: 'Lato';
    font-size: 12px;
    letter-spacing: 0.7px;
    white-space: nowrap;
    text-transform: ${(props) =>
        props.isUppercase ? 'uppercase' : 'capitalize'};
`

type Props = {
    onClick: any
    text: string | undefined
    dataCyId?: string | undefined
    isUppercase?: boolean
    width?: string | undefined
    isdisabled?: boolean
}

const AddNewGreyButton: React.FC<Props> = (props) => {
    let { onClick, text, isUppercase, width, isdisabled, dataCyId } = props

    const { theme } = useThemes()
    return (
        <Button
            width={width}
            onClick={onClick}
            data-attr={dataCyId ? dataCyId : undefined}
            $theme={theme}
        >
            <Text
                isdisabled={isdisabled}
                isUppercase={isUppercase}
                $theme={theme}
            >
                + {text}
            </Text>
        </Button>
    )
}

export default AddNewGreyButton
