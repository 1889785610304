import styled from 'styled-components'
import {
    IAnyObject,
    IEntitiesObjArr,
} from '../../../../redux/insuranceQuoteApplication/reducer'
import { IInsuranceFieldType } from '../../../../redux/localdata/insuranceLocalData/fieldTypes'
import Expander from '../../../atoms/expander/expander'
import { Error } from '../../editForms/carDescriptionEditForm/carDescriptionEditFormDesktop'
import {
    InsuranceFormQuestion,
    RenderInsuranceQuoteFormFieldDesktop,
} from './renderElementDesktop'
import { InsuranceQuoteSubFormGenerator } from './subGenerator'
import { RenderInsuranceQuoteFormFieldMobile } from './renderElementMobile'
import { InsuranceQuoteSelfReplicatingSubFormGenerator } from './selfReplica/selfReplicatingSubGenerator'
import useWindowSize, {
    WindowSize,
} from '../../../templates/displays/windowSizeHook'
import { capitalizeFirstLetter } from '../../../../helpers/strings/capitalizeFirstLetter'
import { IInsuranceApplicationSectionId } from '../../../pages/insurance_quote/dashboards/insuranceApplicationDashboard'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import LicenseNumber from '../../../atoms/Inputfield/custom/licenseNumber'
import useThemes from '../../../../providers/theme/hooks'
import colours from '../../../../providers/theme/colours'
import { useSetInitialInsurance } from '../../../../providers/insurance/insuranceInitialProvider'
import { useEffect, useState } from 'react'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import { EditIcon } from '../../../atoms/statefulicons'
import { addYearsToDate } from '../../../../redux/helpers/dates'

type Props = {
    list: IInsuranceFieldType[]
    onAnswerChange: (id: string, answer: any) => any
    data: IAnyObject
    errors?: IAnyObject
    setError: (id: string, error: any) => any
    customLineBottomPadding?: string
    mobileSearchPickerSectionURL?: string
    // for replica
    setEntityArr?: (p: IAnyObject[], fieldID: string) => any
    entitiesObjArr?: IEntitiesObjArr
    entitiesErrNormalised?: IAnyObject
    sectionID?: IInsuranceApplicationSectionId
    isWizard?: boolean
    bg?: string
    driverid?: string
    carid?: string
}

export const InsuranceHeader = styled.div`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    display: flex;
    align-items: center;
    letter-spacing: 5e-5em;

    color: var(--text-darker, #616161);
    transition: all 300ms;
`

const InsuranceQuoteFormGenerator = (props: Props) => {
    let {
        list,
        onAnswerChange,
        data,
        errors,
        setError,
        customLineBottomPadding,
        mobileSearchPickerSectionURL,
        setEntityArr,
        entitiesObjArr,
        entitiesErrNormalised,
        sectionID,
        bg,
        driverid,
        carid,
    } = props

    const size: WindowSize = useWindowSize()

    let isMobile = size && size.width && size.width < 880 ? true : false

    let application_draft = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft
    })

    const { theme } = useThemes()
    let application_submitted = useAppSelector((state) => {
        return state.insuranceQuoteApplication.submitted_data
    })

    let appliIsNotDraft =
        application_submitted?.status === 'DRAFT' ? false : true

    let { crossFormData, setIsCrossFormOpen, setCrossFormData } =
        useSetInitialInsurance()

    const [listOfFields, setListOfFields] = useState(list ?? [])

    const dispatch = useAppDispatch()

    useEffect(() => {
        for (let i = 0; i < listOfFields.length; i++) {
            let current_field_type = listOfFields[i]

            // if a field on this form depends on a field value from another form

            if (current_field_type.depends_on_field) {
                // console.log(
                //     ' ========== current_field_type ==========',
                //     current_field_type
                // )

                let field_depending_on_section_data = current_field_type
                    .depends_on_field.section_id
                    ? application_draft[
                          current_field_type.depends_on_field.section_id
                      ]
                    : undefined

                let field_depending_on_value

                if (driverid) {
                    let field_depending_on_section_driver_data =
                        field_depending_on_section_data &&
                        driverid && //@ts-ignore
                        field_depending_on_section_data[driverid] //@ts-ignore
                            ? field_depending_on_section_data[driverid]
                            : undefined

                    let field_depending_on_sub_section_data =
                        field_depending_on_section_driver_data &&
                        current_field_type.depends_on_field.sub_section_id // @ts-ignore
                            ? field_depending_on_section_driver_data[
                                  current_field_type.depends_on_field
                                      .sub_section_id
                              ]
                            : undefined

                    field_depending_on_value =
                        field_depending_on_section_driver_data
                            ? field_depending_on_sub_section_data
                                ? field_depending_on_sub_section_data[
                                      current_field_type.depends_on_field
                                          .field_type.id
                                  ]
                                : field_depending_on_section_driver_data
                                ? [
                                      current_field_type.depends_on_field
                                          .field_type.id,
                                  ]
                                : data
                            : data

                    // console.log(
                    //     'field_depending_on_value',
                    //     field_depending_on_value
                    // )
                    // console.log('data', data)
                    // console.log('current_field_type.id', current_field_type.id)
                    // console.log(
                    //     'data[current_field_type.id]',
                    //     data[current_field_type.id]
                    // )
                } else if (carid) {
                    let field_depending_on_section_car_data =
                        field_depending_on_section_data &&
                        carid && //@ts-ignore
                        field_depending_on_section_data[carid] //@ts-ignore
                            ? field_depending_on_section_data[carid]
                            : undefined

                    let field_depending_on_sub_section_data =
                        field_depending_on_section_car_data &&
                        current_field_type.depends_on_field.sub_section_id // @ts-ignore
                            ? field_depending_on_section_car_data[
                                  current_field_type.depends_on_field
                                      .sub_section_id
                              ]
                            : undefined

                    field_depending_on_value =
                        field_depending_on_section_car_data
                            ? field_depending_on_sub_section_data
                                ? field_depending_on_sub_section_data[
                                      current_field_type.depends_on_field
                                          .field_type.id
                                  ]
                                : field_depending_on_section_car_data
                                ? [
                                      current_field_type.depends_on_field
                                          .field_type.id,
                                  ]
                                : data
                            : data

                    // console.log(
                    //     'field_depending_on_value',
                    //     field_depending_on_value
                    // )
                    // console.log('data', data)
                    // console.log('current_field_type.id', current_field_type.id)
                    // console.log(
                    //     'data[current_field_type.id]',
                    //     data[current_field_type.id]
                    // )
                } else {
                    let field_depending_on_sub_section_data =
                        field_depending_on_section_data &&
                        current_field_type.depends_on_field.sub_section_id // @ts-ignore
                            ? field_depending_on_section_data[
                                  current_field_type.depends_on_field
                                      .sub_section_id
                              ]
                            : undefined
                    field_depending_on_value = field_depending_on_section_data
                        ? field_depending_on_sub_section_data
                            ? field_depending_on_sub_section_data[
                                  current_field_type.depends_on_field.field_type
                                      .id
                              ]
                            : field_depending_on_section_data
                            ? [
                                  current_field_type.depends_on_field.field_type
                                      .id,
                              ]
                            : data
                        : data

                    // console.log(
                    //     'field_depending_on_value',
                    //     field_depending_on_value
                    // )
                    // console.log('data', data)
                    // console.log('current_field_type.id', current_field_type.id)
                    // console.log(
                    //     'data[current_field_type.id]',
                    //     data[current_field_type.id]
                    // )
                }

                if (
                    current_field_type.depends_on_field.checks &&
                    field_depending_on_value !== undefined &&
                    data[current_field_type.id] !== undefined
                ) {
                    if (current_field_type.id === 'license_date') {
                        if (
                            data?.license_type?.uid &&
                            data.license_type.uid !== 'NON_UK'
                        ) {
                            field_depending_on_value = addYearsToDate(
                                field_depending_on_value,
                                17
                            )
                        }
                    }
                    let res = current_field_type.depends_on_field.checks(
                        data[current_field_type.id],
                        field_depending_on_value
                    )

                    // console.log('res', res)
                    if (
                        res === false &&
                        current_field_type.depends_on_field.field_type.id &&
                        current_field_type.depends_on_field.error_cta
                    ) {
                        if (!(errors && errors[current_field_type.id])) {
                            setError(
                                current_field_type.id,
                                current_field_type.depends_on_field.error ??
                                    'Invalid'
                            )
                        }

                        setCrossFormData({
                            ...crossFormData,
                            [current_field_type.id]: {
                                affected_field_id: current_field_type.id,
                                affected_field_error_cta:
                                    current_field_type.depends_on_field
                                        .error_cta,
                                field_to_update_info:
                                    current_field_type.depends_on_field
                                        .infoAboutDefaultFieldLocation,
                                field_to_update:
                                    current_field_type.depends_on_field
                                        .field_type,
                                field_to_update_section_id:
                                    current_field_type.depends_on_field
                                        .section_id,
                                field_to_update_sub_section_id:
                                    current_field_type.depends_on_field
                                        .sub_section_id,
                                field_to_update_other_driver_id: driverid,
                                field_to_update_vehicle_id: carid,
                                onAnswerChange: (id: string, answer: any) => {
                                    if (
                                        current_field_type?.depends_on_field
                                            ?.data_update_function
                                    ) {
                                        let answerChangePayload = driverid
                                            ? {
                                                  id: id,
                                                  answer: answer,
                                                  userid: `${driverid}`,
                                              }
                                            : carid
                                            ? {
                                                  id: id,
                                                  answer: answer,
                                                  carid: `${carid}`,
                                              }
                                            : { id: id, answer: answer }
                                        dispatch(
                                            current_field_type.depends_on_field.data_update_function(
                                                answerChangePayload
                                            )
                                        )
                                    }
                                },
                                onSubmit: () => {
                                    if (
                                        current_field_type?.depends_on_field
                                            ?.data_submit_function
                                    ) {
                                        let submission_payload = driverid
                                            ? {
                                                  driver_id: driverid,
                                                  isCrossForm: true,
                                                  message:
                                                      current_field_type
                                                          .depends_on_field
                                                          .submission_confirmation_message,
                                              }
                                            : carid
                                            ? {
                                                  carid: carid,
                                                  isCrossForm: true,
                                                  message:
                                                      current_field_type
                                                          .depends_on_field
                                                          .submission_confirmation_message,
                                              }
                                            : {
                                                  isCrossForm: true,
                                                  message:
                                                      current_field_type
                                                          .depends_on_field
                                                          .submission_confirmation_message,
                                              }
                                        dispatch(
                                            current_field_type.depends_on_field.data_submit_function(
                                                submission_payload
                                            )
                                        )
                                    }
                                },
                                currentFormSnapshop: {
                                    keepUnsavedDataFunction: onAnswerChange,
                                    resetUnsavedDataErrorsFunction: setError,
                                    unsavedDataListOfFields: listOfFields,
                                    unsavedData: data,
                                },
                            },
                        })
                    } else {
                        if (errors && errors[current_field_type.id]) {
                            setError(current_field_type.id, undefined)
                        }
                    }
                }
            }
        }
        return () => {
            // console.log('this is removed')
            // setCrossFormData(undefined)
        }
    }, [application_submitted, application_draft])

    const updateList = (newList: IInsuranceFieldType[]) => {
        setListOfFields((prevstate) => {
            let newArray = [...prevstate]
            newList.forEach((newItem) => {
                if (
                    prevstate.findIndex(
                        (prevlistItem) => newItem.id === prevlistItem.id
                    ) === -1
                ) {
                    newArray.push(newItem)
                }
            })
            return newArray
        })
    }

    return data ? (
        <div style={{ width: '100%' }}>
            {list.map((item: IInsuranceFieldType, index: number) => {
                let prevItemHadDivider: boolean | undefined =
                    index !== 0 &&
                    list[index - 1] &&
                    list[index - 1].ends_section_with_divider

                let showCustomLicenseNumberField: boolean =
                    item.id === 'license_number' ? true : false

                // hide section header and divider for applications submitted before top field is_uk_resident was added
                // if application is submitted
                // and if top field is_uk_resident
                // and if that top field is undefined

                let hideSectionHeader =
                    appliIsNotDraft &&
                    item.id === 'is_uk_resident' &&
                    data['is_uk_resident'] === undefined
                        ? true
                        : false

                let hideSectionDivider =
                    appliIsNotDraft &&
                    data['is_uk_resident'] === undefined &&
                    (item.id === 'resides_in_the_uk' ||
                        item.id === 'license_date' ||
                        item.id === 'license_type')
                        ? true
                        : false

                return (
                    <div
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            position: 'relative',
                            zIndex: 99 - index,
                        }}
                        key={`index-${item.id}-${index}-${
                            isMobile ? 'mobile' : 'desktop'
                        }`}
                        id={`${item.id}-${isMobile}`}
                    >
                        <Expander
                            height={
                                data &&
                                data[item.id] !== undefined &&
                                data[item.id] !== null &&
                                data[item.id] !== '' &&
                                index !== 0 &&
                                item.type !== 'yes_no' &&
                                item.type !== 'contact_medium_choice' &&
                                prevItemHadDivider !== true
                                    ? 'auto'
                                    : 0
                            }
                        >
                            <div
                                style={{
                                    paddingTop: '8px',
                                }}
                            />
                        </Expander>
                        {hideSectionHeader
                            ? null
                            : item.starts_section_with_header && (
                                  <InsuranceHeader
                                      style={{
                                          paddingBottom:
                                              data[item.id] !== undefined
                                                  ? '30px'
                                                  : '20px',
                                      }}
                                  >
                                      {item.starts_section_with_header}
                                  </InsuranceHeader>
                              )}
                        {item.type === 'yes_no' &&
                            index !== 0 &&
                            !prevItemHadDivider && (
                                <div
                                    style={{
                                        paddingTop: '10px',
                                    }}
                                />
                            )}

                        {item.type === 'date' &&
                            index !== 0 &&
                            !prevItemHadDivider && (
                                <div
                                    style={{
                                        paddingTop: '10px',
                                    }}
                                />
                            )}
                        {showCustomLicenseNumberField ? (
                            <>
                                <InsuranceFormQuestion $theme={'light'}>
                                    {item.text}
                                </InsuranceFormQuestion>
                                <div style={{ paddingTop: '16px' }} />
                                <LicenseNumber
                                    isWizard={props.isWizard}
                                    isMobile={isMobile}
                                    onAnswerChange={onAnswerChange}
                                    isDisabled={
                                        application_draft?.status === 'DRAFT'
                                            ? false
                                            : true
                                    }
                                    licenseNumberType={
                                        application_draft?.main_driver
                                            ?.licence_address?.license_type
                                            ?.uid === 'NON_UK'
                                            ? undefined
                                            : application_draft?.main_driver
                                                  ?.licence_address
                                                  ?.license_issue_location
                                                  ?.name === 'Great Britain'
                                            ? 'great_britain'
                                            : application_draft?.main_driver
                                                  ?.licence_address
                                                  ?.license_issue_location
                                                  ?.name === 'Northern Ireland'
                                            ? 'northern_ireland'
                                            : undefined
                                    }
                                    sectionID={sectionID}
                                />
                            </>
                        ) : isMobile ? (
                            <RenderInsuranceQuoteFormFieldMobile
                                data={data}
                                errors={errors}
                                setError={setError}
                                onAnswerChange={(id: string, answer: any) => {
                                    onAnswerChange(id, answer)
                                }}
                                item={item}
                                mobileSearchPickerSectionURL={
                                    mobileSearchPickerSectionURL
                                }
                                sectionID={sectionID}
                                bg={bg}
                            />
                        ) : (
                            <RenderInsuranceQuoteFormFieldDesktop
                                data={data}
                                errors={errors}
                                setError={setError}
                                onAnswerChange={onAnswerChange}
                                item={item}
                                sectionID={sectionID}
                                bg={bg}
                            />
                        )}
                        <Expander
                            height={errors && errors[item.id] ? 'auto' : 0}
                        >
                            <Error
                                $theme={theme}
                                style={{
                                    paddingTop: '8px',
                                    maxWidth: isMobile ? 'auto' : '448px',
                                }}
                            >
                                {errors && errors[item.id]
                                    ? typeof errors[item.id] === 'string'
                                        ? errors[item.id]
                                        : errors[item.id]?.status
                                        ? `${
                                              errors[
                                                  item.id
                                              ].status?.toLowerCase() !==
                                              'invalid'
                                                  ? `${capitalizeFirstLetter(
                                                        errors[item.id].status
                                                    )} - `
                                                  : ''
                                          } ${
                                              errors[item.id]?.message
                                                  ? `${
                                                        errors[item.id]?.message
                                                    }`
                                                  : ''
                                          }`
                                        : item.error_txt
                                        ? item.error_txt
                                        : ''
                                    : ''}
                            </Error>
                            {crossFormData && crossFormData[item.id] ? (
                                <StandardCtaBtn
                                    bg="transparent"
                                    boder="none"
                                    removeShadowOnHover
                                    removePaddings
                                    customWidth="auto"
                                    customHeight="auto"
                                    onClick={() =>
                                        setIsCrossFormOpen({ id: item.id })
                                    }
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 8,
                                            marginTop: 12,
                                            marginBottom: 24,
                                        }}
                                    >
                                        <EditIcon
                                            color={colours[theme].primary}
                                            onClick={() => {}}
                                        />
                                        <span
                                            style={{
                                                color: colours[theme].primary,
                                            }}
                                        >
                                            {
                                                crossFormData[item.id]
                                                    ?.affected_field_error_cta
                                            }
                                        </span>
                                    </div>
                                </StandardCtaBtn>
                            ) : null}
                        </Expander>
                        <div
                            style={{
                                paddingBottom:
                                    showCustomLicenseNumberField && isMobile
                                        ? 8
                                        : showCustomLicenseNumberField
                                        ? 16
                                        : item.isMobileAnimated
                                        ? 24
                                        : hideSectionHeader
                                        ? 0
                                        : 16,
                            }}
                        />
                        <Expander
                            height={
                                (item.has_subform_if_true &&
                                    data &&
                                    (data[item.id] === true ||
                                        data[item.id]?.name === 'Yes')) ||
                                (item.has_subform_if_false &&
                                    data &&
                                    (data[item.id] === false ||
                                        data[item.id]?.name === 'False')) ||
                                (item.has_subform_if_true &&
                                    item.id === 'ncd_years' &&
                                    data &&
                                    data[item.id] > 0)
                                    ? 'auto'
                                    : 0
                            }
                        >
                            <InsuranceQuoteSubFormGenerator
                                data={data}
                                errors={errors}
                                setError={setError}
                                onAnswerChange={onAnswerChange}
                                field_id={item.id}
                                mobileSearchPickerSectionURL={
                                    mobileSearchPickerSectionURL
                                }
                                sectionID={sectionID}
                                updateListOfFields={(
                                    list: IInsuranceFieldType[]
                                ) => {
                                    updateList(list)
                                }}
                            />
                        </Expander>

                        <Expander
                            height={
                                item.has_subform_if_values &&
                                item.subform &&
                                data &&
                                (item.has_subform_if_values.indexOf(
                                    data[item.id]
                                ) !== -1 ||
                                    item.has_subform_if_values.indexOf(
                                        data[item.id]?.name
                                    ) !== -1 ||
                                    item.has_subform_if_values.indexOf(
                                        data[item.id]?.uid
                                    ) !== -1)
                                    ? 'auto'
                                    : 0
                            }
                        >
                            <InsuranceQuoteSubFormGenerator
                                predefined_list={item.subform}
                                data={data}
                                errors={errors}
                                setError={setError}
                                onAnswerChange={onAnswerChange}
                                field_id={item.id}
                                mobileSearchPickerSectionURL={
                                    mobileSearchPickerSectionURL
                                }
                                sectionID={sectionID}
                                updateListOfFields={(
                                    list: IInsuranceFieldType[]
                                ) => {
                                    updateList(list)
                                }}
                            />
                        </Expander>

                        <Expander
                            height={
                                item.has_self_replicating_subform_if_true ===
                                    true &&
                                data &&
                                (data[item.id] === true ||
                                    data[item.id]?.name === 'Yes')
                                    ? 'auto'
                                    : 0
                            }
                        >
                            <InsuranceQuoteSelfReplicatingSubFormGenerator
                                data={data}
                                errors={errors}
                                setError={setError}
                                onAnswerChange={onAnswerChange}
                                field_id={item.id}
                                entity_id={item.parent_entity_id}
                                isShown={
                                    item.has_self_replicating_subform_if_true ===
                                        true &&
                                    data &&
                                    (data[item.id] === true ||
                                        data[item.id]?.name === 'Yes')
                                        ? true
                                        : false
                                }
                                mobileSearchPickerSectionURL={
                                    mobileSearchPickerSectionURL
                                }
                                setEntityArr={setEntityArr}
                                entitiesObjArr={entitiesObjArr}
                                entitiesErrNormalised={entitiesErrNormalised}
                                isMobile={isMobile}
                                sectionID={sectionID}
                            />
                        </Expander>
                        {hideSectionDivider
                            ? null
                            : item.ends_section_with_divider === true && (
                                  <div
                                      style={{
                                          marginTop: customLineBottomPadding
                                              ? customLineBottomPadding
                                              : '24px',
                                          marginBottom: customLineBottomPadding
                                              ? customLineBottomPadding
                                              : '40px',
                                          background:
                                              'var(--border-muted, #e5e5e5)',
                                          height: '1px',
                                      }}
                                  />
                              )}
                    </div>
                )
            })}
        </div>
    ) : (
        <div />
    )
}

export default InsuranceQuoteFormGenerator
