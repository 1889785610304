import React from 'react'
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'
import Faded from '../../templates/animated/faded'
import NoDataRectangle from '../../atoms/noData/noDataRectangle'
import MobilePendingSharesBtn from '../../atoms/Button/mobilePendingSharesBtn'
import CarsSharedWithYouCardMobile from '../../molecules/cards/sharing/carsSharedWithYouCardMobile'
import { sortReceivedSharesByAlphabetical } from '../../../helpers/sort/sortByAlphabetical'
import { sortDirectReceivedSharesByDate } from '../../../helpers/sort/sortByDate'
import { AnimatePresence } from 'framer-motion'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { IDirectSharesReceivedObj } from '../../../redux/user/types'
import { IPrivateShareReceivedItemApprovalPayload } from '../../../redux/entities/cars/types'
import { usersActions } from '../../../redux/user/reducer'

interface Props {
    sort_id: string
}

const WrapperMobileAdjusted = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
`

const SectionLine = styled.div`
    height: 1px;
    background-color: var(--border-muted, #e5e5e5);
    width: 100%;
    box-sizing: border-box;
`

const MainDataWrapper = styled.div`
    width: 100%;
    padding: 0px 8px;
    max-width: 460px;
    margin: 0px auto;
`

const SharedWithYouCarsManagerMobile: React.FC<Props> = ({ sort_id }) => {
    const navigate = useNavigate()
    const location = useLocation()

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)

    const dispatch = useAppDispatch()

    const generateSharesDisplayIds = (
        share_ids: string[],
        shares_data: IDirectSharesReceivedObj,
        sort_id: string
    ) => {
        if (sort_id === 'alphabetical') {
            return sortReceivedSharesByAlphabetical(
                share_ids,
                shares_data,
                'cars'
            )
        } else if (sort_id === 'by_oldest') {
            return sortDirectReceivedSharesByDate(
                share_ids,
                shares_data,
                'oldest'
            )
        }
        return sortDirectReceivedSharesByDate(
            share_ids,
            shares_data,
            'most_recent'
        )
    }

    const cars_shared_received_obj =
        userLoggedIn &&
        userLoggedIn.received_direct_shares &&
        userLoggedIn.received_direct_shares.cars &&
        userLoggedIn.received_direct_shares.cars

    const carsReceivedData: IDirectSharesReceivedObj | undefined =
        cars_shared_received_obj &&
        cars_shared_received_obj.shares_received_ids.length > 0
            ? cars_shared_received_obj.shares_received_data
            : undefined

    const carsReceivedIds: string[] | undefined =
        cars_shared_received_obj &&
        cars_shared_received_obj.shares_received_ids.length > 0
            ? cars_shared_received_obj.shares_received_ids
            : undefined

    const pendingCarsReceivedIds: string[] | undefined =
        cars_shared_received_obj &&
        carsReceivedData &&
        cars_shared_received_obj.pending_shares_received_ids.length > 0
            ? sort_id !== ''
                ? generateSharesDisplayIds(
                      [...cars_shared_received_obj.pending_shares_received_ids],
                      carsReceivedData,
                      sort_id
                  )
                : cars_shared_received_obj.pending_shares_received_ids
            : undefined

    const acceptedCarsReceivedIds: string[] | undefined =
        cars_shared_received_obj &&
        carsReceivedData &&
        cars_shared_received_obj.accepted_shares_received_ids.length > 0
            ? sort_id !== ''
                ? generateSharesDisplayIds(
                      [
                          ...cars_shared_received_obj.accepted_shares_received_ids,
                      ],
                      carsReceivedData,
                      sort_id
                  )
                : cars_shared_received_obj.accepted_shares_received_ids
            : undefined

    const ignoredCarsReceivedIds: string[] | undefined =
        cars_shared_received_obj &&
        carsReceivedData &&
        cars_shared_received_obj.ignored_shares_received_ids.length > 0
            ? sort_id !== ''
                ? generateSharesDisplayIds(
                      [...cars_shared_received_obj.ignored_shares_received_ids],
                      carsReceivedData,
                      sort_id
                  )
                : cars_shared_received_obj.ignored_shares_received_ids
            : undefined

    const params = new URLSearchParams(location.search)
    const view_id = params.get('view_id')
    const entity_id = params.get('entity_id')

    return (
        <React.Fragment>
            {userLoggedIn && (
                <Faded>
                    <WrapperMobileAdjusted>
                        {(view_id === 'view_all' || !view_id) && (
                            <div style={{ width: '100%' }}>
                                {pendingCarsReceivedIds &&
                                    pendingCarsReceivedIds.length > 0 && (
                                        <MainDataWrapper style={{ padding: 0 }}>
                                            <MobilePendingSharesBtn
                                                text={`Review cars shared with you`}
                                                onClick={() =>
                                                    entity_id
                                                        ? navigate(
                                                              `/sharing/with-you?entity_id=${entity_id}&view_id=pending`
                                                          )
                                                        : navigate(
                                                              `/sharing/with-you?view_id=pending`
                                                          )
                                                }
                                                pendingSharesNb={
                                                    pendingCarsReceivedIds.length
                                                }
                                            />
                                            <div
                                                style={{
                                                    paddingTop: '20px',
                                                }}
                                            />
                                            <SectionLine />
                                            <div
                                                style={{
                                                    paddingTop: '20px',
                                                }}
                                            />
                                        </MainDataWrapper>
                                    )}

                                {carsReceivedData &&
                                    acceptedCarsReceivedIds &&
                                    acceptedCarsReceivedIds.length > 0 && (
                                        <WrapperMobileAdjusted>
                                            <AnimatePresence>
                                                {acceptedCarsReceivedIds.map(
                                                    (
                                                        shareid: string,
                                                        index: number
                                                    ) => {
                                                        if (
                                                            carsReceivedData![
                                                                shareid
                                                            ]
                                                        ) {
                                                            return (
                                                                <CarsSharedWithYouCardMobile
                                                                    key={`accepted_view_all_${shareid}_mobile`}
                                                                    share_received={
                                                                        carsReceivedData![
                                                                            shareid
                                                                        ]
                                                                    }
                                                                    hasMarginRight
                                                                    shareApprovalAction={(
                                                                        p: IPrivateShareReceivedItemApprovalPayload
                                                                    ) =>
                                                                        dispatch(
                                                                            usersActions.updateShareReceivedStatusRequest(
                                                                                {
                                                                                    share_id:
                                                                                        p.shareId,
                                                                                    status: p.approvalId,
                                                                                    entity_type:
                                                                                        'car',
                                                                                }
                                                                            )
                                                                        )
                                                                    }
                                                                />
                                                            )
                                                        } else {
                                                            return null
                                                        }
                                                    }
                                                )}
                                            </AnimatePresence>
                                        </WrapperMobileAdjusted>
                                    )}

                                {(!carsReceivedIds ||
                                    carsReceivedIds.length === 0) && (
                                    <MainDataWrapper>
                                        <NoDataRectangle text="Nothing shared with you" />
                                    </MainDataWrapper>
                                )}
                            </div>
                        )}

                        {view_id === 'accepted' &&
                            (carsReceivedData &&
                            acceptedCarsReceivedIds &&
                            acceptedCarsReceivedIds.length > 0 ? (
                                <WrapperMobileAdjusted>
                                    <AnimatePresence>
                                        {acceptedCarsReceivedIds.map(
                                            (
                                                shareid: string,
                                                index: number
                                            ) => {
                                                if (
                                                    carsReceivedData![shareid]
                                                ) {
                                                    return (
                                                        <CarsSharedWithYouCardMobile
                                                            key={`accepted_${shareid}_mobile`}
                                                            share_received={
                                                                carsReceivedData![
                                                                    shareid
                                                                ]
                                                            }
                                                            hasMarginRight
                                                            shareApprovalAction={(
                                                                p: IPrivateShareReceivedItemApprovalPayload
                                                            ) =>
                                                                dispatch(
                                                                    usersActions.updateShareReceivedStatusRequest(
                                                                        {
                                                                            share_id:
                                                                                p.shareId,
                                                                            status: p.approvalId,
                                                                            entity_type:
                                                                                'car',
                                                                        }
                                                                    )
                                                                )
                                                            }
                                                        />
                                                    )
                                                } else {
                                                    return null
                                                }
                                            }
                                        )}
                                    </AnimatePresence>
                                </WrapperMobileAdjusted>
                            ) : (
                                <MainDataWrapper>
                                    <NoDataRectangle text="You don't have accepted shares" />
                                </MainDataWrapper>
                            ))}

                        {view_id === 'pending' &&
                            (carsReceivedData &&
                            pendingCarsReceivedIds &&
                            pendingCarsReceivedIds.length > 0 ? (
                                <WrapperMobileAdjusted>
                                    <AnimatePresence>
                                        {pendingCarsReceivedIds.map(
                                            (
                                                shareid: string,
                                                index: number
                                            ) => {
                                                if (
                                                    carsReceivedData![shareid]
                                                ) {
                                                    return (
                                                        <CarsSharedWithYouCardMobile
                                                            key={`pending_${shareid}_mobile`}
                                                            share_received={
                                                                carsReceivedData![
                                                                    shareid
                                                                ]
                                                            }
                                                            hasMarginRight
                                                            shareApprovalAction={(
                                                                p: IPrivateShareReceivedItemApprovalPayload
                                                            ) =>
                                                                dispatch(
                                                                    usersActions.updateShareReceivedStatusRequest(
                                                                        {
                                                                            share_id:
                                                                                p.shareId,
                                                                            status: p.approvalId,
                                                                            entity_type:
                                                                                'car',
                                                                        }
                                                                    )
                                                                )
                                                            }
                                                        />
                                                    )
                                                } else {
                                                    return null
                                                }
                                            }
                                        )}
                                    </AnimatePresence>
                                </WrapperMobileAdjusted>
                            ) : (
                                <MainDataWrapper>
                                    <NoDataRectangle text="You don't have pending shares" />
                                </MainDataWrapper>
                            ))}
                        {view_id === 'ignored' &&
                            (carsReceivedData &&
                            ignoredCarsReceivedIds &&
                            ignoredCarsReceivedIds.length > 0 ? (
                                <WrapperMobileAdjusted>
                                    <AnimatePresence>
                                        {ignoredCarsReceivedIds.map(
                                            (
                                                shareid: string,
                                                index: number
                                            ) => {
                                                if (
                                                    carsReceivedData![shareid]
                                                ) {
                                                    return (
                                                        <CarsSharedWithYouCardMobile
                                                            key={`ignored_${shareid}_mobile`}
                                                            share_received={
                                                                carsReceivedData![
                                                                    shareid
                                                                ]
                                                            }
                                                            hasMarginRight
                                                            shareApprovalAction={(
                                                                p: IPrivateShareReceivedItemApprovalPayload
                                                            ) =>
                                                                dispatch(
                                                                    usersActions.updateShareReceivedStatusRequest(
                                                                        {
                                                                            share_id:
                                                                                p.shareId,
                                                                            status: p.approvalId,
                                                                            entity_type:
                                                                                'car',
                                                                        }
                                                                    )
                                                                )
                                                            }
                                                        />
                                                    )
                                                } else {
                                                    return null
                                                }
                                            }
                                        )}
                                    </AnimatePresence>
                                </WrapperMobileAdjusted>
                            ) : (
                                <MainDataWrapper>
                                    <NoDataRectangle text="You don't have ignored shares" />
                                </MainDataWrapper>
                            ))}
                    </WrapperMobileAdjusted>
                </Faded>
            )}
        </React.Fragment>
    )
}

export default SharedWithYouCarsManagerMobile
