import { call, fork, put, take } from 'redux-saga/effects'
import { api } from '../../../services'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import posthog from 'posthog-js'
import { IUrlShortenerAPI_res } from '../../../services/types'
import { IReduxError } from '../types'
import { carActions } from '../reducer'

export function* CreateShowcaseCarQRcodeShortUrlSaga(car_url: string) {
    try {
        let code_s: IUrlShortenerAPI_res = yield call(
            api.entities.updateCar.createUrlShortener,
            {
                url: car_url,
            }
        )
        yield put(
            carActions.createShowcaseCarQRcodeShortUrlSuccess(code_s.code)
        )
        posthog.capture('create_showcase_car_qrcode_short_url_success', {
            code: code_s.code,
        })
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: CreateShowcaseCarQRcodeShortUrlSaga,
                payload: car_url,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(
                carActions.createShowcaseCarQRcodeShortUrlError(typedError)
            )
            return
        }
    }
}

function* watcherCreateShowcaseCarShortUrlShare() {
    while (true) {
        const { payload } = yield take(
            carActions.createShowcaseCarQRcodeShortUrlRequest
        )
        yield call(CreateShowcaseCarQRcodeShortUrlSaga, payload)
    }
}

const create_showcase_car_short_url_sagas: any[] = [
    fork(watcherCreateShowcaseCarShortUrlShare),
]

export default create_showcase_car_short_url_sagas
