import React, { useState } from 'react'
import styled from 'styled-components'
import IconButton from '../../atoms/Button/iconButton'
import { device } from '../../templates/displays/devices'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import EntryAttachmentsFormWrapperDesktop from '../../organisms/editForms/attachmentsFormWrapper/entryAttachmentsFormWrapperDesktop'
import SectionHeaderV3 from '../../atoms/typography/sectionHeaderV3'
import useThemes from '../../../providers/theme/hooks'
import colours from '../../../providers/theme/colours'
import NavigateSquareIcon from '../../atoms/icons/components/navigateSquareIcon'
import { ITimelineItem } from '../../../redux/timeline/types'

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    @media ${device.ipad} {
        padding-left: 0px;
        padding-right: 0px;
    }
`

const TitleWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    @media ${device.ipad} {
        display: none;
    }
`

const ArrowButtonsWrapper = styled.div`
    display: none;
    @media ${device.ipad} {
        display: flex;
    }
`

const LeftDiv = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
`

const RightDiv = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`

const SmallPadding = styled.div`
    padding-left: 10px;
`

interface Props {
    item: ITimelineItem
    onAddAttachmentClick?: any
    elementToScrollTo: HTMLElement | null
    onEditTitleClick?: any
    goBackToTimeline?: string
    onDeleteClick?: any
    onAddCost?: any
    goToNextEntry?: any
    goToPreviousEntry?: any
    entityID: string
    carID: string
    entityType: 'entry' | 'cost'
    setFileUploaded?: (uploaded: boolean) => void
    readOnlyMode?: boolean
}

const EntryPageTopHeaderDesktop: React.FC<Props> = (props) => {
    const navigate = useNavigate()
    const location = useLocation()
    const { theme } = useThemes()
    const [tooltipVisible, setTooltipVisible] = useState<
        'close' | 'cost' | 'delete' | undefined
    >(undefined)

    const scrollToElement = () =>
        props.elementToScrollTo &&
        props.elementToScrollTo.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        })

    const { entityID, carID, readOnlyMode } = props

    return (
        <Wrapper>
            <LeftDiv>
                <ArrowButtonsWrapper>
                    <Link
                        to={props.goBackToTimeline || ''}
                        onClick={(e) => {
                            e.preventDefault()
                            navigate(props.goBackToTimeline || '', {
                                state: { prevPath: location.pathname },
                            })
                        }}
                    >
                        <IconButton
                            icon="grey_cross"
                            iconwidth="14px"
                            iconheight="14px"
                            noborder
                            bgcolor="var(--bg-color, #fff)"
                            buttonheight="40px"
                            buttonwidth="40px"
                        />
                    </Link>
                    <SmallPadding />
                    <IconButton
                        icon="arrow"
                        onClick={props.goToPreviousEntry}
                        buttonheight="40px"
                        buttonwidth="40px"
                        bgcolor="var(--bg-color, #fff)"
                    />
                    <SmallPadding />
                    <IconButton
                        icon="arrow"
                        orientation="down"
                        onClick={props.goToNextEntry}
                        buttonheight="40px"
                        buttonwidth="40px"
                        bgcolor="var(--bg-color, #fff)"
                    />
                </ArrowButtonsWrapper>
                <TitleWrapper>
                    <Link
                        to={props.goBackToTimeline || ''}
                        onClick={(e) => {
                            e.preventDefault()
                            navigate(props.goBackToTimeline || '', {
                                state: { prevPath: location.pathname },
                            })
                        }}
                        style={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <NavigateSquareIcon />{' '}
                    </Link>
                    <SmallPadding />
                    <SmallPadding />
                    <SectionHeaderV3
                        fontsize="24px"
                        icon="v2_edit"
                        hasActionIcon={false}
                    >
                        {props.item.title}
                    </SectionHeaderV3>
                </TitleWrapper>
            </LeftDiv>
            {readOnlyMode ? null : (
                <RightDiv>
                    <IconButton
                        buttonheight="48px"
                        buttonwidth="48px"
                        bgcolor="transparent"
                        borderColor={colours[theme].border_muted}
                        icon={'edit_pen_v2'}
                        iconwidth="16px"
                        onClick={props.onEditTitleClick}
                    />
                    <SmallPadding />
                    <div>
                        <EntryAttachmentsFormWrapperDesktop
                            carid={carID}
                            entityID={entityID}
                            isTopPage
                            scrollToElement={scrollToElement}
                        />
                    </div>
                    <SmallPadding />
                    <IconButton
                        buttonheight="48px"
                        buttonwidth="48px"
                        bgcolor="transparent"
                        borderColor={colours[theme].border_muted}
                        icon={'cost_icon_v2'}
                        iconwidth="16px"
                        onClick={props.onAddCost}
                        hasTooltip
                        tooltipColor={'#1a1a1a'}
                        tooltipText={'Add cost'}
                        tooltipPlacement={'bottom'}
                        isTooltipVisible={tooltipVisible === 'cost'}
                        onMouseEnter={() => setTooltipVisible('cost')}
                        onMouseLeave={() => setTooltipVisible(undefined)}
                    />
                    <SmallPadding />
                    <IconButton
                        buttonheight="48px"
                        buttonwidth="48px"
                        bgcolor="transparent"
                        borderColor={colours[theme].border_muted}
                        icon={'grey_bin'}
                        iconheight="17px"
                        onClick={props.onDeleteClick}
                        hasTooltip
                        tooltipColor={'#1a1a1a'}
                        tooltipText={'Delete entry'}
                        isTooltipVisible={tooltipVisible === 'delete'}
                        tooltipPlacement={'bottom'}
                        onMouseEnter={() => setTooltipVisible('delete')}
                        onMouseLeave={() => setTooltipVisible(undefined)}
                    />
                </RightDiv>
            )}
        </Wrapper>
    )
}

export default EntryPageTopHeaderDesktop
