import styled from 'styled-components'
import BigRedDeletionButton from '../../atoms/Button/bigRedDeletionButton'
import Faded from '../../templates/animated/faded'
import { device } from '../../templates/displays/devices'

type IStyle = {
    height?: string
    isEditForm?: boolean
}

const OverWrapper = styled.div<IStyle>`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.65);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 3;
    border-radius: 5px;

    @media ${device.mobile_and_ipad} {
        display: none;
    }
`

const Wrapper = styled.div`
    margin-top: 20vh;
    background-color: var(--off-bg-color, #fafafa);
    padding: 30px;
    padding-top: 20px;
    border-radius: 20px;
    width: 50%;
    min-width: 300px;
    max-width: 500px;
`
const Title = styled.div`
    font-family: Lato;
    font-size: 20px;
    color: var(--text-strong, #1a1a1a);
`

const Details = styled.div`
    font-family: Lato;
    font-size: 15px;
    color: var(--text-default, #666666);
`

const Padding = styled.div`
    padding-top: 10px;
`

interface Props {
    action: any
    actionCopy: string
    titleCopy: string
    detailsCopy: string
    toggle: any
    isOpen: boolean
    height?: string
    isEditForm?: boolean
}

const PublicLinkDeactivConfDesktop = (props: Props) => {
    const {
        action,
        actionCopy,
        titleCopy,
        detailsCopy,
        toggle,
        isOpen,
        isEditForm,
    } = props

    return isOpen ? (
        <Faded>
            <OverWrapper onClick={toggle} isEditForm={isEditForm}>
                <Wrapper data-attr="confirm-deletion-sheet">
                    <Title>{titleCopy}</Title>
                    <Padding />
                    <Details>{detailsCopy}</Details>
                    <Padding />
                    <Padding />
                    <Padding />
                    {/* <ButtonContainer> */}
                    <BigRedDeletionButton
                        text={actionCopy}
                        onClick={() => {
                            setTimeout(() => {
                                action()
                            }, 50)
                            toggle()
                        }}
                    />
                    {/* </ButtonContainer> */}
                </Wrapper>
            </OverWrapper>
        </Faded>
    ) : (
        <div />
    )
}

export default PublicLinkDeactivConfDesktop
