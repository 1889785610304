import * as React from 'react'
import styled from 'styled-components'
import { device } from '../devices'
import { useLocation } from 'react-router-dom'
import { checkIfPwaStandalone } from '../../pwaCustom/checkIpadPwa'
import useThemes from '../../../../providers/theme/hooks'
import colours from '../../../../providers/theme/colours'
import { useAppSelector } from '../../../../redux/store/hooks'

interface Props {
    children?: React.ReactNode
    hasMobileSubNav?: boolean
    isOnboarding?: boolean
    bg?: string
    paddingRight?: string
    paddingTop?: string
    paddingBottom?: string
}

interface Istyle {
    $isCollapsed: boolean
    $isExternalPage?: boolean
    $hasMobileSubNav?: boolean
    $isOnboarding?: boolean
    $isDefaultNavigationRemoved?: boolean
    $paddingRight?: string
    $paddingTop?: string
    $paddingBottom?: string
}

const PageWrapperStyle = styled.div<Istyle>`
    width: 100%;
    box-sizing: border-box;

    @media (min-width: 881px) {
        position: relative;
        margin-top: ${(props) =>
            props.$isDefaultNavigationRemoved
                ? 0
                : props.$isExternalPage
                ? '75px'
                : '64px'};
        transition: all 100ms;

        padding-left: 0px;
        padding-right: 0px;
        transition: all 500ms;
        padding-right: ${(props) => props.$paddingRight ?? undefined};
        padding-top: ${(props) => props.$paddingTop ?? undefined};
    }

    @media (max-width: 880px) {
        padding-top: ${(props) =>
            props.$hasMobileSubNav
                ? '130px'
                : props.$isOnboarding
                ? '0px'
                : '65px'};

        height: 100%;
    }
    @media (max-width: 500px) {
        padding-top: ${(props) =>
            props.$hasMobileSubNav
                ? '115px'
                : props.$isOnboarding
                ? '0px'
                : '65px'};

        height: 100%;
    }
`

export const MenuAdjustFullPage = styled.div<Istyle>`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    justify-content: center;
    transition: all 100ms;

    @media (max-width: 799px) {
        display: flex;
        height: 100%;
        padding-left: 0px;
        padding-right: 0px;
        align-items: start;
        justify-content: flex-start;
        flex-direction: column;
        padding-bottom: ${(props) => props.$paddingBottom ?? '100px'};
    }

    @media ${device.desktop} {
        padding-left: ${(props) => (props.$isCollapsed ? '50px' : '200px')};
    }
    @media ${device.large_desktop} {
        padding-left: ${(props) =>
            props.$isCollapsed ? '71px' : '271px'}; //. 230
    }

    @media ${device.smallest_laptops} {
        padding-left: ${(props) => (props.$isCollapsed ? '50px' : '200px')};
    }
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
`

const FullPageWrapper: React.FC<Props> = ({
    children,
    hasMobileSubNav,
    isOnboarding,
    paddingBottom,
    bg,
    paddingRight,
    paddingTop,
}) => {
    const location = useLocation()

    const isCollapsed = useAppSelector(
        (state) => state.menus.desktopMainAppMenu.isCollapsed
    )
    const isDefaultNavigationRemoved = useAppSelector(
        (state) => state.menus.isDefaultNavigationRemoved
    )

    const currentPath = checkIfPwaStandalone()
        ? window.location.pathname
        : location.pathname

    const isExternalPage: boolean = currentPath.match(/shared/g) ? true : false

    const { theme } = useThemes()

    return (
        <Row>
            <MenuAdjustFullPage
                style={{
                    background: bg ?? colours[theme].background_default,
                }}
                $isCollapsed={isCollapsed}
                $paddingBottom={paddingBottom}
            >
                <PageWrapperStyle
                    $isExternalPage={isExternalPage}
                    $isCollapsed={isCollapsed}
                    $hasMobileSubNav={hasMobileSubNav}
                    $isOnboarding={isOnboarding}
                    $isDefaultNavigationRemoved={isDefaultNavigationRemoved}
                    $paddingRight={paddingRight}
                    $paddingTop={paddingTop}
                >
                    {children}
                </PageWrapperStyle>
            </MenuAdjustFullPage>
        </Row>
    )
}

export default FullPageWrapper
