import { IDropdownItem } from 'entityModels'
import React from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import FilterByMultipleDesktop from '../../organisms/filterByMultiple/timeline/filterByMultipleDesktop'
import FilterTimelineByMultipleWSearchWFuse from '../../organisms/filterByMultiple/timeline/filterTimelineByMultipleWSearchWFuse'
import StandardCtaBtn from '../../atoms/Button/standardCtaBtn'
import { AddIcon } from '../../atoms/statefulicons'
import TimelineSearchBarFuseWrapperDesktop from '../../organisms/searchbar/timeline/timelineSearchBarFuseWrapperDesktop'
import useThemes from '../../../providers/theme/hooks'
import colours from '../../../providers/theme/colours'
import { ITimelineEntryTypes } from '../../../redux/timeline/types'
import { device } from '../../templates/displays/devices'

interface IStyled {
    imgsrc?: string
    isAnimated?: boolean
}

const Wrapper = styled.div<IStyled>`
    width: 100%;
    box-sizing: border-box;
    background-color: var(--bg-color, #fff);
    max-width: 1400px;
    padding-left: 48px;
    padding-right: 48px;

    @media ${device.smallest_laptops} {
        padding-left: 24px;
        padding-right: 24px;
    }
`

const Container = styled.div<IStyled>`
    display: flex;
    justify-content: space-between;
    padding-top: 32px;
    padding-bottom: 32px;
`

const FiltersContainer = styled.div`
    display: flex;
    gap: 16px;
`

const FiltersWithoutSearch = styled.div`
    position: relative;
    z-index: 7;
`
const FiltersWithSearch = styled.div`
    position: relative;
    z-index: 6;
`

const AnimatedWrapper = styled.div<IStyled>`
    @keyframes scale {
        0% {
            transform: scale(1);
        }

        25% {
            transform: scale(1.1);
        }

        50% {
            transform: scale(1);
        }

        100% {
            transform: scale(1.1);
        }
    }

    ${(props) => props.isAnimated && 'animation: 0.4s scale linear'};
    animation-delay: 0.5s;
`

interface Props {
    imgsrc?: any
    dataCyId?: string
    categories?: IDropdownItem[] | null | undefined
    tags?: IDropdownItem[] | null | undefined
    onAddNEwEntry?: () => void
    selectedFilteringLabels: string[]
    selectedFilteringCategories: ITimelineEntryTypes[]
    filterByCategories: (categories: ITimelineEntryTypes[]) => void
    filterByLabels: (labels: string[]) => void
    activeEntryTitleQuery: string | null | undefined
    sendFiltersToApi: (filters: any) => void
    readOnlyMode?: boolean
}

const CarTimelineHeaderDesktop: React.FC<Props> = ({
    tags,
    categories,
    onAddNEwEntry,
    selectedFilteringCategories,
    selectedFilteringLabels,
    filterByCategories,
    filterByLabels,
    activeEntryTitleQuery,
    sendFiltersToApi,
    readOnlyMode,
}) => {
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const section_id: string | null = params.get('section_id')

    const { theme } = useThemes()

    return (
        <Wrapper>
            {!readOnlyMode && (
                <Container>
                    <div style={{ width: 400 }}>
                        <TimelineSearchBarFuseWrapperDesktop
                            categoriesToSearch={categories ?? []}
                            labelsToSearch={tags ?? []}
                            activeFilters={{
                                categories: selectedFilteringCategories,
                                labels: selectedFilteringLabels,
                                titleQuery: activeEntryTitleQuery ?? undefined,
                            }}
                            apiSearchBySelected={sendFiltersToApi}
                        />
                    </div>

                    <FiltersContainer>
                        <FiltersWithoutSearch>
                            <FilterByMultipleDesktop
                                categoryData={categories}
                                selectedFilteringCategories={
                                    selectedFilteringCategories
                                }
                                filterBySelected={filterByCategories}
                                dataCyId="load_filter_hf_by_categories_desktop"
                                dataCyIdSave="filter_hf_by_categories_desktop"
                                isTimeline
                            />
                        </FiltersWithoutSearch>
                        <FiltersWithSearch>
                            <FilterTimelineByMultipleWSearchWFuse
                                placeholder="Search for title or tag"
                                user_searchable_labels_list={tags ?? []}
                                selectedFilteringLabels={
                                    selectedFilteringLabels
                                }
                                onSave={filterByLabels}
                                dataCyId="load_filter_hf_by_labels_desktop"
                                dataCyIdSave="filter_hf_by_labels_desktop"
                            />
                        </FiltersWithSearch>
                        <AnimatedWrapper isAnimated={section_id === 'create'}>
                            <StandardCtaBtn
                                onClick={onAddNEwEntry}
                                customWidth="48px"
                                customHeight="48px"
                                removePaddings
                            >
                                <AddIcon
                                    onClick={() => {}}
                                    colour={colours[theme].background_default}
                                />
                            </StandardCtaBtn>
                        </AnimatedWrapper>
                    </FiltersContainer>
                </Container>
            )}
        </Wrapper>
    )
}

export default CarTimelineHeaderDesktop
