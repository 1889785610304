import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import CarShareSearchBarMobile from '../../searchbar/carShare/carShareSearchBarMobile'
import SearchResultsItemMobile from '../../searchbar/carShare/searchResultsItemMobile'
import Expander from '../../../atoms/expander/expander'
import SearchLoadingPlaceholdersMobile from '../../searchbar/carShare/searchLoadingPlaceholdersMobile'
import { createSearchableIndex } from '../../../../helpers/search/search'
import { IShareRecipientRequest_redux } from '../../../../redux/entities/cars/actions'
import { IDirectShareOwnedItem } from '../../../../redux/entities/cars/types'
import { ISearchUsersResult } from '../../../../redux/searchUsers/types'

const Padding = styled.div`
    padding-top: 20px;
`
const Wrapper = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    z-index: 5;
    top: 50px;
    padding-top: 10px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 2px 4px 7px rgba(0, 0, 0, 0.07);
    background-color: #ffffff;
`

const SearchResultsDropdownWrapper = styled.div<{ $isVisible: boolean }>`
    padding-top: 180px;
    opacity: ${(props) => (props.$isVisible ? '1' : '0')};
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding-left: 24px;
    padding-right: 24px;
`

const SearchForWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    padding-bottom: 10px;
    padding-left: 30px;
`

const renderSearchResults = (payload: {
    share: IDirectShareOwnedItem
    searchResults: ISearchUsersResult[] | undefined
    onSearchResultSelect: (p: IShareRecipientRequest_redux) => void
    selectedUsers: string[]
    setClickedIndex: (index: number) => void
    clickedIndex: number
    redirect?: any
}) =>
    payload.searchResults &&
    payload.searchResults.map((owner: ISearchUsersResult, index) => {
        let isAdded = payload.selectedUsers.includes(owner.uid)
        return (
            <div style={{ paddingBottom: '20px' }} key={`${index}user`}>
                <SearchResultsItemMobile
                    onSearchResultSelect={() => {
                        !isAdded &&
                            payload.onSearchResultSelect({
                                share_id: payload.share.id,
                                recipient_userid: owner.uid,
                                entity_id: payload.share.entity_id,
                            })
                        !isAdded && payload.setClickedIndex(index)
                        payload.redirect()
                    }}
                    owner={owner}
                    isAdded={isAdded}
                    isLoading={
                        payload.clickedIndex >= 0 &&
                        index === payload.clickedIndex
                    }
                />
            </div>
        )
    })

const renderLoadingPlaceholders = () =>
    [1, 2, 3, 4, 5].map((owner, index) => (
        <div style={{ paddingBottom: '20px' }} key={`${index}user`}>
            <SearchLoadingPlaceholdersMobile />
        </div>
    ))

interface Props {
    share: IDirectShareOwnedItem
    searchUsers: (value: string | undefined) => void
    searchResults: ISearchUsersResult[] | undefined
    resultsLoading: boolean
    addShareRecipient: (p: IShareRecipientRequest_redux) => void
    selectedUsers: string[]
    sharesLoading?: boolean
    goBack?: any
}

const PrivateShareSearchFormManagerMobile: React.FC<Props> = ({
    share,
    searchUsers,
    searchResults,
    resultsLoading,
    addShareRecipient,
    selectedUsers,
    sharesLoading,
    goBack,
}) => {
    const [searchResultsInState, setSearchResultsInState] = useState<
        ISearchUsersResult[] | undefined
    >(searchResults)
    const [searchFor, setSearchFor] = useState<string | undefined>(undefined)
    const [_, setIsSearchOpen] = useState(false)
    const [isSearchActive, setIsSearchActive] = useState(false)
    const [typedValue, setTypedValue] = useState('')
    const [clickedIndex, setClickedIndex] = useState(-1)

    useEffect(() => {
        if (searchResults && searchFor) {
            const make_fuse = searchResults
                ? createSearchableIndex(searchResults, [
                      'display_name',
                      'family_name',
                      'given_name',
                  ])
                : undefined

            const fusedSearchResults = make_fuse
                ? make_fuse
                      .search(searchFor, { limit: 5 })
                      .map((item) => item.item)
                : undefined

            setSearchResultsInState(fusedSearchResults)
        }
    }, [searchResults, searchFor])

    useEffect(() => {
        if (clickedIndex >= 0) {
            const timer = setTimeout(() => {
                if (!sharesLoading) {
                    setSearchResultsInState(undefined)
                    setSearchFor(undefined)
                    setIsSearchOpen(false)
                    setIsSearchActive(false)
                    setTypedValue('')
                    setClickedIndex(-1)
                }
            }, 800)
            return () => clearTimeout(timer)
        }
    }, [clickedIndex, sharesLoading])

    const showSearchResultsLoader = typedValue && searchFor !== typedValue

    return (
        <>
            <Wrapper>
                <CarShareSearchBarMobile
                    onSearch={(value: string) => {
                        setSearchFor(value)
                        searchUsers(value)
                    }}
                    searchFor={searchFor}
                    clearSearch={() => {
                        setSearchFor(undefined)
                        setIsSearchActive(false)
                        setTypedValue('')
                        searchUsers(undefined)
                    }}
                    manageissearchactive={(value: boolean) =>
                        setIsSearchActive(value)
                    }
                    issearchactive={isSearchActive}
                    onInputChange={(value: string) => setTypedValue(value)}
                />

                <Padding />
                {isSearchActive && (
                    <Expander height={isSearchActive ? 'auto' : 0} width="100%">
                        <SearchForWrapper>
                            <span
                                style={{
                                    color: '#b9b9b9',
                                    fontFamily: 'Lato',
                                    paddingRight: '5px',
                                }}
                            >
                                search for:
                            </span>
                            <span
                                style={{
                                    color: '#000000',
                                    fontFamily: 'Lato',
                                }}
                            >
                                {searchFor}
                            </span>
                        </SearchForWrapper>
                    </Expander>
                )}
            </Wrapper>
            <SearchResultsDropdownWrapper
                $isVisible={typedValue ? true : false}
            >
                <Expander height={typedValue ? 'auto' : 0}>
                    {searchResultsInState
                        ? renderSearchResults({
                              share: share,
                              searchResults: searchResultsInState,
                              onSearchResultSelect: addShareRecipient,
                              selectedUsers: selectedUsers ? selectedUsers : [],
                              setClickedIndex: setClickedIndex,
                              clickedIndex: clickedIndex,
                              redirect: goBack,
                          })
                        : showSearchResultsLoader || resultsLoading
                        ? renderLoadingPlaceholders()
                        : typedValue.trim() === ''
                        ? 'Please type in a valid name'
                        : typedValue.trim().length < 4
                        ? 'Please type at least 4 characters or more'
                        : 'No user matches your search'}
                    <div style={{ paddingTop: '80px' }} />
                </Expander>
            </SearchResultsDropdownWrapper>
        </>
    )
}

export default PrivateShareSearchFormManagerMobile
