import React, { useState, useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '../../../redux/store/hooks'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import Faded from '../../templates/animated/faded'
import { ICreateTaskPayload, IDropdownItem } from 'entityModels'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import TaskViewsManagerDesktop from '../../organisms/tasksViewManager/tasksViewManagerDesktop'
import TaskViewsManagerMobile from '../../organisms/tasksViewManager/tasksViewManagerMobile'
import { generate_task_cars_dropdown_list } from '../../../redux/conversions/dropdowns/taskCarsDropdownGenerator'
import { editFormsActions } from '../../../redux/editForms/reducer'
import ModalDisplay from '../../templates/displays/pageWrappers/modalDisplay'
import CreateTaskFormDesktop from '../../organisms/editForms/taskFormManager/custom/createTaskFormDesktop'
import CreateTaskFormMobile from '../../organisms/editForms/taskFormManager/custom/createTaskFormMobile'
import TaskListManagerDesktop from '../../organisms/tasksViewManager/taskListManager/taskListManagerDesktop'
import TaskListManagerMobile from '../../organisms/tasksViewManager/taskListManager/taskListManagerMobile'
import { OOtherTaskOptions, OTaskFilterNSort } from '../../../ol/ol'
import TasksSubMenuActions from '../../atoms/menu/pageSubMenuActions/tasksSubMenuActions'
import FabsMenusMobile from '../../templates/menus/fabsMenuMobile'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import FullPagePageWrapper from '../../templates/displays/pageWrappers/FullPagePageWrapper'
import ShimmerOverlay from '../../atoms/skeleton/shimmerOveraly'
import task_skeleton_desktop from '../../atoms/skeleton/tasks/task_skeleton_desktop.png'
import task_skeleton_mobile from '../../atoms/skeleton/tasks/task_skeleton_mobile.png'
import task_skeleton_desktop_dark from '../../atoms/skeleton/tasks/task_skeleton_desktop_dark.png'
import task_skeleton_mobile_dark from '../../atoms/skeleton/tasks/task_skeleton_mobile_dark.png'
import { tasksActions } from '../../../redux/tasks/reducer'
import { carActions } from '../../../redux/entities/cars/reducer'
import { usersActions } from '../../../redux/user/reducer'
import { garagesActions } from '../../../redux/entities/garages/reducer'
import { ICar } from '../../../redux/entities/cars/types'
import useThemes from '../../../providers/theme/hooks'

const Wrapper = styled.section`
    @media ${device.beyond_ipad_mobile} {
        background-color: var(--bg-color, #fff);
        padding-left: 24px;
        padding-right: 24px;
        transform: translateY(-50px);
        min-height: 100vh;
    }
    @media (min-width: 1400px) {
        padding-left: 50px;
        padding-right: 50px;
    }
`

const ViewManagerDesktopWrapper = styled.section`
    @media ${device.beyond_ipad_mobile} {
        padding-top: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
    }
`

const ContentWrapper = styled.section`
    @media ${device.beyond_ipad_mobile} {
        width: 100%;
        box-sizing: border-box;
        padding-bottom: 80px;
        min-height: 100vh;
    }
`

const PaddingViewsMenuMobileIpad = styled.div`
    @media ${device.mobile} {
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 20px;
    }
    @media ${device.ipad} {
        padding-top: 40px;
        padding-left: 24px;
        padding-right: 24px;
    }
`
const SkeletonImg = styled.div`
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    position: relative;
`

const Wrap = styled.div`
    display: grid;
    column-gap: 24px;
    row-gap: 24px;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 1000px) {
        grid-template-columns: 1fr;
    }
    @media ${device.wide_desktop} {
        grid-template-columns: 1fr 1fr 1fr;
    }
`

export const loadingPlaceholders = (n: number, img: string) => (
    <Wrap>
        {Array.from(Array(n).keys()).map((item) => (
            <SkeletonImg key={item}>
                <img
                    src={img}
                    style={{
                        height: '100%',
                        width: '100%',
                        boxSizing: 'border-box',
                        objectFit: 'cover',
                    }}
                />
                <div
                    style={{
                        width: '100%',
                        boxSizing: 'border-box',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                    }}
                >
                    <ShimmerOverlay customOpacity={0.1} isthingloading />
                </div>
            </SkeletonImg>
        ))}
    </Wrap>
)

const CarTasks: React.FC = () => {
    let { theme } = useThemes()

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { carid } = useParams<{ carid: string }>()

    const [dropdownCars, setDropdownCars] = useState<IDropdownItem[]>([])

    const [dropdownCarsWithOptions, setDropdownCarsWithOptions] = useState<
        IDropdownItem[]
    >([])
    const [carSelected, setCarSelected] = useState<string | null>(carid || null)
    const [sortBy, setSortBy] = useState<string>(
        OTaskFilterNSort.sort_by.created_at.name
    )
    const [filterBy, setFilterBy] = useState<string>(
        OTaskFilterNSort.filter_by.outstanding.name
    )

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    const garage_id = useAppSelector(
        (state) => state.user.userLoggedIn?.owns_garage?.uid
    )
    const all_tasks = useAppSelector((state) => state.tasks)
    const carsData = useAppSelector((state) => state.entities.carsData)
    const garagesData = useAppSelector(
        (state) => state.entities.garagesData.garages
    )
    const isCreateCarTaskFormOpen = useAppSelector(
        (state) => state.editForms.formsData.createTaskForm.isOpen
    )
    const isFilterByOpened = useAppSelector(
        (state) => state.editForms.formsData.filterBy.isOpen
    )
    const isSortByOpened = useAppSelector(
        (state) => state.editForms.formsData.sortBy.isOpen
    )
    const tasksDisplayCriteria = useAppSelector(
        (state) => state.tasks.tasksDisplayCriteria
    )
    const tasksLoading = useAppSelector((state) => state.tasks.loading)
    const carsLoading = useAppSelector(
        (state) => state.entities.carsData.loading
    )
    const garagesLoading = useAppSelector(
        (state) => state.entities.garagesData.loading
    )
    const remindersLoading = useAppSelector(
        (state) => state.tasks.isRemindersLoading
    )

    const sortView = (id: string) => {
        setSortBy(id)
    }

    const filterView = (text: string) => {
        setFilterBy(text)
    }

    const selectCarViewId = (name: string) => {
        if (name === OOtherTaskOptions.general_task.name) {
            setCarSelected(OOtherTaskOptions.general_task.name)
            return
        }

        if (carsData.cars) {
            let car_ids_list: string[] = Object.keys(carsData.cars)

            for (const carid of car_ids_list) {
                if (carsData.cars[carid].title === name) {
                    setCarSelected(carid)
                    return
                }
            }
        }
        setCarSelected(null)
    }

    const generate_cars_dropdown_list = (arr: string[]) => {
        let cars = carsData.cars

        if (garage_id) {
            let obj = generate_task_cars_dropdown_list(arr, cars, garage_id)

            let options = [
                {
                    uid: garage_id,
                    name: OOtherTaskOptions.all_tasks.name,
                },
                {
                    uid: OOtherTaskOptions.general_task.uid,
                    name: OOtherTaskOptions.general_task.name,
                },
            ]

            let cars_only_dropdown = [...obj]

            let cars_dropdown_with_options = [...obj]

            cars_dropdown_with_options.unshift(...options)

            setDropdownCars(cars_only_dropdown)
            setDropdownCarsWithOptions(cars_dropdown_with_options)
        }
    }

    useEffect(() => {
        if (location.state) {
            const prevPath = (location.state as any).prevPath
            const taskId = prevPath.replace(`/car/${carid}/tasks/`, '')
            const hasLettersAndNumber = /^[0-9a-zA-Z]+$/

            if (!hasLettersAndNumber.test(taskId)) {
                setCarSelected(carid || null)
                setSortBy(OTaskFilterNSort.sort_by.created_at.name)
                setFilterBy(OTaskFilterNSort.filter_by.outstanding.name)
            }
        }
    }, [location])

    useEffect(() => {
        dispatch(
            customNavDataActions.setInitialLandingUrl(`/car/${carid}/tasks/`)
        )
        dispatch(carActions.setCurrentCar(carid || ''))

        if (userLoggedIn) {
            if (garage_id && !tasksLoading) {
                if (
                    !all_tasks ||
                    !all_tasks.tasks ||
                    Object.keys(all_tasks.tasks).length === 0 ||
                    Object.keys(all_tasks.tasks).length === 1
                ) {
                    dispatch(tasksActions.getTasksByGarageIdRequest(garage_id))
                }
            }
        } else {
            dispatch(usersActions.getCurrentUserDataRequest())
        }
    }, [userLoggedIn])

    useEffect(() => {
        if (
            userLoggedIn &&
            garage_id &&
            garagesData &&
            garagesData[garage_id] &&
            garagesData[garage_id].cars
        ) {
            generate_cars_dropdown_list(garagesData[garage_id].cars)
        } else if (garage_id) {
            if (
                !garagesData ||
                !garagesData[garage_id] ||
                !garagesData[garage_id].cars
            ) {
                dispatch(garagesActions.getGarageByUserIdRequest())
            }
        }
    }, [garagesData, userLoggedIn])

    useEffect(() => {
        if (
            carSelected !== tasksDisplayCriteria.selectedCarID ||
            filterBy !== tasksDisplayCriteria.filteredBy ||
            sortBy !== tasksDisplayCriteria.sortBy
        ) {
            dispatch(
                tasksActions.registerTasksDisplayCriteriaSuccess({
                    selectedCarID: carSelected,
                    sortBy: sortBy,
                    filteredBy: filterBy,
                })
            )
        }
    }, [carSelected, filterBy, sortBy])

    const all_tasks_uids = all_tasks.tasks && Object.keys(all_tasks.tasks)

    const currentCar: ICar | undefined =
        carsData && carsData.cars && carid && carsData.cars[carid]
            ? carsData.cars[carid]
            : undefined

    const isDataLoading =
        tasksLoading || carsLoading || garagesLoading || remindersLoading

    useEffect(() => {
        if (currentCar?.archived_on) {
            navigate(`/archives/car/${currentCar.id}/overview`, {
                replace: true,
            })
        }
    }, [currentCar])

    return (
        <React.Fragment>
            <FabsMenusMobile hasAdd={true} hasSubNav={true} />
            <Wrapper>
                <FullPagePageWrapper hasMobileSubNav bg={'transparent'}>
                    {userLoggedIn &&
                        garage_id &&
                        all_tasks &&
                        garagesData &&
                        garagesData[garage_id] &&
                        garagesData[garage_id].cars &&
                        dropdownCars.length !== 0 && (
                            <Faded>
                                <DesktopDisplayOnly>
                                    <ViewManagerDesktopWrapper>
                                        <TaskViewsManagerDesktop
                                            isCarTasksPage
                                            dropdownCars={
                                                dropdownCarsWithOptions
                                            }
                                            selectCarViewId={selectCarViewId}
                                            toggleCreateTaskForm={() => {
                                                dispatch(
                                                    editFormsActions.toggleCreateTaskForm()
                                                )
                                            }}
                                            sortView={sortView}
                                            filterView={filterView}
                                            selectedCarData={
                                                carSelected !== null
                                                    ? carsData.cars[carSelected]
                                                    : carsData.cars[`${carid}`]
                                            }
                                            initialValues={{
                                                selectedCarID: carSelected,
                                                sortBy: sortBy,
                                                filteredBy: filterBy,
                                            }}
                                        />
                                    </ViewManagerDesktopWrapper>

                                    <div
                                        style={{
                                            paddingTop: '40px',
                                        }}
                                    />

                                    {isDataLoading ||
                                    !(
                                        garagesData &&
                                        garagesData[garage_id] &&
                                        garagesData[garage_id].cars
                                    ) ? (
                                        loadingPlaceholders(
                                            6,
                                            theme === 'light'
                                                ? task_skeleton_desktop
                                                : task_skeleton_desktop_dark
                                        )
                                    ) : (
                                        <ContentWrapper>
                                            <TaskListManagerDesktop
                                                tasks_ids_garage_level_only={
                                                    garagesData[garage_id]
                                                        .tasks_garage_level
                                                }
                                                all_tasks={all_tasks}
                                                all_tasks_uids={all_tasks_uids}
                                                carSelected={carSelected}
                                                sortBy={sortBy}
                                                filterBy={filterBy}
                                                dropdownCars={dropdownCars}
                                                dropdownCarsWithOptions={
                                                    dropdownCarsWithOptions
                                                }
                                                updateTaskRequest={(p) => {
                                                    dispatch(
                                                        tasksActions.updateTaskRequest(
                                                            p
                                                        )
                                                    )
                                                }}
                                                carsData={carsData}
                                                toggleCreateTaskForm={() => {
                                                    dispatch(
                                                        editFormsActions.toggleCreateTaskForm()
                                                    )
                                                }}
                                            />
                                        </ContentWrapper>
                                    )}

                                    {isCreateCarTaskFormOpen && (
                                        <ModalDisplay
                                            toggle={() => {
                                                dispatch(
                                                    editFormsActions.toggleCreateTaskForm()
                                                )
                                            }}
                                            isOpen={true}
                                            customZindex={11}
                                        >
                                            <CreateTaskFormDesktop
                                                toggle={() => {
                                                    dispatch(
                                                        editFormsActions.toggleCreateTaskForm()
                                                    )
                                                }}
                                                submitFunc={(
                                                    p: ICreateTaskPayload
                                                ) => {
                                                    dispatch(
                                                        tasksActions.createTaskRequest(
                                                            p
                                                        )
                                                    )
                                                }}
                                                dropdownData={dropdownCars}
                                                carsData={carsData.cars}
                                                garage_id={garage_id}
                                                currentCarID={carid}
                                                garageCarsIds={
                                                    garagesData[garage_id].cars
                                                }
                                            />
                                        </ModalDisplay>
                                    )}
                                </DesktopDisplayOnly>

                                <IpadAndMobileDisplay>
                                    <PaddingViewsMenuMobileIpad
                                        style={{
                                            paddingBottom: 24,
                                        }}
                                    >
                                        <TaskViewsManagerMobile
                                            dropdownCars={dropdownCars}
                                            selectCarViewId={selectCarViewId}
                                            carSelected={carSelected}
                                            sortBy={sortBy}
                                            filterBy={filterBy}
                                            dropdownCarsWithOptions={
                                                dropdownCarsWithOptions
                                            }
                                            selectedCarData={
                                                carSelected !== null
                                                    ? carsData.cars[carSelected]
                                                    : undefined
                                            }
                                        />
                                    </PaddingViewsMenuMobileIpad>

                                    {isDataLoading ||
                                    !(
                                        garagesData &&
                                        garagesData[garage_id] &&
                                        garagesData[garage_id].cars
                                    ) ? (
                                        <div
                                            style={{
                                                position: 'relative',
                                                marginTop: '50px',
                                                height: '20vh',
                                            }}
                                        >
                                            {loadingPlaceholders(
                                                3,
                                                theme === 'light'
                                                    ? task_skeleton_mobile
                                                    : task_skeleton_mobile_dark
                                            )}
                                        </div>
                                    ) : (
                                        <TaskListManagerMobile
                                            tasks_ids_garage_level_only={
                                                garagesData[garage_id]
                                                    .tasks_garage_level
                                            }
                                            all_tasks={all_tasks}
                                            all_tasks_uids={all_tasks_uids}
                                            carSelected={carSelected}
                                            sortBy={sortBy}
                                            filterBy={filterBy}
                                            dropdownCars={dropdownCars}
                                            dropdownCarsWithOptions={
                                                dropdownCarsWithOptions
                                            }
                                            updateTaskRequest={(p) => {
                                                dispatch(
                                                    tasksActions.updateTaskRequest(
                                                        p
                                                    )
                                                )
                                            }}
                                            carsData={carsData}
                                            toggleCreateTaskForm={() => {
                                                dispatch(
                                                    editFormsActions.toggleCreateTaskForm()
                                                )
                                            }}
                                        />
                                    )}
                                </IpadAndMobileDisplay>
                            </Faded>
                        )}
                </FullPagePageWrapper>
            </Wrapper>
            <IpadAndMobileDisplay>
                {isCreateCarTaskFormOpen && garage_id && userLoggedIn && (
                    <ModalDisplay
                        toggle={() => {
                            dispatch(editFormsActions.toggleCreateTaskForm())
                        }}
                        isOpen={isCreateCarTaskFormOpen}
                    >
                        <CreateTaskFormMobile
                            toggle={() => {
                                dispatch(
                                    editFormsActions.toggleCreateTaskForm()
                                )
                            }}
                            submitFunc={(p: ICreateTaskPayload) => {
                                dispatch(tasksActions.createTaskRequest(p))
                            }}
                            dropdownData={dropdownCars}
                            carsData={carsData.cars}
                            garage_id={garage_id}
                            currentCarID={carid}
                            garageCarsIds={garagesData[garage_id].cars}
                        />
                    </ModalDisplay>
                )}

                {isFilterByOpened && (
                    <TasksSubMenuActions
                        isOpen={isFilterByOpened}
                        items={[
                            {
                                id: OTaskFilterNSort.filter_by.outstanding.uid,
                                text: OTaskFilterNSort.filter_by.outstanding
                                    .name,
                                action: () => {
                                    filterView(
                                        OTaskFilterNSort.filter_by.outstanding
                                            .name
                                    )
                                    dispatch(
                                        editFormsActions.toggleFilterByChoice()
                                    )
                                },
                            },
                            {
                                id: OTaskFilterNSort.filter_by.completed.uid,
                                text: OTaskFilterNSort.filter_by.completed.name,
                                action: () => {
                                    filterView(
                                        OTaskFilterNSort.filter_by.completed
                                            .name
                                    )
                                    dispatch(
                                        editFormsActions.toggleFilterByChoice()
                                    )
                                },
                            },
                            {
                                id: OTaskFilterNSort.filter_by.all.uid,
                                text: OTaskFilterNSort.filter_by.all.name,
                                action: () => {
                                    filterView(
                                        OTaskFilterNSort.filter_by.all.name
                                    )
                                    dispatch(
                                        editFormsActions.toggleFilterByChoice()
                                    )
                                },
                            },
                        ]}
                        toggle={() => {
                            dispatch(editFormsActions.toggleFilterByChoice())
                        }}
                        active_item={filterBy}
                    />
                )}

                {isSortByOpened && (
                    <TasksSubMenuActions
                        isOpen={isSortByOpened}
                        items={[
                            {
                                id: OTaskFilterNSort.sort_by.created_at.uid,
                                text: OTaskFilterNSort.sort_by.created_at.text,
                                action: () => {
                                    sortView(
                                        OTaskFilterNSort.sort_by.created_at.name
                                    )
                                    dispatch(
                                        editFormsActions.toggleSortByChoice()
                                    )
                                },
                                name: OTaskFilterNSort.sort_by.created_at.name,
                            },
                            {
                                id: OTaskFilterNSort.sort_by.due.uid,
                                text: OTaskFilterNSort.sort_by.due.text,
                                action: () => {
                                    sortView(OTaskFilterNSort.sort_by.due.name)
                                    dispatch(
                                        editFormsActions.toggleSortByChoice()
                                    )
                                },
                                name: OTaskFilterNSort.sort_by.due.name,
                            },
                        ]}
                        toggle={() => {
                            dispatch(editFormsActions.toggleSortByChoice())
                        }}
                        active_item={sortBy}
                    />
                )}
            </IpadAndMobileDisplay>
        </React.Fragment>
    )
}

export default CarTasks
