import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { randomCarSvgPlaceholderFunc } from '../../../../helpers/random/randomPlaceholder'
import SkeletonAbsolute from '../../../atoms/skeleton/skeletonAbsolute'
import public_active_circle from '../../../../public/assets/icons/public_active_circle.svg'
import public_active_circle_dark from '../../../../public/assets/icons/public_active_circle_dark.svg'
import public_inactive_circle from '../../../../public/assets/icons/public_inactive_circle.svg'
import public_inactive_circle_dark from '../../../../public/assets/icons/public_inactive_circle_dark.svg'
import { device } from '../../../templates/displays/devices'
import padlock from '../../../../public/assets/icons/padlock_color_fill_grey.svg'
import QrCodeBtn from '../../../atoms/Button/qrCodeBtn'
import LoaderElasticThreeDots from '../../../atoms/loader/loaderElasticThreeDots'
import { ITheme } from '../../../../providers/theme/colours'
import { ICar } from '../../../../redux/entities/cars/types'
import { IGarage } from '../../../../redux/entities/garages/types'
import useThemes from '../../../../providers/theme/hooks'

interface IStyle {
    index: number
}

const ShadowBox = styled(Link)<IStyle>`
    position: relative;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    /* max-width: 380px; */
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-family: Lato;
    height: 135px;
    background-color: var(--shadow-cards-bg, #fff);
    transition: all 100ms;
    margin-bottom: 20px;
    box-shadow: 2px 1px 10px 1px var(--box-shadow, rgba(0, 0, 0, 0.08));
    border-radius: 6px;

    @media ${device.ipad} {
        height: 145px;
    }

    @media (max-width: 350px) {
        height: 135px;
    }
`

const CarTitle = styled.div`
    font-size: 13px;
    color: var(--text-strong, #1a1a1a);
    line-height: 20px;
    hyphens: auto;
    padding-bottom: 5px;
    max-width: 45vw;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    text-overflow: ellipsis;

    @media (max-width: 350px) {
        max-width: 150px;
        font-family: Lato;
        font-size: 12px;
    }
`

const Col = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 165px;
    padding-top: 10px;
    padding-bottom: 5px;

    @media ${device.ipad} {
        padding-left: 200px;
    }

    @media (min-width: 500px) and (max-width: 550px) {
        padding-left: 180px;
    }

    @media (max-width: 400px) {
        padding-left: 155px;
    }
    @media (max-width: 360px) {
        padding-left: 130px;
        padding-top: 15px;
    }
`

const ShareDetailsCol = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`

const Row = styled.div`
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
`

const InnerRow = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
`

const InnerRowPrivate = styled.div`
    @media ${device.ipad} {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding-top: 20px;
    }
    @media ${device.mobile} {
        width: 100%;
        box-sizing: border-box;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: -10px;
    }
`

type IImageStyle = {
    isthingloading: boolean
}

const CarProfileImage = styled.img<IImageStyle>`
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    object-fit: cover;
`

const CarImgWrapper = styled.div`
    position: absolute;

    width: 150px;
    height: 100%;
    object-fit: cover;

    @media ${device.ipad} {
        width: 180px;
    }

    @media (min-width: 500px) and (max-width: 550px) {
        width: 160px;
    }

    @media (max-width: 400px) {
        width: 135px;
    }
    @media (max-width: 360px) {
        width: 115px;
    }
`

const RowText = styled.div`
    font-family: Lato;
    font-size: 12px;
    color: var(--text-strong, #1a1a1a);
    @media (max-width: 350px) {
        font-size: 11px;
    }
`

const Circle = styled.div<{ $isActive?: boolean }>`
    border: ${(props) =>
        props.$isActive
            ? '1px solid var(--primary, #5ec3ca)'
            : ' 1px solid var(--border-muted, #e5e5e5)'};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) =>
        props.$isActive
            ? 'var(--primary, #5ec3ca)'
            : 'var(--border-muted, #e5e5e5)'};
    height: 18px;
    width: 18px;
    font-size: 12px;
    line-height: 10px;
`

const ImgSize = styled.img`
    height: 18px;
    width: 18px;
`

const AdjustedPaddingTop = styled.div`
    padding-top: 5px;
`

const AdjustedPaddingRight = styled.div`
    padding-right: 12px;
    @media (max-width: 320px) {
        padding-right: 8px;
    }
`

const PrivateText = styled.div`
    font-family: Lato-light;
    letter-spacing: 0.8px;
    font-size: 0.7rem;
    color: var(--text-darker, #616161);
    transform: translateY(2px);
`

const HozLine = styled.div`
    @media ${device.ipad} {
        border: 1px solid var(--border_muted_subtle);
        height: 1px;
        width: 30px;
        margin-bottom: 15px;
    }
    @media ${device.mobile} {
        display: none;
    }
`

interface Props {
    car: ICar
    index: number
    imagesData: any
    carIndex: number
    garage: IGarage
    qrCodeRequested: (qr_code_id: string) => void
    isCarDataLoading: boolean
}

const CarSharedWithOthersCardMobile: React.FC<Props> = ({
    car,
    index,
    imagesData,
    carIndex,
    garage,
    qrCodeRequested,
    isCarDataLoading,
}) => {
    let { theme } = useThemes()

    const get_car_cover_url = (carObj: ICar) => {
        if (carObj && carObj.gallery) {
            let imageid =
                carObj.gallery && carObj.gallery.cover && carObj.gallery.cover
                    ? carObj.gallery.cover
                    : undefined

            if (imageid && imagesData) {
                if (imagesData[imageid] && imagesData[imageid].medium_srcset) {
                    return {
                        srcset: imagesData[imageid].medium_srcset,
                        url: imagesData[imageid].url,
                    }
                } else if (imagesData[imageid] && imagesData[imageid].url) {
                    return {
                        url: imagesData[imageid].url,
                    }
                }
            }
        }

        return {
            url: undefined,
        }
    }

    const navigate = useNavigate()
    const location = useLocation()
    const [isLoading, setIsLoading] = useState(true)

    const defaultPlaceholder = randomCarSvgPlaceholderFunc(undefined, theme)

    const car_cover_img = get_car_cover_url(car)

    const imageSrc = car_cover_img.url || defaultPlaceholder

    let imageid =
        car.gallery && car.gallery.cover && car.gallery.cover
            ? car.gallery.cover
            : undefined

    let has_car_public_share: boolean =
        car && car.public_share_owned && car.public_share_owned.isActive
            ? car.public_share_owned.isActive
            : false

    let has_garage_public_share: boolean =
        garage &&
        garage.public_share_owned &&
        garage.public_share_owned.isActive
            ? garage.public_share_owned.isActive
            : false

    let n_of_public_shares = (): number => {
        let n = 0
        if (has_garage_public_share) {
            n = n + 1
        }

        if (has_car_public_share) {
            n = n + 1
        }

        return n
    }

    let n_of_car_private_shares: number =
        car &&
        car.private_shares_owned &&
        car.private_shares_owned.entity_owned_direct_shares_ids
            ? car.private_shares_owned.entity_owned_direct_shares_ids.length
            : 0

    let n_of_garage_private_shares =
        garage &&
        garage.private_shares_owned &&
        garage.private_shares_owned?.entity_owned_direct_shares_ids
            ? garage.private_shares_owned?.entity_owned_direct_shares_ids.length
            : 0

    let isCarPrivate =
        n_of_car_private_shares === 0 &&
        n_of_garage_private_shares === 0 &&
        !has_garage_public_share &&
        !has_car_public_share

    let isQrCodeBtnVisible =
        has_car_public_share || has_garage_public_share ? true : false

    const share_link_icon = (theme: ITheme) => {
        if (n_of_public_shares() > 0) {
            if (theme === 'dark') {
                return public_active_circle_dark
            } else {
                return public_active_circle
            }
        } else {
            if (theme === 'dark') {
                return public_inactive_circle_dark
            } else {
                return public_inactive_circle
            }
        }
    }

    return (
        <>
            {car ? (
                <ShadowBox
                    index={index}
                    to={{
                        pathname: `/sharing/with-others/car`,
                        search: `?carid=${car.id}`,
                    }}
                    onClick={(e) => {
                        e.preventDefault()
                        navigate(`/sharing/with-others/car?carid=${car.id}`, {
                            state: {
                                prevPath: location.pathname,
                            },
                        })
                    }}
                >
                    <Row>
                        <CarImgWrapper>
                            <SkeletonAbsolute isthingloading={isLoading} />
                            <CarProfileImage
                                src={imageSrc}
                                onLoad={() => setIsLoading(false)}
                                isthingloading={isLoading}
                                srcSet={
                                    imageid &&
                                    imagesData[imageid] &&
                                    imagesData[imageid].small_srcset &&
                                    imagesData[imageid].small_srcset
                                }
                                aria-label="car profile image"
                            />
                        </CarImgWrapper>

                        <Col>
                            <CarTitle>
                                {car.title
                                    ? car.title
                                    : `Untitled #${carIndex + 1}`}
                            </CarTitle>

                            <AdjustedPaddingTop
                                style={{
                                    paddingTop:
                                        n_of_public_shares() > 0
                                            ? '10px'
                                            : '5px',
                                }}
                            />
                            <HozLine />

                            {isCarDataLoading && <LoaderElasticThreeDots />}
                            {!isCarDataLoading &&
                                (n_of_public_shares() > 0 ||
                                    n_of_car_private_shares > 0 ||
                                    n_of_garage_private_shares > 0) && (
                                    <>
                                        <ShareDetailsCol>
                                            <InnerRow>
                                                <Circle
                                                    $isActive={
                                                        n_of_public_shares() > 0
                                                    }
                                                >
                                                    <ImgSize
                                                        src={share_link_icon(
                                                            theme
                                                        )}
                                                        alt="public_link_status"
                                                    />
                                                </Circle>
                                                <AdjustedPaddingRight />
                                                <RowText>
                                                    {n_of_public_shares() > 0
                                                        ? 'Active'
                                                        : 'No'}{' '}
                                                    Shareable Link
                                                    {n_of_public_shares() > 1 &&
                                                        's'}
                                                </RowText>
                                            </InnerRow>
                                            <AdjustedPaddingTop />
                                            <InnerRow>
                                                <Circle
                                                    $isActive={
                                                        n_of_car_private_shares >
                                                        0
                                                    }
                                                >
                                                    {n_of_car_private_shares}
                                                </Circle>
                                                <AdjustedPaddingRight />
                                                <RowText>Direct Shares</RowText>
                                            </InnerRow>
                                            <AdjustedPaddingTop />
                                            <InnerRow>
                                                <Circle
                                                    $isActive={
                                                        n_of_garage_private_shares >
                                                        0
                                                    }
                                                >
                                                    {n_of_garage_private_shares}
                                                </Circle>
                                                <AdjustedPaddingRight />
                                                <RowText>Via Garage</RowText>
                                            </InnerRow>
                                        </ShareDetailsCol>
                                        {isQrCodeBtnVisible && (
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    bottom: '12px',
                                                    right: '12px',
                                                    zIndex: 2,
                                                }}
                                                onClick={(e: any) =>
                                                    e.preventDefault()
                                                }
                                            >
                                                <QrCodeBtn
                                                    txt="qr code"
                                                    dropDownOptions={
                                                        has_car_public_share &&
                                                        has_garage_public_share
                                                            ? [
                                                                  {
                                                                      id: 'car',
                                                                      text: 'car',
                                                                      action: () =>
                                                                          qrCodeRequested(
                                                                              'car_only'
                                                                          ),
                                                                  },
                                                                  {
                                                                      id: 'garage',
                                                                      text: 'garage',
                                                                      action: () =>
                                                                          qrCodeRequested(
                                                                              'car_via_garage'
                                                                          ),
                                                                  },
                                                              ]
                                                            : undefined
                                                    }
                                                    onClick={() =>
                                                        qrCodeRequested(
                                                            has_car_public_share
                                                                ? 'car_only'
                                                                : 'car_via_garage'
                                                        )
                                                    }
                                                />
                                            </div>
                                        )}
                                    </>
                                )}
                            {!isCarDataLoading && isCarPrivate && (
                                <ShareDetailsCol style={{ height: '100%' }}>
                                    <InnerRowPrivate>
                                        <ImgSize src={padlock} alt="private" />
                                        <AdjustedPaddingRight />
                                        <PrivateText>PRIVATE</PrivateText>
                                    </InnerRowPrivate>
                                </ShareDetailsCol>
                            )}
                        </Col>
                    </Row>
                </ShadowBox>
            ) : (
                <div />
            )}
        </>
    )
}

export default CarSharedWithOthersCardMobile
