import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { menuActions } from '../../../redux/menus/reducer'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import InsuranceTopBarWithCenteredLogo from '../../templates/bars/insurance/topBars/insuranceTopBarWithCenteredLogo'
import styled from 'styled-components'
import { insuranceActions } from '../../../redux/insuranceQuoteApplication/reducer'
import StandardCtaBtn from '../../atoms/Button/standardsCtaBtn'
import { Title, Text, AdjustedPadding } from './insuranceWelcomeWithCars'
import { device } from '../../templates/displays/devices'
import InsuranceEligibilityCard from '../../molecules/cards/insurance/insuranceEligibilityCard'
import Faded from '../../templates/animated/faded'
import { Link } from 'react-router-dom'
import React from 'react'
import useWindowSize from '../../templates/displays/windowSizeHook'
import posthog from 'posthog-js'
import { usersActions } from '../../../redux/user/reducer'

const PageWrapper = styled.div`
    position: relative;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--off-bg-color, #fafafa);
    min-height: 100vh;
`

const CardsGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 16px;
    @media ${device.mobile} {
        grid-template-columns: 1fr;
    }
`

export const ElementsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    max-width: 960px;
    padding-left: 80px;
    padding-right: 80px;
    margin-top: 80px;
    padding-top: 5vh;
    z-index: 2;
    @media ${device.mobile_and_ipad} {
        padding-left: 24px;
        padding-right: 24px;
    }
    @media ${device.mobile} {
        padding-top: 24px;
    }
    @media (max-width: 320px) {
        gap: 16px;
    }
`

export const NotEligibleBtn = styled(Link)`
    color: var(--primary, #5ec3ca) !important;
    text-align: center;
    font-family: Lato-Semibold;
    font-size: 16px;
    line-height: 24px; /* 150% */
    letter-spacing: 0.001px;
`

const InsuranceEligibilityConfirmation = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    useEffect(() => {
        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        }

        dispatch(menuActions.manageTopSubmenu({ isRemoved: true }))
        return () => {
            dispatch(menuActions.manageTopSubmenu({ isRemoved: false }))
        }
    }, [userLoggedIn])

    const onGoodToGo = () => {
        dispatch(insuranceActions.create_insurance_application_request({}))
    }

    let size = useWindowSize()
    let isMobile = size && size.width && size.width < 881 ? true : false
    let showMobilearray = size && size.width && size.width < 539 ? true : false
    const cards_eligibility_insurance = showMobilearray
        ? cards_eligibility_insurance_mobile
        : cards_eligibility_insurance_desktop

    let is_insurance_loading = useAppSelector(
        (state) => state.insuranceQuoteApplication.isLoading
    )

    return (
        <Faded>
            <PageWrapper>
                <InsuranceTopBarWithCenteredLogo
                    goBack={() => navigate('/garage')}
                />

                <ElementsWrapper>
                    <Title>We don't want to waste your time!</Title>
                    <Text style={{ maxWidth: 600 }}>
                        Please check the following requirements for getting
                        insurance with Custodian. We can only currently arrange
                        insurance for people who meet the following criteria:
                    </Text>
                    <AdjustedPadding />
                    <CardsGrid>
                        {cards_eligibility_insurance.map((card, i) => {
                            return (
                                <React.Fragment
                                    key={`${card.id}_${i}_${
                                        isMobile ? 'mobile' : 'desktop'
                                    }`}
                                >
                                    <InsuranceEligibilityCard
                                        id={card.id}
                                        title={card.title}
                                        text={card.text}
                                    />
                                </React.Fragment>
                            )
                        })}
                    </CardsGrid>
                    <AdjustedPadding />
                    <StandardCtaBtn
                        onClick={onGoodToGo}
                        isLoading={is_insurance_loading}
                    >
                        Good to go!
                    </StandardCtaBtn>

                    <NotEligibleBtn
                        to="/garage"
                        onClick={() => {
                            posthog.capture(
                                'Click on insurance non-eligibility'
                            )
                        }}
                    >
                        I'm not eligible
                    </NotEligibleBtn>
                </ElementsWrapper>
                <div style={{ paddingTop: 100 }} />
            </PageWrapper>
        </Faded>
    )
}

export default InsuranceEligibilityConfirmation

export const cards_eligibility_insurance_desktop = [
    {
        id: 'non_everyday_car_specialist',
        title: 'Non-Everyday Specialist Cars',
        text: 'You own a classic, modified, or high-performance car and also have a separate everyday car for your regular use.',
    },
    {
        id: 'parking',
        title: 'Parking',
        text: 'Your cars are parked on a driveway, in a locked garage or building, or kept at a secure storage facility when not in use.',
    },
    {
        id: 'payment_type',
        title: 'Payment Type',
        text: 'You are able to pay for your insurance in full',
    },
    {
        id: 'ownership_experience',
        title: 'Car Ownership & Experience',
        text: 'You currently own the car you are looking to insure and if looking to insure a high-performance car, you have owned it for more than 12 months, or have previous experience driving similar powered cars. ',
    },
    {
        id: 'everyday_car_specialist',
        title: 'Everyday Specialist Cars',
        text: 'We can insure everyday cars but only if they are either modified, worth at least £100,000 or insured alongside non-everyday specialist car(s).',
    },

    {
        id: 'driver_age',
        title: 'Driver age',
        text: 'You are between 21 and 75 years old (inclusive)',
    },

    {
        id: 'vehicle_use',
        title: 'Vehicle Use',
        text: 'You do not use your specialist car(s) for business or hire & reward (Chauffeur Hire/ Movie Hire)',
    },
    {
        id: 'licence_length',
        title: 'Driving Licence',
        text: 'You have held a full UK driving licence, or international equivalent, for at least 3 years',
    },

    // {
    //     id: 'all_claims',
    //     title: 'All Claims',
    //     text: 'You have had no more than 5 motor claims in the last 5 years',
    // },
    // {
    //     id: 'all_motoring_convictions',
    //     title: 'All Motoring Convictions',
    //     text: 'You have not had more than 3 motoring convictions in the last 5 years',
    // },
    // {
    //     id: 'criminal_convictions',
    //     title: 'Criminal Convictions',
    //     text: 'You do not have any unspent criminal convictions',
    // },
    {
        id: 'processing_time',
        title: 'You have time',
        text: 'You have more than 3 working days before you need insurance',
    },
]

export const cards_eligibility_insurance_mobile = [
    {
        id: 'non_everyday_car_specialist',
        title: 'Non-Everyday Specialist Cars',
        text: 'You own a classic, modified, or high-performance car and also have a separate everyday car for your regular use.',
    },
    {
        id: 'everyday_car_specialist',
        title: 'Everyday Specialist Cars',
        text: 'We can insure everyday cars but only if they are either modified, worth at least £100,000 or insured alongside non-everyday specialist car(s).',
    },

    {
        id: 'parking',
        title: 'Parking',
        text: 'Your cars are parked on a driveway, in a locked garage or building, or kept at a secure storage facility when not in use.',
    },
    {
        id: 'payment_type',
        title: 'Payment Type',
        text: 'You are able to pay for your insurance in full',
    },
    {
        id: 'ownership_experience',
        title: 'Car Ownership & Experience',
        text: 'You currently own the car you are looking to insure and if looking to insure a high-performance car, you have owned it for more than 12 months, or have previous experience driving similar powered cars. ',
    },
    {
        id: 'driver_age',
        title: 'Driver age',
        text: 'You are between 21 and 75 years old (inclusive)',
    },
    {
        id: 'vehicle_use',
        title: 'Vehicle Use',
        text: 'You do not use your specialist car(s) for business or hire & reward (Chauffeur Hire/ Movie Hire)',
    },
    {
        id: 'licence_length',
        title: 'Driving Licence',
        text: 'You have held a full UK driving licence, or international equivalent, for at least 3 years',
    },

    // {
    //     id: 'all_claims',
    //     title: 'All Claims',
    //     text: 'You have had no more than 5 motor claims in the last 5 years',
    // },
    // {
    //     id: 'all_motoring_convictions',
    //     title: 'All Motoring Convictions',
    //     text: 'You have not had more than 3 motoring convictions in the last 5 years',
    // },
    // {
    //     id: 'criminal_convictions',
    //     title: 'Criminal Convictions',
    //     text: 'You do not have any unspent criminal convictions',
    // },
    {
        id: 'processing_time',
        title: 'You have time',
        text: 'You have more than 3 working days before you need insurance',
    },
]
