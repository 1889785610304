import * as React from 'react'
import styled from 'styled-components'
import CarOwnerBox from '../../../atoms/user/carOwnerBox'
import { device } from '../../../templates/displays/devices'
import NoDataRectangle from '../../../atoms/noData/noDataRectangle'
import SkeletonAbsolute from '../../../atoms/skeleton/skeletonAbsolute'
import ButtonAtom from '../../../atoms/Button/ButtonAtom'
import CardMotionDivWrapper from '../../../templates/animated/cardMotionDivWrapper'
import {
    IPrivateShareReceivedItem,
    IPrivateShareReceivedItemApprovalPayload,
} from '../../../../redux/entities/cars/types'
import { Link, useLocation, useNavigate } from 'react-router-dom'

interface IStyle {
    hasMarginRight?: boolean
    $hasApprovalBtns?: boolean
    $allowClick?: boolean
    $justTwoBtns?: boolean
}

const ShadowBox = styled(Link)<IStyle>`
    position: relative;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    align-items: flex-start;
    justify-content: ${(props) =>
        props.$hasApprovalBtns ? 'flex-start' : 'center'};
    font-family: Lato;
    height: ${(props) =>
        props.$hasApprovalBtns
            ? props.$justTwoBtns
                ? '280px'
                : 'auto'
            : '130px'};
    ${(props) => props.$hasApprovalBtns && 'padding: 25px;'};
    border: transparent;
    box-shadow: 5px 5px 20px 3px rgba(0, 0, 0, 0.03);
    border-radius: 6px;
    background-color: var(--shadow-cards-bg, #fff);
    transition: all 100ms;
    margin-bottom: 20px;

    @media ${device.mobile} {
    }

    @media screen and (max-width: 368px) {
        max-width: 380px;
    }

    @media ${device.ipad} {
        max-width: 460px;
    }
`

const CarTitle = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: 'Lato';
    font-size: 13px;
    max-width: 250px;
    height: 35px;
    display: flex;
    align-items: center;

    @media ${device.ipad} {
        font-size: 14px;
    }
    @media (max-width: 500px) {
        max-width: 170px;
    }
    @media (max-width: 400px) {
        max-width: 150px;
    }
    @media (max-width: 320px) {
        max-width: 120px;
    }
`

const Col = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 110px;
    padding-left: 160px;
    @media (max-width: 400px) {
        padding-left: 135px;
    }
    @media (max-width: 300px) {
        padding-left: 125px;
    }
`

const Row = styled.div<IStyle>`
    width: 100%;
    box-sizing: border-box;
    padding-right: ${(props) => (props.$hasApprovalBtns ? '0px' : '20px')};
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 280px) {
        padding-right: ${(props) => (props.$hasApprovalBtns ? '0px' : '10px')};
    }
`

const CarProfileImage = styled.img<IStyle>`
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    border-bottom-left-radius: 7px;
    border-top-left-radius: 7px;
    object-fit: cover;
    ${(props) =>
        props.$hasApprovalBtns &&
        `border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;`};
`

const CarImgWrapper = styled.div<IStyle>`
    position: absolute;
    border-bottom-left-radius: 7px;
    border-top-left-radius: 7px;
    width: 140px;
    height: ${(props) => (props.$hasApprovalBtns ? '120px' : '100%')};
    object-fit: cover;
    ${(props) =>
        props.$hasApprovalBtns &&
        `border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;`};
    @media (max-width: 400px) {
        width: 120px;
    }
    @media (max-width: 300px) {
        width: 110px;
    }
`

const HozLine = styled.div`
    background-color: var(--border_muted_subtle);
    height: 1px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 8px;
    margin-bottom: 12px;
`

const ColFullWidth = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`

const SmallPadding = styled.div`
    padding-top: 16px;
`

type Props = {
    share_received: IPrivateShareReceivedItem
    hasMarginRight?: boolean
    shareApprovalAction?: (
        payload: IPrivateShareReceivedItemApprovalPayload
    ) => void
}

const CarsSharedWithYouCardMobile: React.FC<Props> = ({
    share_received,
    shareApprovalAction,
}) => {
    const [isLoading, setIsLoading] = React.useState(true)
    const navigate = useNavigate()
    const location = useLocation()

    const imageSrc = share_received?.car_profile_pic?.url

    const hasApprovalBts =
        share_received.approvalStatus === 'pending' ||
        share_received.approvalStatus === 'ignored'

    const params = new URLSearchParams(location.search)
    const view_id = params.get('view_id')

    return (
        <CardMotionDivWrapper>
            {share_received && (
                <ShadowBox
                    to={
                        share_received.approvalStatus === 'accepted'
                            ? `/shared/car/${share_received.entity_id}`
                            : view_id
                            ? `/sharing/with-you?view_id=${view_id}`
                            : `/sharing/with-you`
                    }
                    rel="noreferrer"
                    $hasApprovalBtns={hasApprovalBts}
                    $allowClick={share_received.approvalStatus === 'accepted'}
                    $justTwoBtns={share_received.approvalStatus === 'ignored'}
                >
                    <Row $hasApprovalBtns={hasApprovalBts}>
                        <CarImgWrapper $hasApprovalBtns={hasApprovalBts}>
                            {imageSrc ? (
                                <>
                                    <CarProfileImage
                                        src={imageSrc}
                                        aria-label="shared car profile image"
                                        $hasApprovalBtns={hasApprovalBts}
                                        onLoad={() => setIsLoading(false)}
                                    />
                                    <SkeletonAbsolute
                                        isthingloading={isLoading}
                                    />
                                </>
                            ) : (
                                <NoDataRectangle
                                    text={share_received.car_title}
                                    height="100%"
                                    bgColor="var(--off-bg-color, #fafafa)"
                                />
                            )}
                        </CarImgWrapper>

                        <Col>
                            <CarTitle>
                                {share_received.car_title
                                    ? share_received.car_title !== ''
                                        ? share_received.car_title
                                        : 'Untitled'
                                    : 'Untitled'}
                            </CarTitle>

                            <HozLine />
                            <CarOwnerBox
                                owner={share_received.owner}
                                isMobile
                                sharedOn={share_received.created_at}
                            />
                        </Col>
                    </Row>
                    {hasApprovalBts && (
                        <Row $hasApprovalBtns={hasApprovalBts}>
                            <ColFullWidth>
                                <SmallPadding style={{ paddingTop: '25px' }} />
                                <ButtonAtom
                                    theme="secondary"
                                    width="100%"
                                    height="40px"
                                    textTransform="capitalize"
                                    fontSize={14}
                                    onClick={() => {
                                        if (shareApprovalAction) {
                                            shareApprovalAction({
                                                shareId: share_received.id,
                                                recipientId:
                                                    share_received.owner.uid,
                                                approvalId: 'accepted',
                                            })
                                            setTimeout(
                                                () =>
                                                    navigate(
                                                        `/sharing/with-you?entity_id=cars&view_id=view_all`
                                                    ),
                                                50
                                            )
                                        }
                                    }}
                                >
                                    Accept
                                </ButtonAtom>
                                <SmallPadding />
                                {share_received.approvalStatus !==
                                    'ignored' && (
                                    <>
                                        <ButtonAtom
                                            theme="tertiary"
                                            customBgColor="transparent"
                                            width="100%"
                                            height="40px"
                                            textTransform="capitalize"
                                            fontSize={14}
                                            onClick={() =>
                                                shareApprovalAction
                                                    ? shareApprovalAction({
                                                          shareId:
                                                              share_received.id,
                                                          recipientId:
                                                              share_received
                                                                  .owner.uid,
                                                          approvalId: 'ignored',
                                                      })
                                                    : {}
                                            }
                                        >
                                            Ignore
                                        </ButtonAtom>
                                        <SmallPadding />
                                    </>
                                )}
                                <ButtonAtom
                                    theme="lowercase-white-red-background"
                                    width="100%"
                                    height="40px"
                                    fontSize={14}
                                    customBgColor="transparent"
                                    onClick={() =>
                                        shareApprovalAction
                                            ? shareApprovalAction({
                                                  shareId: share_received.id,
                                                  recipientId:
                                                      share_received.owner.uid,
                                                  approvalId: 'rejected',
                                              })
                                            : {}
                                    }
                                >
                                    Decline
                                </ButtonAtom>
                                <SmallPadding />
                            </ColFullWidth>
                        </Row>
                    )}
                </ShadowBox>
            )}
        </CardMotionDivWrapper>
    )
}

export default CarsSharedWithYouCardMobile
