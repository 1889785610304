import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate, useLocation } from 'react-router-dom'
import SwitchLikeBtnDesktop from '../../atoms/Button/switchLikeBtnDesktop'
import H1V3 from '../../atoms/typography/H1V3'
import H2V3 from '../../atoms/typography/H2V3'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import FullPageWrapper from '../../templates/displays/pageWrappers/FullPagePageWrapper'
import { device } from '../../templates/displays/devices'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import FilterButton from '../../atoms/Button/filterButton'
import FabsMenusMobile from '../../templates/menus/fabsMenuMobile'
import SharesViewMenuDesktop from '../../atoms/menu/shareViewMenu/sharesViewMenuDesktop'
import SharedWithYouCarsManagerDesktop from '../../organisms/sharedWithYouDisplayManagers/sharedWithYouCarsManagerDesktop'
import SharedWithYouGaragesManagerDesktop from '../../organisms/sharedWithYouDisplayManagers/sharedWithYouGaragesManagerDesktop'
import SharedWithYouCarsManagerMobile from '../../organisms/sharedWithYouDisplayManagers/sharedWithYouCarsManagerMobile'
import SharedWithYouGaragesManagerMobile from '../../organisms/sharedWithYouDisplayManagers/sharedWithYouGaragesManagerMobile'
import FilterMenuOnSingleCriteria from '../../atoms/menu/bottomSheetMenu/filterMenuOnSingleCriteria'
import Faded from '../../templates/animated/faded'
import AdjustableLoader from '../../atoms/loader/adjustableLoader'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import { journeyRedirectCardsCopy } from '../../../redux/localdata/journey/copyData'
import JourneyRedirectCardDesktop from '../../molecules/cards/journey/redirectCards/redirectCardDesktop'
import FadedSlower from '../../templates/animated/FadedSlower'
import Expander from '../../atoms/expander/expander'
import JourneyPatternCardMobile from '../../molecules/cards/journey/patternCards/patternCardMobile'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { usersActions } from '../../../redux/user/reducer'

const PageWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding-top: 10px;
    min-height: 100vh;
    padding-bottom: 200px;
    @media (min-width: 2200px) {
        padding-left: 50px;
        padding-right: 24px;
        /* max-width: 2200px; */
    }
`

const IpadAndMobileWrapper = styled.div`
    background-color: var(--bg-color, #fff);
    padding: 24px;
    padding-top: 110px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    min-height: 100vh;

    @media ${device.ipad} {
        padding-top: 150px;
    }
    @media (max-width: 400px) {
        padding-top: 110px;
    }
`

const DesktopOverallPageWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    /* max-width: 2200px; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-bottom: 50px;
`

const TopSectionWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding-left: 50px;
    padding-right: 24px;
    /* margin-left: -50px; */
    @media (min-width: 2200px) {
        padding-left: 0px;
        padding-right: 0px;
    }
`

const ButtonsSectionWrapperDesktop = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding-top: 60px;
    padding-bottom: 60px;
`

const ButtonsSectionWrapperMobile = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding-top: 40px;
    padding-bottom: 30px;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    @media screen and (max-width: 368px) {
        max-width: 380px;
    }

    @media ${device.ipad} {
        max-width: 460px;
    }
`

const Line = styled.div`
    height: 1px;
    background-color: var(--border-muted, #e5e5e5);
    width: 100%;
    box-sizing: border-box;
    transform: translateX(-28px);
`
const FiltersContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`

const PendingSharesNotification = styled.div`
    position: absolute;
    right: 25px;
    top: 0;
    margin-top: -7px;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-color: var(--primary, #5ec3ca);
    color: var(--bg-color, #fff);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 200ms;
    @media ${device.mobile_and_ipad} {
        right: 22px;
        top: 30px;
        height: 20px;
        width: 20px;
        font-size: 11px;
    }
`

const SharedWithYou: React.FC = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)

    const areReceivedSharesLoading = useAppSelector(
        (state) => state.user.receivedSharesLoading
    )

    const [state, setState] = useState({
        isViewBottomSheetOpen: false,
        isSortByBottomSheetOpen: false,
        activeTabID: 'cars',
        sort_id: '',
        showJourneyCard: true,
    })

    useEffect(() => {
        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        }
        dispatch(
            usersActions.getUserDirectSharesReceivedRequest('car_and_garage')
        )

        const params = new URLSearchParams(location.search)
        let entity_id = params.get('entity_id')

        if (entity_id === 'garage') {
            setState((prevState) => ({ ...prevState, activeTabID: 'garage' }))
            dispatch(
                customNavDataActions.setInitialLandingUrl(
                    `/sharing/with-you?entity_id=garage`
                )
            )
        } else {
            dispatch(
                customNavDataActions.setInitialLandingUrl(`/sharing/with-you`)
            )
        }
    }, [userLoggedIn?.id, location.search])

    const changeTab = (tabID: string) => {
        setState((prevState) => ({ ...prevState, activeTabID: tabID }))
        navigate(`/sharing/with-you?entity_id=${tabID}`)
    }
    const btnIdsList: string[] = ['cars', 'garage']
    const btnsData: any = {
        cars: {
            id: 'cars',
            txt: 'cars',
            action: () => changeTab('cars'),
            isDisabled: false,
        },
        garage: {
            id: 'garage',
            txt: 'garage',
            action: () => changeTab('garage'),
            isDisabled: false,
        },
    }

    const params = new URLSearchParams(location.search)
    const view_id = params.get('view_id')
    const entity_id = params.get('entity_id')

    const getViewFilterCriteria = (value: string) => value.replace(/_/g, ' ')

    const filteredViewOnStatusData = [
        { uid: 'view_all', name: 'View all' },
        { uid: 'accepted', name: 'Accepted' },
        { uid: 'pending', name: 'Pending' },
        { uid: 'ignored', name: 'Ignored' },
    ]

    const sortByData = [
        {
            uid: 'alphabetical',
            name: 'Alphabetical',
        },
        {
            uid: 'by_most_recent',
            name: 'By most recent',
        },
        {
            uid: 'by_oldest',
            name: 'By oldest',
        },
    ]

    const handleSortBy = (value: string) =>
        setState((prevState) => ({ ...prevState, sort_id: value }))

    const checkIfSortIsDisabled = () => {
        let shared_received_obj =
            userLoggedIn && userLoggedIn.received_direct_shares

        if (shared_received_obj) {
            if (!view_id || view_id === 'view_all') {
                if (shared_received_obj.cars && state.activeTabID === 'cars') {
                    if (
                        (shared_received_obj.cars
                            .accepted_shares_received_ids &&
                            shared_received_obj.cars
                                .accepted_shares_received_ids.length > 0) ||
                        (shared_received_obj.cars.pending_shares_received_ids &&
                            shared_received_obj.cars.pending_shares_received_ids
                                .length > 0)
                    ) {
                        return false
                    }
                }

                if (
                    shared_received_obj.garages &&
                    state.activeTabID === 'garage'
                ) {
                    if (
                        (shared_received_obj.garages
                            .accepted_shares_received_ids &&
                            shared_received_obj.garages
                                .accepted_shares_received_ids.length > 0) ||
                        (shared_received_obj.garages
                            .pending_shares_received_ids &&
                            shared_received_obj.garages
                                .pending_shares_received_ids.length > 0)
                    ) {
                        return false
                    }
                }
            }

            if (view_id && view_id === 'accepted') {
                if (shared_received_obj.cars && state.activeTabID === 'cars') {
                    if (
                        shared_received_obj.cars.accepted_shares_received_ids &&
                        shared_received_obj.cars.accepted_shares_received_ids
                            .length > 0
                    ) {
                        return false
                    }
                }
                if (
                    shared_received_obj.garages &&
                    state.activeTabID === 'garage'
                ) {
                    if (
                        shared_received_obj.garages
                            .accepted_shares_received_ids &&
                        shared_received_obj.garages.accepted_shares_received_ids
                            .length > 0
                    ) {
                        return false
                    }
                }
            }

            if (view_id && view_id === 'pending') {
                if (shared_received_obj.cars && state.activeTabID === 'cars') {
                    if (
                        shared_received_obj.cars.pending_shares_received_ids &&
                        shared_received_obj.cars.pending_shares_received_ids
                            .length > 0
                    ) {
                        return false
                    }
                }
                if (
                    shared_received_obj.garages &&
                    state.activeTabID === 'garage'
                ) {
                    if (
                        shared_received_obj.garages
                            .pending_shares_received_ids &&
                        shared_received_obj.garages.pending_shares_received_ids
                            .length > 0
                    ) {
                        return false
                    }
                }
            }
            if (view_id && view_id === 'ignored') {
                if (shared_received_obj.cars && state.activeTabID === 'cars') {
                    if (
                        shared_received_obj.cars.ignored_shares_received_ids &&
                        shared_received_obj.cars.ignored_shares_received_ids
                            .length > 0
                    ) {
                        return false
                    }
                }
                if (
                    shared_received_obj.garages &&
                    state.activeTabID === 'garage'
                ) {
                    if (
                        shared_received_obj.garages
                            .ignored_shares_received_ids &&
                        shared_received_obj.garages.ignored_shares_received_ids
                            .length > 0
                    ) {
                        return false
                    }
                }
            }
        }
        return true
    }

    const pendingGarageShares: number =
        userLoggedIn &&
        userLoggedIn.received_direct_shares &&
        userLoggedIn.received_direct_shares.garages &&
        userLoggedIn.received_direct_shares.garages
            .pending_shares_received_ids &&
        userLoggedIn.received_direct_shares.garages.pending_shares_received_ids
            .length > 0
            ? userLoggedIn.received_direct_shares.garages
                  .pending_shares_received_ids.length
            : 0

    return (
        <React.Fragment>
            <FabsMenusMobile hasSubNav={true} />
            <DesktopDisplayOnly>
                <FullPageWrapper>
                    <PageWrapper>
                        <DesktopOverallPageWrapper>
                            <TopSectionWrapper>
                                {state.showJourneyCard && (
                                    <div
                                        style={{
                                            width: '100%',
                                            boxSizing: 'border-box',
                                            paddingBottom: 20,
                                        }}
                                    >
                                        <FadedSlower>
                                            <JourneyRedirectCardDesktop
                                                sectionID="share_your_car"
                                                dataPH={
                                                    'start_car_profile_journey'
                                                }
                                                copyData={
                                                    journeyRedirectCardsCopy
                                                }
                                                onStartNow={() => {
                                                    navigate(
                                                        `/journey/share-your-car?step=welcome`
                                                    )
                                                }}
                                            />
                                        </FadedSlower>
                                    </div>
                                )}
                                <H1V3 leftalign>Shared with You</H1V3>

                                <H2V3 leftalign>
                                    This is where you can see and manage the
                                    car(s) which other members have shared with
                                    you.
                                </H2V3>

                                <ButtonsSectionWrapperDesktop>
                                    <div
                                        style={{
                                            transform: 'scale(0.8)',
                                            marginLeft: '-50px',
                                        }}
                                    >
                                        <SwitchLikeBtnDesktop
                                            btnIdsList={btnIdsList}
                                            btnsData={btnsData}
                                            activeID={state.activeTabID}
                                        />

                                        <PendingSharesNotification
                                            style={{
                                                transform:
                                                    pendingGarageShares > 0 &&
                                                    (entity_id === 'cars' ||
                                                        !entity_id)
                                                        ? 'scale(1)'
                                                        : 'scale(0)',
                                            }}
                                        >
                                            {pendingGarageShares}
                                        </PendingSharesNotification>
                                    </div>
                                    <Line />
                                    <FiltersContainer>
                                        <SharesViewMenuDesktop
                                            dropdownData={sortByData}
                                            isSort
                                            currentOption={
                                                state.sort_id === ''
                                                    ? 'sort by'
                                                    : getViewFilterCriteria(
                                                          state.sort_id
                                                      )
                                            }
                                            buttonMinWidth={'155px'}
                                            onSelected={(value: string) =>
                                                handleSortBy(value)
                                            }
                                            isDisabled={checkIfSortIsDisabled()}
                                        />
                                        <div style={{ paddingLeft: '10px' }} />
                                        <SharesViewMenuDesktop
                                            dropdownData={
                                                filteredViewOnStatusData
                                            }
                                            currentOption={
                                                view_id
                                                    ? getViewFilterCriteria(
                                                          view_id
                                                      )
                                                    : 'View all'
                                            }
                                            buttonMinWidth={'140px'}
                                            isDisabled={false}
                                            onSelected={(value: string) =>
                                                entity_id
                                                    ? navigate(
                                                          `/sharing/with-you?entity_id=${entity_id}&view_id=${value}`
                                                      )
                                                    : navigate(
                                                          `/sharing/with-you?view_id=${value}`
                                                      )
                                            }
                                        />
                                    </FiltersContainer>
                                </ButtonsSectionWrapperDesktop>
                            </TopSectionWrapper>
                            {state.activeTabID === 'cars' &&
                                (areReceivedSharesLoading ? (
                                    <div
                                        style={{
                                            position: 'relative',
                                            width: '100%',
                                            boxSizing: 'border-box',
                                            height: '30vh',
                                        }}
                                    >
                                        <AdjustableLoader
                                            isLoading={areReceivedSharesLoading}
                                        />
                                    </div>
                                ) : (
                                    <SharedWithYouCarsManagerDesktop
                                        sort_id={state.sort_id}
                                    />
                                ))}

                            {state.activeTabID === 'garage' &&
                                (areReceivedSharesLoading ? (
                                    <div
                                        style={{
                                            position: 'relative',
                                            width: '100%',
                                            boxSizing: 'border-box',
                                            height: '30vh',
                                        }}
                                    >
                                        <AdjustableLoader
                                            isLoading={areReceivedSharesLoading}
                                        />
                                    </div>
                                ) : (
                                    <SharedWithYouGaragesManagerDesktop
                                        sort_id={state.sort_id}
                                    />
                                ))}
                        </DesktopOverallPageWrapper>
                    </PageWrapper>
                </FullPageWrapper>
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <Faded>
                    <IpadAndMobileWrapper>
                        <H1V3>Shared with You</H1V3>

                        <H2V3>
                            This is where you can see and manage the car(s)
                            which other members have shared with you.
                        </H2V3>

                        <div
                            style={{
                                paddingTop: '30px',
                                position: 'relative',
                            }}
                        >
                            <SwitchLikeBtnDesktop
                                btnIdsList={btnIdsList}
                                btnsData={btnsData}
                                activeID={state.activeTabID}
                            />
                            <PendingSharesNotification
                                style={{
                                    transform:
                                        pendingGarageShares > 0 &&
                                        (entity_id === 'cars' || !entity_id)
                                            ? 'scale(1)'
                                            : 'scale(0)',
                                }}
                            >
                                {pendingGarageShares}
                            </PendingSharesNotification>
                        </div>
                        <ButtonsSectionWrapperMobile>
                            <FilterButton
                                text={
                                    state.sort_id === ''
                                        ? 'Sort By'
                                        : getViewFilterCriteria(state.sort_id)
                                }
                                onClick={() =>
                                    setState((prevState) => ({
                                        ...prevState,
                                        isSortByBottomSheetOpen: true,
                                    }))
                                }
                                isactive={false}
                                minWidth="48%"
                                hasTextLeftAligned
                                fontSize="12px"
                                isDisabled={checkIfSortIsDisabled()}
                                isSort
                                capitalize
                            />
                            <FilterButton
                                text={
                                    view_id
                                        ? getViewFilterCriteria(view_id)
                                        : 'View all'
                                }
                                onClick={() =>
                                    setState((prevState) => ({
                                        ...prevState,
                                        isViewBottomSheetOpen: true,
                                    }))
                                }
                                isactive={state.isViewBottomSheetOpen}
                                isDisabled={false}
                                minWidth="48%"
                                hasChevron
                                capitalize
                                fontSize="12px"
                            />
                        </ButtonsSectionWrapperMobile>
                        <Expander
                            height={state.showJourneyCard === true ? 'auto' : 0}
                        >
                            <ButtonsSectionWrapperMobile
                                style={{
                                    paddingTop: '0px',
                                    width: '100%',
                                    boxSizing: 'border-box',
                                    zIndex: 5,
                                }}
                            >
                                <JourneyPatternCardMobile
                                    dataPH="start_share_your_car"
                                    sectionID="share_your_car"
                                    copyData={journeyRedirectCardsCopy}
                                    onStartNow={() => {
                                        navigate(
                                            `/journey/share-your-car?step=welcome`
                                        )
                                    }}
                                    onDismiss={() => {
                                        alert('dismiss')
                                        setState((prevState) => ({
                                            ...prevState,
                                            showJourneyCard: false,
                                        }))
                                    }}
                                />
                            </ButtonsSectionWrapperMobile>
                        </Expander>

                        {state.activeTabID === 'cars' &&
                            (areReceivedSharesLoading ? (
                                <div
                                    style={{
                                        position: 'relative',
                                        width: '100%',
                                        boxSizing: 'border-box',
                                        height: '20vh',
                                    }}
                                >
                                    <AdjustableLoader
                                        isLoading={areReceivedSharesLoading}
                                    />
                                </div>
                            ) : (
                                <SharedWithYouCarsManagerMobile
                                    sort_id={state.sort_id}
                                />
                            ))}

                        {state.activeTabID === 'garage' &&
                            (areReceivedSharesLoading ? (
                                <div
                                    style={{
                                        position: 'relative',
                                        width: '100%',
                                        boxSizing: 'border-box',
                                        height: '20vh',
                                    }}
                                >
                                    <AdjustableLoader
                                        isLoading={areReceivedSharesLoading}
                                    />
                                </div>
                            ) : (
                                <SharedWithYouGaragesManagerMobile
                                    sort_id={state.sort_id}
                                />
                            ))}
                    </IpadAndMobileWrapper>
                </Faded>

                <FilterMenuOnSingleCriteria
                    currentOption={
                        view_id ? getViewFilterCriteria(view_id) : 'View all'
                    }
                    dropdownData={filteredViewOnStatusData}
                    isFilterBottomSheetMenuOpen={state.isViewBottomSheetOpen}
                    toggle={() =>
                        setState((prevState) => ({
                            ...prevState,
                            isViewBottomSheetOpen: false,
                        }))
                    }
                    onSelected={(value: string) =>
                        entity_id
                            ? navigate(
                                  `/sharing/with-you?entity_id=${entity_id}&view_id=${value}`
                              )
                            : navigate(`/sharing/with-you?view_id=${value}`)
                    }
                    customHeight={'50vh'}
                />
                <FilterMenuOnSingleCriteria
                    currentOption={
                        state.sort_id
                            ? getViewFilterCriteria(state.sort_id)
                            : 'Sort By'
                    }
                    dropdownData={sortByData}
                    isFilterBottomSheetMenuOpen={state.isSortByBottomSheetOpen}
                    toggle={() =>
                        setState((prevState) => ({
                            ...prevState,
                            isSortByBottomSheetOpen: false,
                        }))
                    }
                    onSelected={(value: string) => handleSortBy(value)}
                    customHeight={'50vh'}
                />

                <div style={{ paddingBottom: '80px' }} />
            </IpadAndMobileDisplay>
        </React.Fragment>
    )
}

export default SharedWithYou
