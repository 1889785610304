import React, { useEffect } from 'react'
import { useAppSelector, useAppDispatch } from '../../../redux/store/hooks'
import Faded from '../../templates/animated/faded'
import Loader from '../../atoms/loader/loader'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import styled from 'styled-components'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import { usersActions } from '../../../redux/user/reducer'
import app_store_logo from '../../../public/assets/images/app_store_logo.png'
import play_store_logo from '../../../public/assets/images/play_store_logo.png'
import qr_code_native_app_ios from '../../../public/assets/images/qr_code_native_app_ios.png'
import qr_code_native_app_android from '../../../public/assets/images/qr_code_native_app_android.png'
import qr_code_native_app_ios_dark from '../../../public/assets/images/qr_code_native_app_ios_dark.png'
import qr_code_native_app_android_dark from '../../../public/assets/images/qr_code_native_app_android_dark.png'
import { device } from '../../templates/displays/devices'
import useThemes from '../../../providers/theme/hooks'
const Title = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-semibold;
    font-size: 24px;
    line-height: 40px;
`
const Text = styled.div`
    color: var(--text-default, #666);
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.001px;
`

const AppStoreAppLogo = styled.img`
    height: 48px;
    @media ${device.small_mobile} {
        height: 40px;
    }
`

const QrCodeForNativeAppImg = styled.img`
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    object-fit: cover;
`
const QrCodeForNativeApp = styled.div`
    width: 200px;
    height: 200px;
`

const AppWrapper = styled.div`
    display: flex;
    padding: 16px 18px 32px 16px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 16px;
    border: 1px solid var(--border-muted, #e5e5e5);
`

const InstallApp: React.FC = () => {
    const dispatch = useAppDispatch()

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)

    useEffect(() => {
        dispatch(
            customNavDataActions.setInitialLandingUrl('/account/install-app')
        )
        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        }
    }, [userLoggedIn])

    const { theme } = useThemes()

    return (
        <CenteredPageWrapper hasMobileSubNav removeHorizPaddings>
            <Faded>
                <Loader />
                <DesktopDisplayOnly>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 40,
                            paddingLeft: 50,
                            paddingRight: 50,
                            paddingTop: 50,
                            maxWidth: 676,
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 24,
                            }}
                        >
                            <Title>Take Custodian Anywhere</Title>
                            <Text>
                                Manage your garage on the go with the Custodian
                                app, now available for{' '}
                                <span style={{ fontFamily: 'Lato-semibold' }}>
                                    iOS
                                </span>{' '}
                                and{' '}
                                <span style={{ fontFamily: 'Lato-semibold' }}>
                                    Android
                                </span>
                                . Stay connected, track your cars, and access
                                essential data anytime, anywhere.
                            </Text>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 8,
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 16,
                                }}
                            >
                                <AppWrapper
                                    style={{
                                        paddingTop: '16px',
                                    }}
                                >
                                    <QrCodeForNativeApp>
                                        <QrCodeForNativeAppImg
                                            src={
                                                theme === 'dark'
                                                    ? qr_code_native_app_ios_dark
                                                    : qr_code_native_app_ios
                                            }
                                            alt="native_app_qr_code"
                                        />
                                    </QrCodeForNativeApp>
                                    <a
                                        href="https://apps.apple.com/gb/app/custodian/id1665962547"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <AppStoreAppLogo src={app_store_logo} />
                                    </a>
                                </AppWrapper>
                                <AppWrapper
                                    style={{
                                        paddingTop: '16px',
                                    }}
                                >
                                    <QrCodeForNativeApp>
                                        <QrCodeForNativeAppImg
                                            src={
                                                theme === 'dark'
                                                    ? qr_code_native_app_android_dark
                                                    : qr_code_native_app_android
                                            }
                                            alt="native_app_qr_code"
                                        />
                                    </QrCodeForNativeApp>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.custodian.app"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <AppStoreAppLogo
                                            src={play_store_logo}
                                        />
                                    </a>
                                </AppWrapper>
                            </div>
                        </div>
                    </div>
                </DesktopDisplayOnly>

                <IpadAndMobileDisplay>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 32,
                            padding: 24,
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 8,
                            }}
                        >
                            <Title>Take Custodian Anywhere</Title>
                            <Text>
                                Manage your garage on the go with the Custodian
                                app, now available for{' '}
                                <span style={{ fontFamily: 'Lato-semibold' }}>
                                    iOS
                                </span>{' '}
                                and{' '}
                                <span style={{ fontFamily: 'Lato-semibold' }}>
                                    Android
                                </span>
                                . Stay connected, track your cars, and access
                                essential data anytime, anywhere.
                            </Text>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 8,
                            }}
                        >
                            <Title style={{ fontSize: 18 }}>Download now</Title>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 8,
                                }}
                            >
                                <a
                                    href="https://apps.apple.com/gb/app/custodian/id1665962547"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <AppStoreAppLogo src={app_store_logo} />
                                </a>
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.custodian.app"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <AppStoreAppLogo src={play_store_logo} />
                                </a>
                            </div>
                        </div>
                    </div>
                </IpadAndMobileDisplay>
            </Faded>
        </CenteredPageWrapper>
    )
}

export default InstallApp
