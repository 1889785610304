import * as React from 'react'
import styled from 'styled-components'
import app_store_logo from '../../../public/assets/images/app_store_logo.png'
import play_store_logo from '../../../public/assets/images/play_store_logo.png'
import devices_grid from '../../../public/assets/images/devices_grid_mobile.png'
import { AnimatePresence, motion } from 'framer-motion'
import { device } from '../../templates/displays/devices'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import ModalDisplay from '../../templates/displays/pageWrappers/modalDisplay'

const Wrapper = styled(motion.div)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 8px;
    height: 570px;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    color: var(--text-strong, #1a1a1a);
    position: absolute;
    bottom: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    @media ${device.ipad} {
        height: 580px;
    }
`

const Devices = styled.div`
    height: 192px;
    width: 100%;
    box-sizing: border-box;
    max-width: 550px;

    @media ${device.ipad} {
        height: 230px;
    }
`
const DevicesImg = styled.img`
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    object-fit: cover;
    object-position: top;
`

const AppStoreAppLogo = styled.img`
    height: 48px;
    @media ${device.small_mobile} {
        height: 40px;
    }
`

const Title = styled.div`
    font-size: 28px;
    font-family: Lato;
    color: var(--primary, #5ec3ca);
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 28px;
    text-align: center;
`

const Text = styled.div`
    font-size: 16px;
    line-height: 24px;
    font-family: Lato;
    color: var(--text-darker, #616161);
    padding-left: 24px;
    padding-right: 24px;
    text-align: center;
    max-width: 500px;
`

const Close = styled.div`
    font-size: 16px;
    font-family: Lato;
    color: var(--primary, #5ec3ca);
    padding-left: 3px;
    cursor: pointer;
`
const Handle = styled.div`
    height: 4px;
    width: 60px;
    background-color: var(--text-darker, #616161);
    border-radius: 10px;
`

type IProps = { onClose: any; isOpen: boolean }

const NativeAppPromptMobile: React.FunctionComponent<IProps> = (props) => {
    return (
        <IpadAndMobileDisplay>
            <ModalDisplay isOpen toggle={() => {}}>
                <div
                    onClick={props.onClose}
                    style={{
                        width: '100%',
                        boxSizing: 'border-box',
                        height: '100%',
                    }}
                >
                    <div onClick={(e) => e.stopPropagation()}>
                        <AnimatePresence>
                            <Wrapper
                                initial={{ translateY: 900 }}
                                animate={{ translateY: 0 }}
                                exit={{ translateY: 900 }}
                                transition={{
                                    delay: 0.2,
                                }}
                            >
                                <Handle />
                                <Title>App available now</Title>
                                <div style={{ paddingTop: 24 }} />
                                <Text>
                                    You can now download our app on iOS and
                                    Android to manage your garage anytime,
                                    anywhere. Get it on the App Store or Google
                                    Play today!
                                </Text>
                                <div style={{ paddingTop: 32 }} />
                                <Devices>
                                    <DevicesImg
                                        src={devices_grid}
                                        alt="devices"
                                    />
                                </Devices>
                                <div style={{ paddingTop: 24 }} />
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: 12,
                                        alignItems: 'center',
                                    }}
                                >
                                    <a
                                        href="https://apps.apple.com/gb/app/custodian/id1665962547"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <AppStoreAppLogo src={app_store_logo} />
                                    </a>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.custodian.app"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <AppStoreAppLogo
                                            src={play_store_logo}
                                        />
                                    </a>
                                </div>
                                <div style={{ paddingTop: 32 }} />
                                <Close onClick={props.onClose}>Close</Close>
                                <Handle
                                    style={{
                                        justifySelf: 'flex-end',
                                        width: 240,
                                        marginTop: 20,
                                    }}
                                />
                            </Wrapper>
                        </AnimatePresence>
                    </div>
                </div>
            </ModalDisplay>
        </IpadAndMobileDisplay>
    )
}

export default NativeAppPromptMobile
