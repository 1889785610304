import { convertEnumsSumToArray } from '../../../helpers/convertEnumsSumToArray'
import { IAnyObject } from '../../../insuranceQuoteApplication/reducer'
import {
    IInsuranceApplication_API,
    IInsuranceApplication_APIErrors,
} from '../../../insuranceQuoteApplication/types'

const insurance_detail_field_ids = [
    'policy_requested_start_date',
    'payment_term',
    'application_referral_code',
    'contact_method',
]

export const ConvertApplicationToInsuranceDetails = (
    application: IInsuranceApplication_API | IInsuranceApplication_APIErrors
): IAnyObject => {
    let res: IAnyObject = {}

    //  ownership section
    for (let i = 0; i < insurance_detail_field_ids.length; i++) {
        if (insurance_detail_field_ids[i] === 'contact_method') {
            //  @ts-ignore
            if (application[insurance_detail_field_ids[i]]) {
                res[insurance_detail_field_ids[i]] = convertEnumsSumToArray(
                    [1, 2, 4, 8, 16, 32],
                    //  @ts-ignore
                    application[insurance_detail_field_ids[i]]
                )
            }
        } else {
            res[insurance_detail_field_ids[i]] =
                //  @ts-ignore
                application[insurance_detail_field_ids[i]]
        }
    }

    return res
}
