import styled from 'styled-components'
import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import TimelineSearchBarMobile from '../../searchbar/timeline/timelineSearchBarMobile'
import Expander from '../../../atoms/expander/expander'
import BottomRowWSelectedLabels from './bottomRowWSelectedLabels'
import { device } from '../../../templates/displays/devices'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { IDropdownItem } from 'entityModels'
import ChevronLeft from '../../../atoms/icons/components/chevronLeft'
import FilterIcon from '../../../atoms/icons/components/filterIcon'
import colours from '../../../../providers/theme/colours'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import { ITimelineEntryTypes } from '../../../../redux/timeline/types'
import { timelineActions } from '../../../../redux/timeline/reducer'
import useThemes from '../../../../providers/theme/hooks'

type StyledProps = {
    issearchactive?: boolean | undefined
}

const HeaderWrapper = styled.div<StyledProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${(props) =>
        props.issearchactive
            ? 'var(--modal-side-sheet-pop-over-bg, #fff)'
            : 'var(--bg-color, #fff)'};
    border-bottom: 1px solid var(--border-muted, #e5e5e5);
    box-shadow: ${(props) =>
        props.issearchactive
            ? 'none'
            : '0px 2px 9px -2px var(--box-shadow, rgba(0, 0, 0, 0.08))'};
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: ${(props) => (props.issearchactive ? '65px' : '30px')};
    padding-top: 20px;
    top: 0;
    position: fixed;
    width: 100%;
    box-sizing: border-box;
    z-index: 5;
    /* @media (min-width: 800px) and (max-width: 881px) {
        margin-left: -30px;
    } */
`

const TopRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 20px;
`

const Return = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-semibold;
    font-size: 18px;
    line-height: 32px;
    padding-left: 12px;

    @media ${device.desktop} {
        display: none;
    }
`

const TopLeftSide = styled(Link)`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`

const TopRightSide = styled.button`
    border: none;
    background-color: transparent;
    width: 32px;
    height: 32px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`

const BottomRowOverlay = styled.div<StyledProps>`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 10px;
    background: ${(props) =>
        props.issearchactive
            ? `linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        var(--modal-side-sheet-pop-over-bg, #fff),
        var(--modal-side-sheet-pop-over-bg, #fff)
    )`
            : `linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        var(--bg-color, #fff),
        var(--bg-color, #fff)
    )`};
`

const StopSearchZone = styled.div<StyledProps>`
    display: ${(props) => (!props.issearchactive ? 'none' : 'auto')};
    position: fixed;
    top: 0;
    bottom: 0;
    background-color: var(--off-bg-color, #fafafa);
    left: 0;
    right: 0;
    z-index: 0;
    opacity: 0.7;
    z-index: 4;
`

interface Props {
    returnUrl: string
    onFilterIconClick: (isOpen: boolean) => void
    changeView: () => void
    carid: string
    activeCategoryIds: ITimelineEntryTypes[]
    activeCustomLabelsIds: string[]
    activeEntryTitleQuery: string | undefined
    sendFiltersToApi: (payload: {
        categories: ITimelineEntryTypes[]
        labels: string[]
        entry_title?: string
    }) => void
    updateIsSearching: (isSearching: boolean) => void
    currentSearch: string | undefined
}

const TopHeaderFilterNavMobile: React.FC<Props> = ({
    returnUrl,
    onFilterIconClick,
    changeView,
    carid,
    activeCategoryIds,
    activeCustomLabelsIds,
    activeEntryTitleQuery,
    sendFiltersToApi,
    updateIsSearching,
    currentSearch,
}) => {
    let { theme } = useThemes()

    let location = useLocation()
    const [issearchactive, setIssearchactive] = useState(false)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const category_searchable_items_list = useAppSelector(
        (state) => state.timeline.categories.category_searchable_items_list
    )
    const userTimelineCustomTags = useAppSelector(
        (state) => state.user.userLoggedIn && state.user.userLoggedIn.labels
    )

    useEffect(() => {
        if (issearchactive) {
            disableBodyScroll(document.body)
        } else {
            enableBodyScroll(document.body)
        }

        return () => {
            enableBodyScroll(document.body)
        }
    }, [issearchactive])

    const manageissearchactive = (isActive: boolean) => {
        setIssearchactive(isActive)
        updateIsSearching(isActive)
    }

    let currentSelectedLabels: string[] = activeCustomLabelsIds
        ? [...activeCustomLabelsIds]
        : []
    let currentSelectedCategories: ITimelineEntryTypes[] = activeCategoryIds
        ? [...activeCategoryIds]
        : []

    const addCategory = (criteria: ITimelineEntryTypes) => {
        let indexOfClickedCriteria: number =
            currentSelectedCategories.indexOf(criteria)

        if (indexOfClickedCriteria < 0) {
            currentSelectedCategories.push(criteria)
        }

        currentSelectedCategories.length > 0 &&
            dispatch(
                timelineActions.setFilterTimelineItemsByCarIdCategoryIdsNLabelIdsNTitleQRequest(
                    {
                        car_id: carid,
                        label_ids: activeCustomLabelsIds,
                        category_ids: currentSelectedCategories,
                    }
                )
            )
    }

    const addFreeTitleQuery = (q: string) => {
        dispatch(
            timelineActions.setFilterTimelineItemsByCarIdCategoryIdsNLabelIdsNTitleQRequest(
                {
                    car_id: carid,
                    label_ids: activeCustomLabelsIds,
                    category_ids: activeCategoryIds,
                    entry_title: q,
                }
            )
        )
    }

    const removeCategory = (criteria: ITimelineEntryTypes) => {
        let indexOfClickedCriteria: number =
            currentSelectedCategories.indexOf(criteria)

        if (indexOfClickedCriteria >= 0) {
            currentSelectedCategories.splice(indexOfClickedCriteria, 1)
        }

        if (currentSelectedCategories.length >= 0) {
            sendFiltersToApi({
                categories: currentSelectedCategories,
                labels: activeCustomLabelsIds,
            })
        }
    }

    const addLabels = (criteria: string) => {
        let indexOfClickedCriteria: number =
            currentSelectedLabels.indexOf(criteria)
        if (indexOfClickedCriteria < 0) {
            currentSelectedLabels.push(criteria)
        }
        currentSelectedLabels.length > 0 &&
            dispatch(
                timelineActions.setFilterTimelineItemsByCarIdCategoryIdsNLabelIdsNTitleQRequest(
                    {
                        car_id: carid,
                        label_ids: currentSelectedLabels,
                        category_ids: activeCategoryIds,
                    }
                )
            )
    }

    const removeLabels = (criteria: string) => {
        let indexOfClickedCriteria: number =
            currentSelectedLabels.indexOf(criteria)

        if (indexOfClickedCriteria >= 0) {
            currentSelectedLabels.splice(indexOfClickedCriteria, 1)
        }

        if (currentSelectedLabels.length >= 0) {
            sendFiltersToApi({
                categories: activeCategoryIds,
                labels: currentSelectedLabels,
            })
        }
    }

    let labelsArray: any[] = userTimelineCustomTags
        ? userTimelineCustomTags.map((label: { name: any }) => label.name)
        : []

    let categoriesArray: any = category_searchable_items_list
        ? category_searchable_items_list.map(
              (category: IDropdownItem) => category.name
          )
        : []

    return (
        <>
            <StopSearchZone
                onClick={() => {
                    manageissearchactive(false)
                }}
                issearchactive={issearchactive}
            />
            <HeaderWrapper issearchactive={issearchactive}>
                <Expander
                    duration={80}
                    height={!issearchactive ? 'auto' : 0}
                    width="100%"
                >
                    <TopRow>
                        <TopLeftSide
                            to={returnUrl}
                            onClick={(e) => {
                                e.preventDefault()
                                navigate(returnUrl, {
                                    state: {
                                        prevPath: location.pathname,
                                    },
                                })
                            }}
                        >
                            <ChevronLeft />
                            <Return>Go back</Return>
                        </TopLeftSide>
                        <TopRightSide onClick={() => onFilterIconClick(true)}>
                            <FilterIcon color={colours[theme].text_muted} />
                        </TopRightSide>
                    </TopRow>
                </Expander>

                <TimelineSearchBarMobile
                    placeholder="Search for title or tag"
                    currentSearch={currentSearch}
                    manageissearchactive={manageissearchactive}
                    issearchactive={issearchactive}
                    category_searchable_items_list={
                        category_searchable_items_list
                    }
                    user_searchable_labels_list={userTimelineCustomTags || []}
                    addCriteria={(name: string | ITimelineEntryTypes) => {
                        if (activeEntryTitleQuery !== name) {
                            dispatch(
                                timelineActions.setFilterTimelineItemsByCarIdCategoryIdsNLabelIdsNTitleQRequest(
                                    {
                                        car_id: carid,
                                        label_ids: activeCustomLabelsIds,
                                        category_ids: activeCategoryIds,
                                        entry_title: undefined,
                                    }
                                )
                            )
                        }

                        if (labelsArray && labelsArray.includes(name)) {
                            addLabels(name as string)
                            sendFiltersToApi({
                                categories: currentSelectedCategories,
                                labels: currentSelectedLabels,
                                entry_title: activeEntryTitleQuery,
                            })
                            manageissearchactive(false)
                        } else if (
                            categoriesArray &&
                            categoriesArray.includes(name)
                        ) {
                            let m: ITimelineEntryTypes =
                                name as ITimelineEntryTypes
                            addCategory(m)
                            sendFiltersToApi({
                                categories: currentSelectedCategories,
                                labels: currentSelectedLabels,
                                entry_title: activeEntryTitleQuery,
                            })
                            manageissearchactive(false)
                        } else {
                            addFreeTitleQuery(name as string)
                            sendFiltersToApi({
                                categories: currentSelectedCategories,
                                labels: currentSelectedLabels,
                                entry_title: name as string,
                            })
                            manageissearchactive(false)
                        }
                    }}
                    checkIfSelectedEntryOrLabel={(name: string) => {
                        if (labelsArray && labelsArray.includes(name)) {
                            return true
                        } else if (
                            categoriesArray &&
                            categoriesArray.includes(name)
                        ) {
                            return true
                        } else return false
                    }}
                    clearEntryTitleSearch={() =>
                        dispatch(
                            timelineActions.setFilterTimelineItemsByCarIdCategoryIdsNLabelIdsNTitleQRequest(
                                {
                                    car_id: carid,
                                    label_ids: activeCustomLabelsIds,
                                    category_ids: activeCategoryIds,
                                    entry_title: undefined,
                                    sendToAPI: true,
                                }
                            )
                        )
                    }
                    finalSearchToApi={() => {
                        manageissearchactive(false)
                        sendFiltersToApi({
                            categories: currentSelectedCategories,
                            labels: currentSelectedLabels,
                            entry_title: activeEntryTitleQuery,
                        })
                    }}
                />
                <div style={{ paddingTop: '10px' }} />
                {((activeCategoryIds && activeCategoryIds.length > 0) ||
                    (activeCustomLabelsIds &&
                        activeCustomLabelsIds.length > 0)) && (
                    <div
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            position: 'relative',
                        }}
                    >
                        <BottomRowOverlay issearchactive={issearchactive} />
                        <BottomRowWSelectedLabels
                            labels={activeCustomLabelsIds}
                            categories={activeCategoryIds || []}
                            onCategoryCrossClick={(item: ITimelineEntryTypes) =>
                                removeCategory(item)
                            }
                            onLabelCrossClick={(item: string) =>
                                removeLabels(item)
                            }
                            clearFilters={() =>
                                dispatch(
                                    timelineActions.setFilterTimelineItemsByCarIdCategoryIdsNLabelIdsNTitleQRequest(
                                        {
                                            car_id: carid,
                                            label_ids: [],
                                            category_ids: [],
                                            entry_title: activeEntryTitleQuery,
                                            sendToAPI: true,
                                            resetResults: true,
                                        }
                                    )
                                )
                            }
                        />
                    </div>
                )}
            </HeaderWrapper>
        </>
    )
}

export default TopHeaderFilterNavMobile
