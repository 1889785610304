import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import SwitchButton from '../../atoms/Button/switchButton'
import { useEffect, useState } from 'react'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { IRootState } from '../../../redux/store'
import LoaderElasticThreeDots from '../../atoms/loader/loaderElasticThreeDots'
import { usersActions } from '../../../redux/user/reducer'

const Wrapper = styled.div`
    max-width: 550px;

    @media ${device.mobile_and_ipad} {
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 10px;
        max-width: 100%;
    }
`

const Subheader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    color: var(--text-darker, #616161);
    font-family: Lato;
    font-size: 16px;
    line-height: 24px;

    @media (max-width: 768px) {
        font-size: 14px;
        line-height: 20px;
    }
`
const SectionSubheader = styled.div`
    display: flex;
    padding: 16px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-bottom: 1px solid var(--border-muted, #e5e5e5);
    font-family: Lato;
    font-size: 18px;
    color: var(--text-strong, #1a1a1a);
`

const ItemTxtWrap = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`

const ItemContentWrap = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    @media ${device.mobile_and_ipad} {
        flex-direction: row;
        align-items: flex-start;
    }
`

const ItemTitle = styled.h3`
    all: unset;
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-Semibold;
    font-size: 15px;
    line-height: 20px;
    margin: 0 0 8px 0;

    @media ${device.small_mobile} {
        max-width: 97%;
    }
`

type Props2 = {
    items: INotifItem[]
    onChange: (id: string) => any
    isLoading: boolean
}

const Item = styled.div`
    @media ${device.mobile_and_ipad} {
        border-radius: 8px;
        background: var(--off-bg-color, #fafafa);
        padding: 16px;
    }

    @media ${device.beyond_ipad_mobile} {
        width: 450px;
    }
`

const Container = styled.div`
    gap: 12px;

    @media ${device.mobile_and_ipad} {
        gap: 16px;
    }

    display: flex;
    flex-direction: column;

    @media ${device.beyond_ipad_mobile} {
        width: 550px;
    }
`

const NotificationItem = (props2: Props2) => {
    return (
        <Container>
            {props2.items.map((item: INotifItem, key: number) => {
                return (
                    <Item
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                            gap: 24,
                        }}
                        key={`notification_${key}`}
                    >
                        <ItemContentWrap>
                            <ItemTxtWrap>
                                <ItemTitle>{item.title}</ItemTitle>

                                <p
                                    style={{
                                        color: 'var(--text-darker, #b3b3b3)',
                                        fontFamily: 'Lato',
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                        margin: 0,
                                    }}
                                >
                                    {item.text}
                                </p>
                            </ItemTxtWrap>

                            <IpadAndMobileDisplay>
                                {props2.isLoading ? (
                                    <LoaderElasticThreeDots />
                                ) : (
                                    <SwitchButton
                                        checked={item.optIn ?? false}
                                        toggle={() => {
                                            props2.onChange(item.id)
                                        }}
                                        width={34}
                                        circleSize={12}
                                        height={18}
                                    />
                                )}
                            </IpadAndMobileDisplay>
                        </ItemContentWrap>

                        <DesktopDisplayOnly>
                            {props2.isLoading ? (
                                <LoaderElasticThreeDots />
                            ) : (
                                <SwitchButton
                                    checked={item.optIn ?? false}
                                    toggle={() => {
                                        props2.onChange(item.id)
                                    }}
                                    width={34}
                                    circleSize={12}
                                    height={18}
                                />
                            )}
                        </DesktopDisplayOnly>
                    </Item>
                )
            })}
        </Container>
    )
}

type INotifItem = {
    id: string
    title: string
    text: string
    optIn?: boolean
}

const NotificationsBody = () => {
    const userLoggedIn = useAppSelector(
        (state: IRootState) => state.user.userLoggedIn
    )
    const secondaryLoading = useAppSelector(
        (state: IRootState) => state.user.secondaryLoading
    )

    let currentUsersMailingGroups = userLoggedIn?.mailingGroups || []

    const [notificationItemsGeneral, setNotificationItemsGeneral] = useState<{
        [key: string]: { title: string; items: INotifItem[] }
    }>({
        general: {
            title: 'General',
            items: [
                {
                    id: 'General Communications',
                    title: 'General Marketing & Communications',
                    text: 'Essential information about the platform and your account, including important updates and announcements.',
                    optIn: currentUsersMailingGroups.includes(
                        'General Communications'
                    ),
                },
                {
                    title: 'Product Updates',
                    text: 'News on new platform features, product developments, and enhancements driven by community feedback and requests.',
                    optIn: currentUsersMailingGroups.includes(
                        'Product Updates'
                    ),
                    id: 'Product Updates',
                },
            ],
        },
        showroom: {
            title: 'The Showroom',
            items: [
                {
                    title: 'The Showroom Weekly',
                    text: 'A curated weekly newsletter featuring highlights from The Showroom, including our Car of the Week and other features.',
                    optIn: currentUsersMailingGroups.includes(
                        'Showroom Updates'
                    ),
                    id: 'Showroom Updates',
                },
                {
                    title: 'Today on The Showroom',
                    text: 'A daily update showcasing all the latest car listings added to The Showroom that day.',
                    optIn: currentUsersMailingGroups.includes('Showroom Daily'),
                    id: 'Showroom Daily',
                },
                {
                    title: 'This Week on The Showroom',
                    text: 'A weekly round-up of all new car listings added to The Showroom over the past week.',
                    optIn: currentUsersMailingGroups.includes(
                        'Showroom Weekly'
                    ),
                    id: 'Showroom Weekly',
                },
            ],
        },
        apex: {
            title: 'The Apex',
            items: [
                {
                    title: 'The Apex',
                    text: 'A weekly newsletter and podcast featuring conversations with the titans and tastemakers of the automotive universe. From racers and restorers to purveyors and photographers and everything in between, we share their unique stories and perspectives on car culture and beyond.',
                    optIn: currentUsersMailingGroups.includes('The Apex'),
                    id: 'The Apex',
                },
            ],
        },
    })

    useEffect(() => {
        if (userLoggedIn) {
            setNotificationItemsGeneral((prevSections) => {
                return {
                    general: {
                        ...prevSections.general,
                        items: prevSections.general.items.map((item) => {
                            return {
                                ...item,
                                optIn: currentUsersMailingGroups.includes(
                                    item.id
                                ),
                            }
                        }),
                    },
                    showroom: {
                        ...prevSections.showroom,
                        items: prevSections.showroom.items.map((item) => {
                            return {
                                ...item,
                                optIn: currentUsersMailingGroups.includes(
                                    item.id
                                ),
                            }
                        }),
                    },
                    apex: {
                        ...prevSections.apex,
                        items: prevSections.apex.items.map((item) => {
                            return {
                                ...item,
                                optIn: currentUsersMailingGroups.includes(
                                    item.id
                                ),
                            }
                        }),
                    },
                }
            })
        }
    }, [userLoggedIn])

    const dispatch = useAppDispatch()

    const onChange = (id: string) => {
        dispatch(usersActions.updateUserMailingGroupsRequest(id))
    }

    return (
        <Wrapper>
            <div style={{ marginTop: '10px' }} />
            <Subheader>
                Select email notifications for each type of update and never
                miss an important message again.
            </Subheader>
            {Object.values(notificationItemsGeneral).map((section, key) => (
                <div style={{ marginTop: '20px' }} key={`section_${key}`}>
                    <SectionSubheader>{section.title}</SectionSubheader>
                    <div style={{ marginTop: '20px' }} />
                    <NotificationItem
                        isLoading={secondaryLoading}
                        items={section.items}
                        onChange={onChange}
                    />
                </div>
            ))}
        </Wrapper>
    )
}

export default NotificationsBody
