import React, { useState, useEffect, useCallback } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import WindowPageTemplate from '../../templates/windowPageTemplate/windowPageTemplateDesktop'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import PrivateShareSearchFormManagerDesktop from '../../organisms/shareForms/privateShare/privateShareSearchFormDesktop'
import PrivateShareFormManagerDesktop from '../../organisms/shareForms/privateShare/privateShareFormManagerDesktop'
import EditModeTopHeaderDesktop from '../../atoms/header/editModeTopHeader/editModeTopHeaderDesktop'
import PrivateShareFormManagerMobile from '../../organisms/shareForms/privateShare/privateShareFormManagerMobile'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import SwitchTabMenuDesktop from '../../atoms/Button/switchTabMenuDesktop'
import ShareRowDesktop from '../../atoms/shareRow/shareRowDesktop'
import styled from 'styled-components'
import ModalDisplayAbsolute from '../../templates/displays/pageWrappers/modalDisplayAbsolute'
import AddShareByEmailFormDesktop from '../../organisms/editForms/addShareByEmailForm/addShareByEmailFormDesktop'
import PrivateShareReturnNav from '../../atoms/header/privateShareReturnNav'
import SwitchTabMenuMobile from '../../atoms/Button/switchTabMenuMobile'
import ShareRowMobile from '../../atoms/shareRow/shareRowMobile'
import AddShareByEmailFormMobile from '../../organisms/editForms/addShareByEmailForm/addShareByEmailFormMobile'
import { device } from '../../templates/displays/devices'
import DropdownExpanderHover from '../../atoms/menu/dropdownExpander/dropdownExpanderHover'
import PrivateSharesSortingMobile from '../../atoms/menu/bottomSheetMenu/privateSharesSortingMobile'
import GreyIconButton from '../../atoms/Button/GreyIconButton'
import EditModeFooterDesktop from '../../atoms/footer/editModeFooterDesktop'
import Faded from '../../templates/animated/faded'
import { editFormsActions } from '../../../redux/editForms/reducer'
import DeletionConfirmationDesktop from '../../molecules/deletionConfirmationDesktop/deletionConfirmationDesktop'
import NoIconDataRectangle from '../../atoms/noData/noIconDataRectangle'
import SideSlider from '../../templates/animated/sideSlider'
import SideSliderWithExit from '../../templates/animated/sideSliderWithExit'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import PrivateShareSearchManagerMobileV2 from '../../organisms/shareForms/privateShare/privateShareSearchManagerMobileV2'
import {
    useAppDispatch,
    useAppSelector,
    useThunkAppDispatch,
} from '../../../redux/store/hooks'
import {
    ICar,
    IDirectShareOwnedItem,
    IEntityPrivateOwnedSharesState,
    ISharingPermissionIDS,
    IUpdateCarDirectShareRequest,
} from '../../../redux/entities/cars/types'
import { usersActions } from '../../../redux/user/reducer'
import { carActions } from '../../../redux/entities/cars/reducer'
import { IDirectShareDeletionConfirmation } from '../../../redux/editForms/types'
import { IShareRecipientRequest_redux } from '../../../redux/entities/cars/actions'
import { searchUsersActions } from '../../../redux/searchUsers/reducer'

const DesktopAdjustedWrapper = styled.div`
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
`

const MobileWrapper = styled.div`
    padding-top: 10px;
    padding-left: 24px;
    padding-right: 24px;
`
const SharedWith = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: 'Lato-Bold';
    font-size: 17px;
    padding-bottom: 20px;
`

const Padding10 = styled.div`
    padding-top: 10px;
`
const Padding20 = styled.div`
    padding-top: 20px;
`

const StickyWrapper = styled.div`
    position: sticky;
    width: 100%;
    box-sizing: border-box;
    top: 0;
    z-index: 4;
    display: flex;
    align-items: flex-end;
    background-color: var(--bg-color, #fff);
    height: 50px;
    transform: translateY(-20px);
    margin-top: 16px;
    @media ${device.mobile_and_ipad} {
        top: 75px;
        margin-top: 15px;
    }
`

const DropdownHoverWrapper = styled.div`
    border-radius: 5px;
    height: 40px;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: all 200ms;
`

const Title = styled.div`
    font-family: Lato;
    font-size: 16px;
    color: var(--text-strong, #1a1a1a);
    padding-bottom: 10px;
`

const SubTitle = styled.div`
    font-family: Lato;
    font-size: 15px;
    color: var(--text-default, #666666);
`

const Padding24 = styled.div`
    padding-top: 24px;
`

const CarShareEdit: React.FC = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { carid } = useParams<{ carid: string }>()
    const location = useLocation()
    const q_params = new URLSearchParams(location.search)
    const shareid = q_params.get('shareid')
    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)

    const sharesLoading = useAppSelector(
        (state) => state.entities.carsData.carSharesLoading
    )
    const carsData = useAppSelector((state) => state.entities.carsData.cars)

    const addShareRecipientByEmailForm = useAppSelector(
        (state) =>
            state.editForms.formsData?.addShareRecipientByEmailForm?.isOpen
    )
    const searchResults = useAppSelector((state) => state.searchUsers.list)
    const searchResultsLoading = useAppSelector(
        (state) => state.searchUsers.loading
    )
    const isCollapsed = useAppSelector(
        (state) => state.menus.desktopMainAppMenu.isCollapsed
    )
    const directShareDeletionConfirmation = useAppSelector(
        (state) => state.editForms.directShareDeletionConfirmation
    )

    const [shareBeingEdited, setShareBeingEdited] =
        useState<IDirectShareOwnedItem>({
            entity_id: '',
            title: '',
            active_permission_ids: [],
            id: 'share_being_edited',
            created_at: '',
            type: 'car',
            updated_at: '',
            all_share_recipients_ids_list: [],
            external_share_recipients_ids_list: [],
            platform_share_recipients_ids_list: [],
            share_recipients_data: {},
            total_recipients: 0,
        })
    // const [isMobileEditOpen, setIsMobileEditOpen] = useState(false)
    const [activeTabId, setActiveTabId] = useState('recipients')
    const [isSortBottomSheetOpen, setIsSortBottomSheetOpen] = useState(false)

    const changeTab = useCallback((tabID: string) => {
        setActiveTabId(tabID)
    }, [])

    // const handleResize = useCallback(() => {
    //     setIsMobileEditOpen(window.innerWidth < 881)
    // }, [])

    const setupLocalStateData = useCallback(() => {
        const car_shares: IEntityPrivateOwnedSharesState | undefined =
            carid &&
            carsData &&
            carsData[carid] &&
            carsData[carid].private_shares_owned
                ? carsData[carid].private_shares_owned
                : undefined

        if (
            car_shares &&
            car_shares.entity_owned_direct_shares_data &&
            shareid &&
            car_shares.entity_owned_direct_shares_data[shareid]
        ) {
            const share: IDirectShareOwnedItem =
                car_shares.entity_owned_direct_shares_data[shareid]

            setShareBeingEdited({
                ...share,
                active_display: {
                    item_ids_display: share.active_display?.item_ids_display
                        ? share.active_display?.item_ids_display
                        : share.all_share_recipients_ids_list,
                    activeSort: share.active_display?.activeSort
                        ? share.active_display?.activeSort
                        : 'added_at',
                },
            })
        }
    }, [carid, carsData, shareid])

    const dispatchThunks = useThunkAppDispatch()

    useEffect(() => {
        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        } else {
            dispatchThunks(carActions.setCurrentCar(`${carid}`))

            if (!carsData[`${carid}`]) {
                dispatch(carActions.getCarDataByIdRequest(`${carid}`))
            }

            shareid &&
                dispatch(
                    carActions.getSingleCarDirectShareRequest({
                        entity_id: `${carid}`,
                        share_id: shareid,
                    })
                )
            setupLocalStateData()
        }

        // window.addEventListener('resize', handleResize, { passive: true })

        // if (window.innerWidth < 881) {
        //     setIsMobileEditOpen(true)
        // }

        let active_tab_id = q_params.get('active_tab_id')

        if (active_tab_id === 'settings') {
            changeTab('settings')
        }

        dispatch(
            customNavDataActions.setInitialLandingUrl(
                `/sharing/with-others/car/${carid}/edit-share?shareid=${shareid}`
            )
        )

        return () => {
            // setIsMobileEditOpen(false)
            // window.removeEventListener('resize', handleResize)
            dispatch(
                editFormsActions.toggleAddShareRecipientByEmail({
                    isOpen: false,
                })
            )
        }
    }, [
        userLoggedIn,
        carid,
        // carsData,
        shareid,
        // q_params,
        // setupLocalStateData,
        // handleResize,
        // changeTab,
    ])

    useEffect(() => {
        setupLocalStateData()
    }, [carsData, carid, setupLocalStateData])

    const handleChangePermissions = useCallback(
        (permissionID: ISharingPermissionIDS) => {
            setShareBeingEdited((prevState) => {
                const permissions_arr = prevState.active_permission_ids

                if (permissions_arr.includes(permissionID)) {
                    return {
                        ...prevState,
                        active_permission_ids: permissions_arr.filter(
                            (id) => permissionID !== id
                        ),
                    }
                } else {
                    return {
                        ...prevState,
                        active_permission_ids: [
                            ...permissions_arr,
                            permissionID,
                        ],
                    }
                }
            })
        },
        []
    )

    const handleTitleChange = useCallback((id: string, title: string) => {
        setShareBeingEdited((prevState) => ({
            ...prevState,
            title: title,
        }))
    }, [])

    const handleSubmitToBE = useCallback(() => {
        const payload: IUpdateCarDirectShareRequest = {
            car_id: `${carid}`,
            share_id: shareid ? shareid : '',
            policy_statements: shareBeingEdited.active_permission_ids,
            title: shareBeingEdited.title,
        }

        return dispatch(carActions.updateCarDirectShareRequest(payload))
    }, [carid, shareid, shareBeingEdited])

    const handleDelete = useCallback((p: IDirectShareDeletionConfirmation) => {
        if (p.entity_id && p.share_id) {
            dispatch(
                carActions.deleteCarDirectShareRequest({
                    entity_id: p.entity_id,
                    share_id: p.share_id,
                })
            )
            navigate(`/sharing/with-others/car?carid=${p.entity_id}`)
        }
    }, [])

    const current: ICar | undefined = carsData[`${carid}`]
        ? carsData[`${carid}`]
        : undefined

    const btnIdsList: string[] = ['recipients', 'settings']
    const btnsData: any = {
        recipients: {
            id: 'recipients',
            txt: 'recipients',
            action: () => changeTab('recipients'),
            isDisabled: false,
        },
        settings: {
            id: 'settings',
            txt: 'settings',
            action: () => changeTab('settings'),
            isDisabled: false,
        },
    }

    const car_shares: IEntityPrivateOwnedSharesState | undefined =
        carid &&
        carsData &&
        carsData[carid] &&
        carsData[carid].private_shares_owned
            ? carsData[carid].private_shares_owned
            : undefined

    const current_share: IDirectShareOwnedItem | undefined =
        car_shares &&
        car_shares.entity_owned_direct_shares_data &&
        shareid &&
        car_shares.entity_owned_direct_shares_data[shareid]
            ? car_shares.entity_owned_direct_shares_data[shareid]
            : undefined

    const hasShareChanged = (
        a: IDirectShareOwnedItem,
        b: IDirectShareOwnedItem
    ): boolean => {
        if (a.title !== b.title) {
            return true
        }
        if (a.active_permission_ids && !b.active_permission_ids) {
            return true
        }
        if (!a.active_permission_ids && b.active_permission_ids) {
            return true
        }
        if (
            a.active_permission_ids &&
            b.active_permission_ids &&
            a.active_permission_ids.length !== b.active_permission_ids.length
        ) {
            return true
        }

        if (a.active_permission_ids && b.active_permission_ids) {
            const uniqueValues = new Set([
                ...a.active_permission_ids,
                ...b.active_permission_ids,
            ])
            for (const v of uniqueValues) {
                const aCount = a.active_permission_ids.filter(
                    (e) => e === v
                ).length
                const bCount = b.active_permission_ids.filter(
                    (e) => e === v
                ).length
                if (aCount !== bCount) return true
            }
        }

        return false
    }

    const checkWhatIsBeingUpdated = (): string | undefined => {
        if (
            shareBeingEdited.title &&
            current_share &&
            shareBeingEdited.title !== current_share.title
        ) {
            if (
                shareBeingEdited.active_permission_ids &&
                JSON.stringify(shareBeingEdited.active_permission_ids) !==
                    JSON.stringify(current_share.active_permission_ids)
            ) {
                return 'title and permisions'
            } else {
                return 'title'
            }
        } else if (
            current_share &&
            shareBeingEdited.active_permission_ids &&
            JSON.stringify(shareBeingEdited.active_permission_ids) !==
                JSON.stringify(current_share.active_permission_ids)
        ) {
            return 'permissions'
        }
        return undefined
    }

    return (
        <React.Fragment>
            <DesktopDisplayOnly>
                <CenteredPageWrapper fullwidth>
                    {current && shareBeingEdited && (
                        <WindowPageTemplate
                            hasPositionRelative
                            isCollapsed={isCollapsed}
                            stickyHeaderContent={
                                <EditModeTopHeaderDesktop
                                    headerText={
                                        current_share && current_share.title
                                            ? current_share.title
                                            : 'Untitled'
                                    }
                                    noBtns
                                    isInsideWindowTemplate
                                    goBack={() => {
                                        navigate(
                                            `/sharing/with-others/car?carid=${carid}`
                                        )
                                    }}
                                    removeHorizTopPaddings
                                />
                            }
                            stickyBottomContent={
                                <EditModeFooterDesktop
                                    onCancel={
                                        activeTabId === 'settings'
                                            ? () => {
                                                  navigate(
                                                      `/sharing/with-others/car?carid=${carid}`
                                                  )
                                              }
                                            : undefined
                                    }
                                    onDelete={() =>
                                        setTimeout(() => {
                                            shareid &&
                                                dispatch(
                                                    editFormsActions.toggleShareDeletionConfirmation(
                                                        {
                                                            isOpen: true,
                                                            entity_id: carid,
                                                            entity_type: 'car',
                                                            share_id: shareid,
                                                        }
                                                    )
                                                )
                                        })
                                    }
                                    isInsideWindowTemplate
                                    isActive={
                                        current_share &&
                                        shareBeingEdited &&
                                        hasShareChanged(
                                            current_share,
                                            shareBeingEdited
                                        )
                                    }
                                    onSave={
                                        activeTabId === 'settings'
                                            ? handleSubmitToBE
                                            : undefined
                                    }
                                    isCollapsed={isCollapsed}
                                />
                            }
                        >
                            <DesktopAdjustedWrapper>
                                {addShareRecipientByEmailForm && carid && (
                                    <ModalDisplayAbsolute
                                        isOpen={
                                            addShareRecipientByEmailForm &&
                                            addShareRecipientByEmailForm
                                                ? true
                                                : false
                                        }
                                        toggle={() => {}}
                                        top="20px"
                                        left="0px"
                                        right="20px"
                                        bottom="20px"
                                    >
                                        <AddShareByEmailFormDesktop
                                            user={userLoggedIn}
                                            close={() => {
                                                dispatch(
                                                    editFormsActions.toggleAddShareRecipientByEmail(
                                                        {
                                                            isOpen: false,
                                                        }
                                                    )
                                                )
                                            }}
                                            share_id={shareBeingEdited.id}
                                            entity_id={carid}
                                            dispatch={dispatch}
                                        />
                                    </ModalDisplayAbsolute>
                                )}
                                <StickyWrapper>
                                    <SwitchTabMenuDesktop
                                        btnIdsList={btnIdsList}
                                        btnsData={btnsData}
                                        activeID={activeTabId}
                                    />
                                </StickyWrapper>
                                <div style={{ paddingTop: '10px' }} />
                                {activeTabId === 'recipients' && (
                                    <Faded>
                                        <PrivateShareSearchFormManagerDesktop
                                            share={shareBeingEdited}
                                            searchUsers={(p) => {
                                                dispatch(
                                                    searchUsersActions.checkUserEmailExistsRequest(
                                                        p
                                                    )
                                                )
                                            }}
                                            searchResults={searchResults}
                                            resultsLoading={
                                                searchResultsLoading
                                            }
                                            addShareRecipient={(
                                                p: IShareRecipientRequest_redux
                                            ) => {
                                                dispatch(
                                                    carActions.addCarShareRecipientRequest(
                                                        p
                                                    )
                                                )
                                            }}
                                            selectedUsers={
                                                shareBeingEdited.all_share_recipients_ids_list
                                            }
                                            onClickInvite={() =>
                                                dispatch(
                                                    editFormsActions.toggleAddShareRecipientByEmail(
                                                        {
                                                            isOpen: true,
                                                        }
                                                    )
                                                )
                                            }
                                            inviteTxt="Invite to Custodian via Email & Share"
                                        />

                                        <div style={{ paddingTop: '50px' }} />
                                        <div
                                            style={{
                                                display: 'flex',
                                                width: '100%',
                                                boxSizing: 'border-box',
                                                justifyContent: 'space-between',
                                                minWidth: '730px',
                                            }}
                                        >
                                            <SharedWith>
                                                Direct share recipients
                                            </SharedWith>
                                            <DropdownHoverWrapper>
                                                <DropdownExpanderHover
                                                    width={'210px'}
                                                    fontSize="15px"
                                                    height="40px"
                                                    placeholder={`Sorted by Date added`}
                                                    placeholderWhenSelected="Sorted by"
                                                    rightAligned
                                                    items={[
                                                        {
                                                            text: 'by Date added',
                                                            action: () => {
                                                                current &&
                                                                    dispatch(
                                                                        carActions.sortShareRecipientsDisplay_change_request(
                                                                            {
                                                                                sortID: 'added_at',
                                                                                entityid:
                                                                                    current?.id,
                                                                                share: shareBeingEdited,
                                                                            }
                                                                        )
                                                                    )
                                                            },
                                                        },
                                                        {
                                                            text: 'Alphabetical',
                                                            action: () => {
                                                                current &&
                                                                    dispatch(
                                                                        carActions.sortShareRecipientsDisplay_change_request(
                                                                            {
                                                                                sortID: 'alphabetical',
                                                                                entityid:
                                                                                    current?.id,
                                                                                share: shareBeingEdited,
                                                                            }
                                                                        )
                                                                    )
                                                            },
                                                        },
                                                    ]}
                                                />
                                            </DropdownHoverWrapper>
                                        </div>

                                        <Padding20 />
                                        {shareBeingEdited &&
                                        shareBeingEdited.active_display &&
                                        shareBeingEdited.active_display
                                            .item_ids_display &&
                                        shareBeingEdited.active_display
                                            .item_ids_display.length > 0 ? (
                                            shareBeingEdited.share_recipients_data &&
                                            shareBeingEdited.active_display.item_ids_display.map(
                                                (
                                                    userid: string,
                                                    index: number
                                                ) => (
                                                    <Faded>
                                                        <ShareRowDesktop
                                                            recipient={
                                                                shareBeingEdited!
                                                                    .share_recipients_data![
                                                                    userid
                                                                ]
                                                            }
                                                            index={index}
                                                            isLast={
                                                                shareBeingEdited.all_share_recipients_ids_list &&
                                                                shareBeingEdited
                                                                    .all_share_recipients_ids_list
                                                                    .length -
                                                                    1 ===
                                                                    index
                                                                    ? true
                                                                    : false
                                                            }
                                                            actionsData={{
                                                                email: (payload: {
                                                                    invite_id: string
                                                                    recipient_email: string
                                                                }) =>
                                                                    dispatch(
                                                                        carActions.resendCarShareInviteRequest(
                                                                            {
                                                                                invite_id:
                                                                                    payload.invite_id,
                                                                                recipient_email:
                                                                                    payload.recipient_email,
                                                                            }
                                                                        )
                                                                    ),

                                                                delete: (payload: {
                                                                    recipient_id: string
                                                                    recipient_type:
                                                                        | 'platform'
                                                                        | 'external'
                                                                }) =>
                                                                    payload.recipient_type ===
                                                                    'external'
                                                                        ? dispatch(
                                                                              carActions.deleteCarShareInviteRequest(
                                                                                  {
                                                                                      share_id:
                                                                                          shareBeingEdited.id,
                                                                                      entity_id:
                                                                                          shareBeingEdited.entity_id,
                                                                                      invite_id:
                                                                                          payload.recipient_id,
                                                                                  }
                                                                              )
                                                                          )
                                                                        : dispatch(
                                                                              carActions.deleteCarShareRecipientRequest(
                                                                                  {
                                                                                      share_id:
                                                                                          shareBeingEdited.id,
                                                                                      entity_id:
                                                                                          shareBeingEdited.entity_id,
                                                                                      recipient_userid:
                                                                                          payload.recipient_id,
                                                                                  }
                                                                              )
                                                                          ),
                                                            }}
                                                            sharedOn={
                                                                shareBeingEdited!
                                                                    .share_recipients_data![
                                                                    userid
                                                                ]
                                                                    .date_added_to_share
                                                            }
                                                        />
                                                    </Faded>
                                                )
                                            )
                                        ) : (
                                            <Faded>
                                                <NoIconDataRectangle
                                                    text={`You haven't shared this with anyone yet`}
                                                />
                                            </Faded>
                                        )}
                                    </Faded>
                                )}
                                {activeTabId === 'settings' && (
                                    <Faded>
                                        <PrivateShareFormManagerDesktop
                                            share_being_edited={
                                                shareBeingEdited
                                            }
                                            handleSubmit={handleSubmitToBE}
                                            handlePrivateShareTitleChange={
                                                handleTitleChange
                                            }
                                            handlePermissions={
                                                handleChangePermissions
                                            }
                                            formType="edit"
                                        />
                                    </Faded>
                                )}
                            </DesktopAdjustedWrapper>
                        </WindowPageTemplate>
                    )}
                </CenteredPageWrapper>
                <DeletionConfirmationDesktop
                    isShare
                    isEditForm
                    isOpen={directShareDeletionConfirmation.isOpen}
                    toggle={() =>
                        setTimeout(
                            () =>
                                dispatch(
                                    editFormsActions.toggleShareDeletionConfirmation(
                                        {
                                            isOpen: false,
                                            entity_id: undefined,
                                            entity_type: undefined,
                                            share_id: undefined,
                                        }
                                    )
                                ),
                            50
                        )
                    }
                    action={() => handleDelete(directShareDeletionConfirmation)}
                    actionCopy="Confirm deletion"
                    titleCopy="Are you sure?"
                    detailsCopy="All recipients will lose access to your car."
                />
            </DesktopDisplayOnly>

            <IpadAndMobileDisplay>
                <SideSlider
                    direction={
                        location.state &&
                        location.state.prevPath &&
                        location.state.prevPath.includes(
                            `/edit-share/recipients`
                        )
                            ? 'left'
                            : 'right'
                    }
                    initial="enter"
                >
                    <PrivateShareReturnNav
                        customText={
                            current_share && current_share.title
                                ? current_share.title
                                : 'Untitled'
                        }
                        chevron
                        linkTo={`/sharing/with-others/car`}
                        params={`?carid=${carid}`}
                        noBoxShadow
                    />
                    <div style={{ paddingTop: '60px' }} />
                    <StickyWrapper>
                        <SwitchTabMenuMobile
                            btnIdsList={btnIdsList}
                            btnsData={btnsData}
                            activeID={activeTabId}
                        />
                    </StickyWrapper>
                    {activeTabId === 'settings' && (
                        <PrivateShareFormManagerMobile
                            share_being_edited={shareBeingEdited}
                            handleSubmit={handleSubmitToBE}
                            handlePrivateShareTitleChange={handleTitleChange}
                            handlePermissions={handleChangePermissions}
                            formType="edit"
                            isSaveActive={
                                current_share &&
                                shareBeingEdited &&
                                hasShareChanged(current_share, shareBeingEdited)
                            }
                            shareChanges={checkWhatIsBeingUpdated()}
                            onDeleteShare={() =>
                                setTimeout(
                                    () =>
                                        shareid &&
                                        dispatch(
                                            editFormsActions.toggleShareDeletionConfirmation(
                                                {
                                                    isOpen: true,
                                                    entity_id: carid,
                                                    entity_type: 'car',
                                                    share_id: shareid,
                                                }
                                            )
                                        )
                                )
                            }
                            sharesLoading={sharesLoading}
                        />
                    )}
                    {activeTabId === 'recipients' && (
                        <MobileWrapper>
                            <SideSliderWithExit
                                direction={'right'}
                                initial="enter"
                                isVisible={
                                    addShareRecipientByEmailForm &&
                                    addShareRecipientByEmailForm
                                        ? true
                                        : false
                                }
                            >
                                {carid && (
                                    <AddShareByEmailFormMobile
                                        user={userLoggedIn}
                                        close={() => {
                                            dispatch(
                                                editFormsActions.toggleAddShareRecipientByEmail(
                                                    {
                                                        isOpen: false,
                                                    }
                                                )
                                            )
                                        }}
                                        share_id={shareBeingEdited.id}
                                        entity_id={carid}
                                        dispatch={dispatch}
                                    />
                                )}
                            </SideSliderWithExit>
                            <Container>
                                <Title>Choose your Share Recipient(s)</Title>
                                <SubTitle>
                                    {`Please search and select the recipient(s) that you would like to share your car with by entering their email.`}
                                </SubTitle>
                                <Padding24 />

                                <PrivateShareSearchManagerMobileV2
                                    share={shareBeingEdited}
                                    searchUsers={(p) => {
                                        dispatch(
                                            searchUsersActions.checkUserEmailExistsRequest(
                                                p
                                            )
                                        )
                                    }}
                                    searchResults={searchResults}
                                    resultsLoading={searchResultsLoading}
                                    addShareRecipient={(p) => {
                                        dispatch(
                                            carActions.addCarShareRecipientRequest(
                                                p
                                            )
                                        )
                                    }}
                                    selectedUsers={
                                        shareBeingEdited.all_share_recipients_ids_list
                                    }
                                    onClickInvite={() =>
                                        dispatch(
                                            editFormsActions.toggleAddShareRecipientByEmail(
                                                {
                                                    isOpen: true,
                                                }
                                            )
                                        )
                                    }
                                    inviteTxt="Invite to Custodian via Email & Share"
                                />

                                <Padding24 />
                            </Container>

                            <div style={{ paddingTop: '40px' }} />

                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    boxSizing: 'border-box',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <SharedWith>Direct share recipients</SharedWith>
                                <GreyIconButton
                                    onClick={() =>
                                        setIsSortBottomSheetOpen(false)
                                    }
                                    variant="sort"
                                    isActive={false}
                                    sortingCriteria={'by_date_added'}
                                />
                            </div>

                            <Padding10 />
                            {shareBeingEdited &&
                            shareBeingEdited.active_display &&
                            shareBeingEdited.active_display.item_ids_display &&
                            shareBeingEdited.active_display.item_ids_display
                                .length > 0 ? (
                                shareBeingEdited.share_recipients_data &&
                                shareBeingEdited.active_display.item_ids_display.map(
                                    (userid: string, index: number) => (
                                        <Faded>
                                            <ShareRowMobile
                                                recipient={
                                                    shareBeingEdited!
                                                        .share_recipients_data![
                                                        userid
                                                    ]
                                                }
                                                index={index}
                                                isLast={
                                                    shareBeingEdited.all_share_recipients_ids_list &&
                                                    shareBeingEdited
                                                        .all_share_recipients_ids_list
                                                        .length -
                                                        1 ===
                                                        index
                                                        ? true
                                                        : false
                                                }
                                                actionsData={{
                                                    email: (payload: {
                                                        recipient_id: string
                                                        recipient_email: string
                                                    }) => {
                                                        dispatch(
                                                            carActions.resendCarShareInviteRequest(
                                                                {
                                                                    invite_id:
                                                                        payload.recipient_id,
                                                                    recipient_email:
                                                                        payload.recipient_email,
                                                                }
                                                            )
                                                        )
                                                    },

                                                    delete: (payload: {
                                                        recipient_id: string
                                                        recipient_type:
                                                            | 'platform'
                                                            | 'external'
                                                    }) => {
                                                        return payload.recipient_type ===
                                                            'external'
                                                            ? dispatch(
                                                                  carActions.deleteCarShareInviteRequest(
                                                                      {
                                                                          share_id:
                                                                              shareBeingEdited.id,
                                                                          entity_id:
                                                                              shareBeingEdited.entity_id,
                                                                          invite_id:
                                                                              payload.recipient_id,
                                                                      }
                                                                  )
                                                              )
                                                            : dispatch(
                                                                  carActions.deleteCarShareRecipientRequest(
                                                                      {
                                                                          share_id:
                                                                              shareBeingEdited.id,
                                                                          entity_id:
                                                                              shareBeingEdited.entity_id,
                                                                          recipient_userid:
                                                                              payload.recipient_id,
                                                                      }
                                                                  )
                                                              )
                                                    },
                                                }}
                                                sharedOn={
                                                    shareBeingEdited!
                                                        .share_recipients_data![
                                                        userid
                                                    ].date_added_to_share
                                                }
                                            />
                                        </Faded>
                                    )
                                )
                            ) : (
                                <Faded>
                                    <NoIconDataRectangle
                                        text={`You haven't shared this with anyone yet.`}
                                    />
                                </Faded>
                            )}
                            <PrivateSharesSortingMobile
                                actions_items={[
                                    {
                                        id: 'added_at',
                                        text: 'By date added',
                                        action: () => {
                                            current &&
                                                dispatch(
                                                    carActions.sortShareRecipientsDisplay_change_request(
                                                        {
                                                            sortID: 'added_at',
                                                            entityid:
                                                                current?.id,
                                                            share: shareBeingEdited,
                                                        }
                                                    )
                                                )
                                        },
                                    },
                                    {
                                        id: 'alphabetical',
                                        text: 'Alphabetical',
                                        action: () => {
                                            current &&
                                                dispatch(
                                                    carActions.sortShareRecipientsDisplay_change_request(
                                                        {
                                                            sortID: 'alphabetical',
                                                            entityid:
                                                                current?.id,
                                                            share: shareBeingEdited,
                                                        }
                                                    )
                                                )
                                        },
                                    },
                                ]}
                                toggle={() => setIsSortBottomSheetOpen}
                                isPrivateSharesSortingOpen={
                                    isSortBottomSheetOpen
                                }
                                activeSort={
                                    shareBeingEdited.active_display &&
                                    shareBeingEdited.active_display.activeSort
                                        ? shareBeingEdited.active_display
                                              .activeSort
                                        : 'added_at'
                                }
                            />
                        </MobileWrapper>
                    )}
                    <div style={{ paddingTop: '150px' }} />
                </SideSlider>
            </IpadAndMobileDisplay>
        </React.Fragment>
    )
}

export default CarShareEdit
