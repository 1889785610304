import get_car_sagas from './getcar'
import create_car_sagas from './createCar'
import car_deletion_sagas from './deleteCar'
import update_car_general from './updateCarGeneral'
import get_garage_cars_data from './getGarageCarsData'
import get_external_car_data_by_registration_number from './getExternalCarDatByRegistrationNumber'
import handle_car_shares_display_change from './carSharesDisplayChange'
import public_car_shares_sagas from './getCarPublicShares'
import create_public_car_shares_sagas from './createCarPublicShare'
import delete_car_shares_sagas from './deletecarShare'
import handle_shares_recipients_display_change from './recipientsDisplayChange'
import all_shares_car_saga from './getAllSharesCar'
import owned_car_direct_shares from './getCarDirectShares'
import create_car_direct_share_sagas from './createCarDirectShare'
import car_share_recipient_sagas from './carShareRecipients'
import update_car_direct_share_sagas from './updateCarDirectShare'
import get_external_car_shared_with_you_sagas from './getExternalCarSharedWithYou'
import car_share_invite_sagas from './carShareInvites'
import car_archive_saga from './archiveCar'
import handover_creation_saga from './handoverCreation'
import handover_deletion_saga from './handoverDeletion'
import get_last_timeline_item_insurance from './getLastInsuranceEntryCar'
import get_external_car_data_by_registration_number_version2 from './getCarDetailsByRegNumberVersion2'
import set_empty_car_sagas from './setEmptyCar'
import create_car_sagas_version2 from './createCarVersion2'
import get_car_action_check from './getCarActionCheck'
import car_insights from './getCarInsights'
import create_showcase_car_short_url_sagas from './createQRcodeShortUrl'

let arr: any[] = [
    ...get_car_sagas,
    ...create_car_sagas,
    ...car_deletion_sagas,
    ...update_car_general,
    ...get_garage_cars_data,
    ...get_external_car_data_by_registration_number,
    ...handle_car_shares_display_change,
    ...public_car_shares_sagas,
    ...create_public_car_shares_sagas,
    ...delete_car_shares_sagas,
    ...handle_shares_recipients_display_change,
    ...all_shares_car_saga,
    ...owned_car_direct_shares,
    ...create_car_direct_share_sagas,
    ...car_share_recipient_sagas,
    ...update_car_direct_share_sagas,
    ...get_external_car_shared_with_you_sagas,
    ...car_share_invite_sagas,
    ...car_archive_saga,
    ...handover_creation_saga,
    ...handover_deletion_saga,
    ...get_last_timeline_item_insurance,
    ...get_external_car_data_by_registration_number_version2,
    ...set_empty_car_sagas,
    ...create_car_sagas_version2,
    ...get_car_action_check,
    ...car_insights,
    ...create_showcase_car_short_url_sagas,
]

export default arr
