import styled from 'styled-components'
import colours, { ITheme } from '../../../../providers/theme/colours'
import { timeline_category_data } from '../../../../redux/timeline/data'
import CarInsightsBarChart from '../../../atoms/carInsights/barChart'
import CarInsightsPieChart from '../../../atoms/carInsights/pieChart'
import CarInsightsTile from '../../../atoms/carInsights/tile'
import SortMenuDesktop from '../../../atoms/menu/sortMenu/sortMenuDesktop'
import { device } from '../../../templates/displays/devices'
import useWindowSize, {
    WindowSize,
} from '../../../templates/displays/windowSizeHook'
import CarInsightsMobilePlaceholder from '../placeholder/mobile'
import CarInsightsDesktopPlaceholder from '../placeholder/desktop'
import useThemes from '../../../../providers/theme/hooks'
import { useNavigate } from 'react-router-dom'
import { ITimelineEntryTypes } from '../../../../redux/timeline/types'

const Title = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato;
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 32px;
    z-index: 1;

    @media ${device.mobile_and_ipad} {
        font-size: 18px;
        margin-bottom: 20px;
        margin-top: 80px;
    }

    @media ${device.ipad} {
        margin-top: 90px;
    }

    @media ${device.smallest_laptops} {
        margin-top: 30px;
    }
`

const PaddingPlaceholder = styled.div`
    margin-top: 24px;

    @media ${device.mobile_and_ipad} {
        margin-top: 80px;
    }

    @media ${device.ipad} {
        margin-top: 90px;
    }

    @media ${device.smallest_laptops} {
        margin-top: 30px;
    }
`

const NavTitle = styled.div<{ $theme: ITheme }>`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px; /* 166.667% */
    text-transform: capitalize;
    transition: 100ms;
    cursor: pointer;
    :hover {
        color: ${(props) => colours[props.$theme].primary};
    }

    @media ${device.mobile_and_ipad} {
        font-size: 16px;
    }
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media (min-width: 1400px) {
        padding-right: 20px;
    }
`

const ContentWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    gap: 16px;

    @media ${device.beyond_ipad_mobile} {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
    }

    @media ${device.mobile_and_ipad} {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    @media (min-width: 881px) and (max-width: 900px) {
        gap: 12px;
    }
`

const TilesWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 16px;

    @media (min-width: 881px) and (max-width: 900px) {
        gap: 10px;
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media (min-width: 900px) and (max-width: 1400px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 10px;
    }

    @media ${device.mobile_and_ipad} {
        grid-template-columns: 1fr;
    }
`

const Row2 = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: fit-content;
    gap: 8px;
    margin-top: 32px;
`

const Row1 = styled.div`
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 24px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    max-width: 900px;

    @media ${device.mobile_and_ipad} {
        margin-top: 46px;
        margin-bottom: 16px;
        max-width: 500px;
    }

    z-index: 1;
`

const MenuTimeWrapper = styled.div`
    @media ${device.mobile_and_ipad} {
        transform: translateY(-5px);
    }
`

export type ICarInsightsResAPI_entry = {
    car_entry_type: ITimelineEntryTypes
    count: number
    cost_sum: number
}

export type ICarInsightsResAPI = {
    stats: {
        currency: string
        total_sum: number
        entries: ICarInsightsResAPI_entry[]
    }
}

type Props = {
    activeCategory?: string | null
    hasCost: boolean
}

const optionsMenuData = [
    {
        uid: 'monthly',
        name: 'Monthly',
    },
    {
        uid: 'yearly',
        name: 'Yearly',
    },
]

const CarInsightsBody = (props: Props) => {
    const navigate = useNavigate()
    const size: WindowSize = useWindowSize()
    const { theme } = useThemes()

    // let isBiggerDesktopBreakpoint =
    //     size && size.width && size.width > 1200 ? true : false

    let isMobile = size && size.width && size.width < 800 ? true : false

    // let p1: string[] =
    //     isBiggerDesktopBreakpoint || isMobile
    //         ? Object.keys(timeline_category_data)
    //         : Object.keys(timeline_category_data).slice(0, 6)

    // let p2: string[] | undefined =
    //     isBiggerDesktopBreakpoint || isMobile
    //         ? undefined
    //         : Object.keys(timeline_category_data).slice(6, 8)

    let currURL = new URL(window.location.href)
    let params2 = new URLSearchParams(currURL.search)
    let selectedIDName =
        params2.get('time_range') === 'year'
            ? 'this year'
            : params2.get('time_range') === 'last_year'
            ? 'last year'
            : params2.get('time_range') === 'all_time'
            ? 'all time'
            : params2.get('time_range') === 'custom'
            ? ''
            : 'this year'
    return (
        <Wrapper>
            {props.activeCategory ? (
                <>
                    <Row1>
                        <Row2
                            role={'button'}
                            onClick={() => {
                                params2.delete('representation')
                                params2.delete('categoryID')
                                navigate(
                                    {
                                        search: params2.toString(),
                                    },
                                    { replace: true }
                                )
                            }}
                        >
                            <svg
                                width={isMobile ? '18' : '24'}
                                height={isMobile ? '18' : '24'}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M9.56158 12.0001L16.0312 5.53039L14.9706 4.46973L7.44026 12.0001L14.9706 19.5304L16.0312 18.4697L9.56158 12.0001Z"
                                    fill={colours[theme].text_strong}
                                />
                            </svg>
                            <NavTitle $theme={theme}>
                                {props.activeCategory === 'all'
                                    ? 'In-Depth Cost Analysis'
                                    : props.activeCategory?.toLowerCase() ===
                                      'others'
                                    ? 'other'
                                    : props.activeCategory}
                            </NavTitle>
                        </Row2>

                        <MenuTimeWrapper style={{ alignSelf: 'flex-end' }}>
                            <SortMenuDesktop
                                dropdownData={optionsMenuData}
                                currentOption={
                                    params2.get('representation') === 'yearly'
                                        ? 'yearly'
                                        : 'monthly'
                                }
                                onSelected={(v: string) => {
                                    let currURL = new URL(window.location.href)
                                    let params2 = new URLSearchParams(
                                        currURL.search
                                    )
                                    params2.delete('representation')
                                    params2.append('representation', v)

                                    navigate(
                                        {
                                            search: params2.toString(),
                                        },
                                        { replace: true }
                                    )
                                }}
                            />
                        </MenuTimeWrapper>
                    </Row1>

                    <CarInsightsBarChart categoryID={props.activeCategory} />
                </>
            ) : (
                <>
                    {props.hasCost ? (
                        <Title>Costs Breakdown {selectedIDName}</Title>
                    ) : (
                        <PaddingPlaceholder />
                    )}

                    {props.hasCost ? (
                        <>
                            <ContentWrapper>
                                <CarInsightsPieChart />
                                <TilesWrapper>
                                    {Object.keys(timeline_category_data).map(
                                        (keyID: string, index) => {
                                            return (
                                                <CarInsightsTile
                                                    index={index}
                                                    keyID={keyID}
                                                />
                                            )
                                        }
                                    )}
                                </TilesWrapper>
                            </ContentWrapper>

                            {/* <div style={{ paddingTop: '16px' }} />

                            <ContentWrapper>
                                <TilesWrapper>
                                    {p2 &&
                                        p2.map((keyID, index) => {
                                            return (
                                                <CarInsightsTile
                                                    index={index}
                                                    keyID={keyID}
                                                />
                                            )
                                        })}
                                </TilesWrapper>
                            </ContentWrapper> */}
                        </>
                    ) : (
                        <>
                            <CarInsightsDesktopPlaceholder />
                            <CarInsightsMobilePlaceholder />
                        </>
                    )}
                </>
            )}
        </Wrapper>
    )
}

export default CarInsightsBody
