import React, { useEffect, useRef, useState } from 'react'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import UserBanner from '../../atoms/user/userBanner'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import ViewsMenu from '../../atoms/menu/viewsMenu/viewsMenu'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import UserTagBox from '../../atoms/user/userTagBox'
import MobileContentBody from '../../templates/displays/pageWrappers/MobileContentBody'
import { Paragraph } from '../../atoms/typography'
import ViewsMenuMobile from '../../atoms/menu/viewsMenu/viewsMenuMobile'
import { useNavigate, useLocation } from 'react-router-dom'
import Loader from '../../atoms/loader/loader'
import DesktopGarageViewManager from '../../organisms/garageViewManager/desktopGarageViewManager'
import { editFormsActions } from '../../../redux/editForms/reducer'
import ModalDisplay from '../../templates/displays/pageWrappers/modalDisplay'
import AddACarFormDesktop from '../../organisms/editForms/addACarForm/addACarFormDesktop'
import AddACarFormMobile from '../../organisms/editForms/addACarForm/addACarFormMobile'
import TutorialConfigsGarageDesktop from '../../molecules/tutorialConfigs/garage/tutorialGarageConfigDesktop'
import TutorialConfigsGarageMobile from '../../molecules/tutorialConfigs/garage/tutorialsConfigGarageMobile'
import MobileGarageViewManager from '../../organisms/garageViewManager/mobileGarageViewManager'
// import AndroidInstallPwaInstructionsPropmt from '../../templates/pwaCustom/androidInstallPwaInstructionsPropmt'
// import { getCookie } from '../../../redux/services/authcheck'
import QrCodeBtn from '../../atoms/Button/qrCodeBtn'
import NativeAppPromptDesktop from '../../molecules/nativeAppPrompt/nativeAppPromptDesktop'
import NativeAppPromptMobile from '../../molecules/nativeAppPrompt/nativeAppPromptMobile'
import SortMenuDesktop from '../../atoms/menu/sortMenu/sortMenuDesktop'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { dropDownActions } from '../../../redux/localdata/dropdownData/reducer'

import useWindowSize, {
    WindowSize,
} from '../../templates/displays/windowSizeHook'
import { CardSkeletonItems } from '../showroom/showroom'
import IconButton from '../../atoms/Button/iconButton'
import { Tooltip } from 'antd'
import HandoverStatesBanner from '../../templates/bars/handover/handoverStatesBannerDesktop'
import Faded from '../../templates/animated/faded'
import Expander from '../../atoms/expander/expander'
import FadedSlower from '../../templates/animated/FadedSlower'
import JourneyRedirectCardMobile from '../../molecules/cards/journey/redirectCards/redirectCardMobile'
import { journeyRedirectCardsCopy } from '../../../redux/localdata/journey/copyData'
import useThemes from '../../../providers/theme/hooks'
import colours from '../../../providers/theme/colours'
import {
    IGarage,
    IGarageActiveSort,
} from '../../../redux/entities/garages/types'
import { usersActions } from '../../../redux/user/reducer'
import { garagesActions } from '../../../redux/entities/garages/reducer'
import { tutorialsActions } from '../../../redux/localdata/tutorialsCheck/reducer'

const GarageMenuContainer = styled.section`
    @media ${device.beyond_ipad_mobile} {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-top: 42px;
        padding-bottom: 42px;
        width: 100%;
        box-sizing: border-box;
    }
`

const IpadWrapPadding = styled.div`
    @media ${device.ipad} {
        padding-top: 30px;
    }
`

const IpadWrapPadding06 = styled.div`
    @media ${device.ipad} {
        padding-top: 6px;
    }
`

export type IGarageSortOptionsData = {
    uid:
        | 'OLDEST'
        | 'MOST_RECENT'
        | 'MANUFACTURE_DATE_ASCENDING'
        | 'MANUFACTURE_DATE_DESCENDING'
        | 'MAKE_ASCENDING'
        | 'MAKE_DESCENDING'
        | 'CUSTOM_ORDER'

    name: string
}

export const garageSortDataOptions: IGarageSortOptionsData[] = [
    {
        uid: 'MOST_RECENT',
        name: 'Date Added: Most recent',
    },

    {
        uid: 'OLDEST',
        name: 'Date Added: Oldest',
    },

    {
        uid: 'MANUFACTURE_DATE_DESCENDING',
        name: 'Age: Newest',
    },
    {
        uid: 'MANUFACTURE_DATE_ASCENDING',
        name: 'Age: Oldest',
    },

    {
        uid: 'MAKE_ASCENDING',
        name: 'Make: Ascending',
    },
    {
        uid: 'MAKE_DESCENDING',
        name: 'Make: Descending',
    },
]

const MyGarage = () => {
    const [hideProfileCard, setHideProfileCard] = useState<boolean>(false)
    const [activeView, setActiveView] = useState('grid_view')

    let garageInnerRefDesktop = useRef<HTMLDivElement | null>(null)
    let garageInnerRefMobile = useRef<HTMLDivElement | null>(null)

    const dispatch = useAppDispatch()

    const garage_id = useAppSelector((state) => {
        return state.user.userLoggedIn?.owns_garage?.uid
    })

    const garage_data = useAppSelector((state) => {
        return state.entities.garagesData.garages
    })

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    const garage_active_sort = useAppSelector((state) => {
        return state.entities.garagesData.activeSort
    })

    const isGarageLoading = useAppSelector((state) => {
        return state.entities.garagesData.loading
    })
    const isCarLoading = useAppSelector((state) => {
        return state.entities.carsData.loading
    })
    const isUserLoading = useAppSelector((state) => {
        return state.user.loading
    })
    const isAddACarFormOpened = useAppSelector((state) => {
        return state.editForms.formsData.addCarForm.isOpen
    })
    const countriesdropdownlist = useAppSelector((state) => {
        return state.localdata.dropdownData.countriesCode
    })
    const imagesData = useAppSelector((state) => {
        return state.entities.galleriesData.images
    })
    const tutorialsCheck = useAppSelector((state) => {
        return (
            state.localdata.tutorials_check_state &&
            state.localdata.tutorials_check_state
        )
    })

    const total_cars_number_found = useAppSelector((state) => {
        return state.entities.garagesData.total_cars_number_found
    })

    let current: IGarage | undefined =
        garage_id && garage_data && garage_data[garage_id]
            ? garage_data[garage_id]
            : undefined

    let numberOfCarsInSortedIDS: number | undefined =
        current && current.sorted_car_ids
            ? current.sorted_car_ids.length
            : undefined

    // get user and first cars batch if not already there
    useEffect(() => {
        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        } else {
            if (
                total_cars_number_found === undefined ||
                numberOfCarsInSortedIDS === undefined
            ) {
                dispatch(
                    garagesActions.setGarageActiveSortingRequest({
                        garageid: userLoggedIn.owns_garage?.uid,
                        activeSort: garage_active_sort ?? 'MOST_RECENT',
                    })
                )
            }
        }
    }, [userLoggedIn])

    // getting public shares, handovers
    useEffect(() => {
        if (
            userLoggedIn &&
            garage_id &&
            (!garage_data[garage_id] ||
                (!garage_data[garage_id].public_share_owned &&
                    garage_data[garage_id].public_share_owned !== null))
        ) {
            dispatch(garagesActions.getPublicSharesGarageRequest(garage_id))
        }

        if (userLoggedIn && !userLoggedIn.handovers) {
            dispatch(usersActions.listUserHandoversRequest())
        }
    }, [userLoggedIn])

    const location = useLocation()
    const params = new URLSearchParams(location.search)

    // garage view
    useEffect(() => {
        if (userLoggedIn) {
            dispatch(dropDownActions.reset_make_model_temps())

            const user_garage_view_pref =
                userLoggedIn &&
                userLoggedIn?.preferences?.data?.garage?.data[
                    'default_garage_view'
                ].user_choice_id

            if (user_garage_view_pref && user_garage_view_pref === 'grid') {
                setActiveView('grid_view')
            } else if (
                user_garage_view_pref &&
                user_garage_view_pref === 'list'
            ) {
                setActiveView('banner_view')
            }
        }

        if (params.get('onboarding') === 'true') {
            dispatch(tutorialsActions.setIsFirstSessionSuccess())
        }

        if (
            userLoggedIn &&
            params.get('onboarding') !== 'true' &&
            params.get('native_app_prompt') !== 'true' &&
            tutorialsCheck.isFirstSession === true &&
            tutorialsCheck.tutorialsCheckByPageId.garage === false
        ) {
            navigate(`/garage?tutorial=true`)
        }

        if (params.get('action') === 'add_car') {
            let addACar = () => {
                if (!countriesdropdownlist) {
                    dispatch(
                        dropDownActions.setCountriesCodeDropdownDataRequest()
                    )
                }

                dispatch(editFormsActions.toggleAddACarForm(true))
            }
            setTimeout(addACar, 200)
        }

        return () => {
            setActiveView('grid_view')
        }
    }, [userLoggedIn])

    const navigate = useNavigate()

    const checkIfIsLast = () => {
        if (total_cars_number_found === undefined) {
            return false
        } else if (numberOfCarsInSortedIDS === undefined) {
            return false
        } else if (numberOfCarsInSortedIDS < total_cars_number_found) {
            return false
        } else return true
    }

    let allCarsHaveBeenFetched = checkIfIsLast() ? true : false

    const onScroll = (ref: React.RefObject<HTMLDivElement>) => {
        if (allCarsHaveBeenFetched === false && userLoggedIn) {
            if (!isGarageLoading && !isCarLoading) {
                if (ref.current) {
                    let { scrollTop, scrollHeight, clientHeight } = ref.current

                    let added = scrollTop + clientHeight
                    if (Math.trunc(added) >= Math.trunc(scrollHeight - 5)) {
                        dispatch(
                            garagesActions.setGarageActiveSortingRequest({
                                garageid: userLoggedIn.owns_garage?.uid,
                                activeSort: garage_active_sort ?? 'MOST_RECENT',
                            })
                        )
                    }
                }
            }
        }
    }

    const onHandleSortingSelect = (v: IGarageActiveSort) => {
        garage_id && dispatch(garagesActions.resetSortedCarsInGarage(garage_id))

        garage_id &&
            dispatch(
                garagesActions.setGarageActiveSortingRequest({
                    garageid: garage_id,
                    activeSort: v,
                })
            )
    }

    let numberOfTasksInGarage: number = userLoggedIn?.total_tasks_count ?? 0

    let accountInternalInfoList = userLoggedIn && {
        profile_image_url: userLoggedIn.profile_image_url
            ? userLoggedIn.profile_image_url
            : null,

        email: userLoggedIn.email,
        display_name: userLoggedIn.display_name
            ? userLoggedIn.display_name
            : `${userLoggedIn.given_name} ${userLoggedIn.family_name}`,
        one_liner: userLoggedIn.one_liner ? userLoggedIn.one_liner : '',

        location: `${
            userLoggedIn.address && userLoggedIn.address.locality
                ? `${userLoggedIn.address.locality}, `
                : 'Unknown'
        } ${
            userLoggedIn.address && userLoggedIn.address.country
                ? `${userLoggedIn.address.country}`
                : ''
        }`,
        address: {
            id: 'address',
            text: 'address',
            answer: `${
                userLoggedIn.address && userLoggedIn.address.street_1
                    ? userLoggedIn.address.street_1
                    : ''
            } ${
                userLoggedIn.address && userLoggedIn.address.locality
                    ? userLoggedIn.address.locality
                    : ''
            } 
            ${
                userLoggedIn.address && userLoggedIn.address.postcode
                    ? userLoggedIn.address.postcode
                    : ''
            } ${
                userLoggedIn.address && userLoggedIn.address.country
                    ? userLoggedIn.address.country
                    : ''
            }
            
            `,
        },
    }

    let addACar = () => {
        dispatch(dropDownActions.setCountriesCodeDropdownDataRequest())
        dispatch(editFormsActions.toggleAddACarForm(true))
    }

    let garage =
        userLoggedIn &&
        userLoggedIn.owns_garage &&
        userLoggedIn.owns_garage.uid &&
        garage_data &&
        garage_data[userLoggedIn.owns_garage.uid]

    let has_garage_share: boolean =
        garage &&
        garage.public_share_owned &&
        garage.public_share_owned.isActive
            ? garage.public_share_owned.isActive
            : false

    let activeSorting: IGarageActiveSort = garage_active_sort

    const size: WindowSize = useWindowSize()
    let isMobile = size && size.width && size.width < 880 ? true : false

    const [tooltipVisible, setTooltipVisible] = useState<string | undefined>(
        undefined
    )

    let isFailedHandoverHidden: boolean | undefined = useAppSelector(
        (state) => {
            return state.localdata.customNavData.hide_handovers_failed
        }
    )

    let isProcessingHandoverHidden: boolean | undefined = useAppSelector(
        (state) => {
            return state.localdata.customNavData.hide_handovers_processing
        }
    )

    let hasHandoversBanner = () => {
        let res: boolean = false

        if (userLoggedIn && userLoggedIn?.handovers) {
            if (
                isProcessingHandoverHidden !== true &&
                userLoggedIn?.handovers?.processing &&
                userLoggedIn?.handovers?.processing?.length > 0
            ) {
                res = true
            }

            if (
                isFailedHandoverHidden !== true &&
                userLoggedIn.handovers.failed &&
                userLoggedIn.handovers.failed?.length > 0
            ) {
                res = true
            }
        }

        return res
    }

    const { theme } = useThemes()

    return (
        <div
            ref={isMobile ? garageInnerRefMobile : garageInnerRefDesktop}
            style={{
                height: '100vh',
                // overflowY: 'scroll',
                overflowX: 'hidden',
            }}
            onScroll={() => {
                if (isMobile) {
                    onScroll(garageInnerRefMobile)
                } else {
                    onScroll(garageInnerRefDesktop)
                }
            }}
        >
            {/* {!params.get('id') &&
                params.get('tutorial') !== 'true' &&
                getCookie('android_install_prompt') !== 'seen' && (
                    <AndroidInstallPwaInstructionsPropmt />
                )} */}
            {params.get('native_app_prompt') === 'true' && (
                <>
                    <NativeAppPromptDesktop
                        onClose={() => navigate(`/garage?tutorial=true`)}
                    />

                    <NativeAppPromptMobile
                        onClose={() => navigate(`/garage?tutorial=true`)}
                        isOpen={params.get('native_app_prompt') === 'true'}
                    />
                </>
            )}
            <CenteredPageWrapper removePaddingBottom removeHorizPaddings>
                {userLoggedIn &&
                    userLoggedIn?.handovers &&
                    hasHandoversBanner() && (
                        <Faded>
                            <HandoverStatesBanner
                                handoversObj={userLoggedIn.handovers}
                            />
                        </Faded>
                    )}

                {<Loader isLoading={isUserLoading} />}

                {current !== null &&
                    current !== undefined &&
                    garage_id &&
                    userLoggedIn &&
                    accountInternalInfoList && (
                        <React.Fragment>
                            <DesktopDisplayOnly>
                                <div style={{ paddingLeft: 50 }}>
                                    <div style={{ paddingTop: '16px' }} />
                                    {params.get('tutorial') === 'true' && (
                                        <TutorialConfigsGarageDesktop
                                            registerGarageTutorial={() =>
                                                dispatch(
                                                    tutorialsActions.manageUserTutorialByIdCheck(
                                                        'garage'
                                                    )
                                                )
                                            }
                                        />
                                    )}
                                    {params.get('tutorial') === 'true' && (
                                        <>
                                            <div
                                                style={{
                                                    marginTop: '-200px',
                                                }}
                                                data-tut="first-step-garage-desktop"
                                            />
                                            <div
                                                style={{
                                                    paddingTop: '200px',
                                                }}
                                            />
                                        </>
                                    )}

                                    <UserBanner
                                        garage_id={garage_id}
                                        car_number={
                                            total_cars_number_found
                                                ? total_cars_number_found
                                                : 0
                                        }
                                        user={accountInternalInfoList}
                                        outstanding_tasks_number={
                                            numberOfTasksInGarage
                                        }
                                        linkToAddingCar={addACar}
                                        linkToAddingLocation={() => {
                                            navigate(
                                                '/journey/your-profile?step=address'
                                            )

                                            // dispatch(
                                            //     editFormsActions.toggleAccounPrivateAddressEditForm()
                                            // )
                                        }}
                                        linkToAddingTasks={() => {
                                            navigate('/tasks')

                                            dispatch(
                                                editFormsActions.toggleCreateTaskForm()
                                            )
                                        }}
                                    />

                                    <GarageMenuContainer>
                                        <div data-tut="second-step-garage-desktop">
                                            <ViewsMenu
                                                toggleMenu={(id: string) =>
                                                    setActiveView(id)
                                                }
                                                activeView={activeView}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {has_garage_share ? (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <div
                                                        onMouseEnter={() =>
                                                            setTooltipVisible(
                                                                'qr_code'
                                                            )
                                                        }
                                                        onMouseLeave={() =>
                                                            setTooltipVisible(
                                                                undefined
                                                            )
                                                        }
                                                    >
                                                        <Tooltip
                                                            title={() => (
                                                                <span
                                                                    style={{
                                                                        color: colours[
                                                                            theme
                                                                        ]
                                                                            .background_default,
                                                                    }}
                                                                >
                                                                    Garage QR
                                                                    code
                                                                </span>
                                                            )}
                                                            open={
                                                                tooltipVisible ===
                                                                'qr_code'
                                                            }
                                                            placement="top"
                                                            color={
                                                                colours[theme]
                                                                    .primary
                                                            }
                                                        >
                                                            <QrCodeBtn
                                                                txt="qr code"
                                                                height="48px"
                                                                width="48px"
                                                                bgColor={
                                                                    colours[
                                                                        theme
                                                                    ]
                                                                        .background_default
                                                                }
                                                                borderColor={
                                                                    colours[
                                                                        theme
                                                                    ].primary
                                                                }
                                                                iconSize="25px"
                                                                neverHasText
                                                                onClick={() =>
                                                                    navigate(
                                                                        `/sharing/with-others?entity_id=garage&action=show_qr_code`
                                                                    )
                                                                }
                                                            />
                                                        </Tooltip>
                                                    </div>
                                                    <div
                                                        style={{
                                                            paddingRight:
                                                                '16px',
                                                        }}
                                                    />

                                                    <IconButton
                                                        buttonheight="48px"
                                                        buttonwidth="48px"
                                                        bgcolor="transparent"
                                                        borderColor={
                                                            colours[theme]
                                                                .primary
                                                        }
                                                        icon={'new_share_link'}
                                                        onClick={() => {
                                                            garage &&
                                                                garage.public_share_owned &&
                                                                garage
                                                                    .public_share_owned
                                                                    .url &&
                                                                navigator.clipboard.writeText(
                                                                    garage
                                                                        .public_share_owned
                                                                        .url
                                                                )
                                                            setTooltipVisible(
                                                                'public_link_copied'
                                                            )
                                                        }}
                                                        hasTooltip
                                                        tooltipColor={
                                                            colours[theme]
                                                                .primary
                                                        }
                                                        tooltipTextColor={
                                                            colours[theme]
                                                                .background_default
                                                        }
                                                        tooltipText={
                                                            tooltipVisible ===
                                                            'public_link_copied'
                                                                ? 'Copied!'
                                                                : 'Copy Garage Public Link'
                                                        }
                                                        isTooltipVisible={
                                                            tooltipVisible ===
                                                                'public_link' ||
                                                            tooltipVisible ===
                                                                'public_link_copied'
                                                        }
                                                        onMouseEnter={() =>
                                                            setTooltipVisible(
                                                                'public_link'
                                                            )
                                                        }
                                                        onMouseLeave={() =>
                                                            setTooltipVisible(
                                                                undefined
                                                            )
                                                        }
                                                    />
                                                </div>
                                            ) : (
                                                <IconButton
                                                    buttonheight="48px"
                                                    buttonwidth="48px"
                                                    bgcolor="transparent"
                                                    borderColor={
                                                        colours[theme].primary
                                                    }
                                                    icon={'new_share_link'}
                                                    onClick={() =>
                                                        navigate(
                                                            `/sharing/with-others?entity_id=garage`
                                                        )
                                                    }
                                                    hasTooltip
                                                    tooltipColor={
                                                        colours[theme].primary
                                                    }
                                                    tooltipText={
                                                        'Create Public Link'
                                                    }
                                                    tooltipTextColor={
                                                        colours[theme]
                                                            .background_default
                                                    }
                                                    isTooltipVisible={
                                                        tooltipVisible ===
                                                        'public_link'
                                                    }
                                                    onMouseEnter={() =>
                                                        setTooltipVisible(
                                                            'public_link'
                                                        )
                                                    }
                                                    onMouseLeave={() =>
                                                        setTooltipVisible(
                                                            undefined
                                                        )
                                                    }
                                                />
                                            )}
                                            <div
                                                style={{
                                                    paddingLeft: '16px',
                                                }}
                                            />

                                            <Tooltip
                                                title={() => (
                                                    <span
                                                        style={{
                                                            color: colours[
                                                                theme
                                                            ]
                                                                .background_default,
                                                        }}
                                                    >
                                                        Sort your cars
                                                    </span>
                                                )}
                                                open={tooltipVisible === 'sort'}
                                                placement="top"
                                                color={colours[theme].primary}
                                            >
                                                <SortMenuDesktop
                                                    iconBtn
                                                    btnHeight="48px"
                                                    dropdownWidth={260}
                                                    hasSelectedCheck
                                                    dropdownData={
                                                        garageSortDataOptions
                                                    }
                                                    currentOption={
                                                        activeSorting ??
                                                        undefined
                                                    }
                                                    onSelected={(v: string) => {
                                                        onHandleSortingSelect(
                                                            v as keyof IGarageActiveSort
                                                        )
                                                    }}
                                                    onMouseEnter={() =>
                                                        setTooltipVisible(
                                                            'sort'
                                                        )
                                                    }
                                                    onMouseLeave={() =>
                                                        setTooltipVisible(
                                                            undefined
                                                        )
                                                    }
                                                />
                                            </Tooltip>

                                            <div
                                                style={{
                                                    paddingLeft: '16px',
                                                }}
                                            />

                                            <IconButton
                                                buttonheight="48px"
                                                buttonwidth="48px"
                                                bgcolor={colours[theme].primary}
                                                borderColor={
                                                    colours[theme].primary
                                                }
                                                icon="add_plus_white"
                                                hasTooltip
                                                tooltipColor={
                                                    colours[theme].primary
                                                }
                                                tooltipText="Add car"
                                                tooltipTextColor={
                                                    colours[theme]
                                                        .background_default
                                                }
                                                isTooltipVisible={
                                                    tooltipVisible === 'add_car'
                                                }
                                                onMouseEnter={() =>
                                                    setTooltipVisible('add_car')
                                                }
                                                onMouseLeave={() =>
                                                    setTooltipVisible(undefined)
                                                }
                                                onClick={() => {
                                                    addACar()
                                                }}
                                                dataPH={'add_car'}
                                            />
                                        </div>
                                    </GarageMenuContainer>

                                    <DesktopGarageViewManager
                                        activeView={activeView}
                                        carsIdList={
                                            current && current.sorted_car_ids
                                                ? current.sorted_car_ids
                                                : []
                                        }
                                        addCarForm={addACar}
                                        imagesData={imagesData}
                                        dontShowAddBox={!allCarsHaveBeenFetched}
                                        dataLoading={
                                            isGarageLoading || isCarLoading
                                        }
                                    />

                                    {(isGarageLoading || isCarLoading) &&
                                        !checkIfIsLast() &&
                                        (activeView === 'grid_view' ? (
                                            <div
                                                style={{
                                                    width: '100%',
                                                    boxSizing: 'border-box',
                                                    display: 'grid',
                                                    gridTemplateColumns:
                                                        '1fr 1fr',
                                                    gridGap: 5,
                                                }}
                                            >
                                                <CardSkeletonItems
                                                    height="35vh"
                                                    width="100%"
                                                    paddingBottom="0px"
                                                    narr={[1, 2, 3, 4, 5, 6]}
                                                />
                                            </div>
                                        ) : (
                                            <div
                                                style={{
                                                    width: '100%',
                                                    boxSizing: 'border-box',
                                                    display: 'grid',
                                                    gridTemplateColumns: '1fr',
                                                    gridGap: 5,
                                                }}
                                            >
                                                <CardSkeletonItems
                                                    height="40vh"
                                                    width="100%"
                                                    paddingBottom="0px"
                                                    narr={[1, 2, 3, 4]}
                                                />
                                            </div>
                                        ))}

                                    {isAddACarFormOpened &&
                                        countriesdropdownlist && (
                                            <ModalDisplay
                                                isOpen={isAddACarFormOpened}
                                                toggle={() =>
                                                    dispatch(
                                                        editFormsActions.toggleAddACarForm(
                                                            false
                                                        )
                                                    )
                                                }
                                            >
                                                <AddACarFormDesktop
                                                    countriesdropdownlist={
                                                        countriesdropdownlist
                                                    }
                                                    userCountry={
                                                        userLoggedIn.address &&
                                                        userLoggedIn.address
                                                            .country
                                                    }
                                                    close={() =>
                                                        dispatch(
                                                            editFormsActions.toggleAddACarForm(
                                                                false
                                                            )
                                                        )
                                                    }
                                                    garage_id={garage_id}
                                                    numberOfCarsInGarage={
                                                        numberOfCarsInSortedIDS ??
                                                        0
                                                    }
                                                    dataCyId={
                                                        'addCar-form-desktop'
                                                    }
                                                    dispatch={dispatch}
                                                    navigate={navigate}
                                                />
                                            </ModalDisplay>
                                        )}
                                </div>
                                {allCarsHaveBeenFetched && (
                                    <div style={{ paddingBottom: '200px' }} />
                                )}
                            </DesktopDisplayOnly>

                            {!params.get('id') && (
                                <IpadAndMobileDisplay>
                                    {params.get('tutorial') === 'true' && (
                                        <TutorialConfigsGarageMobile
                                            registerGarageTutorial={() =>
                                                dispatch(
                                                    tutorialsActions.manageUserTutorialByIdCheck(
                                                        'garage'
                                                    )
                                                )
                                            }
                                        />
                                    )}

                                    {params.get('tutorial') === 'true' && (
                                        <>
                                            <div
                                                style={{
                                                    marginTop: '-100px',
                                                }}
                                                data-tut="first-step-garage-mobile"
                                            />
                                            <div
                                                style={{
                                                    paddingTop: '100px',
                                                }}
                                            />
                                        </>
                                    )}

                                    {!hasHandoversBanner() && (
                                        <div
                                            style={{
                                                paddingTop: '40px',
                                            }}
                                        />
                                    )}
                                    <IpadWrapPadding>
                                        <UserTagBox
                                            fullWidth={true}
                                            user={accountInternalInfoList}
                                            car_number={
                                                total_cars_number_found
                                                    ? total_cars_number_found
                                                    : 0
                                            }
                                            hideTasksCount={true}
                                            linkToAddingCar={addACar}
                                            linkToAddingLocation={() => {
                                                navigate(
                                                    '/journey/your-profile?step=address'
                                                )
                                                // dispatch(
                                                //     editFormsActions.toggleAccounPrivateAddressEditForm()
                                                // )
                                            }}
                                        />
                                    </IpadWrapPadding>

                                    <MobileContentBody>
                                        {userLoggedIn.one_liner &&
                                        userLoggedIn.one_liner?.length > 1 ? (
                                            <Paragraph
                                                isSmaller={true}
                                                align="left"
                                                ipadAlign="left"
                                                removeIpadPadding={true}
                                            >
                                                <span
                                                    style={{
                                                        whiteSpace: 'pre-line',
                                                    }}
                                                >
                                                    {userLoggedIn.one_liner}
                                                </span>
                                            </Paragraph>
                                        ) : (
                                            <Expander
                                                height={
                                                    hideProfileCard ? 0 : 'auto'
                                                }
                                                width="100%"
                                            >
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        boxSizing: 'border-box',
                                                    }}
                                                >
                                                    <FadedSlower>
                                                        <JourneyRedirectCardMobile
                                                            dataPH="start_your_profile_journey"
                                                            sectionID="your_profile"
                                                            copyData={
                                                                journeyRedirectCardsCopy
                                                            }
                                                            onStartNow={() => {
                                                                navigate(
                                                                    `/journey/your-profile?step=welcome`,
                                                                    {
                                                                        state: {
                                                                            prevPath: `${
                                                                                location.pathname
                                                                            }${
                                                                                location.search ??
                                                                                ''
                                                                            }`,
                                                                        },
                                                                    }
                                                                )
                                                            }}
                                                            onDismiss={() => {
                                                                setHideProfileCard(
                                                                    true
                                                                )
                                                            }}
                                                        />
                                                    </FadedSlower>
                                                </div>
                                            </Expander>
                                        )}
                                    </MobileContentBody>

                                    <div style={{ paddingTop: '24px' }} />

                                    <IpadWrapPadding06 />

                                    <div data-tut="second-step-garage-mobile">
                                        <ViewsMenuMobile
                                            toggleMenu={(id: string) =>
                                                setActiveView(id)
                                            }
                                            activeView={activeView}
                                            addACar={() => {
                                                dispatch(
                                                    dropDownActions.setCountriesCodeDropdownDataRequest()
                                                )
                                                dispatch(
                                                    editFormsActions.toggleAddACarForm(
                                                        true
                                                    )
                                                )
                                            }}
                                            sortData={garageSortDataOptions}
                                            currentSortOption={
                                                activeSorting ?? undefined
                                            }
                                            onSort={(v: string) => {
                                                onHandleSortingSelect(
                                                    v as keyof IGarageActiveSort
                                                )
                                            }}
                                        />
                                    </div>

                                    <div style={{ paddingTop: '30px' }} />

                                    <MobileGarageViewManager
                                        activeView={activeView}
                                        addCarForm={() => {
                                            dispatch(
                                                editFormsActions.toggleAddACarForm(
                                                    true
                                                )
                                            )
                                        }}
                                        carsIdList={
                                            current && current.sorted_car_ids
                                                ? current.sorted_car_ids
                                                : []
                                        }
                                        getDropdownList={() => {
                                            dispatch(
                                                dropDownActions.setCountriesCodeDropdownDataRequest()
                                            )
                                        }}
                                        imagesData={imagesData}
                                        dontShowAddBox={!allCarsHaveBeenFetched}
                                        dataLoading={
                                            isGarageLoading || isCarLoading
                                        }
                                    />

                                    {(isGarageLoading || isCarLoading) &&
                                        !checkIfIsLast() &&
                                        (activeView === 'grid_view' ? (
                                            <div
                                                style={{
                                                    width: '100%',
                                                    boxSizing: 'border-box',
                                                    display: 'grid',
                                                    gridTemplateColumns:
                                                        '1fr 1fr',
                                                    gridGap: 5,
                                                }}
                                            >
                                                <CardSkeletonItems
                                                    height="25vh"
                                                    width="100%"
                                                    paddingBottom="0px"
                                                    narr={[1, 2, 3, 4, 5, 6]}
                                                />
                                            </div>
                                        ) : (
                                            <div
                                                style={{
                                                    width: '100%',
                                                    boxSizing: 'border-box',
                                                    display: 'grid',
                                                    gridTemplateColumns: '1fr',
                                                    gridGap: 5,
                                                }}
                                            >
                                                <CardSkeletonItems
                                                    height="50vh"
                                                    width="100%"
                                                    paddingBottom="0px"
                                                    narr={[1, 2, 3, 4]}
                                                />
                                            </div>
                                        ))}

                                    {isAddACarFormOpened &&
                                        countriesdropdownlist && (
                                            <ModalDisplay
                                                isOpen={isAddACarFormOpened}
                                                toggle={() =>
                                                    dispatch(
                                                        editFormsActions.toggleAddACarForm(
                                                            false
                                                        )
                                                    )
                                                }
                                            >
                                                <AddACarFormMobile
                                                    countriesdropdownlist={
                                                        countriesdropdownlist
                                                    }
                                                    userCountry={
                                                        userLoggedIn.address &&
                                                        userLoggedIn.address
                                                            .country
                                                    }
                                                    close={() => {
                                                        dispatch(
                                                            editFormsActions.toggleAddACarForm(
                                                                false
                                                            )
                                                        )
                                                    }}
                                                    garage_id={garage_id}
                                                    numberOfCarsInGarage={
                                                        numberOfCarsInSortedIDS ??
                                                        0
                                                    }
                                                    dataCyId={
                                                        'addCar-form-mobile'
                                                    }
                                                    dispatch={dispatch}
                                                    navigate={navigate}
                                                />
                                            </ModalDisplay>
                                        )}
                                    <div style={{ paddingBottom: '200px' }} />
                                </IpadAndMobileDisplay>
                            )}
                        </React.Fragment>
                    )}
            </CenteredPageWrapper>
        </div>
    )
}

export default MyGarage
