import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import { useAppSelector } from '../../../../redux/store/hooks'
import { randomCarSvgPlaceholderFunc } from '../../../../helpers/random/randomPlaceholder'
import { useNavigate } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import SkeletonAbsolute from '../../../atoms/skeleton/skeletonAbsolute'
import {
    IThreeDotsActionsItems,
    OptionsMenuItem,
} from '../../../atoms/menu/actionsMenu/actionsMenuDots'
import { motion } from 'framer-motion'
import { useOnClickOutside } from '../../../templates/clickOutside'
import {
    determineCarArchiveCopy,
    IHandoverMenuItemsArgProps,
} from '../../../pages/archive/garageArchive'
import useWindowSize, {
    WindowSize,
} from '../../../templates/displays/windowSizeHook'
import CarHandoverMenuMobile from '../../../atoms/menu/bottomSheetMenu/carHandoverMenuMobile'
import { Spin, Tooltip } from 'antd'
import React from 'react'
import useThemes from '../../../../providers/theme/hooks'
import colours from '../../../../providers/theme/colours'
import GalleryIcon from '../../../atoms/icons/components/galleryIcon'
import { HistoryFilesIcon } from '../../../atoms/icons/components'
import ThreeDotsIcon from '../../../atoms/icons/components/threeDots'
import { ICar, IHandover } from '../../../../redux/entities/cars/types'
import { IGalleryImage } from '../../../../redux/entities/galleries/types'

type Props = {
    car: ICar
    menu_items: (car: ICar) => IThreeDotsActionsItems[]
    onThreeDotsClick?: (carid: string) => void
}

const Container = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 49%;
    margin-bottom: 2%;

    @media ${device.mobile_and_ipad} {
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 24px;
    }

    border-radius: 1px;
`

const ProfileImage = styled.img`
    object-fit: cover;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
`

const ImageWrapper = styled.div`
    height: 310px;
    border-left: 1px solid var(--border-muted, #e5e5e5);
    border-top: 1px solid var(--border-muted, #e5e5e5);
    border-right: 1px solid var(--border-muted, #e5e5e5);
    box-sizing: border-box;

    @media (max-width: 400px) {
        height: 200px;
    }

    @media (min-width: 400px) and (max-width: 500px) {
        height: 230px;
    }

    @media (min-width: 500px) and (max-width: 700px) {
        height: 280px;
    }

    @media (min-width: 800px) and (max-width: 880px) {
        height: 400px;
    }

    width: 100%;
    box-sizing: border-box;

    @media ${device.smallest_laptops} {
        height: 250px;
    }

    @media ${device.large_desktop} {
        height: 350px;
    }
    position: relative;
    cursor: pointer;
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 8px 16px 16px;

    @media ${device.beyond_ipad_mobile} {
        padding: 24px 32px 32px;
    }

    @media ${device.mobile_and_ipad} {
        padding-top: 24px;
    }

    border-left: 1px solid var(--border-muted, #e5e5e5);
    border-bottom: 1px solid var(--border-muted, #e5e5e5);
    border-right: 1px solid var(--border-muted, #e5e5e5);
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    border-radius: 1px;
`

const Row1 = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;

    @media ${device.beyond_ipad_mobile} {
        padding-bottom: 8px;
    }
`

const Title = styled.div`
    font-family: Lato-Semibold;
    /* font-weight: 600; */
    font-size: 24px;
    color: var(--text-strong, #1a1a1a);

    @media ${device.mobile_and_ipad} {
        font-size: 16px;
        font-weight: 400;
    }

    @media (min-width: 880px) and (max-width: 1200px) {
        font-size: 18px;
    }

    @media (min-width: 1200px) and (max-width: 1400px) {
        font-size: 21px;
    }

    cursor: pointer;

    :hover {
        color: var(--primary, #5ec3ca);
    }
`

type IArchivedAtStyledProps = {
    $isFailed?: boolean
}

const ArchivedAt = styled.div<IArchivedAtStyledProps>`
    font-family: Lato-Semibold;
    font-size: 12px;
    letter-spacing: 0.07em;

    color: ${(props) =>
        props.$isFailed === true
            ? `var(--error, #df6f6f)`
            : 'var(--text-darker, #616161)'};

    text-transform: uppercase;

    @media ${device.mobile_and_ipad} {
        font-weight: 400;
        font-size: 11px;
        color: ${(props) =>
            props.$isFailed === true
                ? `var(--error, #df6f6f)`
                : 'var(--text-darker, #616161)'};
    }

    @media (min-width: 880px) and (max-width: 1200px) {
        font-size: 13px;
    }
`

const Column1 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    width: 100%;
    box-sizing: border-box;

    @media ${device.mobile_and_ipad} {
        padding-bottom: 15px;
    }

    @media ${device.beyond_ipad_mobile} {
        padding-bottom: 15px;
    }
`

const CountsRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px;
    gap: 24px;
    @media ${device.mobile_and_ipad} {
        gap: auto;
        width: 100%;
        box-sizing: border-box;
    }

    @media ${device.beyond_ipad_mobile} {
        height: 24px;
    }
`

const ShortRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 0px;
    gap: 8px;

    @media ${device.mobile_and_ipad} {
        gap: 4px;
    }
`
const ThreeDots = styled.button`
    all: unset;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
    cursor: pointer;
    border: 1px solid var(--border-muted, #e5e5e5);
    border-radius: 4px;

    @media ${device.mobile_and_ipad} {
        width: 30px;
        height: 30px;
    }
`

const Padding = styled.div`
    padding-top: 5px;
    @media ${device.mobile_and_ipad} {
        padding-top: 6px;
    }
`
const DesktopTxt = styled.div`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding-left: 3px;
    @media ${device.mobile_and_ipad} {
        display: none;
    }
`
const CountTxt = styled.div`
    font-family: 'Lato';
    font-weight: 400;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    letter-spacing: 5e-5em;
    color: var(--text-default, #666666);

    @media (min-width: 800px) {
        font-size: 10px;
    }
    @media (min-width: 1000px) {
        font-size: 12px;
    }
    @media (min-width: 1300px) {
        font-size: 14px;
    }
`

// const DesktopHeight = styled.div`
//     @media ${device.beyond_ipad_mobile} {
//         height: 30px;
//     }
// `

const MenuBox = styled.div`
    z-index: 10;
    position: absolute;
    right: 30px;
    bottom: 0px;

    top: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    font-family: Lato;
    font-size: 16px;
    /* Base/White */

    background: var(--modal-side-sheet-pop-over-bg, #fff);
    /* 24 */

    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    border-radius: 4px;

    height: auto;
    width: max-content;

    :hover {
        color: var(--primary, #5ec3ca);
        transition: all 100ms;
    }
`

const StyledSpin = styled(Spin)`
    .ant-spin-dot-item {
        background-color: var(--primary, #5ec3ca);
    }
`

export const ArchiveThreeDotMenuWrapper = styled(motion.div)`
    position: relative;
    cursor: pointer;
`

export type HandoverMenuItemProps = {
    item: IThreeDotsActionsItems
    index: number
    toggle: (p: boolean) => any
    carID: string
    carHandoverData?: IHandover
}

export const ArchivedMenuItem = (props: HandoverMenuItemProps) => {
    const [showTooltip, toggleTooltip] = useState(false)
    const { theme } = useThemes()
    return (
        <Tooltip
            title={props.item.disabledTxt}
            open={showTooltip}
            placement="bottom"
            color={colours[theme].error}
        >
            <OptionsMenuItem
                key={props.index}
                $isDisabled={props.item.isDisabled}
                onMouseEnter={() => {
                    if (props.item.isDisabled && !props.item.isLoading) {
                        toggleTooltip(true)
                    }
                }}
                onMouseLeave={() => {
                    if (props.item.isDisabled && !props.item.isLoading) {
                        toggleTooltip(false)
                    }
                }}
                onClick={(e: any) => {
                    e.preventDefault()

                    let arg: IHandoverMenuItemsArgProps = {
                        carID: props.carID,
                        handoverData: props.carHandoverData,
                    }
                    !props.item.isDisabled &&
                        !props.item.isLoading &&
                        props.item.actionOnClick &&
                        props.item.actionOnClick(arg)
                    props.toggle(false)

                    return
                }}
                style={{
                    color: props.item.isLoading
                        ? colours[theme].border_muted
                        : props.item.color
                        ? props.item.color
                        : colours[theme].text_default,
                    display: 'flex',
                    alignItems: 'center',
                    transition: 'color 200ms',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        opacity: props.item.isLoading ? 1 : 0,
                        width: props.item.isLoading ? 24 : 0,
                        transition: 'all 200ms',
                    }}
                >
                    <StyledSpin size="small" />
                </div>

                {props.item.text}
            </OptionsMenuItem>
        </Tooltip>
    )
}

const ArchivedGarageItemGridView = (props: Props) => {
    let { car, menu_items, onThreeDotsClick } = props

    const images_data = useAppSelector((state) => {
        return state.entities.galleriesData.images
    })

    let cover_id = car?.gallery?.cover
    let cover_img: IGalleryImage | undefined =
        cover_id && images_data && images_data[cover_id]
            ? images_data[cover_id]
            : undefined

    let navigate = useNavigate()

    const [isImgLoading, setIsImgLoading] = useState<boolean>(true)
    const [src, setSrc] = useState<string>('')
    const { theme } = useThemes()

    const randomPlaceholder = randomCarSvgPlaceholderFunc(undefined, theme)

    useEffect(() => {
        let url =
            cover_img && cover_img.url ? cover_img.url : `${randomPlaceholder}`

        setSrc(url)
    }, [cover_img])

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

    const menuRef = useRef<HTMLDivElement>(null)

    useOnClickOutside(menuRef, () => {
        if (isMenuOpen === true) {
            setIsMenuOpen(false)
        }
    })

    const size: WindowSize = useWindowSize()
    let isMobile = size && size.width && size.width < 880 ? true : false

    const [isMobileSheetOpen, setIsMobileSheetOpen] = useState<string | null>(
        null
    )

    return (
        <>
            {isMobile && (
                <CarHandoverMenuMobile
                    isVisible={
                        isMobileSheetOpen && isMobileSheetOpen === car.id
                            ? true
                            : false
                    }
                    carID={car.id}
                    carHandoverData={car.handover}
                    close={() => {
                        setIsMobileSheetOpen(null)
                    }}
                    menu_items={menu_items(car)}
                />
            )}

            <Container key={`${car.id}-desk-list`}>
                <ImageWrapper
                    onClick={() => {
                        navigate(`/archives/car/${car.id}/overview`)
                    }}
                >
                    <SkeletonAbsolute isthingloading={isImgLoading} />

                    <ProfileImage
                        role="button"
                        onClick={() => {}}
                        alt="car profile image"
                        src={src}
                        onLoad={() => {
                            setIsImgLoading(false)
                        }}
                    />
                </ImageWrapper>

                <Column>
                    <Column1>
                        <Row1>
                            <Title
                                role="button"
                                onClick={() => {
                                    navigate(`/archives/car/${car.id}/overview`)
                                }}
                            >
                                {car.title}
                            </Title>

                            <ArchiveThreeDotMenuWrapper
                                whileTap={{ scale: 1.02 }}
                                onClick={() => {
                                    if (isMobile) {
                                        setIsMobileSheetOpen(car.id)
                                    } else if (!isMobile) {
                                        setIsMenuOpen(true)
                                    }
                                    onThreeDotsClick && onThreeDotsClick(car.id)
                                }}
                                ref={menuRef}
                            >
                                <ThreeDots>
                                    <ThreeDotsIcon />
                                </ThreeDots>
                                {isMenuOpen === true && (
                                    <MenuBox>
                                        {menu_items(car).map((item, index) => {
                                            return item.hide_item ? null : (
                                                <React.Fragment
                                                    key={`grid_view_item_${index}_${
                                                        isMobile
                                                            ? 'mobile'
                                                            : 'desktop'
                                                    }`}
                                                >
                                                    <ArchivedMenuItem
                                                        item={item}
                                                        index={index}
                                                        toggle={(
                                                            p: boolean
                                                        ) => {
                                                            setIsMenuOpen(p)
                                                        }}
                                                        carID={car.id}
                                                        carHandoverData={
                                                            car.handover
                                                        }
                                                    />
                                                </React.Fragment>
                                            )
                                        })}
                                    </MenuBox>
                                )}
                            </ArchiveThreeDotMenuWrapper>
                        </Row1>

                        <Padding />

                        <ArchivedAt
                            $isFailed={
                                car.handover?.handover_state === 'FAILED'
                            }
                        >
                            {determineCarArchiveCopy(car)}
                        </ArchivedAt>
                    </Column1>

                    <CountsRow>
                        <ShortRow>
                            <GalleryIcon
                                size="20"
                                color={colours[theme].text_muted}
                            />
                            <CountTxt>
                                {car.gallery_images_count ?? 0}
                                <DesktopTxt> Photos</DesktopTxt>
                            </CountTxt>
                        </ShortRow>
                        <ShortRow>
                            <HistoryFilesIcon
                                size="20"
                                color={colours[theme].text_muted}
                            />
                            <CountTxt>
                                {car.car_entries_count}
                                <DesktopTxt> History Files</DesktopTxt>
                            </CountTxt>
                        </ShortRow>
                    </CountsRow>
                </Column>
            </Container>
        </>
    )
}

export default ArchivedGarageItemGridView
