import React, { useState } from 'react'
import styled from 'styled-components'
import blank_user from '../../../../public/assets/placeholders_svgs/avatar.svg'
import v2_add from '../../../../public/assets/icons/v2_add.svg'
import checked_green from '../../../../public/assets/icons/checked_green.svg'
import { device } from '../../../templates/displays/devices'
import useWindowSize, {
    WindowSize,
} from '../../../templates/displays/windowSizeHook'
import { ISearchUsersResult } from '../../../../redux/searchUsers/types'

type IStyle = { $isHovered: boolean; $isAdded?: boolean }

const SearchResultsDropdownItem = styled.div<IStyle>`
    position: relative;
    background-color: ${(props) =>
        props.$isHovered
            ? 'var(--primary_08)'
            : props.$isAdded
            ? 'var(--primary_04)'
            : 'var(--bg-color, #fff)'};
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 74px;
    cursor: pointer;
    flex-wrap: wrap;
    padding-left: 20px;
    @media ${device.mobile_and_ipad} {
        border-radius: 5px;
    }
`
const ProfilePicWrapper = styled.div`
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid var(--border-muted, #e5e5e5);

    @media ${device.mobile_and_ipad} {
        margin-left: 0px;
    }
`

const ProfilePic = styled.img`
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    border-radius: 50%;
`

const Col = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
`

const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
`

const UserHandle = styled.div`
    font-size: 12px;
    font-family: Lato;
    color: var(--text-default, #666666);
    font-size: 16px;
    line-height: 24px;
`

const UserEmail = styled.div`
    font-size: 12px;
    line-height: 16px;
    font-family: Lato;
    color: var(--text-muted);

    @media ${device.mobile} {
        font-size: 10px;
    }
`

const IconWrapper = styled.div`
    height: auto;
    width: 20px;
    position: absolute;
    right: 20px;
`

interface IItemProps {
    onSearchResultSelect: any
    owner: ISearchUsersResult
    isAdded?: boolean
}

const SearchResultsItem: React.FunctionComponent<IItemProps> = (props) => {
    const { onSearchResultSelect, owner, isAdded } = props

    const size: WindowSize = useWindowSize()

    let isMobile = size && size.width && size.width < 880 ? true : false

    const [isHoveredOver, setIsHoveredOver] = useState(isMobile ? false : true)

    return (
        <SearchResultsDropdownItem
            $isHovered={isHoveredOver}
            onClick={onSearchResultSelect}
            onMouseEnter={() => setIsHoveredOver(true)}
            onMouseLeave={() => setIsHoveredOver(false)}
            $isAdded={isAdded}
            key={owner.uid}
        >
            <Row>
                <ProfilePicWrapper>
                    <ProfilePic
                        src={
                            owner.profile_image_url
                                ? owner.profile_image_url
                                : blank_user
                        }
                    />
                </ProfilePicWrapper>

                <Col style={{ paddingLeft: '20px' }}>
                    <UserHandle>{owner.display_name}</UserHandle>
                    <UserEmail>{owner.email}</UserEmail>
                </Col>
            </Row>

            {isHoveredOver && !isAdded && (
                <IconWrapper>
                    <img src={v2_add} alt="add" />
                </IconWrapper>
            )}

            {isAdded && (
                <IconWrapper>
                    <img
                        src={checked_green}
                        style={{ width: '15px' }}
                        alt="add"
                    />
                </IconWrapper>
            )}
        </SearchResultsDropdownItem>
    )
}

export default SearchResultsItem
