interface IObj {
    [key: string]: any
}

export const actionsObject = {
    garage: ['add your car'],
    car_overview: [
        'Add a Task',
        'Add Image(s)',
        'Add to History File',
        'Update Mileage',
        'Update Car Profile Image',
    ],
    car_gallery: ['Add Image'],
}

export const subMenuList: IObj = {
    '/account': [
        { pageName: 'your profile', url: '/account' },
        { pageName: 'preferences', url: '/account/preferences' },
        // { pageName: 'contact-information', url: '/account' },
        // { pageName: 'security', url: '/account' },
        { pageName: 'notifications', url: '/account/notifications' },
        { pageName: 'FAQ', url: '/account/faq' },
        { pageName: 'install custodian', url: '/account/install-app' },
        { pageName: 'contact', url: '/account/contact' },
        { pageName: 'tutorials', url: '/account/tutorials' },
    ],
    '/apex': [
        {
            pageName: 'overview',
            url: '/apex',
        },
        {
            pageName: 'podcasts',
            url: '/apex/podcasts',
        },
        {
            pageName: 'articles',
            url: '/apex/articles',
        },
        {
            pageName: 'car of the week',
            url: '/apex/cars-of-the-week',
        },
        // {
        //     pageName: 'news highlights',
        //     url: '/apex/news-highlights',
        // },
        {
            pageName: 'interviews',
            url: '/apex/interviews',
        },
    ],
    '/garage': [
        { pageName: 'currently owned', url: '/garage' },

        {
            pageName: 'previously owned',
            url: '/garage/previously-owned',
        },
    ],
    '/': [
        { pageName: 'currently owned', url: '/garage' },

        {
            pageName: 'previously owned',
            url: '/garage/previously-owned',
        },
    ],
    '/car': [
        {
            pageName: 'overview',
            url: '/car',
        },

        {
            pageName: 'gallery',
            url: '/car/gallery',
        },
        {
            pageName: 'history file',
            url: '/car/history-file',
        },

        {
            pageName: 'technical information',
            url: '/car/technical-information',
        },

        {
            pageName: 'tasks',
            url: '/car/tasks',
        },
        {
            pageName: 'sharing',
            url: '/car/sharing',
        },
        {
            pageName: 'insights',
            url: '/car/insights',
        },
        // { pageName: 'reminders', url: '/car/reminders' },
        // { pageName: 'projects', url: '/car/projects' },
        // { pageName: 'timeline', url: '/car/timeline' },
    ],
    '/shared/car': [
        {
            pageName: 'overview',
            url: '/shared/car',
        },
        {
            pageName: 'gallery',
            url: '/shared/car/gallery',
        },

        {
            pageName: 'history file',
            url: '/shared/car/history-file',
        },

        {
            pageName: 'technical information',
            url: '/shared/car/technical-information',
        },
    ],
    '/archives/car': [
        {
            pageName: 'overview',
            url: '/archives/car/overview',
        },
        {
            pageName: 'gallery',
            url: '/archives/car/gallery',
        },

        {
            pageName: 'history file',
            url: '/archives/car/history-file',
        },

        {
            pageName: 'technical information',
            url: '/archives/car/technical-information',
        },
    ],
    '/history-files': [{ pageName: 'history files', url: '/history-files' }],
    '/upcoming': [{ pageName: 'upcoming', url: '/upcoming' }],
    '/sharing': [
        { pageName: 'shared with you', url: '/sharing/with-you' },
        { pageName: 'shared with others', url: '/sharing/with-others' },
    ],
    '/showroom': [
        {
            pageName: 'for sale',
            url: 'https://app.custodian.club/showroom/for-sale',
        },
        { pageName: 'sold', url: 'https://app.custodian.club/showroom/sold' },
        {
            pageName: 'watch list',
            url: 'https://app.custodian.club/showroom/watch-list',
        },
    ],
    '/drive-log': [{ pageName: 'general', url: '/drive-log' }],
    '/reminders': [{ pageName: 'MOT', url: '/reminders' }],
    '/events': [{ pageName: 'events', url: '/events' }],

    '/services': [{ pageName: 'services', url: '/services' }],

    '/membership': [
        { pageName: 'about', url: '/about' },
        { pageName: 'apply', url: '/apply' },
    ],
    '/tasks': [{ pageName: 'Tasks & Reminders', url: '/tasks' }],
    '/insurance/overview': [
        { pageName: 'Insurance', url: '/insurance/overview' },
    ],
    // '/upcoming': [{ pageName: 'upcoming', url: '/upcoming' }],
}

type ImenuList = {
    pageName: string
    url: string
}

export const topMenuList: ImenuList[] = [
    { pageName: 'garage', url: '/garage' },
    { pageName: 'history files', url: '/history-files' },
    { pageName: 'sharing', url: '/sharing/with-you' },
    { pageName: 'insurance', url: '/insurance/application' },
    {
        pageName: 'showroom',
        url: 'https://app.custodian.club/showroom/for-sale',
    },
    { pageName: 'tasks', url: '/tasks' },
    { pageName: 'apex', url: '/apex' },
    { pageName: 'upcoming', url: '/upcoming' },
]

export const middleComingSoonMenuList: ImenuList[] = [
    { pageName: 'dashboard', url: '/dashboard' },
    { pageName: 'reminders', url: '/reminders' },
    { pageName: 'drive log', url: '/drive-log' },
    { pageName: 'services', url: '/services' },
    { pageName: 'events', url: '/events' },
]

export const bottomMenuList: ImenuList[] = [
    { pageName: 'account', url: '/account' },
    { pageName: 'preferences', url: '/account/preferences' },
]

export const currentPageGroup = (activepage: string) => {
    if (activepage.match(/sharing/g) !== null) {
        return '/sharing/with-you'
    }

    if (
        activepage.match(/garage/g) !== null &&
        (activepage.match(/shared/g) === null ||
            activepage.match(/archives/g) === null)
    ) {
        return '/garage'
    }

    if (activepage.match(/showroom/g) !== null) {
        return '/showroom/for-sale'
    }

    if (activepage.match(/shared/g) !== null) {
        return '/sharing/with-you'
    }

    if (activepage === '/') {
        return '/garage'
    }

    if (activepage === '/account') {
        return '/account'
    }
    if (activepage === '/account/preferences') {
        return '/account/preferences'
    }
    if (activepage === '/account/faq') {
        return '/account/faq'
    }

    if (
        activepage === '/insurance/application' ||
        activepage === '/insurance/overview'
    ) {
        return '/insurance/application'
    }
    if (
        activepage === '/history-files' ||
        (activepage.match(/history-file/g) !== null &&
            activepage.match(/car/g) === null)
    ) {
        return '/history-files'
    }

    if (
        activepage.match(/car/g) !== null &&
        (activepage.match(/shared/g) === null ||
            activepage.match(/archives/g) === null)
    ) {
        return '/garage'
    }

    if (activepage === '/account/install-app') {
        return '/account/install-app'
    }
    if (activepage === '/account/contact') {
        return '/account/contact'
    }
    if (activepage === '/account/tutorials') {
        return '/account/tutorials'
    }
    if (activepage.match(/preferences/g) !== null) {
        return '/preferences'
    }
    if (activepage.match(/membership/g) !== null) {
        return '/membership'
    }
    if (activepage.match(/apex/g) !== null) {
        return '/apex'
    }
    if (activepage.match(/drive-log/g) !== null) {
        return '/drive-log'
    }
    if (activepage.match(/reminders/g) !== null) {
        return '/reminders'
    }
    if (activepage.match(/tasks/g) !== null) {
        return '/tasks'
    }
    if (activepage.match(/upcoming/g) !== null) {
        return '/upcoming'
    }
}

export const matchPageSubGroup = (activepage: string) => {
    if (
        (activepage.match(/sharing/g) !== null ||
            activepage.match(/with-others/g) !== null ||
            activepage.match(/with-you/g) !== null) &&
        activepage.match(/car/g) === null
    ) {
        return '/sharing'
    }
    if (
        activepage.match(/garage/g) !== null &&
        (activepage.match(/shared/g) === null ||
            activepage.match(/archives/g) === null)
    ) {
        return '/garage'
    }

    if (activepage === '/insurance/overview') {
        return '/insurance/overview'
    }

    if (
        activepage.match(/shared/g) !== null &&
        activepage.match(/car/g) !== null &&
        activepage.match(/history-file/g) !== null &&
        activepage.match(/gallery/g) !== null
    ) {
        return undefined
    }
    if (
        activepage.match(/shared/g) !== null &&
        activepage.match(/car/g) !== null
    ) {
        return '/shared/car'
    }

    if (
        activepage.match(/archives/g) !== null &&
        activepage.match(/car/g) !== null
    ) {
        return '/archives/car'
    }

    if (
        (activepage.match(/sharing/g) !== null ||
            activepage.match(/with-others/g) !== null ||
            activepage.match(/with-you/g) !== null) &&
        activepage.match(/car/g) === null
    ) {
        return '/sharing'
    }

    if (activepage.match(/showroom/g) !== null) {
        return '/showroom'
    }

    if (
        activepage.match(/sharing/g) !== null &&
        activepage.match(/cars/g) !== null
    ) {
        return '/sharing'
    }
    if (activepage === '/') {
        return '/garage'
    }
    if (activepage.match(/apex/g) !== null) {
        return '/apex'
    }
    if (
        activepage.match(/car/g) !== null &&
        activepage.match(/shared/g) === null &&
        activepage.match(/archives/g) === null &&
        activepage.match(/with-others/g) === null &&
        activepage.match(/with-you/g) === null
    ) {
        return '/car'
    }
    if (activepage === '/tasks') {
        return '/tasks'
    }
    if (activepage === '/history-files') {
        return '/history-files'
    }

    if (activepage.match(/account/g) !== null) {
        return '/account'
    }
    if (activepage.match(/preferences/g) !== null) {
        return '/preferences'
    }
    if (activepage.match(/membership/g) !== null) {
        return '/membership'
    }
    if (activepage.match(/drive-log/g) !== null) {
        return '/drive-log'
    }
    if (activepage.match(/reminders/g) !== null) {
        return '/reminders'
    }

    if (activepage.match(/projects/g) !== null) {
        return '/projects'
    }

    if (activepage.match(/upcoming/g) !== null) {
        return '/upcoming'
    }
}
