import React, { useEffect } from 'react'
import styled from 'styled-components'
import Faded from '../../templates/animated/faded'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import { device } from '../../templates/displays/devices'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import GeneralTimelineCardMobile from '../../molecules/cards/timeline/generalTimelineCardMobile'
import GeneralTimelineCardDesktop from '../../molecules/cards/timeline/generalTimelineCardDesktop'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import NoCarsInGarage from '../../atoms/noCarsInGarage/noCarsInGarage'
import AdjustableLoader from '../../atoms/loader/adjustableLoader'
import FullPagePageWrapper from '../../templates/displays/pageWrappers/FullPagePageWrapper'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { garagesActions } from '../../../redux/entities/garages/reducer'
import { usersActions } from '../../../redux/user/reducer'

const CenteredWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding-left: 24px;
    padding-right: 24px;
    @media ${device.ipad} {
        padding-bottom: 100px;
    }
`
const PageTitle = styled.div<{ $noCars?: boolean }>`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-Semibold;
    font-size: 32px;
    line-height: 48px;

    @media ${device.ipad} {
        font-size: 22px;
        line-height: 32px;
        padding-top: 0px;
    }
    @media ${device.beyond_ipad_mobile} {
        padding-top: 40px;
    }

    @media ${device.mobile} {
        font-size: 20px;
        line-height: 28px;
        padding-top: 0px;
    }
`

const PageDesc = styled.div<{ $noCars?: boolean }>`
    color: var(--text-default, #666666);
    font-family: Lato;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.001px;
    max-width: 700px;
    padding-right: 16px;

    @media ${device.mobile_and_ipad} {
        padding-bottom: 8px;
    }
    @media ${device.mobile} {
        font-size: 14px;
        line-height: 20px;
    }
`

const WrapperDesktopAdjusted = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;
    padding-bottom: 100px;

    @media (min-width: 500px) and (max-width: 880px) {
        padding-top: 0px;
    }
    @media (min-width: 881px) and (max-width: 1200px) {
        padding-top: 32px;
    }
    @media ${device.desktop} {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 24px;
        padding-top: 48px;
    }
    @media (min-width: 2200px) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 24px;
        padding-top: 48px;
    }
    @media ${device.mobile} {
        grid-gap: 16px;
    }
`

const Wrapper = styled.section`
    @media ${device.beyond_ipad_mobile} {
        padding-left: 50px;

        transform: translateY(-20px);
        background-color: var(--bg-color, #fff);
    }
    @media ${device.mobile} {
        background-color: var(--bg-color, #fff);
        min-height: 100vh;
        padding-bottom: 100px;
    }
`

const GeneralTimeline: React.FC = () => {
    const dispatch = useAppDispatch()

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    const garage_data = useAppSelector(
        (state) => state.entities.garagesData.garages
    )
    const isGarageLoading = useAppSelector(
        (state) => state.entities.garagesData.loading
    )

    const carsData = useAppSelector((state) => state.entities.carsData.cars)
    const isUserLoading = useAppSelector((state) => state.user.loading)
    const imagesData = useAppSelector(
        (state) => state.entities.galleriesData.images
    )

    const getNecessaryData = () => {
        let garageid = userLoggedIn?.owns_garage.uid

        if (userLoggedIn && userLoggedIn.id) {
            if (
                garageid &&
                (!garage_data ||
                    !garage_data[garageid] ||
                    !garage_data[garageid].cars ||
                    garage_data[garageid].cars.length === 0)
            ) {
                dispatch(garagesActions.getGarageByUserIdRequest())
            }
        }
    }

    useEffect(() => {
        dispatch(customNavDataActions.setInitialLandingUrl('/history-files'))

        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        }

        if (userLoggedIn) {
            let garage_id = userLoggedIn.owns_garage?.uid

            if (
                garage_id &&
                (!garage_data[garage_id] ||
                    !garage_data[garage_id].cars ||
                    garage_data[garage_id].cars.length === 0)
            ) {
                getNecessaryData()
            }
        }
    }, [userLoggedIn])

    let garage_id = userLoggedIn?.owns_garage?.uid
    let current = garage_id && garage_data && garage_data[garage_id]

    return (
        <React.Fragment>
            <Wrapper>
                <FullPagePageWrapper paddingRight="32px">
                    <DesktopDisplayOnly>
                        <PageTitle>Overview</PageTitle>
                        <div style={{ paddingTop: 16 }} />
                        <PageDesc
                            $noCars={
                                !(
                                    userLoggedIn &&
                                    garage_data &&
                                    garage_id &&
                                    garage_data[garage_id] &&
                                    garage_data[garage_id].cars &&
                                    garage_data[garage_id].cars.length > 0
                                )
                            }
                        >
                            Welcome to your Digital History File.
                            <br />
                            Anything added here will be stored securely and made
                            searchable from any Custodian application.
                        </PageDesc>
                    </DesktopDisplayOnly>
                    <IpadAndMobileDisplay>
                        <div style={{ padding: 24 }}>
                            <PageTitle>Overview</PageTitle>
                            <div style={{ paddingTop: 16 }} />
                            <PageDesc
                                $noCars={
                                    !(
                                        userLoggedIn &&
                                        garage_data &&
                                        garage_id &&
                                        garage_data[garage_id] &&
                                        garage_data[garage_id].cars &&
                                        garage_data[garage_id].cars.length > 0
                                    )
                                }
                            >
                                Welcome to your Digital History File.
                                <br />
                                Anything added here will be stored securely and
                                made searchable from any Custodian application.
                            </PageDesc>
                        </div>
                    </IpadAndMobileDisplay>
                    <DesktopDisplayOnly>
                        <Faded>
                            <WrapperDesktopAdjusted>
                                {isGarageLoading || isUserLoading ? (
                                    <div
                                        style={{
                                            position: 'relative',
                                            height: '20vh',
                                        }}
                                    >
                                        <AdjustableLoader
                                            isLoading={
                                                isGarageLoading || isUserLoading
                                            }
                                        />
                                    </div>
                                ) : userLoggedIn &&
                                  current !== null &&
                                  current !== undefined &&
                                  garage_id &&
                                  carsData &&
                                  garage_data &&
                                  garage_data[garage_id] &&
                                  garage_data[garage_id].cars &&
                                  garage_data[garage_id].cars.length > 0 ? (
                                    garage_data[garage_id].cars.map(
                                        (id: string, index: number) => {
                                            if (carsData[id]) {
                                                return (
                                                    <React.Fragment
                                                        key={`${id}-general-timeline-mobile`}
                                                    >
                                                        <GeneralTimelineCardDesktop
                                                            key={`${id}-general-timeline-desktop`}
                                                            car={carsData[id]}
                                                            carIndex={Object.keys(
                                                                carsData
                                                            ).indexOf(id)}
                                                            index={index}
                                                            imagesData={
                                                                imagesData
                                                            }
                                                        />
                                                    </React.Fragment>
                                                )
                                            } else
                                                return (
                                                    <div
                                                        key={`${id}-general-timeline-mobile`}
                                                    />
                                                )
                                        }
                                    )
                                ) : (
                                    <>
                                        <NoCarsInGarage
                                            variant="desktop"
                                            alignTextLeft
                                        />
                                    </>
                                )}
                            </WrapperDesktopAdjusted>
                        </Faded>
                    </DesktopDisplayOnly>
                    <IpadAndMobileDisplay>
                        <Faded>
                            <CenteredWrapper>
                                {isGarageLoading || isUserLoading ? (
                                    <div
                                        style={{
                                            position: 'relative',
                                            height: '20vh',
                                        }}
                                    >
                                        <AdjustableLoader
                                            isLoading={
                                                isGarageLoading || isUserLoading
                                            }
                                        />
                                    </div>
                                ) : userLoggedIn &&
                                  current !== null &&
                                  current !== undefined &&
                                  garage_id &&
                                  carsData &&
                                  garage_data &&
                                  garage_data[garage_id] &&
                                  garage_data[garage_id].cars &&
                                  garage_data[garage_id].cars.length > 0 ? (
                                    garage_data[garage_id].cars.map(
                                        (id: string, index: number) => {
                                            if (carsData[id]) {
                                                return (
                                                    <React.Fragment
                                                        key={`${id}-general-timeline-mobile`}
                                                    >
                                                        <GeneralTimelineCardMobile
                                                            car={carsData[id]}
                                                            carIndex={Object.keys(
                                                                carsData
                                                            ).indexOf(id)}
                                                            imagesData={
                                                                imagesData
                                                            }
                                                        />
                                                    </React.Fragment>
                                                )
                                            } else
                                                return (
                                                    <div
                                                        key={`${id}-general-timeline-mobile`}
                                                    />
                                                )
                                        }
                                    )
                                ) : (
                                    <NoCarsInGarage variant="mobile" />
                                )}
                            </CenteredWrapper>
                        </Faded>
                    </IpadAndMobileDisplay>
                </FullPagePageWrapper>
            </Wrapper>
        </React.Fragment>
    )
}

export default GeneralTimeline
