import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import grey_placeholder from '../../../public/assets/placeholder/grey_placeholder.svg'
import './styles.css'
import ActionsMenuDots, {
    IThreeDotsActionsItems,
} from '../../atoms/menu/actionsMenu/actionsMenuDots'
import { Link, useNavigate } from 'react-router-dom'
import SkeletonAbsolute from '../../atoms/skeleton/skeletonAbsolute'
import GalleryImChevron from '../../atoms/icons/GalleryImgChevron'
import { LocationIcon } from '../../atoms/icons/components'
import { useAppDispatch } from '../../../redux/store/hooks'
import {
    IGalleryImage,
    IGalleryImagesObject,
    IUpdateCarGalleryImagePayload,
} from '../../../redux/entities/galleries/types'
import { ICar, IExternalCar } from '../../../redux/entities/cars/types'
import { galleriesActions } from '../../../redux/entities/galleries/reducer'
import { ZoomWrapper } from './zoomWrapper'

const SlideWrapper = styled.div`
    position: relative;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
    color: var(--text-strong, #1a1a1a);
    border-radius: 2px;
    height: 100%;
    max-height: 80vh;
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: 1180px) {
        margin-top: 160px;
        width: 68vw;
        max-width: 1000px;
        height: 75vh;
        max-height: 530px;
    }

    @media (min-width: 1180px) and (max-width: 1295px) {
        width: 64vw;
        max-width: 1000px;
        height: 75vh;
        max-height: 530px;
    }

    @media (min-width: 1295px) and (max-width: 1400px) {
        width: 55vw;
        max-width: 1000px;
        height: 75vh;
        max-height: 530px;
    }

    @media (min-width: 1400px) and (max-width: 1550px) {
        width: 54vw;
        max-width: 1100px;
        height: 75vh;
        max-height: 550px;
    }

    @media (min-width: 1550px) and (max-width: 1600px) {
        width: 1200px;
        height: 905px;
    }

    @media (min-width: 1600px) and (max-width: 1810px) {
        width: 1300px;
        height: 1000px;
    }

    @media (min-width: 1810px) {
        width: 1492px;
        max-height: 70vh;
    }
`

const SlideImageBox = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: var(--off-bg-color, #fafafa);
    div {
        height: 100%;
    }
`

const SlideImage = styled.img`
    max-width: 100%;
    box-sizing: border-box;
    max-height: 90dvh;
    width: 100%;
    height: 100%;
    object-fit: contain;
`

const TextBox = styled.div`
    z-index: 6;
    width: 100%;
    box-sizing: border-box;
    height: max-content;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 2px;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 13px;
    font-family: Lato;
    @media ${device.smallest_laptops} {
        font-size: 13px;
    }
`

const Col = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    width: max-content;
    padding-right: 30px;
    text-align: left !important;
    color: var(--text-strong, #1a1a1a);
    font-size: 13px;
    @media ${device.smallest_laptops} {
        font-size: 13px;
    }

    @media ${device.large_desktop} {
        font-size: 16px;
    }
`

const CaptionCol = styled.div`
    font-family: Lato;
    padding-right: 10px;
    min-width: 230px;
    padding-left: 20px;

    font-size: 12px;

    @media (max-width: 1450px) {
        font-size: 11px;
    }

    @media ${device.large_desktop} {
        font-size: 14px;
    }
`

const Line = styled.div`
    height: 50px;
    justify-self: center !important;
    width: 1px;
    background-color: var(--text-muted);
`

const NextBlock = styled(Link)`
    position: absolute;
    right: -130px;
    top: 50%;
    transform: translateY(-50%);

    @media (max-width: 1450px) {
        right: -100px;
    }

    @media (min-width: 1600px) and (max-width: 1650px) {
        right: -100px;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    cursor: pointer;

    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    outline: none !important;
    z-index: 1;

    transition: all 100ms;

    :hover {
        transform: translateY(-50%) scale(1.1);
    }
`
const NextBlockNotLink = styled.div`
    position: absolute;
    right: -130px;
    top: 50%;
    transform: translateY(-50%);

    @media (max-width: 1450px) {
        right: -100px;
    }

    @media (min-width: 1600px) and (max-width: 1650px) {
        right: -100px;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    outline: none !important;
    user-select: none !important;
    opacity: 0.5;
`

const PrevBlock = styled(Link)`
    position: absolute;
    left: -120px;
    top: 50%;
    transform: translateY(-50%);

    @media (max-width: 1450px) {
        left: -85px;
    }

    @media (min-width: 1600px) and (max-width: 1650px) {
        left: -94px;
    }
    height: 50px;
    width: 50px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    cursor: pointer;

    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    outline: none !important;
    user-select: none !important;

    transition: all 100ms;

    :hover {
        transform: translateY(-50%) scale(1.1);
    }
`
const PrevBlockNotLink = styled.div`
    position: absolute;
    left: -120px;
    top: 50%;
    transform: translateY(-50%);

    @media (max-width: 1450px) {
        left: -85px;
    }

    @media (min-width: 1600px) and (max-width: 1650px) {
        left: -94px;
    }
    height: 50px;
    width: 50px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    outline: none !important;
    user-select: none !important;
    opacity: 0.5;
`
const PaddingSpan = styled.span`
    padding-left: 4px;
    color: var(--text-strong, #1a1a1a);
    font-size: 12px;
`

const SpanNoPadding = styled.span`
    color: var(--text-strong, #1a1a1a);
    font-size: 12px;
`

const FeaturedTag = styled.div`
    padding-left: 10px;
    padding-right: 10px;

    text-transform: uppercase;
    height: 24px;
    background-color: var(--primary, #5ec3ca);
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* font-weight: 600; */

    font-family: Lato-Semibold;

    font-size: 12px;
    letter-spacing: 0.07em;
    color: var(--bg-color, #fff);
`

const Uploader = styled.div`
    padding-bottom: 3px;
    color: var(--text-strong, #1a1a1a);
    font-size: 14px;
    font-family: Lato-semibold;
`

interface IProps {
    currentImageData: IGalleryImage
    closeGallery: () => void
    generateRequestedSlide?: () => void
    carid: string
    imagesData: IGalleryImagesObject
    currentCar: ICar | IExternalCar | undefined
    onClickEdit: () => void
    updateImage: (payload: IUpdateCarGalleryImagePayload) => void
    isSomethingLoading: boolean
    prevUrl: string
    nextUrl: string
    readOnlyMode?: boolean
    featuredImagesCount: number
    isImgInShowroom?: boolean
}

const GallerySlide: React.FC<IProps> = ({
    currentImageData,
    closeGallery,
    generateRequestedSlide,
    carid,
    imagesData,
    currentCar,
    onClickEdit,
    updateImage,
    isSomethingLoading,
    prevUrl,
    nextUrl,
    readOnlyMode,
    featuredImagesCount,
    isImgInShowroom,
}) => {
    const [overallMenuOpenId, setOverallMenuOpenId] = useState<string | null>(
        null
    )
    const [isLoading, setIsLoading] = useState(true)
    const [imgLoaded, _] = useState<string | undefined>(undefined)

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const genUrl = useCallback(
        (e?: KeyboardEvent) => {
            if (e) {
                e = e || window.event
                if (e.keyCode === 37 && prevUrl !== '/none') {
                    navigate(prevUrl)
                } else if (e.keyCode === 39 && nextUrl !== '/none') {
                    navigate(nextUrl)
                }
            }
        },
        [navigate, prevUrl, nextUrl]
    )

    useEffect(() => {
        document.addEventListener('keydown', genUrl, { passive: true })
        return () => {
            document.removeEventListener('keydown', genUrl)
        }
    }, [genUrl])

    const manageOverallMenus = (id?: string) => {
        if (id === overallMenuOpenId) {
            setOverallMenuOpenId(null)
        } else {
            setOverallMenuOpenId(id || null)
        }
    }

    const handleOptionClickedOn = (optionClicked: string) => {
        if (optionClicked === 'Set as car profile image' && currentImageData) {
            const payload: IUpdateCarGalleryImagePayload = {
                carid: carid,
                imageid: currentImageData.id,
                body: {
                    cover: true,
                },
            }
            updateImage(payload)

            setTimeout(() => {
                navigate(`/car/${carid}`)
            }, 200)
        } else if (
            optionClicked === 'Set as gallery feature' &&
            currentImageData
        ) {
            const payload: IUpdateCarGalleryImagePayload = {
                carid: carid,
                imageid: currentImageData.id,
                body: {
                    featured: true,
                },
            }
            updateImage(payload)
        } else if (optionClicked === 'Remove feature' && currentImageData) {
            const payload: IUpdateCarGalleryImagePayload = {
                carid: carid,
                imageid: currentImageData.id,
                body: {
                    featured: false,
                },
            }
            updateImage(payload)
        }
    }

    const handleDeletion = () => {
        const ids = {
            carid: carid,
            imageid: currentImageData.id,
        }

        currentImageData &&
            dispatch(galleriesActions.deleteCarGalleryImageRequest(ids))
        closeGallery()
    }

    const actions_items = (): IThreeDotsActionsItems[] => {
        if (currentImageData) {
            if (currentImageData.featured === true) {
                return [
                    {
                        text: 'Update image details',
                        actionOnClick: onClickEdit,
                        idRequired: false,
                    },
                    {
                        text: 'Set as car profile image',
                        actionOnClick: handleOptionClickedOn,
                        idRequired: true,
                    },
                    {
                        text: 'Remove feature',
                        actionOnClick: handleOptionClickedOn,
                        idRequired: true,
                        color: '#DF6F6F',
                    },
                    {
                        text: 'Delete',
                        actionOnClick: handleDeletion,
                        color: '#DF6F6F',
                        idRequired: true,
                        isDisabled: isImgInShowroom,
                        disabledTxt:
                            'Image is part of a published showroom entry.',
                    },
                ]
            } else {
                return [
                    {
                        text: 'Update image details',
                        actionOnClick: onClickEdit,
                        idRequired: false,
                    },
                    {
                        text: 'Set as car profile image',
                        actionOnClick: handleOptionClickedOn,
                        idRequired: true,
                    },
                    {
                        text: 'Set as gallery feature',
                        actionOnClick: handleOptionClickedOn,
                        idRequired: true,
                        isDisabled: featuredImagesCount >= 5,
                        disabledTxt:
                            'Five images already set as gallery features. Please remove at least one before setting new ones.',
                    },
                    {
                        text: 'Delete',
                        actionOnClick: handleDeletion,
                        color: '#DF6F6F',
                        idRequired: true,
                        isDisabled: isImgInShowroom,
                        disabledTxt:
                            'Image is part of a published showroom entry.',
                    },
                ]
            }
        } else return []
    }

    return (
        <div
            style={{
                position: 'fixed',
                height: '100%',
                width: 'fit-content',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '10vh',
            }}
        >
            <SlideWrapper>
                {nextUrl !== '/none' ? (
                    <NextBlock to={nextUrl}>
                        <GalleryImChevron />
                    </NextBlock>
                ) : (
                    <NextBlockNotLink>
                        <GalleryImChevron />
                    </NextBlockNotLink>
                )}

                {prevUrl !== '/none' ? (
                    <PrevBlock to={prevUrl}>
                        <GalleryImChevron direction="left" />
                    </PrevBlock>
                ) : (
                    <PrevBlockNotLink>
                        <GalleryImChevron direction="left" />
                    </PrevBlockNotLink>
                )}

                <SlideImageBox>
                    {isLoading ? (
                        <SkeletonAbsolute
                            isthingloading={isLoading}
                            darkTheme={false}
                        />
                    ) : null}

                    <ZoomWrapper
                        percentage={90}
                        backgroundColor="var(--gallery-image-bg)"
                    >
                        <SlideImage
                            src={imgLoaded ? imgLoaded : grey_placeholder}
                            srcSet={currentImageData?.large_srcset}
                            onLoad={() => setIsLoading(false)}
                            alt="car"
                        />
                    </ZoomWrapper>
                </SlideImageBox>
                <TextBox>
                    <Row>
                        <Row>
                            <Col>
                                <Uploader>
                                    {currentImageData?.uploader || ''}
                                </Uploader>

                                <Row>
                                    ©
                                    <PaddingSpan>
                                        {currentImageData?.credits || 'Unknown'}
                                    </PaddingSpan>
                                </Row>

                                <Row>
                                    <div
                                        style={{
                                            transform: 'translate(-1px, 1px)',
                                        }}
                                    >
                                        <LocationIcon size="14" />
                                    </div>
                                    <SpanNoPadding>
                                        {currentImageData?.location ||
                                            'Unknown'}
                                    </SpanNoPadding>
                                </Row>
                            </Col>
                        </Row>
                        <Line />
                        <CaptionCol style={{ width: '78%' }}>
                            {currentImageData?.caption || ''}
                        </CaptionCol>
                    </Row>

                    <Row>
                        {currentImageData.featured && (
                            <FeaturedTag>featured</FeaturedTag>
                        )}

                        {currentCar?.gallery?.cover === currentImageData.id && (
                            <FeaturedTag>profile image</FeaturedTag>
                        )}

                        {!readOnlyMode && (
                            <div
                                data-tut="car-gallery-image-tuto-desktop-1"
                                style={{ paddingLeft: '20px' }}
                            >
                                <ActionsMenuDots
                                    menuOpened={overallMenuOpenId}
                                    manageMenus={manageOverallMenus}
                                    menuId={currentImageData?.id || ''}
                                    actions_items={actions_items()}
                                />
                            </div>
                        )}
                    </Row>
                </TextBox>
            </SlideWrapper>
        </div>
    )
}

export default GallerySlide
