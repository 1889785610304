import JourneyBottomActionBarMobile from '../../../templates/bars/journey/bottomActionBarMobile'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import useThemes from '../../../../providers/theme/hooks'
import { useEffect, useReducer, useState } from 'react'
import colours, { journey_colours } from '../../../../providers/theme/colours'
import FormStepProgress from '../../../atoms/FormStepProgress/FormStepProgress'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import ChevronDown from '../../../atoms/icons/components/chevronDown'
import Faded from '../../../templates/animated/faded'
import EnterIcon from '../../../atoms/icons/components/enterIcon'

import {
    JourneyBtnContent,
    JourneyCardDesktop,
    JourneyColumnContent,
    JourneyEnterTxt,
    JourneyIconWrapper,
    JourneyLeftSide,
    JourneyRightSide,
    JourneyRightSideSubWrapper,
    JourneyRowContent,
    JourneySectionWrapper,
    JourneyStepTitle,
    JourneyText,
    JourneyWelcomeNewLineOnMobile,
} from '../../../templates/styledcomponents/journeyStyles'
import QrCodeStickerJourneyFormMobile from '../../../molecules/qrCodeCustomiseStickerModal/journey/qrCodeStickerJourneyFormMobile'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import QrCodeStickerJourneyFormDesktopLeft from '../../../molecules/qrCodeCustomiseStickerModal/journey/qrCodeStickerJourneyFormDesktopLeft'
import { changeStickerThemeReducer } from '../../../molecules/qrCodeCustomiseStickerModal/fns'
import QrCodeStickerJourneyFormDesktopRight from '../../../molecules/qrCodeCustomiseStickerModal/journey/qrCodeStickerJourneyFormDesktopRight'
import useWindowSize, {
    WindowSize,
} from '../../../templates/displays/windowSizeHook'
import { usersActions } from '../../../../redux/user/reducer'
import { carActions } from '../../../../redux/entities/cars/reducer'
import LoaderElasticThreeDots from '../../../atoms/loader/loaderElasticThreeDots'

type Props = {
    step: number
    totalSteps: number
    goToPrevStep: () => void
    goToNextStep: () => void
}

export default function QrCodeShareYourCarJourneyStep({
    step,
    totalSteps,
    goToNextStep,
    goToPrevStep,
}: Props) {
    const { theme } = useThemes()

    const continueOnEnter = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            goToNextStep()
        }
    }

    const showcasePublicLinkCar = {
        url: `https://share.custodian.club/car/e477ee6b2a038a6eab6727d879bb386e/overview`,
        title: '1969 Alfa Romeo 1300 GT Junior',
    }

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    const showcaseQrCodeUrl = useAppSelector((state) => {
        return state.entities.carsData.showcaseCarQRcodeShortUrl
    })

    const qrCodeLoading = useAppSelector((state) => {
        return state.entities.carsData.carSharesLoading
    })

    const appDispatch = useAppDispatch()

    useEffect(() => {
        document.addEventListener('keypress', continueOnEnter)
        if (userLoggedIn) {
            if (!showcaseQrCodeUrl) {
                appDispatch(
                    carActions.createShowcaseCarQRcodeShortUrlRequest(
                        showcasePublicLinkCar.url
                    )
                )
            }
        } else {
            appDispatch(usersActions.getCurrentUserDataRequest())
        }
        return () => {
            document.removeEventListener('keypress', continueOnEnter)
        }
    }, [userLoggedIn, showcaseQrCodeUrl])

    const stickerPredefinedThemes = useAppSelector(
        (state) => state.localdata.stickerPredefinedThemes
    )

    const [state, dispatch] = useReducer(changeStickerThemeReducer, {
        colours: {
            colour_one: stickerPredefinedThemes[0].colour_one,
            colour_two: stickerPredefinedThemes[0].colour_two,
        },
        openColourPicker: undefined,
    })

    const [activeThemeIndex, setActiveThemeIndex] = useState<number | null>(0)

    const size: WindowSize = useWindowSize()

    let isMobile = size && size.width && size.width < 880 ? true : false

    return (
        <div style={{ width: '100%' }}>
            <DesktopDisplayOnly>
                <Faded>
                    <JourneySectionWrapper>
                        <JourneyLeftSide $theme={theme}>
                            <FormStepProgress
                                screen_name="desktop"
                                currentStep={step}
                                totalSteps={totalSteps}
                                activeColour={
                                    journey_colours[theme].section[
                                        'share_your_car'
                                    ].primary_500
                                }
                                passiveColour={
                                    journey_colours[theme].section[
                                        `share_your_car`
                                    ].primary_100
                                }
                            />
                            <JourneyColumnContent>
                                <JourneyColumnContent style={{ gap: 16 }}>
                                    <StandardCtaBtn
                                        bg="transparent"
                                        customWidth="auto"
                                        customHeight="auto"
                                        removePaddings
                                        removeShadowOnHover
                                        onClick={goToPrevStep}
                                    >
                                        <JourneyBtnContent $theme={theme}>
                                            <div
                                                style={{
                                                    transform: 'rotate(90deg)',
                                                }}
                                            >
                                                <ChevronDown />
                                            </div>
                                            Back
                                        </JourneyBtnContent>
                                    </StandardCtaBtn>
                                    <JourneyStepTitle $theme={theme}>
                                        Your own personal QR code
                                    </JourneyStepTitle>
                                    <JourneyText $theme={theme}>
                                        Create a quick and easy way to launch
                                        into your car’s shared profile.
                                        <br />
                                        <br />
                                        Customise it to your liking, print it
                                        out, and stick it in your old tax disc
                                        older. Perfect for car meets and event
                                        goers! Your cars information is just a
                                        scan away.
                                    </JourneyText>
                                </JourneyColumnContent>
                                {showcaseQrCodeUrl ? (
                                    <QrCodeStickerJourneyFormDesktopLeft
                                        predefinedStickerThemes={
                                            stickerPredefinedThemes
                                        }
                                        state={state}
                                        dispatch={dispatch}
                                        activeThemeIndex={activeThemeIndex}
                                        setActiveThemeIndex={
                                            setActiveThemeIndex
                                        }
                                    />
                                ) : qrCodeLoading ? (
                                    <div
                                        style={{
                                            position: 'relative',
                                            height: 400,
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <LoaderElasticThreeDots />
                                    </div>
                                ) : null}
                            </JourneyColumnContent>

                            <JourneyRowContent style={{ gap: 32 }}>
                                <StandardCtaBtn
                                    onClick={() => {
                                        goToNextStep()
                                    }}
                                    isDisabled={false}
                                    bg={
                                        journey_colours[theme].section[
                                            'share_your_car'
                                        ].primary_500
                                    }
                                    customHoverColor={
                                        journey_colours[theme].section[
                                            'share_your_car'
                                        ].primary_600
                                    }
                                >
                                    Continue
                                </StandardCtaBtn>

                                <JourneyRowContent style={{ gap: 8 }}>
                                    <JourneyIconWrapper $theme={theme}>
                                        <EnterIcon />
                                    </JourneyIconWrapper>{' '}
                                    <JourneyEnterTxt $theme={theme}>
                                        Or press Enter
                                    </JourneyEnterTxt>
                                </JourneyRowContent>
                            </JourneyRowContent>
                        </JourneyLeftSide>
                        <JourneyRightSide>
                            <JourneyRightSideSubWrapper>
                                <JourneyCardDesktop
                                    $theme={theme}
                                    $customBorderRadius="24px"
                                    $customWidth="600px"
                                >
                                    {showcaseQrCodeUrl ? (
                                        <QrCodeStickerJourneyFormDesktopRight
                                            qr_code_link={showcaseQrCodeUrl}
                                            state={state}
                                            dispatch={dispatch}
                                            activeThemeIndex={activeThemeIndex}
                                            setActiveThemeIndex={
                                                setActiveThemeIndex
                                            }
                                            qr_code_id={
                                                'showcase_journey_qr_code'
                                            }
                                            title={showcasePublicLinkCar.title}
                                        />
                                    ) : qrCodeLoading ? (
                                        <div
                                            style={{
                                                position: 'relative',
                                                height: 400,
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <LoaderElasticThreeDots />
                                        </div>
                                    ) : null}
                                </JourneyCardDesktop>
                            </JourneyRightSideSubWrapper>
                        </JourneyRightSide>
                    </JourneySectionWrapper>
                </Faded>
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <Faded>
                    <div
                        style={{
                            paddingLeft: 24,
                            paddingRight: 24,
                            paddingBottom: 200,
                            paddingTop: 48,
                            overflow: 'hidden',
                        }}
                    >
                        <JourneyColumnContent style={{ gap: 40 }}>
                            <JourneyColumnContent style={{ gap: 24 }}>
                                <JourneyColumnContent style={{ gap: 4 }}>
                                    <JourneyStepTitle
                                        $theme={theme}
                                        style={{ paddingRight: '40px' }}
                                    >
                                        Your own personal
                                        <JourneyWelcomeNewLineOnMobile />
                                        <span
                                            style={{
                                                color: journey_colours[theme]
                                                    .section.share_your_car
                                                    .primary_500,
                                                fontSize: '28px',
                                            }}
                                        >
                                            QR code
                                        </span>
                                    </JourneyStepTitle>
                                    <JourneyText
                                        style={{
                                            fontSize: 14,
                                            color: colours[theme].text_muted,
                                        }}
                                        $theme={theme}
                                    >
                                        Car ownership is best enjoyed together
                                    </JourneyText>
                                </JourneyColumnContent>
                                <JourneyText $theme={theme}>
                                    Create a quick and easy way to launch into
                                    your car’s shared profile.
                                    <br />
                                    <br />
                                    Customise it to your liking, print it out,
                                    and stick it in your old tax disc older.
                                    Perfect for car meets and event goers! Your
                                    car's information is just a scan away.
                                </JourneyText>
                            </JourneyColumnContent>
                            {showcaseQrCodeUrl && isMobile ? (
                                <QrCodeStickerJourneyFormMobile
                                    qr_code_link={showcaseQrCodeUrl}
                                    predefinedStickerThemes={
                                        stickerPredefinedThemes
                                    }
                                    state={state}
                                    dispatch={dispatch}
                                    activeThemeIndex={activeThemeIndex}
                                    setActiveThemeIndex={setActiveThemeIndex}
                                />
                            ) : qrCodeLoading && isMobile ? (
                                <div
                                    style={{
                                        position: 'relative',
                                        height: 200,
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <LoaderElasticThreeDots />
                                </div>
                            ) : null}
                        </JourneyColumnContent>
                    </div>
                    <JourneyBottomActionBarMobile
                        sectionID="share_your_car"
                        currentStep={step}
                        totalSteps={totalSteps}
                        onBack={goToPrevStep}
                        onNext={() => {
                            goToNextStep()
                        }}
                    />
                </Faded>
            </IpadAndMobileDisplay>
        </div>
    )
}
