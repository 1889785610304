import styled from 'styled-components'
import insurance_onboarding_banner from '../../../../../public/assets/images/insurance_onboarding_banner.png'
import insurance_onboarding_banner_mobile from '../../../../../public/assets/images/insurance_onboarding_banner_mobile.jpg'
import insurance_onboarding_banner_mobile_dark from '../../../../../public/assets/images/insurance_onboarding_banner_mobile_dark.png'
import insurance_onboarding_banner_dark from '../../../../../public/assets/images/insurance_onboarding_banner_dark.png'
import Icon from '../../../../atoms/icons'
import useWindowSize, {
    WindowSize,
} from '../../../../templates/displays/windowSizeHook'
import useThemes from '../../../../../providers/theme/hooks'
const Text = styled.p`
    margin: 0;
    padding: 0;
    color: var(--text-default, #666);
    font-family: Lato;
    font-size: 16px;
    line-height: 24px; /* 150% */
    letter-spacing: 0.001px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @media (max-width: 845px) {
        font-size: 14px;
        line-height: 20px;
    }
`

const Imagee = styled.div`
    height: 255px;
    width: 100%;
    box-sizing: border-box;

    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;

    @media (max-width: 1200px) {
        height: 220px;
        object-fit: unset;
    }

    @media (max-width: 845px) {
        /* height: 208px;
        object-fit: unset; */
        /* margin-left: 24px; */
        object-fit: contain;
    }

    @media (max-width: 744px) {
        width: 678px;
        margin-left: 24px;
    }

    @media (max-width: 445px) {
        object-fit: cover;
        width: 100%;
        box-sizing: border-box;
        margin-left: 0px;
    }
`
const Parent = styled.div`
    width: 800px;
    overflow-x: hidded;

    @media (max-width: 1200px) {
        width: 715px;
    }

    @media (max-width: 845px) {
        width: 620px;
    }

    @media (max-width: 744px) {
        width: 100%;
        box-sizing: border-box;
    }
`

const InfoBox = styled.div`
    display: flex;
    padding: 16px;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;
    background: var(--base-overlays-neutral-alpha-50);
    width: 100%;
    box-sizing: border-box;

    @media (max-width: 745px) {
        width: 90dvw;
        margin-left: 5dvw;
    }
`

const InsuranceOnboardingGetStarted = () => {
    const size: WindowSize = useWindowSize()

    let isMobile = size && size.width && size.width < 445 ? true : false

    const { theme } = useThemes()

    return (
        <Parent>
            <Imagee
                style={{
                    backgroundImage:
                        theme === 'dark'
                            ? isMobile
                                ? `url(${insurance_onboarding_banner_mobile_dark})`
                                : `url(${insurance_onboarding_banner_dark})`
                            : isMobile
                            ? `url(${insurance_onboarding_banner_mobile})`
                            : `url(${insurance_onboarding_banner})`,
                }}
            />
            <div style={{ paddingTop: '18px' }} />

            <InfoBox>
                <Icon icon="info_gray" />
                <Text>
                    By starting an application you are giving consent to being
                    contacted to discuss your Custodian Insurance application on
                    the telephone number(s) you provide to us.
                </Text>
            </InfoBox>
        </Parent>
    )
}

export default InsuranceOnboardingGetStarted
