import React, { useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import Faded from '../../templates/animated/faded'
import TimelineItemDesktop from '../../molecules/timelineItemDesktop/timelineItemDesktop'
import { IDropdownItem } from 'entityModels'
import FadedSlower from '../../templates/animated/FadedSlower'
import CustomIconTagQuickFilterItem from '../../atoms/tags/customIconTagQuickFilterItem/customIconTagQuickFilterItemMobile'
import Expander from '../../atoms/expander/expander'
import NoFilterResults from '../../atoms/noFilterResults/noFilterResults'
import colours, { ITheme } from '../../../providers/theme/colours'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { INormalisedAttachmentsByID } from '../../../redux/attachments/types'
import {
    ITimelineEntryTypes,
    ITimelineItem,
} from '../../../redux/timeline/types'
import { carActions } from '../../../redux/entities/cars/reducer'
import useThemes from '../../../providers/theme/hooks'
import { device } from '../../templates/displays/devices'

interface Props {
    timelineItems: ITimelineItem[] | undefined
    attachmentsObj: INormalisedAttachmentsByID
    dataCyId?: string
    categories?: IDropdownItem[] | null | undefined
    tags?: IDropdownItem[] | null | undefined
    withPlaceholder?: boolean
    carid?: string
    selectedCategories?: ITimelineEntryTypes[]
    selectedLabels?: string[]
    onPlaceholderClick?: any
    removeCriteria: any
    clearAll?: any
    hasSampleData?: boolean
    userCurrency: string | undefined
    readOnlyMode?: boolean
    activeEntryTitleQuery?: string
}

const stickyActiveSelectedCriteria = (
    theme: ITheme,
    selectedCategories: ITimelineEntryTypes[],
    selectedLabels: string[],
    carid: string | undefined,
    removeCriteria: any,
    clearAll: any,
    activeEntryTitleQuery?: string
) => {
    let currentSelectedLabels: string[] = selectedLabels
        ? [...selectedLabels]
        : []
    let currentSelectedCategories: ITimelineEntryTypes[] = selectedCategories
        ? [...selectedCategories]
        : []

    const removeCategory = (criteria: ITimelineEntryTypes) => {
        let indexOfClickedCriteria: number =
            currentSelectedCategories.indexOf(criteria)

        if (indexOfClickedCriteria >= 0) {
            currentSelectedCategories.splice(indexOfClickedCriteria, 1)
        }

        if (currentSelectedCategories.length >= 0 && carid) {
            removeCriteria(
                currentSelectedCategories,
                selectedLabels,
                activeEntryTitleQuery
            )
        }
    }

    const removeLabels = (criteria: string) => {
        let indexOfClickedCriteria: number =
            currentSelectedLabels.indexOf(criteria)

        if (indexOfClickedCriteria >= 0) {
            currentSelectedLabels.splice(indexOfClickedCriteria, 1)
        }

        if (currentSelectedLabels.length >= 0 && carid) {
            removeCriteria(
                selectedCategories,
                currentSelectedLabels,
                activeEntryTitleQuery
            )
        }
    }

    const removeKeyword = () => {
        if (carid) {
            removeCriteria(selectedCategories, selectedLabels, undefined)
        }
    }

    return (
        <StickyDiv
            show={
                (selectedCategories && selectedCategories.length > 0) ||
                (selectedLabels && selectedLabels.length > 0) ||
                activeEntryTitleQuery
                    ? true
                    : false
            }
        >
            <Expander
                width="100%"
                height={
                    (selectedCategories && selectedCategories.length > 0) ||
                    (selectedLabels && selectedLabels.length > 0) ||
                    activeEntryTitleQuery
                        ? '100%'
                        : 0
                }
            >
                <Wrapper style={{ minHeight: 'auto' }}>
                    <SearchTagsWrapper>
                        {selectedCategories.length > 0 ? (
                            <CriteriaName>Categories</CriteriaName>
                        ) : null}
                        {selectedCategories.map(
                            (categoryName: ITimelineEntryTypes, i: number) => (
                                <div key={`${categoryName} - ${i}`}>
                                    <FadedSlower duration={0.2}>
                                        <CustomIconTagQuickFilterItem
                                            height="24px"
                                            id={categoryName}
                                            onCrossClick={() =>
                                                removeCategory(categoryName)
                                            }
                                        >
                                            {categoryName}
                                        </CustomIconTagQuickFilterItem>
                                    </FadedSlower>
                                </div>
                            )
                        )}
                        {selectedLabels.length > 0 ? (
                            <CriteriaName>Labels</CriteriaName>
                        ) : null}

                        {selectedLabels.map((label: string, i: number) => (
                            <div key={`${label} - ${i}`}>
                                <FadedSlower duration={0.2}>
                                    <CustomIconTagQuickFilterItem
                                        isActive={true}
                                        onCrossClick={() => removeLabels(label)}
                                        border={`1px solid ${colours[theme].border_muted}`}
                                        height="25px"
                                        bgcolor={
                                            colours[theme].background_default
                                        }
                                        isLabel
                                    >
                                        {label}
                                    </CustomIconTagQuickFilterItem>
                                </FadedSlower>
                            </div>
                        ))}

                        {activeEntryTitleQuery ? (
                            <CriteriaName>Keyword</CriteriaName>
                        ) : null}
                        <FadedSlower duration={0.2}>
                            {activeEntryTitleQuery ? (
                                <CustomIconTagQuickFilterItem
                                    isActive={true}
                                    onCrossClick={() => removeKeyword()}
                                    height="25px"
                                >
                                    {activeEntryTitleQuery}
                                </CustomIconTagQuickFilterItem>
                            ) : null}
                        </FadedSlower>
                    </SearchTagsWrapper>
                </Wrapper>
            </Expander>
        </StickyDiv>
    )
}

const entryPair = (
    data: ITimelineItem[],
    index: number,
    userCurrency: string | undefined,
    theme: ITheme,
    onClick?: any,
    onPlaceholderClick?: any,
    hasSampleData?: boolean,
    readOnlyMode?: boolean
) => (
    <GreyOverWrapper>
        <WrapperView>
            {data[index] ? (
                <TimelineItemWrapperLeft>
                    <TimelineItemDesktop
                        isFirst={index === 0}
                        lineOnRight
                        item={data[index]}
                        onClick={() => onClick(data[index].id)}
                        hasTootip={hasSampleData}
                        userCurrency={userCurrency}
                        hasSampleData={hasSampleData}
                    />
                </TimelineItemWrapperLeft>
            ) : null}

            {data[index] || data[index + 1] ? (
                <VerticalLine
                    $theme={theme}
                    $height={data[index + 1] ? '150%' : '100%'}
                    $fadeBoth={
                        data.length === 1 || data.length === 2 ? true : false
                    }
                    $fade={index === 0 ? true : false}
                    $fadeReverse={
                        index === data.length - 1 ||
                        index + 1 === data.length - 1
                            ? true
                            : false
                    }
                />
            ) : null}
            {data[index + 1] ? (
                <TimelineItemWrapperRight>
                    <TimelineItemDesktop
                        isFirst={index === 0}
                        lineOnLeft
                        item={data[index + 1]}
                        onClick={
                            hasSampleData
                                ? undefined
                                : () => onClick(data[index + 1].id)
                        }
                        hasTootip={hasSampleData}
                        userCurrency={userCurrency}
                        hasSampleData={hasSampleData}
                    />
                </TimelineItemWrapperRight>
            ) : null}
        </WrapperView>
    </GreyOverWrapper>
)

const Container = styled.div<IStyle>`
    width: 100%;
    box-sizing: border-box;
    .ReactVirtualized__Grid__innerScrollContainer {
        margin-bottom: 100px;
    }

    .ReactVirtualized__Grid__innerScrollContainer > div:last-child {
        ${(props) => props.$lastEvenChild && 'z-index: -1;'}
    }

    .noScrollbar {
        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */
        ::-webkit-scrollbar {
            display: none; /* for Chrome, Safari, and Opera */
        }
    }
`

const WrapperView = styled.div`
    position: relative;
    width: 100%;
    box-sizing: border-box;
    min-width: 100%;
    box-sizing: border-box;
    max-width: 1400px;
    padding: 24px 48px;
    display: grid;
    grid-template-columns: 1fr 0px 1fr;
    z-index: 1;

    @media ${device.smallest_laptops} {
        padding-left: 24px;
        padding-right: 24px;
    }
`

type IVerticalLineProps = {
    $height?: string
    $fade: boolean
    $fadeReverse: boolean
    $fadeBoth: boolean
    $theme: ITheme
}

const VerticalLine = styled.div<IVerticalLineProps>`
    position: relative;
    z-index: 4;
    width: 4px;
    background: ${(props) =>
        props.$fadeBoth
            ? `linear-gradient(rgba(26, 26, 26, 0.00) 0%, ${
                  colours[props.$theme].border_muted
              } 50%, rgba(26, 26, 26, 0.00) 100%)`
            : props.$fade
            ? `linear-gradient(rgba(26, 26, 26, 0.00) 0%, ${
                  colours[props.$theme].border_muted
              } 20%, ${colours[props.$theme].border_muted} 100%)`
            : props.$fadeReverse
            ? `linear-gradient(${colours[props.$theme].border_muted} 0%, ${
                  colours[props.$theme].border_muted
              } 80%, rgba(26, 26, 26, 0.00) 100%)`
            : colours[props.$theme].border_muted};
    height: ${(props) => props.$height};
    margin: 0 auto;
`

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    max-width: 1400px;
    min-height: 100vh;
    margin: 0 auto;
`

const GreyOverWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    /* min-height: 500px; */
    display: flex;
    justify-content: center;
`

const StickyDiv = styled.div<{ show: boolean }>`
    position: -webkit-sticky;
    position: sticky;
    width: 100%;
    box-sizing: border-box;
    height: ${(props) => (props.show ? 'auto' : '0px')};
    opacity: ${(props) => (props.show ? 1 : 0)};
    transition: all 200ms;
    top: 64px;
    z-index: 5;
    margin-bottom: ${(props) => (props.show ? '32px' : '0px')};
    background-color: var(--bg-color, #fff);
    display: flex;
    justify-content: center;
    border-top: 1px solid var(--border-muted, #e5e5e5);
    border-bottom: 1px solid var(--border-muted, #e5e5e5);
`

type IStyle = {
    containsPlaceholder?: boolean
    $lastEvenChild?: boolean
}

const TimelineItemWrapperLeft = styled.div<IStyle>`
    position: relative;
    max-width: 100%;
    box-sizing: border-box;
    min-width: 100%;
    box-sizing: border-box;
    transform: ${(props) =>
        props.containsPlaceholder ? 'translateY(100%)' : undefined};
    z-index: 5;
`

const TimelineItemWrapperRight = styled.div<IStyle>`
    position: relative;
    max-width: 100%;
    box-sizing: border-box;
    min-width: 100%;
    box-sizing: border-box;
    transform: ${(props) =>
        props.containsPlaceholder ? 'translate(0, 100%)' : 'translate(0, 50%)'};

    z-index: 5;
`

const SearchTagsWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    padding: 12px 0px;
    align-items: center;
    row-gap: 16px;
    column-gap: 8px;
    flex-wrap: wrap;
    max-width: 1400px;
    padding-left: 48px;
    padding-right: 48px;

    @media ${device.smallest_laptops} {
        padding-left: 24px;
        padding-right: 24px;
    }
`

const CriteriaName = styled.p`
    margin: 0px;
    padding: 0px;
    color: var(--text-muted, #b3b3b3);
    font-family: Lato-Semibold;
    font-size: 12px;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.84px;
    text-transform: uppercase;
    padding-left: 8px;
`

const TimelineViewManagerDesktop: React.FC<Props> = ({
    timelineItems,
    attachmentsObj,
    dataCyId,
    categories,
    tags,
    withPlaceholder,
    carid,
    selectedCategories,
    selectedLabels,
    onPlaceholderClick,
    removeCriteria,
    clearAll,
    hasSampleData,
    userCurrency,
    readOnlyMode,
    activeEntryTitleQuery,
}) => {
    let { theme } = useThemes()

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { carid: routeCarId } = useParams<{ carid: string }>()

    const technicalInformationData = useAppSelector(
        (state) => state.entities.technicalInformationData.technical_information
    )

    useEffect(() => {
        const tech_info_in_state =
            technicalInformationData &&
            technicalInformationData[`hf-${routeCarId}`]
        if (!tech_info_in_state && !readOnlyMode && routeCarId) {
            dispatch(carActions.getCarDataByIdRequest(routeCarId))
        }
    }, [routeCarId, readOnlyMode])

    const renderTimelineItems = (theme: ITheme) => {
        let data =
            timelineItems && timelineItems.length > 0 ? timelineItems : []

        let onClick = hasSampleData
            ? () => navigate(`/car/${carid}/history-file/create`)
            : (timelineItemId: string) => {
                  timelineItems &&
                      timelineItems?.length > 0 &&
                      navigate(
                          `${location.pathname}/entry?entryid=${timelineItemId}`
                      )
              }

        let currentSelectedCategories = selectedCategories
            ? selectedCategories
            : []
        let currentSelectedLabels = selectedLabels ? selectedLabels : []

        return (
            <Faded>
                {stickyActiveSelectedCriteria(
                    theme,
                    currentSelectedCategories,
                    currentSelectedLabels,
                    carid,
                    removeCriteria,
                    clearAll,
                    activeEntryTitleQuery
                )}
                <Wrapper>
                    {data.length > 0 ? (
                        <div style={{ minHeight: '100vh' }}>
                            {data.map((item, index) =>
                                index % 2 === 0 ? (
                                    <div key={`pair_${index}+${index + 1}`}>
                                        {entryPair(
                                            data,
                                            index,
                                            userCurrency,
                                            theme,
                                            onClick,
                                            onPlaceholderClick,
                                            hasSampleData,
                                            readOnlyMode
                                        )}
                                    </div>
                                ) : null
                            )}
                        </div>
                    ) : (
                        <NoFilterResults variant="desktop" />
                    )}
                </Wrapper>
            </Faded>
        )
    }

    return (
        <Container
            $lastEvenChild={
                timelineItems && timelineItems.length % 2 === 0 && !readOnlyMode
            }
        >
            {renderTimelineItems(theme)}
        </Container>
    )
}

export default TimelineViewManagerDesktop
