import { useEffect } from 'react'
import Faded from '../../templates/animated/faded'
import SectionHeader from '../../atoms/header/section/sectionHeader'
import Loader from '../../atoms/loader/loader'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import ContactFormDesktop from '../../organisms/editForms/contactForm/contactFormDesktop'
import ContactFormMobile from '../../organisms/editForms/contactForm/contactFormMobile'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { usersActions } from '../../../redux/user/reducer'

const ContactHelp = () => {
    const dispatch = useAppDispatch()

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    const contactReasonsData = useAppSelector(
        (state) => state.localdata.help_page_copy.selectedContactReason
    )

    useEffect(() => {
        dispatch(customNavDataActions.setInitialLandingUrl('/account/contact'))

        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        }
    }, [userLoggedIn])

    return (
        <CenteredPageWrapper hasMobileSubNav removeHorizPaddings>
            {userLoggedIn && (
                <Faded>
                    <Loader />
                    <DesktopDisplayOnly>
                        <div
                            style={{
                                paddingLeft: '50px',
                                paddingRight: '50px',
                            }}
                        >
                            <SectionHeader
                                title={'Contact'}
                                removeEdit={true}
                            />

                            <ContactFormDesktop
                                contactReasonsData={contactReasonsData}
                            />
                        </div>
                    </DesktopDisplayOnly>

                    <IpadAndMobileDisplay>
                        <ContactFormMobile
                            contactReasonsData={contactReasonsData}
                        />
                    </IpadAndMobileDisplay>
                </Faded>
            )}
        </CenteredPageWrapper>
    )
}

export default ContactHelp
