import * as React from 'react'
import styled from 'styled-components'
import TimelineCostItemDesktop from '../../atoms/timeline/costItem/timelineCostItemDesktop'
import { device } from '../../templates/displays/devices'
import TotalCost from '../../atoms/timeline/totalCost/totalCost'
import AttachmentInItemSinglePageDesktop from '../../atoms/timeline/attachmentInItemSinglePage/attachmentInItemSinglePageDesktop'
import Icon from '../../atoms/icons'
import { fromIsoStringToDDMMYYY } from '../../../redux/conversions/time'
import TimelineNotesDesktop from '../../atoms/typography/timelineNotesDesktop'
import SectionHeaderAttachment from '../../atoms/typography/sectionHeaderAttachment'
import LoaderElasticThreeDots from '../../atoms/loader/loaderElasticThreeDots'
import ButtonAtom from '../../atoms/Button/ButtonAtom'
import NoDataRectangle from '../../atoms/noData/noDataRectangle'
import TimelineEntryGalleryGrid from '../imageGrid/timelineEntryGalleryGrid'
import EntryAttachmentsFormWrapperDesktop from '../../organisms/editForms/attachmentsFormWrapper/entryAttachmentsFormWrapperDesktop'
import TimeAgoEntryTag from '../../atoms/tags/timeAgoEntryTag/timeAgoEntryTag'
import SectionHeaderV3 from '../../atoms/typography/sectionHeaderV3'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { useNavigate } from 'react-router-dom'
import {
    INormalisedCostItems,
    ITimelineItem,
} from '../../../redux/timeline/types'
import { INormalisedAttachmentsByID } from '../../../redux/attachments/types'
import { IisOpen } from '../../../redux/menus/types'
import { IExternalCar } from '../../../redux/entities/cars/types'
import { IDeleteEntryAttachmentPayloadReq } from '../../../redux/services/types'
import { timelineActions } from '../../../redux/timeline/reducer'

interface IStyled {
    someProp?: any
}

const Container = styled.div<IStyled>`
    width: calc(100% - 296px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 48px;
    padding-right: 48px;

    @media ${device.smallest_laptops} {
        padding-left: 24px;
        padding-right: 24px;
    }
`

const TitleWrapper = styled.div<IStyled>`
    display: none;
    @media ${device.ipad} {
        display: block;
        max-width: 450px;
    }
`

const Col = styled.div<IStyled>`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    /* padding-top: 2px;
    padding-bottom: 2px; */
    padding-bottom: 40px;

    @media ${device.ipad} {
        padding-bottom: 30px;
    }
`

const SimpleCol = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 6px;
`

const NameTxt = styled.div`
    color: var(--text-muted);
    font-family: Lato;
    font-size: 14px;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.001px;
`

const AnswerTxt = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.001px;
`

const CostItemsSection = styled.div<IStyled>`
    width: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 16px;

    @media ${device.ipad} {
        grid-template-columns: 1fr;
    }
`

const InsuranceItemsSection = styled.div<IStyled>`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 16px;

    @media (max-width: 1280px) {
        flex-direction: column;
    }
`

const TotalCostWrapper = styled.div<IStyled>`
    width: 30%;
    max-width: 300px;
`

const AttachmentWrapper = styled.div<IStyled>`
    width: 100%;
    box-sizing: border-box;
    z-index: 2;
`

const InsurerBox = styled.div`
    display: flex;
    padding: 16px;
    align-items: flex-start;
    gap: 16px;
    flex-direction: row;
    border-radius: 8px;
    border: 1px solid var(--border-muted, #e5e5e5);
    width: 300px;
    position: relative;

    @media (max-width: 1280px) {
        width: 100%;
        box-sizing: border-box;
        max-width: 420px;
    }

    @media (max-width: 980px) {
        width: 100%;
        box-sizing: border-box;
        max-width: 350px;
    }
`

const IconWrapper = styled.div`
    display: flex;
    width: 56px;
    height: 56px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: var(--bg-color, #fff);
    box-shadow: 0px 4px 8px 0px var(--box-shadow-stronger);
`

const DateText = styled.div<IStyled>`
    color: var(--text-strong, #1a1a1a);
    font-family: 'Lato-semibold';
    font-size: 18px;
    letter-spacing: 0.7px;
    display: flex;
    padding-bottom: 5px;
`

const Description = styled.div<IStyled>`
    font-family: 'Lato';
    font-size: 16px;
    line-height: 24px;
    overflow-wrap: break-word;
    white-space: pre-line;
    color: var(--text-default, #666666);

    @media ${device.ipad} {
        width: 70%;
        max-width: 450px;
    }
`

const NotesText = styled.div<IStyled>`
    font-family: 'Lato';
    font-size: 16px;
    line-height: 24px;
    color: var(--text-default, #666666);
`

const SmallPadding = styled.div<IStyled>`
    padding-top: 10px;
`

const NoContentWrapper = styled.div<IStyled>`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: -7px;
`

const NoAttachmentWrapper = styled.div<IStyled>`
    display: flex;
    margin-left: -15px;
`
const Row = styled.div<IStyled>`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    /* padding-top: 2px;
    padding-bottom: 2px; */
    padding-bottom: 40px;

    @media ${device.ipad} {
        padding-bottom: 30px;
    }
`

interface Props {
    item: ITimelineItem
    dataCyId?: string
    attachmentsObj: INormalisedAttachmentsByID
    costsObj: INormalisedCostItems | undefined
    addNewCost?: any
    onEditShortDescription?: any
    onEditInsurer?: () => any
    onEditNotes?: any
    onEditTitleClick?: any
    onCostClick?: any
    setFileUploaded?: any
    // setFileUploaded: any
    entityID: string
    carID: string
    entityType: 'entry' | 'cost'
    isEntryAttachmentBeingUploaded?: boolean
    isCostAttachmentBeingUploaded: boolean
    userCurrency: string | undefined
    readOnlyMode?: boolean
    toggleAddGalleryImageToEntryForm: (p: IisOpen) => void
    sharedWithYouCar?: IExternalCar
}

export default function SingleTimelineItemBodyDesktop(props: Props) {
    const {
        item,
        dataCyId,
        attachmentsObj,
        costsObj,
        addNewCost,
        onEditShortDescription,
        onEditInsurer,
        onEditNotes,
        onEditTitleClick,
        onCostClick,
        // setFileUploaded,
        entityID,
        carID,
        entityType,
        isEntryAttachmentBeingUploaded,
        isCostAttachmentBeingUploaded,
        userCurrency,
        readOnlyMode,
        toggleAddGalleryImageToEntryForm,
        sharedWithYouCar,
    } = props

    let dispatch = useAppDispatch()

    let entry_has_no_attachments: boolean =
        !item.attachmentIDs || item.attachmentIDs.length === 0 ? true : false

    let shared_with_you_car_policies =
        props.readOnlyMode &&
        sharedWithYouCar &&
        sharedWithYouCar.policy_statements
            ? sharedWithYouCar.policy_statements
            : undefined

    let is_cost_info_restricted =
        shared_with_you_car_policies &&
        !shared_with_you_car_policies.includes('entry costs')

    let readOnlylinkPrefix = sharedWithYouCar ? '/shared' : '/archives'

    const imagesData = useAppSelector(
        (state) => state.entities.galleriesData.images
    )

    const navigate = useNavigate()

    return (
        <Container data-attr={dataCyId && dataCyId}>
            <TitleWrapper>
                <Col>
                    <SectionHeaderV3
                        fontsize="25px"
                        icon="edit_pen_naked"
                        onClick={onEditTitleClick}
                        hasActionIcon={!readOnlyMode}
                    >
                        {item.title}
                    </SectionHeaderV3>
                </Col>
            </TitleWrapper>
            <Col>
                <DateText>
                    {item.date && fromIsoStringToDDMMYYY(item.date)}
                    {!item.date && 'no date'}
                    <TimeAgoEntryTag
                        device="mobile"
                        height="24px"
                        entry_date={item.date}
                    />
                </DateText>
                <SmallPadding />

                <Description>
                    {item.description ? (
                        <>
                            {item.description}
                            {!readOnlyMode && (
                                <span
                                    style={{
                                        padding: 12,
                                        cursor: 'pointer',
                                    }}
                                    onClick={onEditShortDescription}
                                >
                                    <Icon icon="edit_pen_naked" width="16" />
                                </span>
                            )}
                        </>
                    ) : !readOnlyMode ? (
                        <NoContentWrapper onClick={onEditShortDescription}>
                            <ButtonAtom theme="naked-text" fontSize={16}>
                                Add a short description
                            </ButtonAtom>
                            <div
                                style={{
                                    cursor: 'pointer',
                                }}
                            >
                                <Icon icon="add_strong" />
                            </div>
                        </NoContentWrapper>
                    ) : (
                        <NoDataRectangle height="100px" text="no description" />
                    )}
                </Description>
            </Col>

            {item.categoryID === 'insurance' && (
                <Col>
                    <SectionHeaderV3
                        isbold
                        icon={
                            item.insurance_entry_broker ||
                            item.insurance_entry_underwriter
                                ? 'edit_pen_naked'
                                : undefined
                        }
                        onClick={() => {
                            !readOnlyMode && onEditInsurer && onEditInsurer()
                        }}
                        hasActionIcon={readOnlyMode ? false : true}
                    >
                        Insurer
                    </SectionHeaderV3>

                    <div style={{ paddingTop: '8px' }} />

                    {item.insurance_entry_broker ||
                    item.insurance_entry_underwriter ? (
                        <InsuranceItemsSection>
                            {item.insurance_entry_broker && (
                                <InsurerBox>
                                    <IconWrapper>
                                        <Icon icon="broker" />
                                    </IconWrapper>

                                    <SimpleCol>
                                        <NameTxt>Broker</NameTxt>
                                        <AnswerTxt>
                                            {item.insurance_entry_broker}
                                        </AnswerTxt>
                                    </SimpleCol>
                                </InsurerBox>
                            )}

                            {item.insurance_entry_underwriter && (
                                <InsurerBox>
                                    <IconWrapper>
                                        <Icon icon="underwriter" />
                                    </IconWrapper>

                                    <SimpleCol>
                                        <NameTxt>Insurer</NameTxt>
                                        <AnswerTxt>
                                            {item.insurance_entry_underwriter}
                                        </AnswerTxt>
                                    </SimpleCol>
                                </InsurerBox>
                            )}
                        </InsuranceItemsSection>
                    ) : (
                        <NoContentWrapper
                            onClick={() => {
                                !readOnlyMode &&
                                    onEditInsurer &&
                                    onEditInsurer()
                            }}
                        >
                            {!readOnlyMode ? (
                                <>
                                    <ButtonAtom
                                        theme="naked-text"
                                        fontSize={16}
                                    >
                                        Add insurer
                                    </ButtonAtom>
                                    <div
                                        style={{
                                            paddingRight: '5px',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <Icon icon="add_strong" />{' '}
                                    </div>
                                </>
                            ) : (
                                <Description>
                                    <NoDataRectangle
                                        text="no insurer data"
                                        height="100px"
                                    />
                                </Description>
                            )}
                        </NoContentWrapper>
                    )}
                </Col>
            )}

            <Col>
                <SectionHeaderV3
                    isbold
                    icon="add_strong"
                    onClick={addNewCost}
                    hasActionIcon={
                        !readOnlyMode &&
                        item.costUIDS &&
                        item.costUIDS.length > 0
                            ? true
                            : false
                    }
                >
                    Costs
                </SectionHeaderV3>

                <CostItemsSection>
                    {item.costUIDS && item.costUIDS.length > 0 && costsObj ? (
                        item.costUIDS.map((costID: string, index: number) => {
                            if (costsObj[costID] !== undefined) {
                                let cost = costsObj[costID]
                                return (
                                    <React.Fragment key={`cost_entry_${index}`}>
                                        <TimelineCostItemDesktop
                                            costItem={cost}
                                            unit={cost.currency}
                                            key={index}
                                            attachment={
                                                cost.attachmentID
                                                    ? attachmentsObj[
                                                          cost.attachmentID
                                                      ]
                                                    : undefined
                                            }
                                            // attachmentsObj={attachmentsObj}
                                            // file={attachmentsObj[item]}
                                            dataCyId={`edit-${cost.id}`}
                                            onClick={() =>
                                                !readOnlyMode && onCostClick
                                                    ? onCostClick(cost.id)
                                                    : {}
                                            }
                                            isCostAttachmentBeingUploaded={
                                                isCostAttachmentBeingUploaded
                                            }
                                            readOnlyMode={readOnlyMode}
                                            isCostAttachmentRestricted={
                                                readOnlyMode &&
                                                shared_with_you_car_policies &&
                                                !shared_with_you_car_policies.includes(
                                                    'entry cost attachments'
                                                )
                                            }
                                        />
                                    </React.Fragment>
                                )
                            } else return <div style={{ display: 'none' }} />
                        })
                    ) : !readOnlyMode ? (
                        <>
                            <NoContentWrapper onClick={addNewCost}>
                                <ButtonAtom theme="naked-text" fontSize={16}>
                                    Add any invoices or costs
                                </ButtonAtom>
                                <div
                                    style={{
                                        paddingRight: '5px',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <Icon icon="add_strong" />
                                </div>
                            </NoContentWrapper>
                            <div style={{ paddingTop: '30px' }} />
                        </>
                    ) : (
                        <NoDataRectangle
                            height="100px"
                            text="no costs"
                            isInfoRestricted={is_cost_info_restricted}
                        />
                    )}
                </CostItemsSection>
                <div style={{ paddingTop: '20px' }} />
                {!is_cost_info_restricted && (
                    <TotalCostWrapper>
                        <TotalCost
                            amount={
                                item.total_cost_amount_display
                                    ? item.total_cost_amount_display
                                    : '0.00'
                            }
                            unit={userCurrency ? userCurrency : 'GBP'}
                        />
                    </TotalCostWrapper>
                )}
            </Col>
            <Col>
                <SectionHeaderV3
                    isbold
                    icon="edit_pen_naked"
                    onClick={onEditNotes}
                    hasActionIcon={item.notes && !readOnlyMode ? true : false}
                >
                    Notepad
                </SectionHeaderV3>

                {item.notes && item.notes !== '<p><br></p>' ? (
                    <NotesText>
                        <TimelineNotesDesktop notes={item.notes} />
                    </NotesText>
                ) : !readOnlyMode ? (
                    <NoContentWrapper onClick={onEditNotes}>
                        <ButtonAtom theme="naked-text" fontSize={16}>
                            Add any notes or comments
                        </ButtonAtom>
                        <div
                            style={{
                                paddingRight: '5px',
                                cursor: 'pointer',
                            }}
                        >
                            <Icon icon="add_strong" />{' '}
                        </div>
                    </NoContentWrapper>
                ) : (
                    <NoDataRectangle height="100px" text="no notes" />
                )}
            </Col>
            <Row>
                <SectionHeaderV3
                    isbold
                    icon="add_strong"
                    onClick={() =>
                        !readOnlyMode &&
                        toggleAddGalleryImageToEntryForm({ isOpen: true })
                    }
                    hasActionIcon={
                        readOnlyMode
                            ? false
                            : imagesData &&
                              item &&
                              item.gallery_image_ids &&
                              item.gallery_image_ids.length > 0
                            ? true
                            : false
                    }
                >
                    Gallery
                </SectionHeaderV3>

                {imagesData &&
                item &&
                item.gallery_image_ids &&
                item.gallery_image_ids.length > 0 ? (
                    <TimelineEntryGalleryGrid
                        images_data_object={imagesData}
                        ids_list={item.gallery_image_ids}
                        onImageClick={(imageid: string) =>
                            readOnlyMode
                                ? navigate(
                                      `${readOnlylinkPrefix}/car/${carID}/history-file/${entityID}/gallery?id=${imageid}`
                                  )
                                : navigate(
                                      `/car/${carID}/history-file/${entityID}/gallery?id=${imageid}`
                                  )
                        }
                        onPlaceholderClick={() =>
                            readOnlyMode
                                ? navigate(
                                      `${readOnlylinkPrefix}/car/${carID}/history-file/${entityID}/gallery`
                                  )
                                : navigate(
                                      `/car/${carID}/history-file/${entityID}/gallery`
                                  )
                        }
                    />
                ) : (
                    <NoContentWrapper
                        onClick={() =>
                            !readOnlyMode &&
                            toggleAddGalleryImageToEntryForm({
                                isOpen: true,
                            })
                        }
                    >
                        {!readOnlyMode ? (
                            <>
                                <ButtonAtom theme="naked-text" fontSize={16}>
                                    Add images to this entry
                                </ButtonAtom>
                                <div
                                    style={{
                                        paddingRight: '5px',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <Icon icon="add_strong" />{' '}
                                </div>
                            </>
                        ) : (
                            <NoDataRectangle text="no images" height="100px" />
                        )}
                    </NoContentWrapper>
                )}
            </Row>

            <Col>
                <SectionHeaderAttachment
                    isbold
                    icon="add_strong"
                    id="attachments-section"
                    // setFileUploaded={setFileUploaded}
                    entityID={entityID}
                    carID={carID}
                    entityType={entityType}
                    noActionIcon={
                        !readOnlyMode &&
                        item.attachmentIDs &&
                        item.attachmentIDs.length > 0
                            ? false
                            : true
                    }
                >
                    Attachments
                </SectionHeaderAttachment>

                {isEntryAttachmentBeingUploaded && (
                    <AttachmentWrapper>
                        <LoaderElasticThreeDots />
                        {!item.attachmentIDs ||
                            (item.attachmentIDs.length === 0 && (
                                <div style={{ paddingBottom: '50px' }} />
                            ))}
                        <div style={{ paddingBottom: '24px' }} />
                    </AttachmentWrapper>
                )}
                {item.attachmentIDs && item.attachmentIDs.length > 0
                    ? item.attachmentIDs.map((atID: string, index: number) => {
                          if (attachmentsObj[atID]) {
                              return (
                                  <AttachmentWrapper
                                      key={`attachment_entry_${index}`}
                                  >
                                      <AttachmentInItemSinglePageDesktop
                                          key={index}
                                          id={index}
                                          attachment={attachmentsObj[atID]}
                                          // file={attachmentsObj[item]}
                                          downloadAttachment={() => {}}
                                          onClickDelete={() => {
                                              let p: IDeleteEntryAttachmentPayloadReq =
                                                  {
                                                      attachmentID:
                                                          attachmentsObj[atID]
                                                              .id,
                                                      carID: carID,
                                                      entryID: entityID,
                                                  }
                                              dispatch(
                                                  timelineActions.deleteEntryAttachmentRequest(
                                                      p
                                                  )
                                              )
                                          }}
                                          readOnlyMode={readOnlyMode}
                                      />
                                  </AttachmentWrapper>
                              )
                          } else return null
                      })
                    : !readOnlyMode
                    ? !isEntryAttachmentBeingUploaded && (
                          <NoAttachmentWrapper>
                              <SectionHeaderAttachment
                                  isbold
                                  icon="add_strong"
                                  id="attachments-section"
                                  entityID={entityID}
                                  carID={carID}
                                  entityType={entityType}
                                  uploadChildren={
                                      <ButtonAtom
                                          theme="naked-text"
                                          fontSize={16}
                                      >
                                          Upload any documents or files
                                      </ButtonAtom>
                                  }
                              />
                          </NoAttachmentWrapper>
                      )
                    : null}

                {item.attachmentIDs && item.attachmentIDs.length > 0 && (
                    <div style={{ paddingTop: '16px' }} />
                )}

                {!readOnlyMode && (
                    <EntryAttachmentsFormWrapperDesktop
                        carid={props.carID}
                        entityID={entityID}
                    />
                )}
                {entry_has_no_attachments && readOnlyMode && (
                    <NoDataRectangle
                        text="no attachments"
                        height="100px"
                        isInfoRestricted={
                            shared_with_you_car_policies &&
                            !shared_with_you_car_policies.includes(
                                'entry attachments'
                            )
                        }
                    />
                )}
            </Col>

            <div style={{ paddingTop: '50px' }} />
        </Container>
    )
}
